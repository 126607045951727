import get from "lodash/get";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { Badge } from "triangular/components/Badge/Badge";
import { NotSet } from "triangular/components/NotSet/NotSet";
import { FetchedSystem } from "triangular/stores/SystemsStore/SystemsStore";
import { translateIfExists } from "triangular/utils/components";

import { Property } from "./Property/Property";
import { Section } from "./Section";
import css from "./SystemDetails.module.scss";

interface SystemDetailsSecondPartProps {
  system: FetchedSystem;
}

export const SystemDetailsSecondPart: React.FC<SystemDetailsSecondPartProps> = observer(({ system }) => {
  const { t, i18n } = useTranslation();
  const installedPowerKw: string | undefined = get(system, "installedPower.kw");
  const materialCategoryNames = (system.materialCategories || []).map(eachCategory => eachCategory.name);

  return (
    <Section>
      <div className={css.details}>
        {system.minimumMaterialAmount && system.minimumMaterialAmount !== "no" && (
          <Property
            title={t("systemDetails.minimumMaterialAmount")}
            value={`${system.minimumMaterialAmount.replace(".", ",")} kg`}
          />
        )}

        <Property
          title={t("systemDetails.possibleMaterialColor")}
          value={
            system.colorsWhitelist && system.colorsWhitelist.length
              ? system.colorsWhitelist.map((key: string) => (
                  <Badge key={key} margins={true} variant="greyishBrown">
                    {translateIfExists({ i18n, fallback: key, path: `systemSettings.colorOptions.${key}` })}
                  </Badge>
                ))
              : null
          }
        />

        <Property
          title={t("materialCategories")}
          scrollList={true}
          value={
            materialCategoryNames && materialCategoryNames.length
              ? materialCategoryNames.map((eachName, i) => (
                  <Badge key={i} margins={true} variant="greyishBrown">
                    {t(`systemSettings.materialCategoryOptions.${eachName}`)}
                  </Badge>
                ))
              : null
          }
        />

        <Property
          title={t("systemDetails.possibleProductsAbrasive")}
          value={
            system.abrasiveness === "no" || system.abrasiveness === null || system.abrasiveness === ""
              ? system.abrasiveness
              : t(`systemDetails.possibleProductsAbrasiveValue`, { value: system.abrasiveness })
          }
        />

        <Property
          title={t("systemDetails.dustExplosiveProductsPossible")}
          value={
            system.explosiveness === "no" || system.explosiveness === null || system.explosiveness === ""
              ? system.explosiveness
              : t(`systemDetails.dustExplosiveProductsPossibleValue`, {
                  value: t(`systemSettings.explosivenessOptions.${system.explosiveness}`)
                })
          }
        />

        <Property
          title={t("systemDetails.possibleProductsCorrosive")}
          value={
            system.corrosiveness
              ? t(`systemDetails.possibleProductsCorrosiveValue`, { value: system.corrosiveness })
              : null
          }
        />

        <Property
          title={t("systemDetails.hazardousProductsPossible")}
          translationPath="materialSettings.hazardousnessOptions"
          value={system.hazardousness}
        />

        {system.installedPowerMill && (
          <Property
            title={t("systemDetails.installedPowerMill")}
            value={
              system.installedPowerMill["m3/h"] && system.installedPowerMill.bar ? (
                <>
                  {system.installedPowerMill["m3/h"].replace(".", ",")} m<sup>3</sup>/h @{" "}
                  {system.installedPowerMill.bar.replace(".", ",")} bar
                </>
              ) : (
                <NotSet />
              )
            }
          />
        )}

        {system.installedPowerClassifierKw && (
          <Property
            title={t("systemDetails.installedPowerClassifier")}
            value={`${system.installedPowerClassifierKw.replace(".", ",")} kW`}
          />
        )}

        {installedPowerKw && (
          <Property title={t("installedPower")} value={`${installedPowerKw.replace(".", ",")} kW`} />
        )}

        {system.airflow && system.airflow !== "no" && (
          <Property
            title={t("systemDetails.airflow")}
            value={
              <>
                {system.airflow.replace(".", ",")} m<sup>3</sup>/h
              </>
            }
          />
        )}

        <Property title={t("brand")} value={system.brand === "" || system.brand === null ? <NotSet /> : system.brand} />

        <Property title={t("model")} value={system.model === "" || system.model === null ? <NotSet /> : system.model} />
      </div>
    </Section>
  );
});
