import classNames from "classnames";
import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Breakpoint } from "triangular/components/Breakpoint/Breakpoint";
import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { countriesSelectOptions } from "triangular/consts";

import { DropDownHeader } from "../../DropDownHeader/DropDownHeader";
import { createBooleanOptions, SelectField } from "../../SelectField/SelectField";
import { TextField } from "../../TextField";

import css from "./AdditionalInfo.module.scss";
import { FlowingPropertiesField } from "./FlowingPropertiesField/FlowingPropertiesField";
import { ParticleSizeField } from "./ParticleSizeField/ParticleSizeField";
import { StorageNeededField } from "./StorageNeededField/StorageNeededField";

export const DesiredParticleSizeField = () => {
  const { t } = useTranslation();
  const currentT = (path: string) => t(`materialForm.additionalMaterialInfo.${path}`);

  return (
    <ParticleSizeField
      name="desiredParticleSize"
      label={currentT("desiredParticleSize.label")}
      unit={currentT("desiredParticleSize.unit")}
      data-testid="desiredParticleSize"
    />
  );
};

export const AdditionalInfo = React.memo(() => {
  const { t } = useTranslation();
  const currentT = (path: string) => t(`materialForm.additionalMaterialInfo.${path}`);

  const indexNum = "4";
  const [activeKey, setActiveKey] = useState(indexNum);

  const flowingPropertiesField = <FlowingPropertiesField label={currentT("flowingProperties")} />;
  const feedParticleSizeField = (
    <ParticleSizeField
      name="feedParticleSize"
      label={currentT("feedParticleSize.label")}
      unit={currentT("feedParticleSize.unit")}
      data-testid="feedParticleSize"
    />
  );
  const storageNeededField = <StorageNeededField label={currentT("storageNeeded")} />;

  return (
    <Accordion>
      <FormSection>
        <DropDownHeader
          eventKeyNum={indexNum}
          handleClick={() => {
            if (activeKey === indexNum) {
              setActiveKey("");
            } else {
              setActiveKey(indexNum);
            }
          }}
        >
          {t("materialForm.additionalMaterialInfo.additionalMaterialInfo")}
        </DropDownHeader>
        <Accordion.Collapse eventKey={indexNum}>
          <>
            <FieldsRow>
              <FieldWrapper>
                <TextField
                  name="processTemperature"
                  label={currentT("processTemperature")}
                  data-testid="processTemperature"
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectField
                  name="hygroscopic"
                  label={currentT("hygroscopic")}
                  selectProps={{ options: createBooleanOptions(t) }}
                  data-testid="hygroscopic"
                />
              </FieldWrapper>
              <FieldWrapper>
                <SelectField
                  name="countryCode"
                  label={currentT("location")}
                  selectProps={{
                    options: countriesSelectOptions,
                    autoComplete: true
                  }}
                  data-testid="location"
                />
              </FieldWrapper>
              <FieldWrapper>
                <TextField name="density" label={currentT("density")} data-testid="density" />
              </FieldWrapper>
              <FieldWrapper>
                <TextField name="bulkDensity" label={currentT("bulkDensity")} data-testid="bulkDensity" />
              </FieldWrapper>
              <FieldWrapper>
                <TextField name="humidity" label={currentT("humidity")} data-testid="humidity" />
              </FieldWrapper>
            </FieldsRow>
            <Breakpoint minWidth="small" maxWidth="medium">
              <FieldsRow>
                <FieldWrapper>{flowingPropertiesField}</FieldWrapper>
                <FieldWrapper>{storageNeededField}</FieldWrapper>
                <FieldWrapper>
                  <div className={css.particleSizeWrapper}>{feedParticleSizeField}</div>
                </FieldWrapper>
                <FieldWrapper>
                  <div className={css.particleSizeWrapper}>
                    <DesiredParticleSizeField />
                  </div>
                </FieldWrapper>
              </FieldsRow>
            </Breakpoint>
            <Breakpoint minWidth="large" maxWidth="large">
              <FieldsRow>
                <div className={classNames("col-sm-12 col-md-6 col-lg-4", css.leftToParticleSizeLarge)}>
                  <FieldWrapper className={css.secondRowOfLeftToParticleSizeLarge}>
                    {flowingPropertiesField}
                  </FieldWrapper>
                  <FieldWrapper className={css.secondRowOfLeftToParticleSizeLarge}>{storageNeededField}</FieldWrapper>
                </div>
                <FieldWrapper className="col-sm-6 col-lg-4">{feedParticleSizeField}</FieldWrapper>
                <FieldWrapper className="col-sm-6 col-lg-4">
                  <DesiredParticleSizeField />
                </FieldWrapper>
              </FieldsRow>
            </Breakpoint>
          </>
        </Accordion.Collapse>
      </FormSection>
    </Accordion>
  );
});
