import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { CompanyNameLabel } from "triangular/components/CompanyNameLabel/CompanyNameLabel";
import { DefaultFormButtons } from "triangular/components/form/DefaultFormButtons";
import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { Form } from "triangular/components/form/Form";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { TextField } from "triangular/components/form/TextField";
import { PageLoader } from "triangular/components/PageLoader/PageLoader";
import { countriesSelectOptions } from "triangular/consts";
import { TaxIdField } from "triangular/scenes/Registration/CompanyInfo/CompanyInfoForm/CompanyInfoForm";

import { SectionHeader } from "../ExpertForm/SectionHeader/SectionHeader";

import { useOwnerForm } from "./useOwnerForm";

export const OwnerForm = observer(() => {
  const { t } = useTranslation();
  const { schema, initialValues, loadingState, handleSubmit } = useOwnerForm();

  if (loadingState !== "done") {
    return <PageLoader loadingState={loadingState} />;
  }

  return (
    <Form
      initialValues={initialValues}
      validationSchema={schema}
      render={({ values }) => {
        const { countryCode } = values;
        return (
          <>
            <FormSection>
              <SectionHeader>{t("editProfile.sceneTitle")}</SectionHeader>
              <FieldsRow className="mt-3">
                <FieldWrapper>
                  <TextField
                    label={t("registration.personalInfo.firstName")}
                    name="firstName"
                    data-testid="firstName"
                    required={true}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label={t("registration.personalInfo.lastName")}
                    name="lastName"
                    data-testid="lastName"
                    required={true}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField label={t("email")} name="email" data-testid="email" disabled={true} />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label={t("registration.personalInfo.phoneNumber")}
                    name="phoneNumber"
                    data-testid="phoneNumber"
                    required={true}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <SelectField
                    name="countryCode"
                    label={t("registration.personalInfo.country")}
                    selectProps={{
                      options: countriesSelectOptions,
                      autoComplete: true
                    }}
                    required={true}
                    data-testid="countryCode"
                    disabled={true}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    label={<CompanyNameLabel />}
                    name="companyName"
                    data-testid="companyName"
                    required={true}
                    disabled={true}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TaxIdField t={t} name="companyIdentificationNumber" countryCode={countryCode} disabled={true} />
                </FieldWrapper>
              </FieldsRow>
              <DefaultFormButtons
                confirmPromptTitle={t("editProfile.submitConfirmation.header")}
                confirmPromptMessage={t("editProfile.submitConfirmation.message")}
              />
            </FormSection>
          </>
        );
      }}
      onSubmit={handleSubmit}
    />
  );
});
