import { types } from "mobx-state-tree";

import { createPagination } from "../utils/createPagination";
import { createStore } from "../utils/createStore";

import { materialModel } from "./materialModel";

export const MyMaterialsStore = createStore(storeDeps =>
  types
    .model("MyMaterialStore", {
      pagination: createPagination({
        storeDeps,
        model: materialModel,
        onChange: async params => {
          const response = await storeDeps.api.material.find(
            {
              pagination: params.pagination,
              include: {
                processCategory: true,
                systemCategory: true,
                systemType: true,
                designType: true
              }
            },
            params.config
          );
          const data = response.data;
          const meta = (response.meta || {}) as { recordCount?: number; pageCount?: number };
          return {
            totalItems: meta.recordCount || 0,
            items: data,
            querySearch: {
              pagination: params.pagination
            }
          };
        },
        loading: false
      })
    })
    .actions(self => ({
      async fetchMyMaterials({ pushHistory = false, changeUrl }: { pushHistory?: boolean; changeUrl?: boolean } = {}) {
        const query = self.pagination.getQueryParams();
        self.pagination.setState(query.pagination);
        return await self.pagination.refresh({ pushHistory, changeUrl });
      },
      async deleteMyMaterial(id: string) {
        return await storeDeps.api.materialOwnerMaterial.delete(id);
      }
    }))
);

export type MyMaterialsStoreTypeModel = ReturnType<typeof createStore>;

export const myMaterialsStoreInitialState = {
  pagination: {
    size: 9,
    number: 1,
    totalItems: 0,
    pages: {},
    loading: false
  }
};
