import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";

import { DropDownHeader } from "../../DropDownHeader/DropDownHeader";
import { FormSection } from "../../FormSection/FormSection";
import { DesignTypeField } from "../../system/DesignTypeField";
import { SystemTypeField } from "../../system/SystemTypeField";

import { MaterialCategoryField } from "./MaterialCategoryField";

export const Technology = React.memo(() => {
  const { t } = useTranslation();
  const currentT = (path: string) => t(`materialForm.technology.${path}`);

  const indexNum = "1";
  const [activeKey, setActiveKey] = useState(indexNum);

  return (
    <Accordion>
      <FormSection>
        <DropDownHeader
          eventKeyNum={indexNum}
          handleClick={() => {
            if (activeKey === indexNum) {
              setActiveKey("");
            } else {
              setActiveKey(indexNum);
            }
          }}
        >
          {t("materialForm.technology.technology")}
        </DropDownHeader>
        <Accordion.Collapse eventKey={indexNum}>
          <FieldsRow>
            <FieldWrapper>
              <MaterialCategoryField />
            </FieldWrapper>
            <FieldWrapper>
              <SystemTypeField label={currentT("systemType")} />
            </FieldWrapper>
            <FieldWrapper>
              <DesignTypeField label={currentT("designNeeded")} isMultiSelect={false} />
            </FieldWrapper>
          </FieldsRow>
        </Accordion.Collapse>
      </FormSection>
    </Accordion>
  );
});
