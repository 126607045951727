import { AxiosInstance } from "axios";

import { Resource } from "../Resource/Resource";
import { Entity, EntityAttributes, EntityRelationships } from "../Resource/utils/types";

import { SubscriptionEntity } from "./SubscriptionResource";

export interface AccountAttributes extends EntityAttributes {
  currentProfileType: ProfileType;
  currentProfileId: string;
  accountType: AccountType;
  email: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  phoneNumber: string;
  CreatedAt: string;
  company?: {
    name: string;
    countryCode: string;
    number: string;
  } | null;
}
interface AccountRelationships extends EntityRelationships {
  subscription: SubscriptionEntity;
}

type AccountEntity = Entity<AccountAttributes, AccountRelationships>;

export class AccountResource extends Resource<AccountEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "accounts",
      prefixes: ["auth"],
      relationships: {
        subscription: {
          type: "subscriptions"
        }
      }
    });
  }
}
