import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { Badge } from "triangular/components/Badge/Badge";
import { NotSet } from "triangular/components/NotSet/NotSet";
import { Typography } from "triangular/components/Typography/Typography";
import { FetchedSystem } from "triangular/stores/SystemsStore/SystemsStore";
import { translateIfExists } from "triangular/utils/components";

import { Property } from "./Property/Property";
import { Section } from "./Section";
import css from "./SystemDetails.module.scss";

interface SystemDetailsFirstPartProps {
  system: FetchedSystem;
}

export const SystemDetailsFirstPart: React.FC<SystemDetailsFirstPartProps> = observer(({ system }) => {
  const { t, i18n } = useTranslation();
  return (
    <Section>
      <div className={css.constructions}>
        <Typography component="h3" bold={true} size="big" upperCase={true} color="mint">
          {t("systemDetails.materialOfConstruction")}
        </Typography>
        <Property
          title={t("systemDetails.contactParts")}
          value={
            system.contactMaterials && system.contactMaterials.length ? (
              system.contactMaterials.map((key: string) => (
                <Badge key={key} margins={true} variant="greyishBrown">
                  {t(`systemSettings.constructionMaterialOptions.${key}`)}
                </Badge>
              ))
            ) : (
              <NotSet />
            )
          }
        />
        <Property
          title={t("systemDetails.nonContactParts")}
          value={
            system.nonContactMaterials && system.nonContactMaterials.length ? (
              system.nonContactMaterials.map((key: string) => (
                <Badge key={key} margins={true} variant="greyishBrown">
                  {t(`systemSettings.constructionMaterialOptions.${key}`)}
                </Badge>
              ))
            ) : (
              <NotSet />
            )
          }
        />
      </div>
      <div className={css.materials}>
        <Typography component="h3" bold={true} size="big" upperCase={true} color="mint">
          {t("materials")}
        </Typography>
        <Property
          title={t("systemDetails.possible")}
          scrollList={true}
          value={
            system.materialsWhitelist && system.materialsWhitelist.length ? (
              system.materialsWhitelist.map((key: string) => {
                return (
                  <Badge key={key} margins={true} variant="darkMint">
                    {translateIfExists({ path: `systemSettings.materialOptions.${key}`, i18n, fallback: key })}
                  </Badge>
                );
              })
            ) : (
              <NotSet />
            )
          }
        />
        <Property
          title={t("systemDetails.noGo")}
          value={
            system.materialsBlacklist && system.materialsBlacklist.length ? (
              system.materialsBlacklist.map((key: string) => (
                <Badge key={key} margins={true} variant="mushroom">
                  {translateIfExists({ path: `systemSettings.materialOptions.${key}`, i18n, fallback: key })}
                </Badge>
              ))
            ) : (
              <NotSet />
            )
          }
        />
      </div>
    </Section>
  );
});
