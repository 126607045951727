import classNames from "classnames";
import React from "react";

import css from "./BreadCrumbsMobile.module.scss";

export type StepTypes = "profileType" | "personalInfo" | "companyInfo";

export type StepsState = {
  [key in StepTypes]: {
    route?: string;
    isActive: boolean;
    isFinished: boolean;
  };
};

interface BreadCrumbsMobileProps {
  steps: StepsState;
  isExpert?: boolean;
}

export const BreadCrumbsMobile: React.FC<BreadCrumbsMobileProps> = ({ steps, isExpert = false }) => {
  return (
    <ul className={classNames(css.mobileStepsWrapper)}>
      <li className={classNames(steps.profileType.isActive && css.active)}> &nbsp; </li>
      <li className={classNames(steps.personalInfo.isActive && css.active)}> &nbsp; </li>
      {!isExpert && <li className={classNames(steps.companyInfo.isActive && css.active)}> &nbsp; </li>}
    </ul>
  );
};
