import { connect } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { MultiSelectField } from "triangular/components/form/MultiSelectField/MultiSelectField";
import { useStore } from "triangular/stores/StoreContext";
import { settingItemsToSelectOptions } from "triangular/utils/systems";

import { SelectField } from "../SelectField/SelectField";

interface DesignTypeFieldProps {
  label: string;
  isMultiSelect: boolean;
}

export const DesignTypeField = connect<DesignTypeFieldProps, any>(
  observer(({ formik, label, isMultiSelect }) => {
    const { t } = useTranslation();
    const { commonsStore } = useStore();
    const { commonSettings } = commonsStore;
    const { processCategory } = formik.values;

    const options = commonSettings
      ? settingItemsToSelectOptions(t)("designTypeOptions", commonSettings.designTypes.get(processCategory))
      : [];

    const Component = isMultiSelect ? MultiSelectField : SelectField;

    return (
      <Component
        name="designType"
        label={label}
        selectProps={{
          options,
          allowEmpty: true,
          emptyValue: null
        }}
        disabled={options.length === 0}
        data-testid="designType"
      />
    );
  })
);
