import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { Checkbox } from "triangular/components/Checkbox/Checkbox";
import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { PaymentMethodList } from "triangular/components/form/PaymentMethodList/PaymentMethodList";
import { ReadOnlyField } from "triangular/components/form/ReadOnlyField/ReadOnlyField";
import { IconButton } from "triangular/components/IconButton/IconButton";
import { Typography } from "triangular/components/Typography/Typography";
import { PaymentMethodItems } from "triangular/stores/PaymentsStore";
import { useStore } from "triangular/stores/StoreContext";

import css from "./MethodList.module.scss";

interface Props {
  onDelete: (id: string) => void;
  methods: PaymentMethodItems;
  defaultMethodId: string | null;
  invoicing: boolean;
  onSetDefault: (id: string) => void;
  loadingState: LoadingState;
}

export const MethodList = observer<Props>(
  ({ onDelete, methods, defaultMethodId, invoicing, onSetDefault, loadingState }) => {
    const { t } = useTranslation();
    const { userStore } = useStore();

    const renderRow = ({
      left,
      middle,
      id,
      methodType
    }: {
      left: React.ReactNode;
      middle?: React.ReactNode;
      id: string;
      methodType: string;
    }) => {
      const isManualMethod = methodType === "manual";
      const isDefaultMethod = defaultMethodId ? id === defaultMethodId : false;
      const manualPayment = !defaultMethodId && isManualMethod ? invoicing : false;
      return (
        <FieldsRow key={id}>
          <PaymentMethodList left={left} middle={middle} methodType={methodType}></PaymentMethodList>
          <FieldWrapper className="col-5 col-sm-4 d-flex flex-row align-items-end justify-content-end">
            <div className={css.actions}>
              <Checkbox
                checked={isDefaultMethod || manualPayment}
                label="Default"
                size="big"
                className={{ wrapper: css.checkboxWrapper }}
                onChange={() => onSetDefault(id)}
                disabled={isDefaultMethod || manualPayment}
                data-testid="defaultMethodCheckbox"
              />
              <IconButton
                testId="deleteMethodButton"
                onClick={() => onDelete(id)}
                disabled={isDefaultMethod || manualPayment || isManualMethod}
                icon="trash"
                tooltipMessage={isDefaultMethod ? t("payments.defaultMethodTooltip") : undefined}
              />
            </div>
          </FieldWrapper>
        </FieldsRow>
      );
    };

    const renderCardMethod = ({
      last4,
      id,
      expMonth,
      expYear,
      methodType
    }: PaymentMethodItems[0] & { expMonth: string; expYear: string }) =>
      renderRow({
        left: <ReadOnlyField label={t("stripeField.cardNumber")}>**** **** **** {last4}</ReadOnlyField>,
        middle: (
          <ReadOnlyField label={t("stripeField.cardExpiry")}>
            {expMonth} / {expYear}
          </ReadOnlyField>
        ),
        id,
        methodType
      });

    const renderSepaMethod = ({ last4, id, methodType }: PaymentMethodItems[0]) =>
      renderRow({
        left: <ReadOnlyField label={t("stripeField.iban")}>****************{last4}</ReadOnlyField>,
        id,
        methodType
      });
    const renderManualMethod = ({ id, methodType }: PaymentMethodItems[0]) =>
      renderRow({
        left: (
          <ReadOnlyField label="Pay by invoice">
            <span className={css.payByInvoiceText}>{t("payments.manualPaymentMethod")}</span>
            <br></br>
            <span className={css.email}>{userStore.email}</span>
          </ReadOnlyField>
        ),
        id,
        methodType
      });

    if (methods.length === 0) {
      return (
        <Typography center={true} component="div" bold={true} verticalSpacing="large">
          {t("payments.emptyMethodList")}
        </Typography>
      );
    }

    return (
      <>
        {methods.map(eachMethod =>
          eachMethod.methodType === "card"
            ? renderCardMethod(eachMethod)
            : eachMethod.methodType === "sepa_debit"
            ? renderSepaMethod(eachMethod)
            : renderManualMethod(eachMethod)
        )}
      </>
    );
  }
);
