import { SnapshotIn, types } from "mobx-state-tree";

import { createStore } from "./utils/createStore";

export const agreementStoreInitialState = {
  accountId: "",
  lastAgreementId: null,
  lastAgreedTermId: null,
  lastTermId: null,
  status: "pending" as const
};

const agreementStoreModel = types.model({
  accountId: types.string,
  lastAgreementId: types.maybeNull(types.string),
  lastAgreedTermId: types.maybeNull(types.string),
  lastTermId: types.maybeNull(types.string),
  status: types.enumeration<AgrementType>(["pending", "rejected", "completed"])
});

export type AgreementStoreModel = typeof agreementStoreModel["SnapshotType"];

export const AgreementStore = createStore(({ api }) =>
  agreementStoreModel
    .actions(self => ({
      setState(snapshot: DeepPartial<SnapshotIn<typeof self>>) {
        Object.assign(self, snapshot);
      }
    }))
    .actions(self => ({
      async acceptAgreement(term_id: any) {
        const { accountId } = self;
        const status = "completed" as const;
        const termId = term_id;

        self.setState({ status: status, lastAgreedTermId: termId });

        const requestPayload = {
          accountId,
          termId,
          status
        };

        await api.agreement.create(requestPayload);
      },
      async rejectAgreement(term_id: any) {
        const { accountId } = self;
        const status = "rejected" as const;
        const termId = term_id;

        self.setState({ status: status, lastAgreedTermId: termId });

        const requestPayload = {
          accountId,
          termId,
          status
        };

        await api.agreement.create(requestPayload);
      },
      cleanUp() {
        self.setState(agreementStoreInitialState);
      }
    }))
    .views(self => ({
      get openConfirmation() {
        const { lastAgreedTermId, lastTermId, status } = self;
        const lastTerm = lastTermId;
        const checkLastAgreedTerm = lastAgreedTermId === null || lastAgreedTermId !== lastTerm;
        const termIdExists = lastTermId !== null;

        return (status === "pending" || checkLastAgreedTerm) && termIdExists;
      }
    }))
);
