// in order to work, polyfills have to be imported at the very top of the index file
import "polyfills";

import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import { hotjar } from "react-hotjar";
import "react-loader-spinner";

import { App } from "triangular/App/App";
import "triangular/i18next/i18next";
import { setupInitialLanguage } from "triangular/i18next/i18next";
import { Api } from "triangular/services/Api/Api";
import { googleAnalytics } from "triangular/services/googleAnalytics";
import { createRootStore } from "triangular/stores/RootStore";
import "./triangular/styles/styles.scss";

const renderApp = () => {
  const api = new Api();

  const history = createBrowserHistory({
    basename: process.env.REACT_APP_BASENAME
  });
  setupInitialLanguage(history);

  const store = createRootStore({ api, history });

  api.onRefreshTokenExpired = store.onRefreshTokenExpired;

  api.onSubscriptionExpired = store.onSubscriptionExpired;

  if (process.env.REACT_APP_HOTJAR_TRACKING_ID && process.env.REACT_APP_HOTJAR_SNIPPET_VERSION) {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_TRACKING_ID, process.env.REACT_APP_HOTJAR_SNIPPET_VERSION);
  }

  googleAnalytics.initialize(history);

  return ReactDOM.render(<App store={store} history={history} />, document.getElementById("root"));
};

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

if (module.hot) {
  module.hot.accept("triangular/App/App", () => {
    renderApp();
  });
}
