import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { PageLoader } from "triangular/components/PageLoader/PageLoader";
import { Typography } from "triangular/components/Typography/Typography";
import { useStore } from "triangular/stores/StoreContext";

import { DefaultFormButtons } from "../../DefaultFormButtons";
import { Form } from "../../Form";

import css from "./StripeForm.module.scss";

interface Props {
  onCancel?: () => void;
  onSubmit: (params: { stripe: Stripe; elements: StripeElements }) => Promise<void>;
  render: (props: { isValid: boolean }) => React.ReactNode;
  isLoading: boolean;
  handlePayByInvoice?: () => void | Promise<void>;
}

export const StripeForm: React.FC<Props> = ({ onSubmit, render, onCancel, isLoading }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const { snackbarStore, userStore, paymentsStore } = useStore();
  const status = paymentsStore.subscription.status;

  useEffect(() => {
    if (stripe === null) {
      snackbarStore.showGenericError("Stripe is not available");
    }
  }, [snackbarStore, stripe]);

  if (stripe === null) {
    return (
      <Typography center={true} component="div" size="big" bold={true}>
        {t("errors.stripeNotAvailable")}
      </Typography>
    );
  }

  // When a user is registering a payment method on an inactive subscription it should show that the user will be charged within 10 days.
  // It is supposed to show only while registering the first time. Not serious if it shows at other time though its not supposed too.
  function showPeriod() {
    if (status === "inactive") {
      return (
        <div className={css.paymentPeriod}>
          {t("subscriptionSetup.paymentMethodSetup.invoicePeriod")}
          <br></br>
          {t("subscriptionSetup.paymentMethodSetup.sendInvoiceTo")}
          <span className={css.email}>{userStore.email}</span>
        </div>
      );
    }
    return null;
  }

  return (
    <Form
      // Workaround for handling custom validation using Stripe events
      validateOnBlur={false}
      validateOnChange={false}
      isInitialValid={false}
      // ---
      initialValues={{
        subscriptionInterval: "year" as const
      }}
      onSubmit={async () => {
        if (!stripe || !elements) {
          throw new Error("Stripe not available");
        }

        try {
          await onSubmit({
            stripe,
            elements
          });
        } catch (err) {
          if (err.type === "validation_error") {
            return snackbarStore.showValidationError();
          }

          if (err.message && err.type) {
            return snackbarStore.addSnackbar({
              type: "error",
              message: err.message
            });
          }

          return snackbarStore.showGenericError(err);
        }
      }}
      render={formik => {
        const hasErrors = Object.keys(formik.errors).some(key => {
          const errors: { [Key: string]: string | undefined } = formik.errors;
          return typeof errors[key] === "string";
        });

        const stripeIsAvailable = !!(stripe && elements);

        const isValid = !hasErrors && stripeIsAvailable;

        return (
          <div className={css.wrapper}>
            {isLoading && <PageLoader />}
            <div className={classNames(css.form, { [css.formHidden]: isLoading })}>
              {render({ isValid })}
              {showPeriod()}
              <DefaultFormButtons onGoBack={onCancel} backButtonLabel={t("modal.cancel")} isSubmitDisabled={!isValid} />
            </div>
          </div>
        );
      }}
    />
  );
};
