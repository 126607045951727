import React from "react";
import { useTranslation } from "react-i18next";

import { IndustryFilterBadgeLabel } from "triangular/components/IndustryFilterBadgeLabel/IndustryFilterBadgeLabel";
import { useStore } from "triangular/stores/StoreContext";
import { getSystemSettingTranslation, useMaterialCategoriesOptions } from "triangular/utils/systems";

import { useExpertsPaginationContext } from "./pagination";
import { updateIndustryIds, updateLanguages, updateMaterialCategoryIds, updateProcessCategories } from "./updates";

export const useFilterBadges = () => {
  const { t } = useTranslation();
  const { commonsStore } = useStore();
  const { paginationQuery, buildUrl } = useExpertsPaginationContext();
  const settings = commonsStore.getSettings();
  const getOptionTranslation = getSystemSettingTranslation(t);

  const languages = paginationQuery.languages || [];
  const processCategories = paginationQuery.processCategories || [];
  const industryIds = paginationQuery.industryIds || [];
  const materialCategoryIds = paginationQuery.materialCategoryIds || [];
  const materialCategoryOptions = useMaterialCategoriesOptions(industryIds);

  const getMaterialCategoryLabel = (categoryId: string) => {
    const foundCategory = materialCategoryOptions.find(({ value }) => value === categoryId);

    if (foundCategory) {
      return foundCategory.label;
    }

    return null;
  };

  const languageBadgesProps = languages.map(eachLanguage => ({
    value: eachLanguage,
    label: t(`expertSettings.languageOptions.${eachLanguage}`),
    removingUrl: buildUrl(prevQuery =>
      prevQuery.languages
        ? updateLanguages(prevQuery.languages.filter(prevLanguage => prevLanguage !== eachLanguage))
        : prevQuery
    )
  }));

  const processCategoriesBadgesProps = processCategories.map(eachProcessCategory => ({
    value: eachProcessCategory,
    label: getOptionTranslation(settings.processes, { id: String(eachProcessCategory) }, "processCategoryOptions"),
    removingUrl: buildUrl(prevQuery =>
      prevQuery.processCategories
        ? updateProcessCategories(
            prevQuery.processCategories.filter(prevProcessCategories => prevProcessCategories !== eachProcessCategory)
          )
        : prevQuery
    )
  }));

  const industryBadgesProps = industryIds.map(eachIndustryId => ({
    value: eachIndustryId,
    label: <IndustryFilterBadgeLabel industryId={eachIndustryId} />,
    removingUrl: buildUrl(prevQuery =>
      prevQuery.industryIds
        ? updateIndustryIds(prevQuery.industryIds.filter(prevIndustry => prevIndustry !== eachIndustryId))
        : prevQuery
    )
  }));

  const materialCategoriesBadgesProps = materialCategoryIds.map(eachMaterialCategoryId => ({
    value: eachMaterialCategoryId,
    label: getMaterialCategoryLabel(eachMaterialCategoryId),
    removingUrl: buildUrl(prevQuery =>
      prevQuery.materialCategoryIds
        ? updateMaterialCategoryIds(
            prevQuery.materialCategoryIds.filter(
              prevMaterialCategory => prevMaterialCategory !== eachMaterialCategoryId
            )
          )
        : prevQuery
    )
  }));

  return [
    ...languageBadgesProps,
    ...processCategoriesBadgesProps,
    ...industryBadgesProps,
    ...materialCategoriesBadgesProps
  ];
};
