import { connect } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { DoubleField } from "triangular/components/form/DoubleField/DoubleField";
import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { TextField } from "triangular/components/form/TextField";
import { useStore } from "triangular/stores/StoreContext";
import { namesToSelectOptions } from "triangular/utils/materials";

import { MaterialSchema } from "../../materialSchema";

import css from "./ProductNumberField.module.scss";

interface ProductNumberFieldProps {
  label: string;
}

export const ProductNumberField = connect<ProductNumberFieldProps, MaterialSchema>(
  observer(({ label, formik }) => {
    const { materialSettings } = useStore().materialsStore;
    const { t } = useTranslation();
    const { values } = formik;

    const materialIndexOptions = materialSettings
      ? namesToSelectOptions(t)("materialIndexOptions", materialSettings.materialIndexes)
      : [];

    return (
      <DoubleField
        label={label}
        leftField={
          <SelectField
            className={{
              wrapper: css.field
            }}
            name="materialIndex"
            selectProps={{
              options: materialIndexOptions
            }}
            data-testid="productNumberSelect"
          />
        }
        rightField={
          values.materialIndex !== "not_available" && (
            <TextField
              className={{
                wrapper: css.field
              }}
              name="indexNumber"
              data-testid="productNumberInput"
            />
          )
        }
        data-testid="materialIndex"
      />
    );
  })
);
