import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";

import { Layout } from "triangular/components/Layout/Layout";
import { SystemErrorPage } from "triangular/components/SystemErrorPage/SystemErrorPage";
import { useStore } from "triangular/stores/StoreContext";
import { SystemStoreType } from "triangular/stores/SystemsStore/SystemsStore";

import { SystemDetailsContent } from "./SystemDetailsContent";

interface SystemDetailsProps extends RouteComponentProps {
  id: string;
  isSystemOwner: boolean;
}

type FetchedSystem = FromPromise<ReturnType<SystemStoreType["getSystem"]>>;

export const SystemDetails: React.FC<SystemDetailsProps> = observer(({ id, history, isSystemOwner }) => {
  const { t, i18n } = useTranslation();
  const landingPageUrl = process.env.REACT_APP_LP_URL;
  const howItWorksUrl =
    i18n.language !== "en" && i18n.language !== "pl"
      ? landingPageUrl + "/" + i18n.language + "/how-it-works"
      : landingPageUrl + "/how-it-works";
  const { systemsStore, commonsStore, snackbarStore, userStore } = useStore();
  const [system, setSystem] = useState<FetchedSystem>();
  const [errorOccurred, setErrorOccurred] = useState(false);

  const fetchSystem = useCallback(async () => {
    try {
      const fetchedSystem = await systemsStore.getSystem(id, {
        include: {
          processCategory: true,
          designTypes: true,
          systemCategory: true,
          systemType: true,
          systemPhotos: true,
          systemDocuments: true,
          industries: true,
          materialCategories: true
        },
        isSystemOwner
      });

      setSystem(fetchedSystem);
    } catch (e) {
      setErrorOccurred(true);
    }
  }, [id, isSystemOwner, systemsStore]);

  useEffect(() => {
    if (userStore.isAuthenticated) {
      fetchSystem();
    }
  }, [systemsStore, snackbarStore, t, id, isSystemOwner, history, fetchSystem, userStore.isAuthenticated]);

  const loading = !(systemsStore.systemSettings && system) && !errorOccurred;

  if (userStore.isAuthenticated) {
    return (
      <Layout withMolecules={false} loading={loading}>
        {errorOccurred ? (
          <SystemErrorPage />
        ) : (
          <SystemDetailsContent system={system!} commonSettings={commonsStore.getSettings()} />
        )}
      </Layout>
    );
  }

  window.location.replace(howItWorksUrl);
  return null;
});
