import React from "react";
import { useTranslation } from "react-i18next";

import { NO_RESTRICTIONS } from "triangular/consts";

import { IndustryPictogram } from "../IndustryPictogram/IndustryPictogram";
import { NotSet } from "../NotSet/NotSet";

import css from "./IndustryList.module.scss";

export const IndustryList = ({ industryNames }: { industryNames: string[] | null }) => {
  const { t } = useTranslation();

  if (!industryNames || industryNames.length === 0) {
    return <NotSet />;
  }

  if (industryNames.includes(NO_RESTRICTIONS)) {
    return <div className={css.wrapper}>{t("systemSettings.targetIndustryOptions.no_restrictions")}</div>;
  }

  return (
    <div className={css.wrapper}>
      {industryNames.map((eachValue, i) => (
        <IndustryPictogram key={i} industry={eachValue} />
      ))}
    </div>
  );
};
