import classNames from "classnames";
import React from "react";

import css from "./Segment.module.scss";

type SegmentProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Segment: React.FC<SegmentProps> = ({ children, className, ...props }) => {
  return (
    <div className={classNames(css.segment, className)} {...props}>
      {children}
    </div>
  );
};
