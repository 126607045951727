import React from "react";

import css from "./Address.module.scss";

export const Address: React.FC = () => {
  return (
    <address className={css.mainContainer}>
      <ul className={css.list}>
        <li>TRIANGULAR-PST GMBH & CO. KG</li>
        <li>Bahnhofstraße 35</li>
        <li>86316 Friedberg</li>
        <li>Germany</li>
      </ul>
    </address>
  );
};
