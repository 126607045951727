import React, { useState } from "react";

import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { useStore } from "triangular/stores/StoreContext";

import { StripeField } from "./StripeField";
import { StripeForm } from "./StripeForm/StripeForm";
import { PaymentMethodFormProps } from "./types";

export const StripeSepaForm: React.FC<PaymentMethodFormProps> = ({ onCancel, afterSubmit, handlePayByInvoice }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { userStore } = useStore();

  return (
    <StripeForm
      handlePayByInvoice={handlePayByInvoice}
      onSubmit={async ({ stripe, elements }) => {
        const { firstName, lastName, email } = userStore;
        const ibanElement = elements.getElement("iban");

        if (!ibanElement) {
          throw new Error("Iban element not available");
        }

        if (!firstName || !lastName || !email) {
          throw new Error("User data not available");
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          sepa_debit: ibanElement,
          billing_details: {
            name: `${firstName} ${lastName}`,
            email
          },
          type: "sepa_debit"
        });

        if (error) {
          throw error;
        }

        if (!paymentMethod) {
          throw new Error("Payment method not available");
        }

        await afterSubmit({ paymentMethod });
      }}
      render={() => (
        <FieldsRow>
          <FieldWrapper className="col-12">
            <StripeField
              type="IbanElement"
              onReady={() => setIsLoading(false)}
              options={{
                supportedCountries: ["SEPA"]
              }}
            />
          </FieldWrapper>
        </FieldsRow>
      )}
      isLoading={isLoading}
      onCancel={onCancel}
    />
  );
};
