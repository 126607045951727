import { getParent, types } from "mobx-state-tree";

import { NO_RESTRICTIONS } from "triangular/consts";
import { SettingItem } from "triangular/services/Api/Resource/utils/types";

import { SnackbarStoreType } from "../SnackbarStore";
import { createStore } from "../utils/createStore";
import { createStateMerger } from "../utils/state";

import { commonSettingsModel } from "./commonSettingsModel";

export type CommonsStoreModel = ReturnType<typeof createCommonsStoreModel>;

export const createCommonsStoreModel = () =>
  types.model("CommonsStore", {
    commonSettings: types.maybeNull(commonSettingsModel)
  });

export const CommonsStore = createStore(storeDeps =>
  createCommonsStoreModel()
    .actions(self => ({
      mergeWithState: createStateMerger(self),
      getSnackbars() {
        return getParent(self).snackbarStore as SnackbarStoreType;
      },
      getSettings() {
        if (!self.commonSettings) {
          throw new Error("Settings not available!");
        }

        return self.commonSettings;
      }
    }))
    .actions(self => ({
      async loadSettings() {
        if (self.commonSettings) {
          return;
        }

        const {
          data: [fetchedSettings]
        } = await storeDeps.api.commonSettings.find();

        const { materials = [], colors = [], processes = [], industries = [] } = fetchedSettings;

        const noRestrictionsIds = {
          industry: "",
          materialCategory: ""
        };
        const flatProcesses: SettingItem[] = [];
        const designTypes: Dictionary<SettingItem[]> = {};
        const systemCategories: Dictionary<SettingItem[]> = {};
        const systemTypes: Dictionary<SettingItem[]> = {};
        const flatIndustries: SettingItem[] = [];
        const materialCategoriesByIndustries: Dictionary<SettingItem[]> = {};

        processes.forEach(process => {
          flatProcesses.push({ name: process.name, id: String(process.id) });

          designTypes[process.id] = process.designTypes.map(({ name, id }) => ({ name, id: String(id) }));

          systemCategories[process.id] = process.systemCategories.map(systemCategory => {
            systemTypes[systemCategory.id] = systemCategory.systemTypes.map(({ name, id }) => ({
              name,
              id: String(id)
            }));
            return { name: systemCategory.name, id: String(systemCategory.id) };
          });
        });

        industries.forEach(eachIndustry => {
          flatIndustries.push({ name: eachIndustry.name, id: eachIndustry.id });

          if (eachIndustry.name === NO_RESTRICTIONS) {
            noRestrictionsIds.industry = eachIndustry.id;
          }

          materialCategoriesByIndustries[eachIndustry.id] = eachIndustry.materialCategories;
        });

        Object.values(materialCategoriesByIndustries).some(eachMaterialCategories =>
          eachMaterialCategories.some(({ name, id }) => {
            const isNoRestrictionCategory = name === NO_RESTRICTIONS;

            if (isNoRestrictionCategory) {
              noRestrictionsIds.materialCategory = id;
            }

            return isNoRestrictionCategory;
          })
        );

        self.mergeWithState({
          commonSettings: {
            processes: flatProcesses,
            materials,
            systemTypes,
            colors,
            industries: flatIndustries,
            systemCategories,
            designTypes,
            materialCategoriesByIndustries,
            noRestrictionsIds
          }
        });
      },
      cleanUp() {
        const { commonSettings, ...initialValues } = commonsStoreInitialState;
        self.mergeWithState(initialValues);
      }
    }))
);

export const commonsStoreInitialState = {
  commonSettings: null
};

export type CommonStoreType = ReturnType<typeof CommonsStore>["Type"];
export type CommonStoreSnapshot = ReturnType<typeof CommonsStore>["SnapshotType"];
export type FetchedCommonSettings = NonNullable<CommonsStoreModel["Type"]["commonSettings"]>;
