import { connect } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { useStore } from "triangular/stores/StoreContext";
import { settingItemsToSelectOptions } from "triangular/utils/systems";

interface SystemCategoryFieldProps {
  label: string;
}

export const SystemCategoryField = connect<SystemCategoryFieldProps, any>(
  observer(({ formik, label }) => {
    const { t } = useTranslation();
    const { commonsStore } = useStore();
    const { commonSettings } = commonsStore;
    const { processCategory } = formik.values;

    const options = commonSettings
      ? settingItemsToSelectOptions(t)("systemCategoryOptions", commonSettings.systemCategories.get(processCategory))
      : [];

    const onSystemCategoryChange = () => {
      formik.setFieldValue("systemType", null);
    };

    return (
      <SelectField
        name="systemCategory"
        label={label}
        selectProps={{
          options,
          allowEmpty: true
        }}
        required={true}
        disabled={options.length === 0}
        onChangeCallback={onSystemCategoryChange}
        data-testid="systemCategory"
      />
    );
  })
);
