import React from "react";

import { SearchPhrase as BaseSearchPhrase } from "triangular/components/SearchPhrase/SearchPhrase";

import { useSystemsPaginationContext } from "./utils/pagination";

export const SearchPhrase: React.FC = () => {
  const { paginationQuery, buildUrl } = useSystemsPaginationContext();
  return <BaseSearchPhrase value={paginationQuery.search} urlWithoutSearchPhrase={buildUrl(() => ({ search: "" }))} />;
};
