import { connect } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { useStore } from "triangular/stores/StoreContext";
import { getAnalyzingProcessId, settingItemsToSelectOptions } from "triangular/utils/systems";

interface ProcessCategoryFieldProps {
  label: string;
}

export const ProcessCategoryField = connect<ProcessCategoryFieldProps, any>(
  observer(({ formik, label }) => {
    const { t } = useTranslation();
    const { commonsStore } = useStore();
    const { commonSettings } = commonsStore;
    const { setFieldValue, values } = formik;

    const options = commonSettings
      ? settingItemsToSelectOptions(t)("processCategoryOptions", commonSettings.processes)
      : [];

    const onProcessCategoryChange = (__: any, value: string) => {
      const isSystemForm = values.installedPowerKw !== undefined;
      setFieldValue("systemCategory", null);
      setFieldValue("systemType", null);
      setFieldValue("designType", null);

      if (isSystemForm) {
        if (value === getAnalyzingProcessId(commonSettings)) {
          setFieldValue("contactMaterials", null);
          setFieldValue("nonContactMaterials", null);
          setFieldValue("installedPowerMillSelect", null);
          setFieldValue("installedPowerClassifier", null);
          setFieldValue("installedPowerFlowRate", null);
          setFieldValue("installedPowerBar", null);
          setFieldValue("installedPowerKw", null);
        }
      }
    };

    return (
      <SelectField
        name="processCategory"
        label={label}
        placeholder={t("selectField.placeholder")}
        selectProps={{
          options
        }}
        onChangeCallback={onProcessCategoryChange}
        required={true}
        data-testid="processCategory"
      />
    );
  })
);
