import uniqueId from "lodash/uniqueId";
import { useTranslation } from "react-i18next";

import { routes } from "triangular/Routes/routesConfiguration";

import { MenuItemsGroup } from "./Header";

type MenuTypes = { [Key in ProfileType | "default"]: MenuItemsGroup[] };

export const useMenuTypes = (): MenuTypes => {
  const { t } = useTranslation();
  return {
    material_owners: [
      {
        id: uniqueId(),
        items: [
          {
            name: t("header.menu.searchSystems"),
            path: routes.systems.build({})
          },
          {
            name: t("header.menu.searchExperts"),
            path: routes.experts.build()
          },
          {
            name: t("header.menu.addMaterial"),
            path: routes.addMaterial.build(),
            color: "mint"
          },
          {
            name: t("header.menu.myMaterials"),
            path: routes.myMaterialsList.build(),
            color: "darkMint"
          }
        ]
      }
    ],
    experts: [
      {
        id: uniqueId(),
        items: [
          {
            name: t("header.menu.searchSystems"),
            path: routes.systems.build({}),
            color: "mint"
          }
        ]
      }
    ],
    system_owners: [
      {
        id: uniqueId(),
        items: [
          {
            name: t("header.menu.searchSystems"),
            path: routes.systems.build({})
          },
          {
            name: t("header.menu.searchExperts"),
            path: routes.experts.build()
          },
          {
            name: t("header.menu.addSystem"),
            path: routes.addSystem.build(),
            color: "mint"
          },
          {
            name: t("header.menu.mySystems"),
            path: routes.mySystemsList.build(),
            color: "darkMint"
          }
        ]
      }
    ],
    default: [
      {
        id: uniqueId(),
        items: [
          {
            name: t("header.menu.searchSystems"),
            path: routes.systems.build({})
          },
          {
            name: t("header.menu.searchExperts"),
            path: routes.experts.build()
          }
        ]
      },
      {
        id: uniqueId(),
        items: [
          {
            name: t("header.menu.login"),
            path: routes.login.build(),
            color: "mint"
          },
          {
            name: t("header.menu.register"),
            path: routes.registration.root.build(),
            color: "darkMint"
          }
        ]
      }
    ]
  };
};
