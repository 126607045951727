import { TFunction } from "i18next";
import * as Yup from "yup";

import { FileListItem } from "triangular/components/form/system/SystemFilesField/FilesList/FilesList";
import { filesValidator } from "triangular/components/form/validators/filesValidator";
import { requiredBoolean } from "triangular/components/form/validators/requiredBoolean";
import { requiredString } from "triangular/components/form/validators/requiredString";

export interface ExperienceSchema {
  title: string | null;
  company: string | null;
  countryCode: string | null;
  currentRole: boolean | null;
  startDate: string | null;
  endDate: string | null;
  headline: string | null;
  description: string | null;
  experienceFiles: FileListItem[] | null;
}

export const experienceDefaultsValues: ExperienceSchema = {
  title: null,
  company: null,
  countryCode: null,
  currentRole: false,
  startDate: new Date(Date.now()).toISOString(),
  endDate: new Date(Date.now()).toISOString(),
  headline: null,
  description: null,
  experienceFiles: null
};

export const createExperienceSchema = (t: TFunction) =>
  Yup.object<ExperienceSchema>({
    title: requiredString(t),
    company: requiredString(t),
    countryCode: requiredString(t),
    startDate: requiredString(t),
    currentRole: requiredBoolean(t),
    endDate: Yup.string().nullable(),
    headline: Yup.string().nullable(),
    description: Yup.string().nullable(),
    experienceFiles: filesValidator.nullable()
  });
