import classNames from "classnames";
import React from "react";
import {
  Slider as CompoundSlider,
  SliderProps as CompoundSliderProps,
  Handles,
  Rail,
  Ticks,
  Tracks
} from "react-compound-slider";

import css from "./Slider.module.scss";

const modeMapping = {
  allowCrossing: 1,
  preventCrossing: 2,
  pushable: 3
};

export interface SliderProps {
  onChange?: CompoundSliderProps["onChange"];
  onUpdate?: CompoundSliderProps["onUpdate"];
  values: CompoundSliderProps["values"];
  mode: keyof typeof modeMapping;
  step: CompoundSliderProps["step"];
  domain: CompoundSliderProps["domain"];
  areTicksVisible?: boolean;
  testId?: string;
}

export const Slider = ({
  onChange,
  values,
  mode,
  step,
  domain = [],
  onUpdate,
  areTicksVisible = true,
  testId
}: SliderProps) => {
  return (
    <CompoundSlider
      mode={modeMapping[mode]}
      step={step}
      domain={domain}
      className={css.slider}
      onChange={onChange}
      onUpdate={onUpdate}
      values={values}
    >
      <Rail>
        {({ getRailProps }) => (
          <div className={css.railContainer}>
            <div className={classNames(css.railTipStart, css.railTip)} />
            <div className={css.rail} {...getRailProps()} />
            <div className={classNames(css.railTipEnd, css.railTip)} />
          </div>
        )}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div className="slider-handles">
            {handles.map(handle => (
              <div
                key={handle.id}
                role="slider"
                aria-valuemin={domain[0]}
                aria-valuemax={domain[1]}
                aria-valuenow={handle.value}
                className={css.handle}
                style={{
                  left: `${handle.percent}%`
                }}
                {...getHandleProps(handle.id)}
              >
                <div className={css.handleValue}>{handle.value % 1 ? handle.value.toFixed(2) : handle.value}</div>
              </div>
            ))}
          </div>
        )}
      </Handles>
      <Tracks left={false} right={false}>
        {({ tracks, getTrackProps }) => (
          <div className="slider-tracks">
            {tracks.map(({ id, source, target }) => (
              <div
                key={id}
                className={css.track}
                style={{
                  left: `${source.percent}%`,
                  width: `${target.percent - source.percent}%`
                }}
                {...getTrackProps()}
              />
            ))}
          </div>
        )}
      </Tracks>
      {areTicksVisible && (
        <Ticks count={1}>
          {({ ticks }) => {
            return (
              <div className="slider-ticks">
                {ticks.map(tick => (
                  <div key={tick.id}>
                    <div
                      className={css.tickMark}
                      style={{
                        left: `${tick.percent}%`
                      }}
                    />
                    <div
                      className={css.tickValue}
                      style={{
                        marginLeft: `${-(100 / ticks.length) / 2}%`,
                        width: `${100 / ticks.length}%`,
                        left: `${tick.percent}%`
                      }}
                    >
                      {tick.value}
                    </div>
                  </div>
                ))}
              </div>
            );
          }}
        </Ticks>
      )}
    </CompoundSlider>
  );
};
