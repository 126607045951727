import jwtDecode from "jwt-decode";
import { SnapshotIn, types } from "mobx-state-tree";

import { FileToUpload } from "triangular/components/form/system/SystemFilesField/FilesField/FilesField";

import { createStore } from "./utils/createStore";

const profilesType = types.enumeration<ProfileType>(["experts", "system_owners", "material_owners"]);

export const UserStore = createStore(({ api }) =>
  types
    .model("UserStore", {
      id: types.maybeNull(types.string),
      type: types.maybeNull(profilesType),
      accountType: types.maybeNull(
        types.enumeration<AccountType>(["regular", "master"])
      ),
      isAuthenticated: types.boolean,
      email: types.maybeNull(types.string),
      profileId: types.maybeNull(types.string),
      firstName: types.maybeNull(types.string),
      lastName: types.maybeNull(types.string),
      phoneNumber: types.maybeNull(types.string),
      avatar: types.maybeNull(types.string),
      createdAt: types.maybeNull(types.string),
      availableProfileTypes: types.maybeNull(types.array(profilesType))
    })
    .actions(self => ({
      setState(snapshot: DeepPartial<SnapshotIn<typeof self>>) {
        Object.assign(self, snapshot);
      },
      getProfileType() {
        if (!self.type) {
          throw new Error("User type not available!");
        }

        return self.type;
      }
    }))
    .actions(self => ({
      async confirmAccount(token: string) {
        await api.confirmAccount(token);
      },
      async forgotPassword(email: string) {
        await api.passwordRecovery.create({ email });
      },
      async resetPassword(params: { password: string; token: string }) {
        const { sub: accountId } = jwtDecode(params.token);
        await api.account.update(accountId, params);
      },
      async updatePassword(params: { account: any; password: string }) {
        await api.account.update(params.account, params);
      },

      async uploadAvatar(file: FileToUpload) {
        if (!self.profileId) {
          throw new Error("Profile id not available!");
        }

        const [uploadedAvatar] = await api.avatar.create({ ...file, ownerable: { id: self.profileId } });

        self.setState({
          avatar: uploadedAvatar.originalUrl
        });
      }
    }))
);

export type UserStoreSnapshot = ReturnType<typeof UserStore>["SnapshotType"];

export const userStoreInitialState: UserStoreSnapshot = {
  isAuthenticated: false,
  accountType: null,
  id: null,
  email: null,
  type: null,
  profileId: null,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  avatar: null,
  createdAt: null,
  availableProfileTypes: null
};
