import { TFunction } from "i18next";
import get from "lodash/get";
import uniqueId from "lodash/uniqueId";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { defaultValues } from "triangular/components/form/system/SystemForm/systemSchema";
import { SystemSchema } from "triangular/components/form/system/SystemForm/types";
import { SettingItem } from "triangular/services/Api/Resource/utils/types";
import { SystemEntity } from "triangular/services/Api/resources/SystemResource";
import { CommonStoreType } from "triangular/stores/CommonStore/CommonsStore";
import { useStore } from "triangular/stores/StoreContext";

import { createNumberOrNull } from "./api";
import { toBase64 } from "./browser";

export const namesToSelectOptions = (t: TFunction) => (key?: string, values?: string[]) =>
  (values || []).map(name => ({
    key: uniqueId(name),
    label: t(`systemSettings.${key}.${name}`),
    value: name
  }));

export const settingItemsToSelectOptions = (t: TFunction, valueOf: "id" | "name" = "id") => (
  key?: string,
  settingItems?: SettingItem[]
) =>
  (settingItems || []).map(({ id, name }) => ({
    key: id,
    label: t(`systemSettings.${key}.${name}`),
    value: valueOf === "id" ? id : name
  }));

export const settingItemsToFilterOptions = (t: TFunction) => (key?: string, settingItems?: SettingItem[]) =>
  (settingItems || []).map(({ id, name }) => ({
    key: id,
    label: t(`systemSettings.${key}.${name}`),
    value: { id, name }
  }));

export const createFileEntity = async (
  file: File
): Promise<{
  filename: string;
  data: string;
}> => ({
  filename: file.name,
  data: await toBase64(file)
});

export const getSystemSettingTranslation = (t: TFunction) => (
  sourceArray: SettingItem[],
  relation: { id: string } | null | undefined,
  translationKey: string
) => {
  const relationId = get(relation, "id");

  if (Array.from(sourceArray).length === 0 || !relationId) {
    return "";
  }
  const name = get(
    sourceArray.find(element => element.id === relationId),
    "name",
    ""
  );
  return name ? t(`systemSettings.${translationKey}.${name}`) : "";
};

export const flattenSystemSettings = (detailsTypes: { [key: string]: SettingItem[] }): SettingItem[] =>
  Object.keys(detailsTypes).reduce(
    (acc: SettingItem[], key: string) => (Array.isArray(detailsTypes[key]) ? [...acc, ...detailsTypes[key]] : acc),
    []
  );

function getDerivedInstalledPowerMillSelect(
  installedPower: SystemEntity["attributes"]["installedPower"],
  installedPowerMill: SystemEntity["attributes"]["installedPowerMill"]
) {
  if (installedPower && installedPower.kw) {
    return "kw";
  }

  if (installedPowerMill && installedPowerMill.bar && installedPowerMill["m3/h"]) {
    return "m3/h@bar";
  }

  return null;
}

export function isOptionalValueEnabled(providedValue: string | null) {
  if (providedValue === null) {
    return null;
  }

  if (providedValue === "no") {
    return false;
  }

  return true;
}

export function resolveRequestOptionalValue(isEnabled: boolean | null, value: string | null) {
  if (isEnabled) {
    return value;
  }

  if (isEnabled === false) {
    return "no";
  }

  return null;
}

export const useFetchedSystem = (systemId: string) => {
  const { systemsStore } = useStore();
  const [system, setSystem] = useState<Required<SystemSchema>>();

  const fetchSystem = useCallback(async () => {
    const {
      type,
      links,
      processCategory: providedProcessCategory,
      systemCategory: providedSystemCategory,
      systemType: providedSystemType,
      designTypes: providedDesignTypes,
      systemPhotos: providedSystemPhotos,
      systemDocuments: providedSystemDocuments,
      installedPowerClassifierKw,
      materialCategories: providedMaterialCategories,
      industries: providedIndustries,
      ...providedAttributes
    } = await systemsStore.getSystem(systemId, {
      include: {
        processCategory: true,
        systemOwner: true,
        systemCategory: true,
        systemType: true,
        designTypes: true,
        systemDocuments: true,
        systemPhotos: true,
        industries: true,
        materialCategories: true
      },
      isSystemOwner: true
    });

    setSystem({
      ...defaultValues,
      ...providedAttributes,
      installedPowerMillSelect: getDerivedInstalledPowerMillSelect(
        providedAttributes.installedPower,
        providedAttributes.installedPowerMill
      ),
      installedPowerKw: get(providedAttributes, "installedPower.kw", null),
      installedPowerBar: get(providedAttributes, "installedPowerMill.bar", null),
      installedPowerFlowRate: get(providedAttributes, ["installedPowerMill", "m3/h"], null),
      installedPowerClassifier: installedPowerClassifierKw,
      abrasiveness: providedAttributes.abrasiveness,
      corrosiveness: providedAttributes.corrosiveness,
      explosiveness: providedAttributes.explosiveness,
      processCategory: get(providedProcessCategory, "id", null),
      systemCategory: get(providedSystemCategory, "id", null),
      systemType: get(providedSystemType, "id", null),
      designType: providedDesignTypes ? providedDesignTypes.map(({ id }) => id) : [],
      systemPhotoFiles: providedSystemPhotos
        ? providedSystemPhotos.map(eachPhoto => ({
            id: eachPhoto.id,
            filename: eachPhoto.filename || "",
            originalUrl: eachPhoto.originalUrl
          }))
        : [],
      systemDocumentFiles: providedSystemDocuments
        ? providedSystemDocuments.map(eachDocument => ({
            id: eachDocument.id,
            filename: eachDocument.filename || "",
            originalUrl: eachDocument.originalUrl
          }))
        : [],
      airflow: providedAttributes.airflow === "no" ? null : createNumberOrNull(providedAttributes.airflow),
      airflowEnabled: isOptionalValueEnabled(providedAttributes.airflow),
      minimumMaterialAmount:
        providedAttributes.minimumMaterialAmount === "no"
          ? null
          : createNumberOrNull(providedAttributes.minimumMaterialAmount),
      minimumMaterialAmountEnabled: isOptionalValueEnabled(providedAttributes.minimumMaterialAmount),
      targetIndustries: providedIndustries ? providedIndustries.map(({ id }) => id) : [],
      materialCategories: providedMaterialCategories ? providedMaterialCategories.map(({ id }) => id) : []
    });
  }, [systemsStore, systemId]);

  return {
    system,
    fetchSystem
  };
};

export function getAnalyzingProcessId(commonsSettings: CommonStoreType["commonSettings"]) {
  return get(
    commonsSettings!.processes.find(({ name }) => name === "analyzing"),
    "id"
  );
}

export function useMaterialCategoriesOptions(providedIndustryIds?: string[] | null) {
  const { t } = useTranslation();
  const industryIds = providedIndustryIds || [];
  const { commonsStore } = useStore();
  const { materialCategoriesByIndustries } = commonsStore.getSettings();

  const uniqueMaterialCategories: {
    [Key: string]: SettingItem;
  } = {};

  industryIds.forEach(eachIndustryId => {
    const foundCategories = materialCategoriesByIndustries.get(eachIndustryId);

    if (foundCategories) {
      foundCategories.forEach(eachCategory => {
        uniqueMaterialCategories[eachCategory.id] = eachCategory;
      });
    }
  });

  const options = settingItemsToSelectOptions(t)(
    "materialCategoryOptions",
    Object.values(uniqueMaterialCategories)
  ).sort((a, b) => a.label.localeCompare(b.label));

  return options;
}
