import classNames from "classnames";
import { FormikActions } from "formik";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "triangular/components/form/Form";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { Typography } from "triangular/components/Typography/Typography";
import { FormAdditionalContext } from "triangular/utils/components";

import { DefaultFormButtons } from "../DefaultFormButtons";

import { AdditionalInfo } from "./AdditionalInfo/AdditionalInfo";
import css from "./MaterialForm.module.scss";
import { createMaterialValidationSchema, materialDefaultValues, MaterialSchema } from "./materialSchema";
import { PlantProtection } from "./PlantProtection/PlantProtection";
import { RequiredMaterialInfo } from "./RequiredMaterialInfo/RequiredMaterialInfo";
import { Safety } from "./Safety/Safety";
import { Technology } from "./Technology/Technology";
import { UploadFiles } from "./UploadFiles";

interface MaterialFormProps {
  initialValues: Partial<MaterialSchema>;
  materialId?: string;
  onSubmit?(values: MaterialSchema, actions: FormikActions<MaterialSchema>): void;
  onSave?(values: MaterialSchema): void;
  onGoBack(): void;
  onSavedModalClose?(): void;
}

export const MaterialForm: React.FC<MaterialFormProps> = observer(
  ({ initialValues, onSubmit, onSave, onGoBack, onSavedModalClose }) => {
    const { t } = useTranslation();
    const materialValidationSchema = createMaterialValidationSchema(t);
    const { isReadOnly } = useContext(FormAdditionalContext);

    return (
      <Form
        initialValues={{ ...materialDefaultValues, ...initialValues }}
        validationSchema={materialValidationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
        render={() => {
          return (
            <>
              <FormSection>
                <div className={classNames("d-flex justify-content-center", css.buttonSpace)}>
                  <DefaultFormButtons
                    onGoBack={onGoBack}
                    submitButtonLabel={t("publish")}
                    confirmPromptTitle={t("addMaterial.submitConfirmation.header")}
                    confirmPromptMessage={t("addMaterial.submitConfirmation.message")}
                    savedModalMessage={t("addMaterial.systemSavedModal.message")}
                    savedModalTitle={t("addMaterial.systemSavedModal.title")}
                    fieldsToValidateBeforeSave={["name"]}
                    onSavedModalClose={onSavedModalClose}
                  />
                </div>
              </FormSection>
              <RequiredMaterialInfo />
              <Technology />
              <Safety />
              <PlantProtection />
              <AdditionalInfo />
              <UploadFiles />
              <FormSection>
                {!isReadOnly && (
                  <div className={css.requiredField}>
                    <Typography size="small">{t("requiredField")}</Typography>
                  </div>
                )}
                <DefaultFormButtons
                  onGoBack={onGoBack}
                  onSave={onSave}
                  confirmPromptTitle={t("materialForm.submitConfirmation.header")}
                  confirmPromptMessage={t("materialForm.submitConfirmation.message")}
                  savedModalMessage={t("materialForm.materialSavedModal.message")}
                  savedModalTitle={t("materialForm.materialSavedModal.title")}
                  fieldsToValidateBeforeSave={["name"]}
                  submitButtonLabel={t("publish")}
                  onSavedModalClose={onSavedModalClose}
                />
              </FormSection>
            </>
          );
        }}
      />
    );
  }
);
