import { getParent, types } from "mobx-state-tree";

import { EntityBlueprint, UrlConfig } from "triangular/services/Api/Resource/utils/types";
import { MaterialEntity } from "triangular/services/Api/resources/MaterialResource";

import { SnackbarStoreType } from "../SnackbarStore";
import { createStore } from "../utils/createStore";
import { createStateMerger } from "../utils/state";

import { materialSettingsModel } from "./materialSettingsModel";

export type MaterialsStoreModel = ReturnType<typeof createMaterialsStoreModel>;

export const createMaterialsStoreModel = () =>
  types.model("MaterialsStore", {
    materialSettings: types.maybeNull(materialSettingsModel)
  });

export const MaterialsStore = createStore(storeDeps =>
  createMaterialsStoreModel()
    .actions(self => ({
      mergeWithState: createStateMerger(self),
      getSnackbars() {
        return getParent(self).snackbarStore as SnackbarStoreType;
      },
      getSettings() {
        if (!self.materialSettings) {
          throw new Error("Settings not available!");
        }

        return self.materialSettings;
      }
    }))
    .actions(self => ({
      async loadSettings() {
        if (self.materialSettings) {
          return;
        }

        const {
          data: [fetchedSettings]
        } = await storeDeps.api.materialSettings.find();

        const { materialIndexes = [], hazardousness = [], flowingProperties = [], storages = [] } = fetchedSettings;

        self.mergeWithState({
          materialSettings: {
            materialIndexes,
            flowingProperties,
            hazardousness,
            storages
          }
        });
      },
      async getMaterial(id: string, config: UrlConfig<MaterialEntity>) {
        const {
          data: [material]
        } = await storeDeps.api.material.find({ id, ...config });
        return material;
      },
      async createMaterial(params: Partial<EntityBlueprint<MaterialEntity>>) {
        const [data] = await storeDeps.api.material.create(params);
        return data;
      },
      async updateMaterial(id: string, params: Partial<EntityBlueprint<MaterialEntity>>) {
        const [data] = await storeDeps.api.material.update(id, params);
        return data;
      },
      uploadMaterialSafetyFile(filename: string, data: string) {
        return storeDeps.api.materialSafetyFile.create({ filename, data });
      },
      uploadMaterialTechFile(filename: string, data: string) {
        return storeDeps.api.materialTechFile.create({ filename, data });
      },
      deleteMaterialSafetyFile(id: string) {
        return storeDeps.api.materialSafetyFile.delete(id);
      },
      deleteMaterialTechFile(id: string) {
        return storeDeps.api.materialTechFile.delete(id);
      },
      cleanUp() {
        const { materialSettings, ...initialValues } = materialsStoreInitialState;
        self.mergeWithState(initialValues);
      }
    }))
);

export const materialsStoreInitialState = {
  materialSettings: null
};

export type MaterialStoreType = ReturnType<typeof MaterialsStore>["Type"];
export type MaterialStoreSnapshot = ReturnType<typeof MaterialsStore>["SnapshotType"];
export type FetchedMaterial = FromPromise<ReturnType<MaterialStoreType["getMaterial"]>>;
export type FetchedMaterialSettings = NonNullable<MaterialsStoreModel["Type"]["materialSettings"]>;
