import classNames from "classnames";
import noop from "lodash/noop";
import { observer } from "mobx-react-lite";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "triangular/components/Button/Button";
import { StripeCardForm } from "triangular/components/form/stripe/StripeCardForm";
import { StripeInvoiceForm } from "triangular/components/form/stripe/StripeInvoiceForm";
import { StripeSepaForm } from "triangular/components/form/stripe/StripeSepaForm";
import { WithTooltip } from "triangular/components/WithTooltip/WithTooltip";

import { PaymentMethodFormProps } from "../types";

import css from "./PaymentMethodForm.module.scss";

const stripeForms = {
  sepa: StripeSepaForm,
  card: StripeCardForm,
  invoice: StripeInvoiceForm
};

export const PaymentMethodForm = observer<PaymentMethodFormProps>(props => {
  const { t } = useTranslation();
  const [currentMethod, setCurrentMethod] = useState<keyof typeof stripeForms>("card");

  const MethodForm = stripeForms[currentMethod];

  const MethodButton = ({ type, comingSoon = false }: { type: keyof typeof stripeForms; comingSoon?: boolean }) => {
    const comingSoonTooltipId = `${type}PaymentComingSoonTooltip`;
    const comingSoonTooltipProps = {
      "data-tip": "true",
      "data-for": comingSoonTooltipId
    };

    const ButtonWrapper = comingSoon
      ? ({ children }: { children: React.ReactNode }) => (
          <WithTooltip tooltipMessage={t("paymentMethodForm.comingSoon")}>{children}</WithTooltip>
        )
      : Fragment;

    return (
      <ButtonWrapper>
        <Button
          className={classNames(css.methodButton, { [css.methodButtonComingSoon]: comingSoon })}
          onClick={comingSoon ? noop : () => setCurrentMethod(type)}
          disabled={currentMethod === type}
          transparent={currentMethod !== type}
          size="big"
          {...(comingSoon ? comingSoonTooltipProps : {})}
        >
          {t(`payments.methodButton.${type}`)}
        </Button>
      </ButtonWrapper>
    );
  };

  return (
    <div className={css.wrapper}>
      <div className={css.buttons}>
        <MethodButton type="card" />
        <MethodButton type="invoice" />
        <MethodButton type="sepa" comingSoon={true} />
      </div>
      <MethodForm {...props} />
    </div>
  );
});
