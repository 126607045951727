import classNames from "classnames";
import React from "react";

import css from "./Hr.module.scss";

interface HrProps {
  className?: string;
  style?: object;
}

export const Hr: React.FC<HrProps> = ({ className, style }) => {
  return <hr style={style} className={classNames(css.hr, className)} />;
};
