import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { Hr } from "triangular/components/Hr/Hr";
import { ReadMore } from "triangular/components/ReadMore/ReadMore";
import { Typography } from "triangular/components/Typography/Typography";
import { FetchedSystem } from "triangular/stores/SystemsStore/SystemsStore";

import { Section } from "./Section";
import css from "./SystemDetails.module.scss";

interface SystemDetailsTextProps {
  type: "additional" | "safety";
  system: FetchedSystem;
}

export const SystemDetailsText: React.FC<SystemDetailsTextProps> = observer(({ type, system }) => {
  const { t } = useTranslation();
  const isAdditional = type === "additional";
  const title = t(isAdditional ? "systemDetails.additionalInfo" : "systemDetails.safetyInfo");
  const content = isAdditional ? system.additionalInfo : system.safetyMeasures;

  if (!content) {
    return null;
  }

  return (
    <>
      <Section>
        <div className={css.additionalInfo}>
          <Typography component="h3" bold={true} size="big" upperCase={true} color="mint">
            {title}
          </Typography>
          <ReadMore variant="dark" limit={700} className={{ p: css.paragraph }} content={content || undefined} />
        </div>
      </Section>
      <Hr className={css.hr} />
    </>
  );
});
