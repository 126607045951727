import { types } from "mobx-state-tree";

export type FilterCategories = "languages" | "processCategories" | "industries" | "materialCategories";

export const filtersModel = types.model("Filters", {
  languages: types.maybeNull(types.array(types.string)),
  processCategories: types.maybeNull(types.array(types.string)),
  industries: types.maybeNull(types.array(types.string)),
  specialties: types.maybeNull(types.array(types.string)),
  materialCategories: types.maybeNull(types.array(types.string))
});

export type ExpertFiltersModelSnapshot = typeof filtersModel["SnapshotType"];
