import { types } from "mobx-state-tree";

import { createStore } from "./utils/createStore";

const ownerResources = {
  system_owners: "systemOwner" as const,
  material_owners: "materialOwner" as const
};

type OwnerType = keyof typeof ownerResources;

export const OwnerStore = createStore(({ api }) =>
  types
    .model("OwnerStore", {
      profile: types.maybeNull(
        types.model({
          firstName: types.string,
          lastName: types.string,
          email: types.string,
          phoneNumber: types.string,
          company: types.model({
            countryCode: types.string,
            name: types.string,
            number: types.string
          })
        })
      )
    })
    .actions(self => ({
      setProfile(profile: typeof self.profile) {
        self.profile = profile;
      }
    }))
    .actions(self => ({
      updateProfile(
        type: OwnerType,
        data: {
          id: string;
          firstName?: string;
          lastName?: string;
          phoneNumber?: string;
        }
      ) {
        return api[ownerResources[type]].update(data.id, data);
      },
      async getProfile(type: OwnerType, id: string | null) {
        if (!id) {
          throw new Error("Invalid user id");
        }

        const { data } = await api[ownerResources[type]].findById(id);

        self.setProfile({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          company: data.company || { countryCode: "", name: "", number: "" }
        });
      }
    }))
);

export const ownerStoreInitialState = {
  profile: null
};
