import { useCallback, useEffect, useState } from "react";

import { PaymentMethodItems } from "triangular/stores/PaymentsStore";
import { useStore } from "triangular/stores/StoreContext";
import { useLoadable } from "triangular/utils/components";

export const usePaymentMethods = () => {
  const { paymentsStore } = useStore();
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodItems>([]);
  const { loadingState, setLoadable } = useLoadable();

  const reloadPayments = useCallback(async () => {
    await setPaymentMethods(await paymentsStore.fetchPaymentMethods());
    await paymentsStore.fetchSubscription();
  }, [paymentsStore]);

  const fetchPaymentMethods = useCallback(() => setLoadable(reloadPayments), [reloadPayments, setLoadable]);

  const createPaymentMethod = useCallback(
    async (paymentMethodId: string) => {
      await paymentsStore.createPaymentMethod(paymentMethodId);
      await reloadPayments();
    },
    [paymentsStore, reloadPayments]
  );

  const deletePaymentMethod = (paymentMethodId: string) =>
    setLoadable(async () => {
      await paymentsStore.deletePaymentMethod(paymentMethodId);
      await reloadPayments();
    });

  const setDefaultPaymentMethod = (paymentMethodId: string) =>
    setLoadable(async () => {
      if (paymentMethodId === "invoice") {
        await paymentsStore.setupManualPayment();
      } else {
        await paymentsStore.updateSubscriptionPaymentMethod(paymentMethodId);
      }
      await setPaymentMethods(await paymentsStore.fetchPaymentMethods());
      await reloadPayments();
    });

  useEffect(() => {
    fetchPaymentMethods();
  }, [fetchPaymentMethods]);

  return {
    loadingState,
    paymentMethods,
    createPaymentMethod,
    deletePaymentMethod,
    setDefaultPaymentMethod,
    fetchPaymentMethods
  };
};
