import { AxiosInstance } from "axios";

import { Resource } from "../Resource/Resource";
import { Entity } from "../Resource/utils/types";

type ForgotPasswordEntity = Entity<
  {
    email: string;
  },
  {}
>;

export class PasswordRecoveryResource extends Resource<ForgotPasswordEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "password_recoveries",
      prefixes: ["auth"]
    });
  }
}
