import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { FormSection } from "triangular/components/form/FormSection/FormSection";

import { DropDownHeader } from "../../DropDownHeader/DropDownHeader";
import { AbrasivenessField } from "../../system/AbrasivenessField/AbrasivenessField";
import { TextField } from "../../TextField";

import { ColorField } from "./ColorField/ColorField";

export const PlantProtection = React.memo(() => {
  const { t } = useTranslation();
  const currentT = (path: string) => t(`materialForm.plantProtection.${path}`);

  const indexNum = "3";
  const [activeKey, setActiveKey] = useState(indexNum);

  return (
    <Accordion>
      <FormSection>
        <DropDownHeader
          eventKeyNum={indexNum}
          handleClick={() => {
            if (activeKey === indexNum) {
              setActiveKey("");
            } else {
              setActiveKey(indexNum);
            }
          }}
        >
          {t("materialForm.plantProtection.plantProtection")}
        </DropDownHeader>
        <Accordion.Collapse eventKey={indexNum}>
          <FieldsRow>
            <FieldWrapper>
              <ColorField label={currentT("color")} />
            </FieldWrapper>
            <FieldWrapper>
              <AbrasivenessField label={currentT("abrasiveness")} />
            </FieldWrapper>
            <FieldWrapper>
              <TextField
                name="corrosiveness"
                label={currentT("corrosiveness")}
                data-testid="corrosiveness"
                placeholder={currentT("corrosivenessPlaceholder")}
              />
            </FieldWrapper>
          </FieldsRow>
        </Accordion.Collapse>
      </FormSection>
    </Accordion>
  );
});
