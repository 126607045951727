import React, { useCallback, useState } from "react";
import ReactEasyCrop from "react-easy-crop";
import { Area } from "react-easy-crop/types";
import { useTranslation } from "react-i18next";

import { DefaultFormButtons } from "triangular/components/form/DefaultFormButtons";
import { Form } from "triangular/components/form/Form";
import { Modal, ModalProps } from "triangular/components/Modal/Modal";
import { Slider } from "triangular/components/Slider/Slider";
import { Typography } from "triangular/components/Typography/Typography";

import css from "./EditorModal.module.scss";
import { cropImage } from "./utils";

export const EditorModal = ({
  isOpen,
  onClose,
  imageData,
  onUpload,
  size
}: {
  isOpen: ModalProps["isOpen"];
  onClose: ModalProps["onClose"];
  imageData: string;
  onUpload: (imageDate: string) => Promise<void>;
  size: number;
}) => {
  const { t } = useTranslation();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const maxZoom = 3;
  const [minZoom, setMinZoom] = useState(0.5);
  const [zoom, setZoom] = useState(minZoom);
  const [pixelCrop, setPixelCrop] = useState<Area | null>(null);

  const handleUpload = useCallback(async () => {
    if (pixelCrop === null) {
      throw new Error("pixelCrop is null!");
    }

    const croppedImage = await cropImage({ imageSrc: imageData, pixelCrop, quality: 0.95, size });
    await onUpload(croppedImage);
    onClose();
  }, [pixelCrop, imageData, size, onUpload, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={{ modal: css.modal }} closeOnOverlayClick={false}>
      <Form
        initialValues={{}}
        onSubmit={handleUpload}
        render={() => (
          <>
            <div className={css.modalContentWrapper}>
              <ReactEasyCrop
                image={imageData}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={(_pixelCrop, pixelCrop) => {
                  setPixelCrop(pixelCrop);
                }}
                classes={{
                  containerClassName: css.cropContainer,
                  cropAreaClassName: css.cropArea
                }}
                cropSize={{
                  width: size,
                  height: size
                }}
                minZoom={minZoom}
                onMediaLoaded={media => {
                  if (media.height === 0 || media.width === 0) {
                    return;
                  }

                  const smallestDimension = Math.min(media.height, media.width);
                  const actualMinZoom = Math.round((128 / smallestDimension) * 100) / 100;

                  if (smallestDimension * minZoom < 128) {
                    setMinZoom(actualMinZoom);
                    setZoom(actualMinZoom);
                  }
                }}
              />
            </div>
            <div className={css.slider}>
              <Typography verticalSpacing="medium" component="div" size="small" className={css.sliderTitle}>
                {t("editExpert.editorModal.zoom")}
              </Typography>
              <Slider
                onUpdate={([value]) => setZoom(value)}
                values={[zoom]}
                mode="preventCrossing"
                step={0.001}
                domain={[minZoom, maxZoom]}
                areTicksVisible={false}
              />
            </div>
            <div className={css.buttons}>
              <DefaultFormButtons
                confirmPromptTitle={t("editExpert.editorModal.confirmationHeader")}
                confirmPromptMessage={t("editExpert.editorModal.confirmationMessage")}
                onGoBack={onClose}
              />
            </div>
          </>
        )}
      ></Form>
    </Modal>
  );
};
