import React from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { LabeledSelect } from "triangular/components/LabeledSelect/LabeledSelect";
import { useStore } from "triangular/stores/StoreContext";
import { settingItemsToSelectOptions } from "triangular/utils/systems";

import { useSystemsPaginationContext } from "../utils/pagination";
import { updateIndustryIds } from "../utils/updates";

export const TargetIndustries: React.FC = () => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const { paginationQuery, buildUrl } = useSystemsPaginationContext();
  const { commonsStore } = useStore();
  const commonSettings = commonsStore.getSettings();

  const industrySettings = commonSettings.industries;
  const noRestrictionsIndustryId = commonSettings.noRestrictionsIds.industry;

  const selectedIndustryIds = paginationQuery.industryIds || [];

  const options = settingItemsToSelectOptions(t)("targetIndustryOptions", industrySettings);

  const handleChange = (selectedValue: string | string[]) =>
    history.push(buildUrl(() => updateIndustryIds(selectedValue as string[])));

  return (
    <LabeledSelect
      label={t("systems.targetIndustries")}
      value={selectedIndustryIds}
      options={options}
      onChange={handleChange}
      autoComplete={true}
      data-testid="targetIndustriesFilter"
      noRestrictionsValue={noRestrictionsIndustryId}
    />
  );
};
