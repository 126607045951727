import get from "lodash/get";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "triangular/components/Avatar/Avatar";
import { BackButton } from "triangular/components/BackButton/BackButton";
import { Typography } from "triangular/components/Typography/Typography";

import { ExpertPublicData } from "./ExpertDetails";
import css from "./ExpertDetails.module.scss";
import { Section } from "./Section";

interface ExpertDetailsContentProps {
  expert: ExpertPublicData;
  origin?: string | null;
}
export const getExpertTitle = (title?: string | null, description?: string | null, company?: string | null) => {
  const emptyExperience = {
    title: null,
    description: null,
    company: null
  };

  const currentExperience = {
    title: title,
    description: description,
    company: company
  };

  return currentExperience
    ? {
        ...emptyExperience,
        title: currentExperience.title,
        description: currentExperience.description,
        company: currentExperience.company
      }
    : emptyExperience;
};

export const ExpertDetailsHeader: React.FC<ExpertDetailsContentProps> = observer(({ expert, origin }) => {
  const { t } = useTranslation();

  const { title, description, company } = getExpertTitle(
    expert.showcaseTitle,
    expert.description,
    expert.expertCompany
  );
  const avatar = get(expert, "avatar.originalUrl", "");

  return (
    <>
      <Section>
        <BackButton />
      </Section>
      {origin === "search" ? (
        <Section>
          <Typography component="h1" bold={true} size="large" className={css.originTitle}>
            {t("expertDetails.searchComment")}
          </Typography>
        </Section>
      ) : null}
      <Section>
        <header className={css.header}>
          <div className={css.avatarWrapper}>
            <Avatar className={css.avatar} imageUrl={avatar} />
          </div>

          <div className={css.personalDetails}>
            <Typography component="div" bold={true} size="large" className={css.position}>
              {company}
            </Typography>

            <Typography component="h2" bold={true} size="mediumlarge" className={css.expertName}>
              {expert.firstName} {expert.lastName}
            </Typography>

            <Typography component="div" bold={true} size="big" className={css.position}>
              {title}
            </Typography>

            <Typography component="div" bold={true} size="small" className={css.subtitle}>
              {description}
            </Typography>
          </div>
        </header>
      </Section>
    </>
  );
});
