import * as Yup from "yup";

import { paginationSchema as systemSearchPaginationSchema } from "triangular/scenes/SystemSearch/utils/pagination";
import { Location } from "triangular/utils/routes/Location";

export const routes = {
  home: new Location("/"),
  registration: {
    root: new Location("/registration"),
    profileType: new Location("/registration/profile-type"),
    personalInfo: new Location("/registration/personal-info"),
    companyInfo: new Location("/registration/company-info")
  },
  login: new Location("/login"),
  accountConfirmation: new Location(
    "/account-confirmation",
    Yup.object({
      token: Yup.string()
        .required()
        .trim()
    })
  ),
  myMaterialsList: new Location("/my-materials"),
  myMaterialDetails: new Location(
    "/my-materials/:id",
    Yup.object({
      id: Yup.string()
        .required()
        .trim()
    })
  ),
  mySystemsList: new Location("/my-systems"),
  mySystemDetails: new Location(
    "/my-systems/:id",
    Yup.object({
      id: Yup.string()
        .required()
        .trim()
    })
  ),
  myExpertProfile: new Location("/my-expert-profile"),
  addSystem: new Location("/add-system"),
  editSystem: new Location(
    "/edit-system/:id",
    Yup.object({
      id: Yup.string()
        .required()
        .trim()
    })
  ),
  systems: new Location("/systems"),
  systemsForRequest: new Location(
    "/materials/:materialId/systems-for-request",
    systemSearchPaginationSchema.shape({ materialId: Yup.string().required() })
  ),
  systemDetailsForRequest: new Location(
    "/materials/:materialId/systems-for-request/:id",
    Yup.object({
      id: Yup.string(),
      materialId: Yup.string()
    })
  ),
  systemDetails: new Location(
    "/systems/:id",
    Yup.object({
      id: Yup.string()
        .required()
        .trim()
    })
  ),
  passwordRecovery: {
    root: new Location("/forgot-password"),
    changePassword: new Location(
      "/forgot-password/change-password",
      Yup.object({
        token: Yup.string()
          .required()
          .trim()
      })
    )
  },
  changePassword: new Location("/change-password"),
  systemRequest: new Location(
    "/system-request/:systemId",
    Yup.object({
      systemId: Yup.string().trim(),
      materialId: Yup.string()
        .trim()
        .notRequired()
    })
  ),
  addMaterial: new Location(
    "/add-material",
    Yup.object({
      copyFrom: Yup.string()
        .notRequired()
        .trim()
    })
  ),
  editMaterial: new Location(
    "/edit-material/:id",
    Yup.object({
      id: Yup.string()
        .required()
        .trim()
    })
  ),
  experts: new Location("/experts"),
  expertDetails: new Location(
    "/experts/:id",
    Yup.object({
      id: Yup.string()
        .required()
        .trim()
    })
  ),
  editProfile: new Location("/edit-profile"),
  payments: new Location("/payments")
};

const landingPageUrl = process.env.REACT_APP_LP_URL;

export const createLandingPageRoutes = (language: string) => {
  const landingPageDisclaimerUrlWithLanguage = `${landingPageUrl}/disclaimers/${language}`;

  return {
    imprint: `${landingPageDisclaimerUrlWithLanguage}/imprint`,
    termsOfUse: `${landingPageDisclaimerUrlWithLanguage}/terms-of-use`,
    privacyPolicy: `${landingPageDisclaimerUrlWithLanguage}/privacy-policy`
  };
};

export const privateRouteFallback = routes.login;
export const GuestRouteFallback = routes.home;

export type RouteParameters<T extends Location<any>> = Parameters<T["build"]>[0];
