export const updateProcessCategoryId = (processCategoryId: string | null) => ({
  processCategoryId,
  systemCategoryId: null,
  systemTypeId: null
});

export const updateSystemCategoryIds = (systemCategoryId: string[] | null) => ({
  systemCategoryId,
  systemTypeId: null
});

export const updateSystemTypeIds = (systemTypeId: string[] | null) => ({ systemTypeId });
export const updateColorsWhitelist = (colorsWhitelist: string[] | null) => ({ colorsWhitelist });
export const updateInstalledPowerKw = (installedPowerKw: number[] | null) => ({ installedPowerKw });
export const updateMaterialsWhitelist = (materialsWhitelist: string[] | null) => ({ materialsWhitelist });
export const updateMaterialsBlacklist = (materialsBlacklist: string[] | null) => ({ materialsBlacklist });
export const updateCountryCode = (countryCode: string | null) => ({ countryCode });
export const updateContactMaterials = (contactMaterials: string[] | null) => ({ contactMaterials });
export const updateNonContactMaterials = (nonContactMaterials: string[] | null) => ({ nonContactMaterials });
export const updateIndustryIds = (industryIds: string[] | null) => ({
  industryIds,
  materialCategoryIds: []
});
export const updateMaterialCategoryIds = (materialCategoryIds: string[] | null) => ({ materialCategoryIds });
