import uniqueId from "lodash/uniqueId";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Breakpoint } from "triangular/components/Breakpoint/Breakpoint";
import { TriLink } from "triangular/components/TriLink/TriLink";
import { createLandingPageRoutes } from "triangular/Routes/routesConfiguration";

import { FooterLogo } from "../FooterLogo/FooterLogo";
import { Logo } from "../Logo/Logo";

import { Address } from "./Address/Address";
import css from "./Footer.module.scss";

interface FooterLink {
  name: string;
  path: string;
  id: string;
}

export const Footer: React.FC = () => {
  const { t, i18n } = useTranslation();
  const landingPageRoutes = createLandingPageRoutes(i18n.language);

  const FOOTER_LINKS: FooterLink[] = [
    {
      name: t("footer.menu.imprint"),
      path: landingPageRoutes.imprint,
      id: uniqueId()
    },
    {
      name: t("footer.menu.termsOfUse"),
      path: landingPageRoutes.termsOfUse,
      id: uniqueId()
    },
    {
      name: t("footer.menu.privacyPolicy"),
      path: landingPageRoutes.privacyPolicy,
      id: uniqueId()
    }
  ];

  return (
    <footer className={css.footer}>
      <Container fluid className={css.container}>
        <Row>
          <Col md={3} lg={3} className={css.logoContainer}>
            <Breakpoint minWidth="small" maxWidth="small">
              <Logo />
            </Breakpoint>
            <Breakpoint minWidth="medium" maxWidth="large">
              <FooterLogo />
            </Breakpoint>
          </Col>
          <Col md={3} lg={3}>
            <Address />
          </Col>
          <Col md={{ span: 3, offset: 3 }} lg={{ span: 3, offset: 3 }}>
            <div className={css.footerDetails}>
              <nav className={css.menu}>
                {FOOTER_LINKS.slice(0, 3).map(item => (
                  <li key={item.id}>
                    <TriLink to={item.path} external={true}>
                      {item.name}
                    </TriLink>
                  </li>
                ))}
              </nav>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
