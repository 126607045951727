import { format } from "date-fns";

import { i18n } from "triangular/i18next/i18next";

export function formatDate(date: string | number | Date, withTime = true) {
  return withTime ? format(date, "YYYY/MM/DD HH:mm") : format(date, "YYYY/MM/DD");
}

export function formatPeriod(startDate: string, endDate: string | null) {
  const from = format(startDate, "MM/YYYY");
  const to = endDate ? format(endDate, "MM/YYYY") : i18n.t("present");
  return `${from} - ${to}`;
}

export const formatInputDate = (date: Date) => format(date, "YYYY-MM-DD");
