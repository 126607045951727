import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { Layout } from "triangular/components/Layout/Layout";
import { PageTitle } from "triangular/components/PageTitle/PageTitle";
import { useStore } from "triangular/stores/StoreContext";

import css from "./EditProfile.module.scss";
import { ExpertForm } from "./ExpertForm/ExpertForm";
import { OwnerForm } from "./OwnerForm/OwnerForm";

const getFormComponent = (profileType: ProfileType | null) => {
  const ownerTypes: ProfileType[] = ["system_owners", "material_owners"];

  if (ownerTypes.includes(profileType as ProfileType)) {
    return OwnerForm;
  }

  return ExpertForm;
};

export const EditProfile = observer<RouteComponentProps>(props => {
  const { userStore } = useStore();
  const FormComponent = getFormComponent(userStore.type);
  const { t } = useTranslation();

  return (
    <Layout withMolecules={false}>
      <PageTitle
        className={{
          title: css.title,
          description: css.description
        }}
        title={t("editProfile.sceneTitle")}
      />
      <FormComponent {...props} />
    </Layout>
  );
});
