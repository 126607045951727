import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Alert } from "triangular/components/Alert/Alert";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { SubscriptionPlanForm } from "triangular/components/form/SubscriptionPlanForm/SubscriptionPlanForm";
import { LinkButton } from "triangular/components/LinkButton/LinkButton";
import { useModal } from "triangular/components/Modal/Modal";
import { Prompt } from "triangular/components/Prompt/Prompt";
import { SectionTitle } from "triangular/components/SectionTitle/SectionTitle";
import { SubscriptionPlanBlockedTooltip } from "triangular/components/SubscriptionPlanBlockedTooltip/SubscriptionPlanBlockedTooltip";
import { Typography } from "triangular/components/Typography/Typography";
import { useStore } from "triangular/stores/StoreContext";
import { useLoadable } from "triangular/utils/components";
import { formatDate } from "triangular/utils/dates";

import css from "./SubscriptionPlan.module.scss";
import { UpgradeToMasterPrompt } from "./UpgradeToMasterPrompt";

export const SubscriptionPlan = observer(() => {
  const { paymentsStore, snackbarStore, userStore, switchProfileType } = useStore();
  const { subscription } = paymentsStore;
  const { t } = useTranslation();
  const planChangeConfirmationModal = useModal<{
    level: SubscriptionLevel;
    subscriptionInterval: SubscriptionInterval;
  }>();
  const subscriptionCancelConfirmationModal = useModal();
  const upgradeToMasterModal = useModal();
  const planUpdating = useLoadable("done");
  const planCancelling = useLoadable("done");
  const isSubscriptionUnpaid = subscription.status === "unpaid";
  const isSubscriptionCancelled = subscription.status === "active_cancelled";

  const updatePlan = useCallback(async () => {
    if (!planChangeConfirmationModal.params) {
      throw new Error("Subscription plan update params not available");
    }

    if (!userStore.accountType) {
      throw new Error("Account type not available");
    }

    await paymentsStore.updateSubscriptionPlan({
      accountType: userStore.accountType,
      level: planChangeConfirmationModal.params.level,
      billingInterval: planChangeConfirmationModal.params.subscriptionInterval
    });

    snackbarStore.addSnackbar({
      type: "success",
      message: t("payments.planChangedSuccessfully")
    });
  }, [planChangeConfirmationModal.params, userStore.accountType, paymentsStore, snackbarStore, t]);

  const cancelSubscription = useCallback(async () => {
    await paymentsStore.cancelSubscription();

    snackbarStore.addSnackbar({
      type: "success",
      message: t("payments.subscriptionCancelledSuccessfully")
    });

    paymentsStore.setSubscription({
      status: "active_cancelled"
    });
  }, [paymentsStore, snackbarStore, t]);

  const getSubscriptionIntervalLabel = useCallback(
    (type: SubscriptionInterval) => {
      const labels: { [Key in SubscriptionInterval]: string } = {
        year: t("registration.subscriptionInterval.labelAnnually"),
        month: t("registration.subscriptionInterval.labelMonthly")
      };
      return labels[type];
    },
    [t]
  );

  const upgradeToMaster = useCallback(async () => {
    await paymentsStore.upgradeToMaster();
    snackbarStore.addSnackbar({
      type: "success",
      message: t("payments.planChangedSuccessfully")
    });
    await switchProfileType(userStore.getProfileType());
  }, [paymentsStore, snackbarStore, switchProfileType, t, userStore]);

  const getPlanChangeConfirmationMessage = useCallback(() => {
    const oldLevel = subscription.level;
    const oldInterval = getSubscriptionIntervalLabel(subscription.billingInterval as SubscriptionInterval);
    const newLevel = planChangeConfirmationModal.params && planChangeConfirmationModal.params.level;
    const newInterval =
      planChangeConfirmationModal.params &&
      getSubscriptionIntervalLabel(planChangeConfirmationModal.params.subscriptionInterval);

    if (!planChangeConfirmationModal.isOpen || !oldLevel || !newLevel) {
      return null;
    }

    const levelWeights = {
      basic: 0,
      advanced: 1,
      professional: 2
    };

    const isLevelDowngraded = levelWeights[oldLevel] > levelWeights[newLevel];
    const isLevelUnchanged = oldLevel === newLevel;
    const shouldShowAdditionalMessage = isLevelDowngraded || isLevelUnchanged;

    const additionalMessage = shouldShowAdditionalMessage
      ? t("payments.planChangeConfirmationModal.additionalMessage")
      : "";

    return t("payments.planChangeConfirmationModal.message", {
      oldLevel,
      oldInterval,
      newLevel,
      newInterval
    }).concat(" ", additionalMessage);
  }, [
    getSubscriptionIntervalLabel,
    planChangeConfirmationModal.isOpen,
    planChangeConfirmationModal.params,
    subscription.billingInterval,
    subscription.level,
    t
  ]);

  return (
    <>
      <FormSection>
        <div className={css.topSection}>
          <SectionTitle>{t("payments.subscriptionPlan")}</SectionTitle>
          <SectionTitle>
            {userStore.accountType === "regular" && (
              <SubscriptionPlanBlockedTooltip
                isSubscriptionCancelled={isSubscriptionCancelled}
                isSubscriptionUnpaid={isSubscriptionCancelled}
              >
                <LinkButton
                  className={css.upgradeButton}
                  onClick={upgradeToMasterModal.open}
                  disabled={isSubscriptionCancelled || isSubscriptionUnpaid}
                >
                  {t("payments.upgradeToMaster")}
                </LinkButton>
              </SubscriptionPlanBlockedTooltip>
            )}
          </SectionTitle>
        </div>
      </FormSection>
      <SubscriptionPlanForm
        hideSubmit={true}
        onSubmit={({ level, subscriptionInterval }) => {
          if (level !== subscription.level || subscriptionInterval !== subscription.billingInterval) {
            planChangeConfirmationModal.open({
              level,
              subscriptionInterval
            });
          }
        }}
        onCancel={() => subscriptionCancelConfirmationModal.open()}
        isUpdating={planUpdating.loadingState === "in_progress"}
        isCancelling={planCancelling.loadingState === "in_progress"}
        initialValues={{
          level: subscription.level,
          subscriptionInterval: subscription.billingInterval
        }}
        isSubscriptionUnpaid={isSubscriptionUnpaid}
        isSubscriptionCancelled={isSubscriptionCancelled}
      />
      <Prompt
        isOpen={planChangeConfirmationModal.isOpen}
        onClose={planChangeConfirmationModal.close}
        onAgree={updatePlan}
        header={t("payments.planChangeConfirmationModal.title")}
        message={getPlanChangeConfirmationMessage()}
      />
      <Prompt
        isOpen={subscriptionCancelConfirmationModal.isOpen}
        onClose={subscriptionCancelConfirmationModal.close}
        onAgree={cancelSubscription}
        header={t("payments.subscriptionCancelConfirmationModal.title")}
        message={
          <>
            <Typography verticalSpacing="large" component="p" bold={true}>
              {t("payments.subscriptionCancelConfirmationModal.message")}
            </Typography>
            <Alert>
              {/* TODO: add currentPeriodEnd to the translation when it starts to work as expected */}
              {t("payments.subscriptionCancelConfirmationModal.alert", {
                currentPeriodEnd: formatDate(paymentsStore.subscription.currentPeriodEnd || "")
              })}
            </Alert>
          </>
        }
      />
      <UpgradeToMasterPrompt
        isOpen={upgradeToMasterModal.isOpen}
        onClose={upgradeToMasterModal.close}
        onAgree={upgradeToMaster}
      />
    </>
  );
});
