import classNames from "classnames";
import React from "react";

import { WithTooltip } from "triangular/components/WithTooltip/WithTooltip";
import { ReactComponent as TooltipIcon } from "triangular/static/images/tooltip.svg";

import css from "./HelpIconToolTip.module.scss";

interface Props {
  className?: {
    icon?: string;
    content?: string;
    wrapper?: string;
  };
}

export const HelpIconTooltip: React.FC<Props> = ({ className = {}, children }) => {
  const { icon: iconClassName, ...tooltipClassName } = className;

  return (
    <WithTooltip tooltipMessage={children} tooltipClassName={tooltipClassName} delayHide={200}>
      <TooltipIcon className={classNames(iconClassName, css.iconMargin)} />
    </WithTooltip>
  );
};
