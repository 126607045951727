import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useStore } from "triangular/stores/StoreContext";

import { HamburgerToggle } from "../../HamburgerToggle/HamburgerToggle";
import { useProfileTypeSwitches } from "../../useProfileTypeSwitches";
import { useUserMenuItems } from "../../useUserMenuItems";

import css from "./ToggleableMenu.module.scss";
interface Props {
  isNav?: boolean;
}

export const ToggleableMenu: React.FC<Props> = observer(({ isNav }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const userMenuItems = useUserMenuItems();
  const profileTypeSwitches = useProfileTypeSwitches();
  const menuRef = useRef<HTMLDivElement>(null);
  const { userStore } = useStore();
  const { t } = useTranslation();

  function userType() {
    if (isNav) {
      if (userStore.type === "system_owners") {
        return t("profileTypeNames.system_owners");
      }
      if (userStore.type === "material_owners") {
        return t("profileTypeNames.material_owners");
      }
      if (userStore.type === "experts") {
        return t("profileTypeNames.experts");
      }

      return userStore.type;
    }
  }

  useEffect(() => {
    const handleToggleMenu = (event: MouseEvent) => {
      const clickedOnTrigger = menuRef.current === event.target;

      if (clickedOnTrigger) {
        return setIsMenuVisible(prevState => !prevState);
      }

      if (isMenuVisible) {
        return setIsMenuVisible(false);
      }
    };

    window.addEventListener("click", handleToggleMenu);
    return () => window.removeEventListener("click", handleToggleMenu);
  }, [isMenuVisible]);

  const ProfileTypeSwitches = () =>
    profileTypeSwitches ? (
      <>
        {profileTypeSwitches.map(({ label, onClick, isCurrent }, index) => (
          <li
            key={index}
            className={classNames(css.tooltipMenuItem, css.profileTypeSwitch, {
              [css.profileTypeSwitchActive]: isCurrent
            })}
            onClick={onClick}
          >
            {label}
          </li>
        ))}
        <hr className={css.hr} />
      </>
    ) : null;

  const UserMenuItems = () => (
    <>
      {userMenuItems.map(({ label, onClick, danger }, index) => (
        <li
          className={classNames(css.tooltipMenuItem, { [css.danger]: danger })}
          key={index}
          onClick={event => {
            event.preventDefault();
            onClick();
            setIsMenuVisible(false);
          }}
        >
          {label}
        </li>
      ))}
    </>
  );

  return (
    <>
      <div>
        {/* <div className={css.hamburgerToggle} data-testid="hamburgerToggle"> */}
        <div
          className={classNames(css.hamburgerToggle, { [css.hamburgerFormat]: isNav })}
          data-testid="hamburgerToggle"
        >
          <HamburgerToggle isOpen={isMenuVisible} ref={menuRef} isNavBar={isNav} />
          <h4 className={css.userTypeWrapper}>{userType()}</h4>
        </div>
        {isMenuVisible && (
          <ul className={css.tooltipMenuWrapper}>
            <ProfileTypeSwitches />
            <UserMenuItems />
          </ul>
        )}
      </div>
    </>
  );
});
