import classNames from "classnames";
import React from "react";

import { AbstractFieldProps } from "./AbstractField";
import css from "./AbstractField.module.scss";

type DisconnectAbstractFieldProps = Omit<AbstractFieldProps, "name"> & {
  name?: string;
};

export const DisconnectedAbstractField: React.FC<DisconnectAbstractFieldProps> = ({
  className = {},
  children,
  label,
  disabled,
  name,
  value,
  placeholder,
  render,
  "data-testid": testId,
  ...props
}) => {
  const labelClassName = disabled
    ? classNames(className.label, css.label, css.labelDisabled)
    : classNames(className.label, css.label);

  const input = render ? (
    render({} as any)
  ) : (
    <input
      className={classNames(css.input, className.input)}
      value={value}
      placeholder={placeholder}
      data-testid={testId}
      id={name}
      title={disabled ? value : ""}
      {...props}
    />
  );

  return (
    <div className={classNames(className.wrapper, css.wrapper)}>
      {label && (
        <div className={css.labelWrapper}>
          <label className={labelClassName} htmlFor={name}>
            {label}
          </label>
        </div>
      )}
      <div className={classNames(className.inputContainer, css.inputContainer)}>{input}</div>
    </div>
  );
};
