import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { TextArea } from "triangular/components/form/TextArea/TextArea";

import { DropDownHeader } from "../../DropDownHeader/DropDownHeader";
import { FieldWrapper } from "../../FieldWrapper/FieldWrapper";
import { createBooleanOptions, SelectField } from "../../SelectField/SelectField";
import { ExplosivenessField } from "../ExplosivenessField/ExplosivenessField";

import css from "./SystemForm.module.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export const Safety: React.FC = React.memo(
  () => {
    const { t } = useTranslation();

    const indexNum = "3";
    const [activeKey, setActiveKey] = useState(indexNum);

    return (
      <Accordion>
        <FormSection>
          <DropDownHeader
            eventKeyNum={indexNum}
            handleClick={() => {
              if (activeKey === indexNum) {
                setActiveKey("");
              } else {
                setActiveKey(indexNum);
              }
            }}
          >
            {t("addSystem.safety")}
          </DropDownHeader>
          <Accordion.Collapse eventKey={indexNum}>
            <FieldsRow>
              <FieldWrapper>
                <SelectField
                  name="hazardousness"
                  label={t("addSystem.hazardousness")}
                  selectProps={{
                    options: createBooleanOptions(t)
                  }}
                  data-testid="hazardousness"
                />
              </FieldWrapper>
              <FieldWrapper>
                <ExplosivenessField label={t("addSystem.explosiveness")} />
              </FieldWrapper>
              <FieldWrapper className="col-sm-12 col-md-12 col-lg-12">
                <TextArea
                  className={{ wrapper: css.textArea }}
                  name="safetyMeasures"
                  label={t("addSystem.safetyMeasures")}
                  placeholder={t("addSystem.specify")}
                  data-testid="safetyMeasures"
                />
              </FieldWrapper>
            </FieldsRow>
          </Accordion.Collapse>
        </FormSection>
      </Accordion>
    );
  },
  () => true
);
