import isEqual from "lodash/isEqual";
import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";

import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { MultiSelectField } from "triangular/components/form/MultiSelectField/MultiSelectField";
import { namesToSelectOptions } from "triangular/utils/systems";

import { DropDownHeader } from "../../DropDownHeader/DropDownHeader";
import { TextField } from "../../TextField";
import { AbrasivenessField } from "../AbrasivenessField/AbrasivenessField";

import { FormSectionProps } from "./types";

export const PlantProtection: React.FC<FormSectionProps> = React.memo(
  ({ commonSettings }) => {
    const { t } = useTranslation();

    const colorOptions = namesToSelectOptions(t)("colorOptions", commonSettings.colors);

    const indexNum = "4";
    const [activeKey, setActiveKey] = useState(indexNum);

    return (
      <Accordion>
        <FormSection>
          <DropDownHeader
            eventKeyNum={indexNum}
            handleClick={() => {
              if (activeKey === indexNum) {
                setActiveKey("");
              } else {
                setActiveKey(indexNum);
              }
            }}
          >
            {t("addSystem.plantProtection")}
          </DropDownHeader>
          <Accordion.Collapse eventKey={indexNum}>
            <FieldsRow>
              <FieldWrapper>
                <MultiSelectField
                  name="colorsWhitelist"
                  label={t("addSystem.colorsWhitelist")}
                  selectProps={{
                    options: colorOptions,
                    autoComplete: true
                  }}
                  allowCustomValues={true}
                  data-testid="colorsWhitelist"
                />
              </FieldWrapper>
              <FieldWrapper>
                <AbrasivenessField label={t("addSystem.abrasiveness")} />
              </FieldWrapper>
              <FieldWrapper>
                <TextField
                  name="corrosiveness"
                  label={t("addSystem.corrosiveness")}
                  placeholder={t("addSystem.corrosivenessPlaceholder")}
                  data-testid="corrosiveness"
                />
              </FieldWrapper>
            </FieldsRow>
          </Accordion.Collapse>
        </FormSection>
      </Accordion>
    );
  },
  ({ formikProps: { values: prevValues } }, { formikProps: { values: nextValues } }) => {
    const prevWhitelist = prevValues.materialsWhitelist;
    const nextWhitelist = nextValues.materialsWhitelist;
    const prevBlacklist = prevValues.materialsBlacklist;
    const nextBlacklist = nextValues.materialsBlacklist;

    return isEqual(
      { whitelist: prevWhitelist, blacklist: prevBlacklist },
      { whitelist: nextWhitelist, blacklist: nextBlacklist }
    );
  }
);
