import React from "react";
import { useTranslation } from "react-i18next";

import { ErrorText } from "triangular/components/form/ErrorText/ErrorText";
import { SelectableField } from "triangular/components/form/SelectableField/SelectableField";
import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { TextField } from "triangular/components/form/TextField";

import css from "./SystemForm.module.scss";
import { FormSectionProps } from "./types";

export const InstalledPowerField: React.FC<FormSectionProps> = ({ formikProps, systemSettings }) => {
  const { t } = useTranslation();

  const getInstalledPowerMillError = () => {
    const { errors, touched } = formikProps;

    const powerMillInstalled = [
      "installedPowerMillSelect",
      "installedPowerKw",
      "installedPowerBar",
      "installedPowerFlowRate"
    ] as Array<"installedPowerMillSelect" | "installedPowerKw" | "installedPowerBar" | "installedPowerFlowRate">;

    const errorField = powerMillInstalled.find(field => touched[field] && errors[field]);
    return errorField ? errors[errorField] : null;
  };

  const installedPowerOptions = systemSettings.installedPowerKw.map(el => ({
    label: `${el} ${t("addSystem.kW")}`.replace(".", ","),
    value: el
  }));

  return (
    <SelectableField
      name="installedPowerMillSelect"
      label={t("addSystem.installedPowerMill")}
      selectFieldProps={{
        className: {
          input: css.selectableFieldSelect,
          inputContainer: css.installedPowerContainer,
          wrapper: css.installedPowerWrapper
        },
        placeholder: t("selectField.placeholder"),
        onChangeCallback: (form, value) => {
          if (value === "kw") {
            form.setFieldValue("installedPowerFlowRate", null);
            form.setFieldValue("installedPowerBar", null);
          } else if (value === "m3/h@bar") {
            form.setFieldValue("installedPowerKw", null);
          } else {
            form.setFieldValue("installedPowerFlowRate", null);
            form.setFieldValue("installedPowerBar", null);
            form.setFieldValue("installedPowerKw", null);
          }
        },
        "data-testid": "installedPowerMillSelect"
      }}
      customError={getInstalledPowerMillError()}
      options={[
        {
          label: t("addSystem.irrelevantInstalledPower"),
          value: null,
          reactNode: null
        },
        {
          label: t("addSystem.kW"),
          value: "kw",
          reactNode: (
            <SelectField
              className={{ wrapper: css.selectedField }}
              name="installedPowerKw"
              renderError={() => <ErrorText />}
              selectProps={{
                options: installedPowerOptions,
                autoComplete: true
              }}
              data-testid="installedPowerKw"
            />
          )
        },
        {
          label: "m3/h@bar",
          value: "m3/h@bar",
          reactNode: (
            <>
              <TextField
                className={{ wrapper: css.selectedField }}
                name="installedPowerFlowRate"
                placeholder="m3/h"
                renderError={() => <ErrorText />}
              />
              <div className={css.installedPowerSeparator}>@</div>
              <TextField
                className={{ wrapper: css.selectedField }}
                name="installedPowerBar"
                placeholder="bar"
                renderError={() => <ErrorText />}
              />
            </>
          )
        }
      ]}
      data-testid="installedPowerMillSelect"
    />
  );
};
