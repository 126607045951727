import { Formik, FormikActions, FormikConfig, Form as FormikForm, FormikProps, FormikValues } from "formik";
import isEqual from "lodash/isEqual";
import noop from "lodash/noop";
import React, { useEffect } from "react";
import usePrevious from "react-use/lib/usePrevious";
import * as Yup from "yup";

import { useStore } from "triangular/stores/StoreContext";

interface Props<Values extends object = FormikValues> extends Omit<FormikConfig<Values>, "onSubmit"> {
  validationSchema?: Yup.ObjectSchema<Values>;
  render: NonNullable<FormikConfig<Values>["render"]>;
  className?: string;
  onChange?: (props: FormikProps<Values>) => void;
  onSubmit?: FormikConfig<Values>["onSubmit"];
}

export interface FormChangeWatcherProps {
  children: React.ReactNode;
  onChange?: (props: FormikProps<any>) => void;
  formikProps: FormikProps<any>;
  enableReinitialize: boolean;
}

const FormChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  children,
  formikProps,
  onChange,
  enableReinitialize
}) => {
  const prevValues = usePrevious(formikProps.values);
  const prevInitialValues = usePrevious(formikProps.initialValues);

  useEffect(() => {
    if (onChange && prevValues && !isEqual(prevValues, formikProps.values)) {
      onChange(formikProps);
    }
  }, [formikProps, prevValues, onChange, prevInitialValues, enableReinitialize]);

  return <>{children}</>;
};

export function Form<Values extends object>({
  onSubmit = noop,
  validationSchema = Yup.object(),
  render,
  className,
  onChange,
  enableReinitialize = false,
  ...formikProps
}: Props<Values>) {
  const { snackbarStore } = useStore();

  const handleSubmit = React.useCallback<FormikConfig<Values>["onSubmit"]>(
    async (values, actions) => {
      try {
        await onSubmit(values, actions);
      } catch (err) {
        snackbarStore.showGenericError(err);
      }
      actions.setSubmitting(false);
    },
    [onSubmit, snackbarStore]
  );

  return (
    <Formik
      enableReinitialize={enableReinitialize}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      render={props => {
        const form = <FormikForm className={className}>{render(props)}</FormikForm>;
        return onChange || enableReinitialize ? (
          <FormChangeWatcher onChange={onChange} formikProps={props} enableReinitialize={enableReinitialize}>
            {form}
          </FormChangeWatcher>
        ) : (
          form
        );
      }}
      {...formikProps}
    />
  );
}

export type SubmitForm<T = {}> = (values: T, formikActions: FormikActions<T>) => void;
