import classNames from "classnames";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { HelpIconTooltip } from "triangular/components/HelpIconTooltip/HelpIconTooltip";
import { TriLink } from "triangular/components/TriLink/TriLink";
import { createLandingPageRoutes } from "triangular/Routes/routesConfiguration";

import css from "./SubscriptionIntervalField.module.scss";

export const SubscriptionIntervalLabel: React.FC = () => {
  const { t, i18n } = useTranslation();
  const landingPageRoutes = createLandingPageRoutes(i18n.language);

  return (
    <div className={classNames(css.subscriptionIntervalLabel)}>
      {t("registration.subscriptionInterval.fieldLabel")}{" "}
      <HelpIconTooltip>
        <Trans i18nKey="registration.subscriptionInterval.helpMessage">
          For more details please read{" "}
          <TriLink to={landingPageRoutes.termsOfUse} external={true}>
            Terms of Use
          </TriLink>
        </Trans>
      </HelpIconTooltip>
    </div>
  );
};
