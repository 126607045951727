import { TFunction } from "i18next";
import * as Yup from "yup";

import { euCountries } from "triangular/consts/countries";

export const genericTaxIdMinLength = 5;

const mexicanCompanyTaxIdLength = 12;
const mexicanIndividualTaxIdLength = 13;

const getMexicanTaxIdPattern = (type: "company" | "individual") => {
  const firstPartLength = type === "individual" ? "4" : "3";
  return new RegExp(
    `[A-Z]{${firstPartLength}}\\d{2}([1][0-2]|[0][1-9])([0][1-9]|([1-2][0-9]|[3][0-1]))\\w{2}(\\d|A){1}`
  );
};

const mexicanIndividualTaxIdPattern = getMexicanTaxIdPattern("individual");
const mexicanCompanyTaxIdPattern = getMexicanTaxIdPattern("company");

const validateAgainstRegexps = (regexpsPatterns: RegExp[]) => (schema: Yup.StringSchema<string>, t: TFunction) =>
  schema.test("regexpsPatterns", t("validation.invalidValue"), (value: unknown) =>
    regexpsPatterns.some(eachPattern => eachPattern.test(String(value)))
  );

const defaultIdentificationNumberValidator = (t: TFunction) => {
  return Yup.string().when("countryCode", {
    is: value => euCountries.check(value),
    then: validateAgainstRegexps(euCountries.regex())(Yup.string(), t),
    otherwise: Yup.string()
      .trim()
      .min(genericTaxIdMinLength, t("validation.minLength", { minLength: genericTaxIdMinLength }))
      .required(t("validation.required"))
  });
};

const euIdentificationNumberValidator = (t: TFunction) => {
  return Yup.string().when("countryCode", {
    // RO has a different validation since it is way to broad: [0-9]{2,10}
    is: "RO",
    then: validateAgainstRegexps(euCountries.regex("RO"))(Yup.string(), t),
    otherwise: defaultIdentificationNumberValidator(t)
  });
};

const expertTaxIdValidator = (t: TFunction) => {
  return Yup.string().when("countryCode", {
    is: "MX",
    then: validateAgainstRegexps([mexicanIndividualTaxIdPattern, mexicanCompanyTaxIdPattern])(
      Yup.string()
        .min(mexicanCompanyTaxIdLength, t("validation.minLength", { minLength: mexicanCompanyTaxIdLength }))
        .max(mexicanIndividualTaxIdLength, t("validation.maxLength", { maxLength: mexicanIndividualTaxIdLength })),
      t
    ),
    otherwise: euIdentificationNumberValidator(t)
  });
};

const ownerTaxIdValidator = (t: TFunction) => {
  return Yup.string().when("countryCode", {
    is: "MX",
    then: validateAgainstRegexps([mexicanCompanyTaxIdPattern])(
      Yup.string().length(mexicanCompanyTaxIdLength, t("validation.length", { length: mexicanCompanyTaxIdLength })),
      t
    ),
    otherwise: euIdentificationNumberValidator(t)
  });
};

export const taxIdValidator: { [Key in ProfileType]: (t: TFunction) => Yup.StringSchema<string> } = {
  experts: expertTaxIdValidator,
  system_owners: ownerTaxIdValidator,
  material_owners: ownerTaxIdValidator
};
