import classNames from "classnames";
import React, { CSSProperties } from "react";
import ReactDOM from "react-dom";
import ReactTooltip from "react-tooltip";

import css from "./TooltipMessage.module.scss";

interface TooltipMessageProps {
  id: string;
  className?: {
    content?: string;
    wrapper?: string;
  };
  delayHide?: number;
  style?: CSSProperties;
}

const getTooltipsContainer = () => {
  const tooltipsContainerId = "tooltips";
  const tooltipsContainer = document.getElementById(tooltipsContainerId);

  if (!tooltipsContainer) {
    const newTooltipsContainer = document.createElement("div");
    newTooltipsContainer.id = tooltipsContainerId;
    document.body.appendChild(newTooltipsContainer);
    return newTooltipsContainer;
  }

  return tooltipsContainer;
};

export const TooltipMessage: React.FC<TooltipMessageProps> = ({ id, className = {}, children, delayHide, style }) => {
  const tooltipJsx = (
    <ReactTooltip
      id={id}
      className={classNames(className.wrapper, css.wrapper)}
      effect="solid"
      clickable={true}
      delayHide={delayHide}
    >
      <div className={classNames(className.content, css.content)} data-tip={id} style={style}>
        {children}
      </div>
    </ReactTooltip>
  );

  return ReactDOM.createPortal(tooltipJsx, getTooltipsContainer());
};
