import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { LinkButton } from "triangular/components/LinkButton/LinkButton";
import { useModal } from "triangular/components/Modal/Modal";
import { Prompt } from "triangular/components/Prompt/Prompt";
import { Typography } from "triangular/components/Typography/Typography";
import { WithTooltip } from "triangular/components/WithTooltip/WithTooltip";
import { useStore } from "triangular/stores/StoreContext";

import css from "./DeactivateAccount.module.scss";

export const DeactivateAccount = observer(() => {
  const { t } = useTranslation();
  const deactivateAccountPrompt = useModal();
  const { paymentsStore, userStore, snackbarStore } = useStore();

  const MaybeTooltip: React.FC = ({ children }) =>
    paymentsStore.deactivatedRequestSent ? (
      <WithTooltip tooltipMessage={t("payments.deactivateAccountRequestSent")}>{children}</WithTooltip>
    ) : (
      <>{children}</>
    );

  const handleAccountDeactivationRequest = useCallback(async () => {
    try {
      await paymentsStore.deactivateAccount(userStore.id);
      snackbarStore.addSnackbar({
        type: "success",
        message: t("payments.deactivateAccountSuccess")
      });
    } catch (err) {
      snackbarStore.showGenericError(err);
    }
  }, [paymentsStore, snackbarStore, t, userStore.id]);

  return (
    <FormSection>
      <MaybeTooltip>
        <LinkButton
          variant="danger"
          onClick={deactivateAccountPrompt.open}
          className={css.deactivateButton}
          disabled={paymentsStore.deactivatedRequestSent}
        >
          {t("payments.deactivateAccount")}
        </LinkButton>
      </MaybeTooltip>
      <Prompt
        onAgree={handleAccountDeactivationRequest}
        isOpen={deactivateAccountPrompt.isOpen}
        onClose={deactivateAccountPrompt.close}
        header={t("payments.deactivateAccountPrompt.title")}
        message={
          <>
            <Typography component="p" verticalSpacing="small" bold={true}>
              {t("payments.deactivateAccountPrompt.message0")}
            </Typography>
            <Typography component="p" verticalSpacing="big" bold={true}>
              {t("payments.deactivateAccountPrompt.message1")}
            </Typography>
            <Typography component="p" verticalSpacing="big" bold={true}>
              {t("payments.deactivateAccountPrompt.message2")}
            </Typography>
          </>
        }
      />
    </FormSection>
  );
});
