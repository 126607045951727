import { types } from "mobx-state-tree";

import { FlatEntity } from "triangular/services/Api/Resource/utils/types";
import { SystemEntity } from "triangular/services/Api/resources/SystemResource";
import { CancelTokenType, isCancel } from "triangular/utils/api";
import { PaginationBase } from "triangular/utils/pagination/types";

import { createStore } from "../utils/createStore";

import { systemModel } from "./models";

type SystemFilters = {
  processCategoryId: string | null | undefined;
  systemCategoryId: string[] | null | undefined;
  systemTypeId: string[] | null | undefined;
  colorsWhitelist: string[] | null | undefined;
  materialsWhitelist: string[] | null | undefined;
  materialsBlacklist: string[] | null | undefined;
  countryCode: string | null | undefined;
  contactMaterials: string[] | null | undefined;
  nonContactMaterials: string[] | null | undefined;
  installedPowerKw: number[] | null | undefined;
  industryIds: string[] | null | undefined;
  materialCategoryIds: string[] | null | undefined;
};

export const SystemSearchStore = createStore(storeDeps =>
  types
    .model("SystemSearchStore", {
      totalItems: 0,
      systems: types.array(systemModel),
      isLoading: types.boolean
    })
    .actions(self => ({
      async fetchSystems({
        filter,
        pagination,
        sortBy,
        cancelToken
      }: {
        filter: {
          q?: string;
        } & SystemFilters;
        pagination: {
          size: number;
          number: number;
        };
        sortBy: string;
        cancelToken: CancelTokenType;
      }) {
        const response = await storeDeps.api.system.find(
          {
            filter,
            pagination,
            sort: [[sortBy, "ASC" as const]],
            include: {
              systemType: true,
              industries: true
            }
          },
          { cancelToken }
        );
        return {
          data: response.data,
          meta: response.meta as {
            pageCount: number;
            recordCount: number;
          }
        };
      },
      setSystems(systems: FlatEntity<SystemEntity>[]) {
        self.systems.clear();
        systems.forEach(eachSystem => self.systems.push(eachSystem));
      },
      setTotalItems(totalItems: number) {
        self.totalItems = totalItems;
      },
      setIsLoading(isLoading: boolean) {
        self.isLoading = isLoading;
      }
    }))
    .actions(self => ({
      async loadSystems(
        {
          page,
          sortBy,
          search,
          processCategoryId,
          systemCategoryId,
          systemTypeId,
          installedPowerKw,
          colorsWhitelist,
          materialsWhitelist,
          materialsBlacklist,
          countryCode,
          contactMaterials,
          nonContactMaterials,
          industryIds,
          materialCategoryIds
        }: {
          page: PaginationBase["page"];
          sortBy: string;
          search?: string;
        } & SystemFilters,
        cancelToken: CancelTokenType
      ) {
        self.setIsLoading(true);

        try {
          const { data, meta } = await self.fetchSystems({
            filter: {
              q: search && search.length > 0 ? search : undefined,
              processCategoryId,
              systemCategoryId,
              systemTypeId,
              installedPowerKw,
              colorsWhitelist,
              materialsWhitelist,
              materialsBlacklist,
              countryCode,
              contactMaterials,
              nonContactMaterials,
              industryIds,
              materialCategoryIds
            },
            pagination: {
              size: page.size,
              number: page.number
            },
            sortBy: sortBy,
            cancelToken
          });
          self.setTotalItems(meta.recordCount);
          self.setSystems(data);
          return self.setIsLoading(false);
        } catch (err) {
          if (isCancel(err)) {
            return;
          }

          self.setIsLoading(false);

          throw err;
        }
      }
    }))
);

export const systemSearchStoreInitialState = {
  totalItems: 0,
  systems: [],
  isLoading: true
};
