import { isAfter } from "date-fns";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { CheckboxField } from "triangular/components/form/CheckboxField/CheckboxField";
import { DefaultFormButtons } from "triangular/components/form/DefaultFormButtons";
import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { Form } from "triangular/components/form/Form";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { FileToUpload } from "triangular/components/form/system/SystemFilesField/FilesField/FilesField";
import { FileListItem } from "triangular/components/form/system/SystemFilesField/FilesList/FilesList";
import { SystemFilesField } from "triangular/components/form/system/SystemFilesField/SystemFilesField";
import { TextArea } from "triangular/components/form/TextArea/TextArea";
import { TextField } from "triangular/components/form/TextField";
import { YearMonthField } from "triangular/components/form/YearMonthField/YearMonthField";
import { RequiredField } from "triangular/components/RequiredField/RequiredField";
import { countriesSelectOptions } from "triangular/consts";
import { ExpertExperienceItem } from "triangular/stores/ExpertStore/ExpertStore";
import { useStore } from "triangular/stores/StoreContext";

import { createExperienceSchema, experienceDefaultsValues } from "./experienceSchema";

interface ExperienceFormProps {
  onClose: () => void;
  experience?: ExpertExperienceItem;
}

const getInitialValues = (experience?: ExpertExperienceItem) => {
  if (experience) {
    const { id, ...experienceFormValues } = experience;
    return { ...experienceDefaultsValues, ...experienceFormValues, currentRole: !experienceFormValues.endDate };
  }

  return experienceDefaultsValues;
};

export const ExperienceForm: React.FC<ExperienceFormProps> = observer(({ experience, onClose }) => {
  const { t } = useTranslation();
  const experienceSchema = createExperienceSchema(t);
  const currentT = (path: string) => t(`editExpert.experienceModal.${path}`);
  const { expertStore } = useStore();

  const initialValues = getInitialValues(experience);

  const handleAddExperienceFile = async (
    filesToUpload: FileToUpload[],
    addFiles: (fileEntities: FileListItem[] | null) => void
  ) => {
    const responses = await Promise.all(
      filesToUpload.map(({ filename, data }) => expertStore.uploadExperienceFile(filename, data))
    );

    const addedExperienceFiles = responses.flat().map(({ id, filename }) => ({ id, filename }));
    addFiles(addedExperienceFiles);
  };

  const handleRemoveExperienceFile = (file: FileListItem) => expertStore.removeExperienceFile(file.id);

  return (
    <Form
      onSubmit={async ({ experienceFiles, currentRole, startDate, endDate, ...values }) => {
        const experienceBlueprint = {
          ...values,
          experienceFiles: experienceFiles ? experienceFiles.map(({ id }) => ({ id })) : null,
          startDate: startDate ? new Date(startDate).toISOString() : null,
          endDate: endDate ? new Date(endDate).toISOString() : null,
          expert: {
            id: expertStore.currentExpert.id!
          }
        };

        if (experience) {
          await expertStore.updateExperience(experience.id, experienceBlueprint);
        } else {
          await expertStore.createExperience(experienceBlueprint);
        }

        onClose();
      }}
      initialValues={{
        ...experienceDefaultsValues,
        ...initialValues
      }}
      validationSchema={experienceSchema}
      enableReinitialize={true}
      render={({ values, setFieldValue }) => (
        <FormSection>
          <FieldsRow>
            <FieldWrapper className="col-12">
              <TextArea name="headline" label={currentT("headline")} data-testid="headline" />
            </FieldWrapper>
          </FieldsRow>
          <FieldsRow>
            <FieldWrapper>
              <TextField name="title" label={currentT("title")} data-testid="title" required={true} />
            </FieldWrapper>
            <FieldWrapper>
              <TextField name="company" label={currentT("company")} data-testid="company" required={true} />
            </FieldWrapper>
            <FieldWrapper>
              <SelectField
                name="countryCode"
                label={t("editExpert.country")}
                selectProps={{
                  options: countriesSelectOptions,
                  autoComplete: true
                }}
                required={true}
                data-testid="countryCode"
              />
            </FieldWrapper>
            <div className="col-12">
              <CheckboxField
                name="currentRole"
                label={currentT("currentRole")}
                onChange={nextValue => {
                  if (nextValue === false) {
                    setFieldValue("endDate", new Date().toISOString());
                  } else {
                    setFieldValue("endDate", null);
                  }
                }}
              />
            </div>
          </FieldsRow>
          <FieldsRow>
            <FieldWrapper>
              <YearMonthField
                maxDate={Date.now()}
                name="startDate"
                label={currentT("startDate")}
                data-testid="startDate"
                required={true}
                onChange={startDate => {
                  if (values.endDate && isAfter(startDate, values.endDate)) {
                    setFieldValue("endDate", startDate);
                  }
                }}
              />
            </FieldWrapper>
            {values.endDate && (
              <FieldWrapper>
                <YearMonthField
                  minDate={values.startDate ? values.startDate : undefined}
                  maxDate={Date.now()}
                  name="endDate"
                  label={currentT("endDate")}
                  data-testid="endDate"
                  required={true}
                />
              </FieldWrapper>
            )}
          </FieldsRow>
          <TextArea name="description" label={currentT("description")} data-testid="description" />
          <SystemFilesField
            name="experienceFiles"
            label={currentT("experienceFiles")}
            onAddFile={handleAddExperienceFile}
            onRemoveFile={handleRemoveExperienceFile}
            fileType="document"
            data-testid="experienceFiles"
          />
          <RequiredField />
          <DefaultFormButtons
            confirmPromptTitle={currentT("experienceSubmitConfirmationHeader")}
            confirmPromptMessage={currentT("experienceSubmitConfirmationMessage")}
            onGoBack={onClose}
          />
        </FormSection>
      )}
    />
  );
});
