import { format } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";

import { IconText } from "triangular/components/IconText/IconText";
import { ReactComponent as Location } from "triangular/components/LocationMarker/images/marker.svg";
import { Typography } from "triangular/components/Typography/Typography";
import { getCountryBy } from "triangular/consts";
import { formatPeriod } from "triangular/utils/dates";

import { ExpertPublicCertificate, ExpertPublicExperiences } from "../ExpertDetails";

import css from "./ExpertDetailItem.module.scss";
import { ReactComponent as ExperienceDescription } from "./images/experience2.svg";
import { ReactComponent as HourGlass } from "./images/hourglass.svg";
import { ReactComponent as Id } from "./images/id.svg";
import { ReactComponent as Job } from "./images/job.svg";

const formatted = (date: string | null) => (date ? format(date, "MM/YYYY") : null);

export const ExpertExperienceItem: React.FC<{ detail: ArrayElement<ExpertPublicExperiences> }> = ({ detail }) => {
  const { t } = useTranslation();
  const country = detail.countryCode ? getCountryBy.code(detail.countryCode) : null;
  const data = detail.description ? (
    <li>
      <IconText className={css.expertDescription} icon={<ExperienceDescription />}>
        {detail.description}
      </IconText>
    </li>
  ) : null;

  return (
    <div className={css.expertDetail}>
      <Typography component="div" bold={true} upperCase={true} size="small" className={css.detailName}>
        {detail.company}
      </Typography>
      <ul className={css.list}>
        <li>
          <IconText className={css.text} icon={<Job />}>
            {detail.company}
          </IconText>
        </li>
        <li>
          <IconText className={css.text} icon={<HourGlass />}>
            {formatPeriod(detail.startDate || "", detail.endDate!)}
          </IconText>
        </li>
        <li>
          <IconText className={css.text} icon={<Location />}>
            {country ? country.name : t("addSystem.globalLocation")}
          </IconText>
        </li>
        {data}
      </ul>
    </div>
  );
};

export const ExpertCertificateItem: React.FC<{ detail: ArrayElement<ExpertPublicCertificate> }> = ({ detail }) => {
  const { t } = useTranslation();

  return (
    <div className={css.expertDetail}>
      <Typography component="div" bold={true} upperCase={true} size="small" className={css.detailName}>
        {detail.name}
      </Typography>
      <ul className={css.list}>
        <li>
          <IconText className={css.text} icon={<Job />}>
            {detail.organization}
          </IconText>
        </li>
        <li>
          <IconText className={css.text} icon={<HourGlass />}>
            {detail.expirationDate ? formatted(detail.expirationDate) : t("expertDetails.noExpireDate")}
          </IconText>
        </li>
        <li>
          <IconText className={css.text} icon={<Id />}>
            {detail.credentialId}
          </IconText>
        </li>
      </ul>
    </div>
  );
};
