import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { Typography } from "triangular/components/Typography/Typography";
import { routes } from "triangular/Routes/routesConfiguration";
import { gaActions, GaCategories, googleAnalytics } from "triangular/services/googleAnalytics";
import { useStore } from "triangular/stores/StoreContext";
import { getErrorStatus, useCancellation } from "triangular/utils/api";

import css from "./CompanyInfo.module.scss";
import { CompanyInfoForm, CompanyInfoFormProps } from "./CompanyInfoForm/CompanyInfoForm";

export const CompanyInfo: React.FC = observer(props => {
  const { t } = useTranslation();
  const { cancelToken, cancel } = useCancellation();
  const { registrationStore, snackbarStore } = useStore();
  const { history } = useRouter();

  const handleSubmit: CompanyInfoFormProps["onSubmit"] = async (
    { name, number: companyNumber, countryCode, agreement },
    actions
  ) => {
    registrationStore.setState({ agreement, company: { name, number: companyNumber, countryCode: countryCode || "" } });

    try {
      await registrationStore.createAccount(cancelToken);

      googleAnalytics.sendEvent({
        category: GaCategories.registration,
        action: gaActions.registration.succeeded,
        label: registrationStore.type
      });
    } catch (error) {
      const errorStatus = getErrorStatus(error);
      if (errorStatus === 422) {
        actions.setFieldError("number", t("registration.companyInfo.invalidVatNumber"));
        snackbarStore.showValidationError();

        googleAnalytics.sendEvent({
          category: GaCategories.registration,
          action: gaActions.registration.failed,
          label: "Invalid VAT"
        });
      } else {
        snackbarStore.showGenericError(error);

        googleAnalytics.sendEvent({
          category: GaCategories.registration,
          action: gaActions.registration.failed
        });
      }
    }
  };

  const handleGoBack = (e: React.MouseEvent) => {
    e.preventDefault();
    history.push(routes.registration.personalInfo.build());
  };

  useEffect(() => () => cancel(), [cancel]);

  const formInitialValues = {
    name: registrationStore.company.name,
    number: registrationStore.company.number,
    subscriptionInterval: null,
    countryCode: registrationStore.countryCode,
    agreement: false
  };

  return (
    <div className={css.wrapper}>
      <Typography size="big" component="h2" color="mint" upperCase={true} bold={true}>
        {t("registration.companyInfo.companyInfo")}
      </Typography>
      <CompanyInfoForm onSubmit={handleSubmit} onGoBack={handleGoBack} initialValues={formInitialValues} />
    </div>
  );
});
