import classNames from "classnames";
import React from "react";

import linkCss from "../TriLink/TriLink.module.scss";

import css from "./LinkButton.module.scss";

export type Variant = "regular" | "danger";

interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant?: Variant;
}

export const LinkButton: React.FC<ButtonProps> = ({ children, className, variant, ...buttonPropsRest }) => {
  const classes = classNames(className, css.button, linkCss.triLink, { [css.danger]: variant === "danger" });

  return (
    <button className={classes} {...buttonPropsRest}>
      {children}
    </button>
  );
};
