import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { SpecialtiesField } from "triangular/components/form/system/SpecialtiesField";
import { TextArea } from "triangular/components/form/TextArea/TextArea";
import { TextField } from "triangular/components/form/TextField";
import { HelpIconTooltip } from "triangular/components/HelpIconTooltip/HelpIconTooltip";
import { Typography } from "triangular/components/Typography/Typography";
import { countriesSelectOptions } from "triangular/consts";
import { useStore } from "triangular/stores/StoreContext";

import { AvatarUploading } from "./AvatarUploading/AvatarUploading";
import css from "./ExpertForm.module.scss";

export const PersonalData: React.FC = observer(() => {
  const { t } = useTranslation();
  const { expertStore } = useStore();
  const isFormDisabled = expertStore.isConfirmed();

  const showcaseTitleTip = (
    <>
      {t("editExpert.showcaseTitle")}&nbsp;
      <HelpIconTooltip>{t("editExpert.showcaseTitleTip")}</HelpIconTooltip>
    </>
  );
  const expertCompanyTip = (
    <>
      {t("editExpert.expertCompany")}&nbsp;
      <HelpIconTooltip>{t("editExpert.expertCompanyTip")}</HelpIconTooltip>
    </>
  );

  return (
    <FormSection className={css.spacing}>
      <div className={css.profileConfirmed}>
        {isFormDisabled && <Typography bold={true}>{t("editExpert.profileConfirmed")}</Typography>}
      </div>
      <FieldsRow className={css.altHeader}>
        <Typography bold component="h3" color="white" size="big" upperCase={true}>
          {t("editProfile.sceneTitle")}
        </Typography>
      </FieldsRow>
      <FieldsRow>
        <FieldWrapper className="col-sm-12 col-md-4 col-lg-4">
          <div className={"d-flex justify-content-center"}>
            <AvatarUploading />
          </div>
        </FieldWrapper>
        <FieldsRow className={classNames("col-sm-12 col-md-8 col-lg-8", css.noBorderSpacing)}>
          <FieldWrapper className="col-sm-12 col-md-6 col-lg-6">
            <TextField
              label={t("editExpert.firstName")}
              name="firstName"
              data-testid="firstName"
              required={true}
              disabled={isFormDisabled}
            />
          </FieldWrapper>
          <FieldWrapper className="col-sm-12 col-md-6 col-lg-6">
            <TextField
              label={t("editExpert.lastName")}
              name="lastName"
              data-testid="lastName"
              required={true}
              disabled={isFormDisabled}
            />
          </FieldWrapper>
          <FieldWrapper className="col-12">
            <TextField label={t("email")} name="email" data-testid="email" disabled={true} />
          </FieldWrapper>
        </FieldsRow>
      </FieldsRow>
      <FieldsRow>
        <FieldWrapper>
          <SelectField
            name="countryCode"
            label={t("editExpert.country")}
            selectProps={{
              options: countriesSelectOptions,
              autoComplete: true
            }}
            required={true}
            data-testid="countryCode"
            disabled={true}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextField
            label={t("editExpert.phoneNumber")}
            name="phoneNumber"
            data-testid="phoneNumber"
            required={true}
            disabled={isFormDisabled}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextField
            label={t("editExpert.mobileNumber")}
            name="mobileNumber"
            data-testid="mobileNumber"
            disabled={isFormDisabled}
          />
        </FieldWrapper>
        <FieldWrapper>
          <SpecialtiesField
            className={{ wrapper: css.fullWidthSelect }}
            label={t("specialty")}
            name="specialties"
            disabled={isFormDisabled}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextField
            label={showcaseTitleTip}
            name="showcaseTitle"
            data-testid="showcaseTitle"
            disabled={isFormDisabled}
          />
        </FieldWrapper>
        <FieldWrapper>
          <TextField
            label={expertCompanyTip}
            name="expertCompany"
            data-testid="expertCompany"
            disabled={isFormDisabled}
          />
        </FieldWrapper>
        <FieldWrapper className="col-12">
          <TextArea
            label={t("editExpert.description")}
            name="description"
            data-testid="description"
            disabled={isFormDisabled}
          />
        </FieldWrapper>
      </FieldsRow>
    </FormSection>
  );
});
