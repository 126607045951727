import { AxiosInstance } from "axios";

import { Resource } from "../Resource/Resource";
import { Entity } from "../Resource/utils/types";

type AccountConfirmationEntity = Entity<{}, {}>;

export class AccountConfirmationResource extends Resource<AccountConfirmationEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "account_confirmations",
      prefixes: ["auth"],
      relationships: {}
    });
  }
}
