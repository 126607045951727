import React from "react";

import { useBreakpoint } from "triangular/components/Breakpoint/Breakpoint";
import { LoadingOverlay } from "triangular/components/LoadingOverlay/LoadingOverlay";
import { Masonry } from "triangular/components/Masonry/Masonry";
import { PageLoader } from "triangular/components/PageLoader/PageLoader";
import { Typography } from "triangular/components/Typography/Typography";
import { Pagination, PaginationProps } from "triangular/scenes/SystemSearch/Pagination/Pagination";
import { PaginationBase } from "triangular/utils/pagination/types";

import css from "./SearchResultsList.module.scss";

interface SearchResultsProps<T> extends PaginationProps {
  items: T[];
  isLoading: boolean;
  noResultsMessage: string | (() => React.ReactNode);
  children: (params: T) => React.ReactNode;
  paginationQuery: PaginationBase;
  isOnFirstPage: boolean;
  isOnLastPage: boolean;
  maxPageNumber: number;
  buildUrl: (callback: (prevQuery: PaginationBase) => Partial<PaginationBase>) => string;
  previousPageUrl: string;
  nextPageUrl: string;
  totalItems: number;
  hasTripleColumnsLayout?: boolean;
}

export function SearchResultsList<T>({
  items,
  isLoading,
  noResultsMessage,
  children,
  totalItems,
  paginationQuery,
  isOnFirstPage,
  isOnLastPage,
  maxPageNumber,
  buildUrl,
  previousPageUrl,
  nextPageUrl,
  hasTripleColumnsLayout
}: SearchResultsProps<T>) {
  const initialIsLoading = isLoading === true && items.length === 0;
  const isMobileView = useBreakpoint({ minWidth: "small", maxWidth: "medium" });
  const isDesktopView = useBreakpoint({ minWidth: "medium" });
  const doubleMasonryColumns = isMobileView ? 1 : 2;
  const tripleMasonryColumns = isDesktopView ? 3 : doubleMasonryColumns;
  const appliedMasonryColumns = hasTripleColumnsLayout ? tripleMasonryColumns : doubleMasonryColumns;

  if (initialIsLoading) {
    return <PageLoader />;
  }

  if (items.length === 0) {
    return (
      <Typography size="big" bold={true} component="div">
        {typeof noResultsMessage === "function" ? noResultsMessage() : noResultsMessage}
      </Typography>
    );
  }

  return (
    <div className={css.wrapper}>
      <LoadingOverlay isVisible={isLoading} withFloatingLoader={true} />
      <Masonry columns={appliedMasonryColumns}>{items.map(eachItem => children(eachItem))}</Masonry>
      <Pagination
        totalItems={totalItems}
        paginationQuery={paginationQuery}
        isOnFirstPage={isOnFirstPage}
        isOnLastPage={isOnLastPage}
        maxPageNumber={maxPageNumber}
        buildUrl={buildUrl}
        previousPageUrl={previousPageUrl}
        nextPageUrl={nextPageUrl}
      />
    </div>
  );
}
