import { useStore } from "triangular/stores/StoreContext";

import { routes } from "./routesConfiguration";

export const useHomeRoute = () => {
  const { userStore, expertStore } = useStore();
  switch (userStore.type) {
    case "experts":
      if (expertStore.currentExpert.state === "confirmed") {
        return routes.systems.build({});
      }

      return routes.myExpertProfile.build();

    case "system_owners":
      return routes.mySystemsList.build();

    case "material_owners":
      return routes.myMaterialsList.build();

    default:
      return routes.login.build({});
  }
};
