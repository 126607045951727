import { AxiosInstance } from "axios";

import { FileEntity } from "../entities/FileEntity";
import { Resource } from "../Resource/Resource";
import { Entity, EntityAttributes, EntityRelationships } from "../Resource/utils/types";

export interface SystemAttributes extends EntityAttributes {
  name: string | null;
  brand: string | null;
  model: string | null;
  countryCode: string | null;
  installedPower: {
    kw: string | null;
  } | null;
  installedPowerMill: {
    bar: string | null;
    "m3/h": string | null;
  } | null;
  installedPowerClassifierKw: string | null;
  contactMaterials: string[] | null;
  nonContactMaterials: string[] | null;
  materialsWhitelist: string[] | null;
  materialsBlacklist: string[] | null;
  colorsWhitelist: string[] | null;
  abrasiveness: string | null;
  corrosiveness: string | null;
  explosiveness: string | null;
  hazardousness: boolean | null;
  safetyMeasures: string | null;
  additionalInfo: string | null;
  state: string | null;
  hideSensitiveData: boolean | null;
  airflow: string | null;
  minimumMaterialAmount: string | null;
}
export interface SystemRelationships extends EntityRelationships {
  systemOwner: Entity;
  processCategory: Entity;
  systemCategory?: Entity;
  systemType?: Entity;
  designTypes?: Entity[];
  industries?: Entity[];
  materialCategories?: Entity[];
  systemPhotos?: FileEntity[];
  systemDocuments?: FileEntity[];
}

export type SystemEntity = Entity<SystemAttributes, SystemRelationships>;

export class SystemResource extends Resource<SystemEntity> {
  constructor(protected axios: AxiosInstance, prefixes: string[] | undefined) {
    super(axios, {
      type: "systems",
      prefixes,
      relationships: {
        systemOwner: {
          type: "system_owners"
        },
        processCategory: {
          type: "process_categories"
        },
        systemCategory: {
          type: "system_categories"
        },
        systemType: {
          type: "system_types"
        },
        designTypes: {
          type: "design_types"
        },
        systemPhotos: {
          type: "system_photos"
        },
        systemDocuments: {
          type: "system_documents"
        },
        industries: {
          type: "industries"
        },
        materialCategories: {
          type: "material_categories"
        }
      }
    });
  }
}
