import React from "react";

import { CurrentFilterBadges, CurrentFilterBadgesProps } from "../CurrentFilterBadges/CurrentFilterBadges";

import { MobileFilters } from "./MobileFilters/MobileFilters";
import css from "./MobileViewContent.module.scss";

export interface MobileViewProps {
  sortSection: React.ReactNode;
  helpButtonSection?: React.ReactNode;
  searchSection: React.ReactNode;
  results: React.ReactNode;
  filters: {
    search: React.ReactNode;
    searchPhrase: React.ReactNode;
    fields: React.ReactNode[];
  };
  filterBadges: CurrentFilterBadgesProps["badges"];
}

export const MobileViewContent: React.FC<MobileViewProps> = ({
  sortSection,
  helpButtonSection,
  searchSection,
  results,
  filters,
  filterBadges
}) => {
  return (
    <div>
      <div className={css.settings}>
        <div className={css.sortSection}>{sortSection}</div>
        <MobileFilters search={filters.search} searchPhrase={filters.searchPhrase} fields={filters.fields} />
        {helpButtonSection && <div className={css.helpButtonSection}>{helpButtonSection}</div>}
      </div>
      <div className={css.search}>
        {searchSection}
        <CurrentFilterBadges badges={filterBadges} />
      </div>
      {results}
    </div>
  );
};
