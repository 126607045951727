import { TFunction } from "i18next";
import * as Yup from "yup";

export const PASSWORD_MIN_CHARACTERS = 8;

export const passwordValidator = (t: TFunction) =>
  Yup.string()
    .required(t("validation.required"))
    .min(PASSWORD_MIN_CHARACTERS, t("validation.passwordLength", { minCharacters: PASSWORD_MIN_CHARACTERS }))
    // https://stackoverflow.com/a/21456918
    // At least one uppercase letter and one number
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{1,}$/, t("validation.weakPassword"));
