import { AxiosInstance } from "axios";

import { Resource } from "../Resource/Resource";
import { Entity } from "../Resource/utils/types";

export type AvailableSubscriptionPlanEntity = Entity<
  {
    subscriptionType: "masters" | ProfileType;
    level: SubscriptionLevel;
    billingInterval: SubscriptionInterval;
    pricePerMonth: number;
    pricePerYear: number;
    currency: string;
    isCurrent: boolean;
    discount: {
      percent: number;
      pricePerMonth: number;
      pricePerYear: number;
    } | null;
  },
  {}
>;

export class AvailableSubscriptionPlanResource extends Resource<AvailableSubscriptionPlanEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "available_subscription_plans"
    });
  }
}
