import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { routes } from "triangular/Routes/routesConfiguration";
import { useStore } from "triangular/stores/StoreContext";

export function useUserMenuItems() {
  const { t } = useTranslation();
  const { signOut } = useStore();
  const { history } = useRouter();

  return [
    {
      label: t("userMenuItems.editProfile"),
      onClick: () => history.push(routes.editProfile.build({}))
    },
    {
      label: t("userMenuItems.payments"),
      onClick: () => history.push(routes.payments.build({}))
    },
    {
      label: t("userMenuItems.changePassword"),
      onClick: () => history.push(routes.changePassword.build({}))
    },
    {
      danger: true,
      label: t("userMenuItems.logout"),
      onClick: signOut
    }
  ];
}
