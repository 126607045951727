import React from "react";

import { TextField } from "triangular/components/form/TextField";
import { Typography } from "triangular/components/Typography/Typography";

import css from "./ParticleSizeField.module.scss";

interface ParticleSizeFieldProps {
  label: string;
  unit: string;
  "data-testid"?: string;
  name: string;
}

export const ParticleSizeField: React.FC<ParticleSizeFieldProps> = ({ label, unit, "data-testid": testId, name }) => {
  const createFieldsRow = (fieldNames: string[]) => {
    if (fieldNames.length !== 2) {
      throw new Error("Expected exactly two field names.");
    }

    return (
      <div className={css.fieldsRow}>
        <div className={css.fieldColumn}>
          <TextField
            className={{
              wrapper: css.textFieldWrapper,
              input: css.textFieldInput
            }}
            name={fieldNames[0]}
            data-testid={testId}
          />
        </div>
        <div className={css.textColumn}>{"% <"}</div>
        <div className={css.fieldColumn}>
          <TextField
            className={{
              wrapper: css.textFieldWrapper,
              input: css.textFieldInput
            }}
            name={fieldNames[1]}
            data-testid={testId}
          />
        </div>
        <div className={css.textColumn}>
          <Typography bold={true}>{unit}</Typography>
        </div>
      </div>
    );
  };

  return (
    <div className={css.wrapper}>
      <div className={css.labelRow}>{label}</div>
      {createFieldsRow([`${name}[0].percent`, `${name}[0].size`])}
      {createFieldsRow([`${name}[1].percent`, `${name}[1].size`])}
      {createFieldsRow([`${name}[2].percent`, `${name}[2].size`])}
    </div>
  );
};
