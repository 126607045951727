import classNames from "classnames";
import React from "react";

import { Button, ButtonProps } from "triangular/components/Button/Button";

import css from "./FormButtonsSection.module.scss";

interface FormButtonsSectionProps {
  buttons: ButtonProps[] | React.ReactNode;
  className?: string;
  withSpaceBetween?: boolean;
}

export const FormButtonsSection: React.FC<FormButtonsSectionProps> = ({ buttons, className, withSpaceBetween }) => {
  return (
    <div className={classNames(css.formButtonsSection, { [css.spaceBetween]: withSpaceBetween }, className)}>
      {Array.isArray(buttons)
        ? (buttons as ButtonProps[]).map((eachButtonProps, index) => <Button key={index} {...eachButtonProps} />)
        : buttons}
    </div>
  );
};
