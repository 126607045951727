import { TFunction } from "i18next";

import { rangeValidator } from "./rangeValidator";

export const phValidator = (t: TFunction, allowEmpty?: boolean) =>
  rangeValidator(t)({ min: 0, max: 14, allowFractions: true, allowEmpty: allowEmpty }).transform(value => {
    if (typeof value === "string") {
      return value.replace(",", ".");
    }
    return value;
  });
