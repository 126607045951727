export const languageOptions = [
  {
    value: "en" as const,
    label: "English"
  },
  {
    value: "de" as const,
    label: "Deutsch"
  },
  {
    value: "es" as const,
    label: "Español"
  },
  {
    value: "pl" as const,
    label: "Polski"
  }
];

export type AppLanguage = ArrayElement<typeof languageOptions>["value"];
