import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "triangular/components/Typography/Typography";

interface Props {
  status?: number;
  title?: string;
}

export const AccountConfirmationError: React.FC<Props> = ({ status = "", title = "" }) => {
  const { t } = useTranslation();

  const errorTitleMessages: { [Key: string]: string | undefined } = {
    invalid_vat: t("accountConfirmation.errors.invalidTaxId")
  };

  const errorStatusMessages: { [Key: string]: string | undefined } = {
    401: t("accountConfirmation.errors.invalidToken")
  };

  const existingAccountError: { [Key: string]: string | undefined } = {
    already_confirmed: t("accountConfirmation.errors.accountAlreadyConfirmed")
  };

  const unknownErrorMessage = t("errors.generic");

  const errorMessage = errorTitleMessages[title] || errorStatusMessages[status] || existingAccountError[title];

  return (
    <Typography center={true} component="p" bold={true}>
      {errorMessage || unknownErrorMessage}
    </Typography>
  );
};
