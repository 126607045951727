import { History } from "history";
import { _NotCustomized, IModelType, ModelProperties } from "mobx-state-tree";

import { i18n } from "triangular/i18next/i18next";
import { Api } from "triangular/services/Api/Api";

export interface StoreDeps {
  api: Api;
  history: History<any>;
  i18n: typeof i18n;
}

export const createStore = <PROPS extends ModelProperties, OTHERS, CustomC = _NotCustomized, CustomS = _NotCustomized>(
  storeDefinition: (deps: StoreDeps) => IModelType<PROPS, OTHERS, CustomC, CustomS>
) => {
  return (deps: StoreDeps) => storeDefinition(deps);
};
