import classNames from "classnames";
import React, { useContext } from "react";
import TextAreaAutosize from "react-autosize-textarea";

import { FormAdditionalContext } from "triangular/utils/components";

import { AbstractField, AbstractFieldProps } from "../AbstractField/AbstractField";

import css from "./TextArea.module.scss";

export interface TextAreaProps extends Omit<AbstractFieldProps, "component" | "render" | "children" | "type"> {
  maxRows?: number;
}

export const TextArea: React.FC<TextAreaProps> = ({
  className = {},
  label,
  maxRows = 5,
  "data-testid": testId,
  ...props
}) => {
  const { wrapper, input, inputContainer, ...classNameRest } = className;
  const { isReadOnly } = useContext(FormAdditionalContext);

  if (isReadOnly) {
    props.disabled = true;
  }

  const renderDisabled = (value: any) => (
    <div className={css.disabled}>
      {String(value || "")
        .trim()
        .split("\n")
        .map((line, i) => (
          <p key={i}>{line}</p>
        ))}
    </div>
  );

  return (
    <AbstractField
      className={{
        wrapper: classNames(css.wrapper, wrapper),
        inputContainer: classNames(css.inputContainer, inputContainer),
        ...classNameRest
      }}
      label={label}
      render={({ field, css: fieldCss }) =>
        !props.disabled ? (
          <TextAreaAutosize
            {...props}
            {...field}
            maxRows={maxRows}
            className={classNames(css.input, fieldCss.input, input)}
            value={field.value === null || field.value === undefined ? "" : field.value}
            data-testid={testId}
            id={props.name}
          />
        ) : (
          renderDisabled(field.value)
        )
      }
      {...props}
    />
  );
};
