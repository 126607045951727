import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";

import { Layout } from "triangular/components/Layout/Layout";
import { Segment } from "triangular/components/Segment/Segment";
import { Typography } from "triangular/components/Typography/Typography";
import { routes } from "triangular/Routes/routesConfiguration";
import { useStore } from "triangular/stores/StoreContext";

import {
  ChangePasswordForm,
  ChangePasswordFormValues
} from "../PasswordRecovery/ChangePassword/ChangePasswordForm/ChangePasswordForm";

import css from "./UpdatePassword.module.scss";

const UpdatePassword: React.FC<RouteComponentProps> = ({ history }) => {
  const { t } = useTranslation();
  const { userStore, snackbarStore } = useStore();
  const handleSubmit = async ({ password }: ChangePasswordFormValues) => {
    try {
      const account = userStore.id;
      await userStore.updatePassword({ account, password });
      snackbarStore.addSnackbar({ type: "success", message: t("passwordRecovery.successMessage") });
      history.push(routes.home.build());
    } catch (err) {
      snackbarStore.addSnackbar({ type: "error", message: t("errors.generic") });
    }
  };

  const handleOnCancel = () => {
    history.push(routes.home.build());
  };
  return (
    <Layout>
      <Segment className={css.segment}>
        <Typography component="h2" size="large" bold={true}>
          {t("passwordRecovery.setNewPasswordTitle")}
        </Typography>
        <ChangePasswordForm onSubmit={handleSubmit} onCancel={handleOnCancel} />
      </Segment>
    </Layout>
  );
};

export default UpdatePassword;
