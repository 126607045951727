import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { ReactNode } from "react";

import { Card } from "triangular/components/Card/Card";
import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FormSectionHeader } from "triangular/components/form/FormSectionHeader/FormSectionHeader";

import css from "./ExpertItemList.module.scss";

interface ExpertItemListProps {
  header: ReactNode;
  onAdd: () => void;
  disabled?: boolean;
}

export const ExpertItemList: React.FC<ExpertItemListProps> = observer(({ header, children, onAdd, disabled }) => {
  return (
    <>
      <FieldsRow>
        <div className="col-sm-12 col-md-6 col-lg-4">
          <Card
            className={classNames(css.item, css.minHeader, css.linkPointer)}
            data-testid="expertItemTile"
            onClick={onAdd}
          >
            <div className={css.listHeader}>
              <FormSectionHeader>{header}</FormSectionHeader>
            </div>
          </Card>
        </div>
        {children}
      </FieldsRow>
    </>
  );
});
