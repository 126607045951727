import { observer } from "mobx-react-lite";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { useTranslation } from "react-i18next";

import { SearchScene } from "triangular/components/SearchSceneLayout/SearchSceneLayout";
import { SearchSelector } from "triangular/components/SearchSelector/SearchSelector";

import css from "./DesktopViewContent.module.scss";
import { CompatibleMaterials } from "./filters/CompatibleMaterials";
import { ContactMaterials } from "./filters/ContactMaterials";
import { InstalledPower } from "./filters/InstalledPower/InstalledPower";
import { Location } from "./filters/Location";
import { MaterialCategories } from "./filters/MaterialCategories";
import { NoGoMaterials } from "./filters/NoGoMaterials.tsx";
import { NonContactMaterials } from "./filters/NonContactMaterials";
import { PossibleMaterialColor } from "./filters/PossibleMaterialColor";
import { ProcessCategory } from "./filters/ProcessCategory";
import { SystemCategory } from "./filters/SystemCategory";
import { SystemType } from "./filters/SystemType";
import { TargetIndustries } from "./filters/TargetIndustries";
import { Search } from "./Search";
import { SearchPhrase } from "./SearchPhrase";
import { SortBy } from "./SortBy/SortBy";
import { SystemList } from "./SystemList";
import { SystemsCount } from "./SystemsCount";
import { useFilterBadges } from "./utils/useFilterBadges";

export const DesktopViewContent = observer(() => {
  const filterBadges = useFilterBadges();
  const { t } = useTranslation();

  return (
    <>
      <Container fluid className={css.container}>
        <Row className="justify-content-md-center">
          <Col md={2}>
            <SearchSelector className={css.circle} position={css.position} logoSize={css.logo} />
          </Col>
          <Col md={"auto"}>
            <h1 className={css.tittle}>{t("systemSearch.sectionHeader")}</h1>
          </Col>
        </Row>
      </Container>
      <SearchScene.DesktopViewContent
        searchSection={<Search />}
        searchPhraseSection={<SearchPhrase />}
        sortSection={
          <>
            <SystemsCount />
            <SortBy />
          </>
        }
        basicFilters={
          <>
            <ProcessCategory />
            <SystemCategory />
            <SystemType />
            <PossibleMaterialColor />
            <TargetIndustries />
            <MaterialCategories />
          </>
        }
        advancedFilters={[
          <InstalledPower />,
          <CompatibleMaterials />,
          <NoGoMaterials />,
          <Location />,
          <ContactMaterials />,
          <NonContactMaterials />
        ]}
        filterBadges={filterBadges}
        results={<SystemList />}
      />
    </>
  );
});
