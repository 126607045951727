import classNames from "classnames";
import React, { ReactNode, useContext } from "react";
import { Accordion, AccordionContext, useAccordionToggle } from "react-bootstrap";

import { Typography } from "triangular/components/Typography/Typography";
import { ReactComponent as ArrowSvg } from "triangular/static/images/arrow-down.svg";

import { FieldsRow } from "../FieldsRow/FieldsRow";

import css from "./DropDownHeader.module.scss";

interface ToggleProps {
  children: ReactNode;
  eventKeyNum: any;
  handleClick: any;
}

export const DropDownHeader: React.FC<ToggleProps> = ({ children, eventKeyNum, handleClick }: ToggleProps) => {
  const currentEventKey = useContext(AccordionContext);
  const isCurrentEventKey = currentEventKey === eventKeyNum;
  const decoratedOnClick = useAccordionToggle(eventKeyNum, () => {
    handleClick();
  });

  return (
    <div onClick={decoratedOnClick}>
      <Accordion.Toggle as={Typography} eventKey={eventKeyNum}>
        <FieldsRow className={classNames(css.altHeader, "d-flex justify-content-between bd-highlight")}>
          <Typography className="p2" bold component="h3" color="white" size="big" upperCase={true}>
            {children}
          </Typography>
          <span className={classNames(css.arrow, "p2")}>
            <ArrowSvg className={classNames(css.dropdownSvg, { [css.dropdownSvgOpen]: isCurrentEventKey })} />
          </span>
        </FieldsRow>
      </Accordion.Toggle>
    </div>
  );
};
