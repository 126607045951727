import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { Button } from "triangular/components/Button/Button";
import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { useStore } from "triangular/stores/StoreContext";

import css from "./SelectMaterial.module.scss";
import { SelectMaterialModal } from "./SelectMaterialModal";

export const SelectMaterial: React.FC = observer(() => {
  const { myMaterialsStore } = useStore();
  const { location, history } = useRouter();
  // Modal can be open only when pagination is included into URL.
  // This make it work properly with browser's navigation.
  const isModalOpen = location.search.includes("pagination");
  const { t } = useTranslation();

  function openModal() {
    myMaterialsStore.fetchMyMaterials({ pushHistory: false });
  }

  function closeModal() {
    history.replace(location.pathname);
  }

  return (
    <>
      <FormSection>
        <FieldsRow className="justify-content-end">
          <FieldWrapper>
            <Button
              type="button"
              transparent={true}
              className={css.selectMaterialButton}
              onClick={openModal}
              disabled={myMaterialsStore.pagination.loading}
              showLoader={myMaterialsStore.pagination.loading}
            >
              {t("systemRequest.selectMaterial")}
            </Button>
          </FieldWrapper>
        </FieldsRow>
      </FormSection>
      {isModalOpen && <SelectMaterialModal isOpen={isModalOpen} onClose={closeModal} />}
    </>
  );
});
