import React from "react";
import { useTranslation } from "react-i18next";

import { useBreakpoint } from "triangular/components/Breakpoint/Breakpoint";
import { Button } from "triangular/components/Button/Button";
import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { Modal, useModal } from "triangular/components/Modal/Modal";

import css from "./MobileFilters.module.scss";

interface MobileFiltersProps {
  search: React.ReactNode;
  searchPhrase: React.ReactNode;
  fields: React.ReactNode[];
}

export const MobileFilters = ({ search, searchPhrase, fields }: MobileFiltersProps) => {
  const { isOpen, close, open } = useModal();
  const { t } = useTranslation();

  const isVisible = useBreakpoint({
    minWidth: "small",
    maxWidth: "small"
  });

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <div className={css.triggerButtonWrapper}>
        <Button onClick={open} transparent={true}>
          {t("search.showFilters")}
        </Button>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={close}
        className={{
          modal: css.modal,
          overlay: css.modalOverlay
        }}
      >
        <FormSection>
          <FieldsRow>
            <FieldWrapper>{search}</FieldWrapper>
          </FieldsRow>
          <div className={css.searchPhrase}>{searchPhrase}</div>
          <FieldsRow>
            {fields.map((eachField, i) => (
              <FieldWrapper key={i}>{eachField}</FieldWrapper>
            ))}
          </FieldsRow>
        </FormSection>
        <FormSection>
          <FieldsRow className={css.buttonSection}>
            <Button onClick={close} transparent={true}>
              {t("search.hideFilters")}
            </Button>
          </FieldsRow>
        </FormSection>
      </Modal>
    </>
  );
};
