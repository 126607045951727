import React from "react";

import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FormSection } from "triangular/components/form/FormSection/FormSection";

import { AdvancedFilters } from "./AdvancedFilters/AdvancedFilters";
import css from "./DesktopFilters.module.scss";

export const DesktopFilters: React.FC<{
  advancedFilters?: React.ReactNode[];
}> = ({ children, advancedFilters }) => {
  return (
    <FormSection className={css.wrapper}>
      <FieldsRow className={css.fieldRow}>
        {children}
        {advancedFilters && <AdvancedFilters fields={advancedFilters} />}
      </FieldsRow>
    </FormSection>
  );
};
