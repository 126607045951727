import { AxiosInstance } from "axios";

import { FileEntity } from "../entities/FileEntity";
import { Resource } from "../Resource/Resource";

export class MaterialTechFile extends Resource<FileEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "material_tech_files",
      prefixes: ["material_owners"],
      relationships: {}
    });
  }
}
