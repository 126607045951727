import { createBrowserHistory } from "history";
import merge from "lodash/merge";
import { SnapshotIn, types } from "mobx-state-tree";
import { connectReduxDevtools } from "mst-middlewares";

import { i18n } from "triangular/i18next/i18next";
import { routes } from "triangular/Routes/routesConfiguration";
import { Credentials } from "triangular/services/Api/Api";
import { setBugsnagUserId } from "triangular/services/bugsnag";
import { gaActions, GaCategories, googleAnalytics } from "triangular/services/googleAnalytics";
import { SubscriptionExpiredError } from "triangular/utils/errors";

import { AgreementStore, agreementStoreInitialState } from "./AgreementStore";
import { CommonsStore, commonsStoreInitialState } from "./CommonStore/CommonsStore";
import { ExpertSearchStore, expertSearchStoreInitialState } from "./ExpertSearchStore/ExpertSearchStore";
import { ExpertStore, expertStoreInitialState } from "./ExpertStore/ExpertStore";
import { MaterialsStore, materialsStoreInitialState } from "./MaterialStore/MaterialsStore";
import { MyMaterialsStore, myMaterialsStoreInitialState } from "./MyMaterialsStore/MyMaterialsStore";
import { MySystemsStore, mySystemsStoreInitialState } from "./MySystemsStore";
import { OwnerStore, ownerStoreInitialState } from "./OwnerStore";
import { PaymentsStore, paymentsStoreInitialState } from "./PaymentsStore";
import { RegistrationStore, registrationStoreInitialState } from "./RegistrationStore";
import { SnackbarStore, snackbarStoreInitialState } from "./SnackbarStore";
import { SystemSearchStore, systemSearchStoreInitialState } from "./SystemSearchStore/SystemSearchStore";
import { SystemsStore, systemsStoreInitialState } from "./SystemsStore/SystemsStore";
import { UserStore, userStoreInitialState } from "./UserStore";
import { StoreDeps } from "./utils/createStore";

const initialSnapshot: DeepRequired<SnapshotIn<RootStoreType>> = {
  registrationStore: registrationStoreInitialState,
  userStore: userStoreInitialState,
  snackbarStore: snackbarStoreInitialState,
  systemsStore: systemsStoreInitialState,
  materialsStore: materialsStoreInitialState,
  myMaterialsStore: myMaterialsStoreInitialState,
  mySystemsStore: mySystemsStoreInitialState,
  expertStore: expertStoreInitialState,
  ownerStore: ownerStoreInitialState,
  paymentsStore: paymentsStoreInitialState,
  commonsStore: commonsStoreInitialState,
  agreementStore: agreementStoreInitialState,
  appLoadingState: "in_progress",
  systemSearchStore: systemSearchStoreInitialState,
  expertSearchStore: expertSearchStoreInitialState
};

function RootStoreWithDeps(deps: StoreDeps) {
  return types
    .model("RootStore", {
      registrationStore: RegistrationStore(deps),
      userStore: UserStore(deps),
      snackbarStore: SnackbarStore(deps),
      systemsStore: SystemsStore(deps),
      materialsStore: MaterialsStore(deps),
      myMaterialsStore: MyMaterialsStore(deps),
      mySystemsStore: MySystemsStore(deps),
      expertStore: ExpertStore(deps),
      ownerStore: OwnerStore(deps),
      paymentsStore: PaymentsStore(deps),
      commonsStore: CommonsStore(deps),
      agreementStore: AgreementStore(deps),
      appLoadingState: types.enumeration<LoadingState | "idle">([
        "in_progress" as const,
        "done" as const,
        "failed" as const
      ]),
      systemSearchStore: SystemSearchStore(deps),
      expertSearchStore: ExpertSearchStore(deps)
    })
    .actions(self => ({
      setAppLoadingState(state: LoadingState) {
        self.appLoadingState = state;
      },
      async loadAccountData(accountId: string) {
        const { api } = deps;
        const { paymentsStore, expertStore, userStore, agreementStore } = self;

        if (userStore.isAuthenticated) {
          return;
        }

        setBugsnagUserId(accountId);

        const { data: account } = await api.account.findById(accountId, {
          include: {
            subscription: {
              invoiceDetail: true
            }
          }
        });
        const { data: agreedTerm } = await api.agreement.find({
          filter: { account_id: accountId, status: "completed" }
        });

        const { data: terms } = await api.term.find();

        // Get latest term id with at least 1 uploaded file
        let lastTermId;
        if (terms[0]?.id && terms[0].tFiles.length > 0 && terms[0].pFiles.length > 0) {
          lastTermId = terms[0].id;
        } else {
          lastTermId = terms[1]?.id || null;
        }

        // Check if last agreed term is the same as the latest term
        // Used to decide when to show the agreement modal
        let lastAgreementId;
        if (agreedTerm.length > 0) {
          if (agreedTerm[0].termId === lastTermId) {
            lastAgreementId = { id: agreedTerm[0].id, termId: agreedTerm[0].termId, status: "completed" as const };
          } else {
            lastAgreementId = { id: agreedTerm[0].id, termId: agreedTerm[0].termId, status: "pending" as const };
          }
        } else {
          lastAgreementId = { id: null, termId: null, status: "pending" as const };
        }

        if (!account.subscription) {
          throw new Error("Subscription relationship not found");
        }

        if (userStore.type === "experts") {
          await expertStore.fetchCurrentExpert(account.ownerable.id);
        }

        paymentsStore.setSubscription({
          id: account.subscription.id,
          status: account.subscription.status,
          paymentMethodId: account.subscription.paymentMethodId,
          billingInterval: account.subscription.billingInterval,
          level: account.subscription.level,
          manualPayment: account.subscription.manualPayment,
          currentPeriodEnd: account.subscription.currentPeriodEnd,
          invoiceDetailId: account.subscription.invoiceDetail && account.subscription.invoiceDetail.id
        });

        agreementStore.setState({
          accountId: accountId,
          lastAgreementId: lastAgreementId.id,
          lastAgreedTermId: lastAgreementId.termId,
          lastTermId: lastTermId,
          status: lastAgreementId.status
        });

        self.userStore.setState({
          id: accountId,
          email: account.email,
          type: account.currentProfileType,
          profileId: account.currentProfileId,
          firstName: account.firstName,
          lastName: account.lastName,
          phoneNumber: account.phoneNumber,
          isAuthenticated: true,
          accountType: account.accountType,
          createdAt: account.createdAt,
          availableProfileTypes: account.accountType === "master" ? ["system_owners", "material_owners", "experts"] : []
        });
      }
    }))
    .actions(self => ({
      async signIn({ email, password, rememberMe }: Credentials & { rememberMe: boolean }) {
        const { api } = deps;
        const accountId = await api.signIn({ email, password }, { rememberMe });
        await self.loadAccountData(accountId);
      },
      async reauthenticate() {
        const { api } = deps;
        const { userStore } = self;
        try {
          if (api.isRefreshTokenAvailable() && !userStore.isAuthenticated) {
            const accountId = await api.reauthenticate();
            await self.loadAccountData(accountId);
          }
        } catch (err) {
          userStore.setState(userStoreInitialState);
        }
      },
      signOut() {
        deps.history.push(routes.login.build({}));
        deps.api.cleanUpTokens();

        // We don't want to reload system store and appLoadingState after signing out
        const {
          systemsStore,
          materialsStore,
          expertStore,
          commonsStore,
          agreementStore,
          appLoadingState,
          ...overrides
        } = initialSnapshot;

        Object.assign(self, overrides);

        googleAnalytics.sendEvent({ category: GaCategories.user, action: gaActions.user.logout });
      }
    }))
    .actions(self => ({
      async switchProfileType(currentProfileType: ProfileType) {
        const accountId = self.userStore.id;

        if (!accountId) {
          throw new Error("Account id not available");
        }

        self.setAppLoadingState("in_progress");

        try {
          const { id, ...userStoreInitialStateRest } = userStoreInitialState;
          self.userStore.setState(userStoreInitialStateRest);
          await deps.api.account.update(accountId, {
            currentProfileType
          });
          await self.loadAccountData(accountId);
        } catch (err) {
          self.signOut();
          self.setAppLoadingState("done");
          throw err;
        }

        self.setAppLoadingState("done");
      },
      async onSubscriptionExpired() {
        if (self.paymentsStore.subscription.status !== "inactive") {
          await self.setAppLoadingState("in_progress");
          self.userStore.setState(userStoreInitialState);
          deps.history.push(routes.payments.build());

          try {
            await self.reauthenticate();
          } finally {
            self.setAppLoadingState("done");
            self.snackbarStore.addSnackbar({
              type: "error",
              message: i18n.t("errors.subscriptionExpiredError")
            });
          }

          throw new SubscriptionExpiredError();
        }
      },
      onRefreshTokenExpired() {
        self.signOut();
      }
    }));
}

export type TRootStore = ReturnType<typeof RootStoreWithDeps>;
export type RootStoreType = TRootStore["Type"];

export function createRootStore(
  { api, history = createBrowserHistory() }: Omit<StoreDeps, "types" | "i18n">,
  derivedSnapshot: DeepPartial<TRootStore["SnapshotType"]> = {}
) {
  const initialState: SnapshotIn<RootStoreType> = merge({}, initialSnapshot, derivedSnapshot);

  const RootStore = RootStoreWithDeps({
    api,
    history,
    i18n
  });

  const store = RootStore.create(initialState);

  if (process.env.REACT_APP_ENVIRONMENT === "development") {
    connectReduxDevtools(require("remotedev"), store, {
      logIdempotentActionSteps: true,
      logArgsNearName: false,
      logChildActions: false
    });
  }

  return store;
}
