import classNames from "classnames";
import { connect } from "formik";
import get from "lodash/get";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";

import { LoadingOverlay } from "triangular/components/LoadingOverlay/LoadingOverlay";
import { TriLoader } from "triangular/components/TriLoader/TriLoader";
import { DOCUMENT_EXTS, MAX_FILE_SIZE_MB, PHOTO_EXTS } from "triangular/consts";
import { useStore } from "triangular/stores/StoreContext";
import { FormAdditionalContext } from "triangular/utils/components";

import { FileFieldProps, FilesField } from "./FilesField/FilesField";
import { FileListItem, FilesList } from "./FilesList/FilesList";
import css from "./SystemFilesField.module.scss";

interface SystemFilesFieldProps {
  fileType: "photo" | "document";
  label: string;
  name: string;
  onAddFile: FileFieldProps["onAddFile"];
  required?: boolean;
  "data-testid"?: string;
  maxSizeMb?: number;
  onRemoveFile(fileToRemove: FileListItem): Promise<any>;
  noMarginTop?: boolean;
}

export const SystemFilesField = connect<SystemFilesFieldProps, any>(
  observer(
    ({
      noMarginTop,
      formik,
      onRemoveFile,
      fileType,
      name,
      label,
      onAddFile,
      required,
      "data-testid": testId,
      maxSizeMb = MAX_FILE_SIZE_MB
    }) => {
      const { snackbarStore } = useStore();
      const [loading, setLoading] = useState(false);
      const { values, setFieldValue } = formik;
      const accept = fileType === "photo" ? PHOTO_EXTS : `${PHOTO_EXTS},${DOCUMENT_EXTS}`;
      const files = get(values, name, []) as FileListItem[] | null;
      const appliedFiles = files ? files : [];
      const { isReadOnly } = useContext(FormAdditionalContext);

      const handleAddFile: FileFieldProps["onAddFile"] = async (...params) => {
        setLoading(true);

        try {
          await onAddFile(...params);
        } catch (err) {
          snackbarStore.showGenericError(err);
        } finally {
          setLoading(false);
        }
      };

      const handleRemoveFile = async (fileToRemove: FileListItem) => {
        setLoading(true);

        try {
          await onRemoveFile(fileToRemove);
          setFieldValue(
            name,
            appliedFiles.filter(eachFile => eachFile.id !== fileToRemove.id)
          );
        } catch (err) {
          snackbarStore.showGenericError(err);
        } finally {
          setLoading(false);
        }
      };

      const acceptedExtensions = accept.split(",").join(", ");

      return (
        <div
          className={noMarginTop ? classNames(css.wrapper, css.noMarginTop) : css.wrapper}
          data-testid={`${testId}Test`}
        >
          {!isReadOnly && (
            <div>
              <label className={css.filesOuterLabel}>
                <strong>{label}</strong>
              </label>
              <FilesField
                className={{ wrapper: css.filesField }}
                name={name}
                label={`(${acceptedExtensions})`}
                accept={accept}
                onAddFile={handleAddFile}
                required={required}
                data-testid={`${testId}FilesField`}
                maxSizeMb={maxSizeMb}
                disabled={isReadOnly}
              />
            </div>
          )}
          <FilesList
            className={{
              ul: classNames(css.filesList, {
                [css.filesListEmpty]: appliedFiles.length === 0,
                [css.readOnlyList]: isReadOnly
              })
            }}
            files={appliedFiles}
            onRemoveFile={isReadOnly ? undefined : handleRemoveFile}
            data-testid={`${testId}FilesList`}
          />
          <LoadingOverlay isVisible={loading}>
            <TriLoader className={{ container: css.triLoaderContainer }} />
          </LoadingOverlay>
        </div>
      );
    }
  )
);
