import { Stripe } from "@stripe/stripe-js";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { notifyBugsnag } from "triangular/services/bugsnag";
import { createStripe } from "triangular/services/stripe";
import { RootStoreType } from "triangular/stores/RootStore";

export const useInitialData = (store: RootStoreType) => {
  const { i18n } = useTranslation();
  const {
    commonsStore,
    systemsStore,
    materialsStore,
    expertStore,
    snackbarStore,
    reauthenticate,
    setAppLoadingState,
    appLoadingState
  } = store;
  const [stripe, setStripe] = useState<Stripe | null>(null);

  const initializeStripe = useCallback(async () => {
    if (!stripe) {
      try {
        setStripe(await createStripe(i18n.language));
      } catch (err) {
        notifyBugsnag(err);
      }
    }
  }, [i18n.language, stripe]);

  const requestInitialData = useCallback(async () => {
    setAppLoadingState("in_progress");
    try {
      await Promise.all([
        initializeStripe(),
        expertStore.loadSettings(),
        commonsStore.loadSettings(),
        systemsStore.loadSettings(),
        materialsStore.loadSettings(),
        reauthenticate()
      ]);
      setAppLoadingState("done");
    } catch (err) {
      snackbarStore.showGenericError(err);
      notifyBugsnag(err);
      setAppLoadingState("failed");
    }
  }, [
    initializeStripe,
    commonsStore,
    expertStore,
    systemsStore,
    materialsStore,
    reauthenticate,
    setAppLoadingState,
    snackbarStore
  ]);

  useEffect(() => {
    requestInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    appLoadingState,
    stripe
  };
};
