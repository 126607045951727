import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "triangular/components/Typography/Typography";
import { useStore } from "triangular/stores/StoreContext";

export const ExpertsCount = observer(() => {
  const { t } = useTranslation();
  const { expertSearchStore } = useStore();
  return (
    <Typography upperCase={true} size="small" verticalSpacing="medium" component="div">
      {expertSearchStore.totalItems} {t("expertsSearch.searchUnit")}
    </Typography>
  );
});
