import { AxiosInstance } from "axios";

import { Resource } from "../Resource/Resource";
import { Entity } from "../Resource/utils/types";

export type SubscriptionPlanAttributes = {
  level: SubscriptionLevel;
  billingInterval: SubscriptionInterval;
  accountType: AccountType;
};

export type SubscriptionPlanEntity = Entity<SubscriptionPlanAttributes, {}>;

export class SubscriptionPlanResource extends Resource<SubscriptionPlanEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "subscription_plan"
    });
  }
}
