import { types } from "mobx-state-tree";

import { FlatEntity } from "triangular/services/Api/Resource/utils/types";
import { SystemEntity } from "triangular/services/Api/resources/SystemResource";
import { CancelTokenType, isCancel } from "triangular/utils/api";

import { systemModel } from "./SystemSearchStore/models";
import { createStore } from "./utils/createStore";

export const MySystemsStore = createStore(storeDeps =>
  types
    .model("MySystemsStore", {
      totalItems: 0,
      systems: types.array(systemModel),
      isLoading: types.boolean
    })
    .actions(self => ({
      async fetchSystems({
        page,
        cancelToken
      }: {
        page: {
          size: number;
          number: number;
        };
        cancelToken: CancelTokenType;
      }) {
        const response = await storeDeps.api.systemOwnerSystem.find(
          {
            pagination: page,
            include: {
              systemType: true,
              industries: true
            }
          },
          { cancelToken }
        );
        return {
          data: response.data,
          meta: response.meta as {
            pageCount: number;
            recordCount: number;
          }
        };
      },
      setSystems(systems: FlatEntity<SystemEntity>[]) {
        self.systems.clear();
        systems.forEach(eachSystem => self.systems.push(eachSystem));
      },
      setTotalItems(totalItems: number) {
        self.totalItems = totalItems;
      },
      setIsLoading(isLoading: boolean) {
        self.isLoading = isLoading;
      },
      async deleteMySystem(id: string) {
        return await storeDeps.api.systemOwnerSystem.delete(id);
      }
    }))
    .actions(self => ({
      async loadSystems(...params: Parameters<typeof self.fetchSystems>) {
        self.setIsLoading(true);

        try {
          const { data, meta } = await self.fetchSystems(...params);
          self.setSystems(data);
          self.setTotalItems(meta.recordCount);
          return self.setIsLoading(false);
        } catch (err) {
          if (isCancel(err)) {
            return;
          }

          self.setIsLoading(false);

          throw err;
        }
      }
    }))
);

export const mySystemsStoreInitialState = {
  totalItems: 0,
  systems: [],
  isLoading: true
};
