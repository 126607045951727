import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { SearchResultsList } from "triangular/components/SearchResultsList/SearchResultsList";
import { ExpertModelSnapshot } from "triangular/stores/ExpertSearchStore/models";
import { useStore } from "triangular/stores/StoreContext";

import { ExpertTile } from "./ExpertTile/ExpertTile";
import { useExpertsPaginationContext } from "./utils/pagination";

export const ExpertList: React.FC = observer(() => {
  const { t } = useTranslation();
  const { expertSearchStore } = useStore();
  const { experts, isLoading } = expertSearchStore;
  const pagination = useExpertsPaginationContext();

  return (
    <SearchResultsList
      items={experts}
      isLoading={isLoading}
      noResultsMessage={t("expertsSearch.noResults")}
      totalItems={expertSearchStore.totalItems}
      {...pagination}
    >
      {(eachExpert: ExpertModelSnapshot) => (
        <ExpertTile
          key={eachExpert.id}
          id={eachExpert.id}
          experiences={eachExpert.experiences}
          avatar={eachExpert.avatar}
          firstName={eachExpert.firstName}
          lastName={eachExpert.lastName}
          title={eachExpert.showcaseTitle}
          processCategories={eachExpert.processCategories}
          industries={eachExpert.industries}
          company={eachExpert.expertCompany}
          description={eachExpert.description}
          origin="search"
        />
      )}
    </SearchResultsList>
  );
});
