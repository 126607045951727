import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

import { AgreementModal } from "triangular/components/AgreementModal/AgreementModal";
import { Layout } from "triangular/components/Layout/Layout";
import { useModal } from "triangular/components/Modal/Modal";
import { UserGreeting } from "triangular/components/UserGreeting/UserGreeting";
import { UserHomeButtons } from "triangular/components/UserHomeButtons/UserHomeButtons";
import { useStore } from "triangular/stores/StoreContext";

export const MyExpertProfile: React.FC<RouteComponentProps> = observer(() => {
  const { agreementStore } = useStore();
  const agreementConfirmation = useModal();

  const closeAgreementModal = () => {
    agreementConfirmation.close();
  };

  useEffect(() => {
    if (agreementStore.openConfirmation) {
      agreementConfirmation.open();
    }
  }, [agreementConfirmation, agreementStore]);

  return (
    <Layout withMolecules={false}>
      <UserGreeting />
      <UserHomeButtons />
      <AgreementModal isOpen={agreementConfirmation.isOpen} onClose={closeAgreementModal} />
    </Layout>
  );
});
