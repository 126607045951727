import classNames from "classnames";
import React from "react";

import avatarPlaceholder from "triangular/static/images/expert.svg";

import css from "./Avatar.module.scss";

export const Avatar: React.FC<{ imageUrl?: string | null; className?: string }> = ({ imageUrl, className }) => {
  const style = {
    backgroundImage: `url(${imageUrl || avatarPlaceholder})`
  };

  return <div className={classNames(css.avatar, className)} style={style} role="img" />;
};
