import classNames from "classnames";
import { FormikProps } from "formik";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { NO_RESTRICTIONS } from "triangular/consts";
import { FormAdditionalContext } from "triangular/utils/components";
import { uniqueValues } from "triangular/utils/uniqueValues";

import { Badge } from "../../Badge/Badge";
import { Select, SelectProps } from "../../Select/Select";
import { AbstractField, AbstractFieldProps } from "../AbstractField/AbstractField";

import css from "./MultiSelectField.module.scss";

export interface MultiSelectFieldProps extends Omit<AbstractFieldProps, "component" | "render" | "children"> {
  selectProps: Omit<SelectProps<string>, "onChange" | "onBlur" | "value" | "placeholder" | "disabled">;
  onChangeCallback?: (form: FormikProps<any>, values: string[]) => void;
  placeholder?: string;
  tabIndex?: number;
  "data-testid"?: string;
  allowCustomValues?: boolean;
  areSelectedItemsHidden?: boolean;
  className?: {
    wrapper?: string;
  };
  noRestrictionsValue?: string;
}

export function MultiSelectField({
  onChangeCallback,
  tabIndex,
  "data-testid": testId,
  selectProps: { options },
  placeholder,
  className = {},
  areSelectedItemsHidden = false,
  allowCustomValues = false,
  noRestrictionsValue = NO_RESTRICTIONS,
  ...props
}: MultiSelectFieldProps) {
  const { t } = useTranslation();
  const { isReadOnly } = useContext(FormAdditionalContext);

  if (isReadOnly) {
    props.disabled = true;
  }

  const defaultPlaceholder = options.length === 0 ? t("selectField.notAvailable") : t("selectField.placeholder");

  return (
    <AbstractField
      className={{
        wrapper: classNames(css.wrapper, className.wrapper)
      }}
      {...props}
      render={({ field: { value: fieldValue, name }, form, form: { setFieldValue }, css: fieldCss }) => {
        const values: string[] = uniqueValues(fieldValue);

        const handleChangeCallback = (updatedValues: string[]) => {
          if (onChangeCallback) {
            onChangeCallback(form, updatedValues);
          }
        };

        const handleDelete = (__?: React.MouseEvent, item?: string) => {
          if (props.disabled) {
            return;
          }

          const newValues = values.filter(value => value !== item);

          setFieldValue(name, newValues);
          handleChangeCallback(newValues);
        };

        const handleSelect = (value: string) => {
          const newValues = values.includes(value)
            ? values.filter(eachValue => eachValue !== value)
            : [...values, value];

          setFieldValue(name, newValues);
          handleChangeCallback(newValues);
        };

        const selectedOptions = values.map(value => {
          const selectedOption = options.find(eachOption => eachOption.value === value);
          return {
            value,
            label: selectedOption ? selectedOption.label : value
          };
        });

        const areSelectedOptionsVisible = selectedOptions.length > 0 && areSelectedItemsHidden === false;

        const noRestrictionOptionSelected = selectedOptions.some(
          eachOption => eachOption.value === noRestrictionsValue
        );

        const displayedOptions = options
          .map(eachOption => {
            const differentOptionDisabled = eachOption.value !== noRestrictionsValue && noRestrictionOptionSelected;

            const noRestrictionOptionDisabled =
              eachOption.value === noRestrictionsValue &&
              (selectedOptions.length > 1 ||
                selectedOptions.some(eachOption => eachOption.value !== noRestrictionsValue));

            return {
              ...eachOption,
              disabled:
                typeof eachOption.disabled === "undefined"
                  ? differentOptionDisabled || noRestrictionOptionDisabled
                  : eachOption.disabled
            };
          })
          .sort(({ value }) => (value === noRestrictionsValue ? -1 : 0));

        return (
          <div>
            <Select
              autoComplete={true}
              tabIndex={tabIndex}
              disabled={props.disabled || displayedOptions.length === 0}
              placeholder={placeholder || defaultPlaceholder}
              value={values}
              options={displayedOptions}
              onChange={handleSelect}
              className={{
                container: fieldCss.inputContainer,
                input: fieldCss.input
              }}
              keepOpen={true}
              allowCustomValues={!noRestrictionOptionSelected && allowCustomValues}
              data-testid={testId}
            />

            {areSelectedOptionsVisible && (
              <ul className={css.itemsList}>
                {selectedOptions.map(item => (
                  <li key={item.value} data-testid={`${testId}Item`}>
                    <Badge
                      onDelete={handleDelete}
                      variant={props.disabled ? "mushroom" : "darkMint"}
                      value={item.value}
                    >
                      {item.label}
                    </Badge>
                  </li>
                ))}
              </ul>
            )}
          </div>
        );
      }}
    />
  );
}
