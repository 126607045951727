import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "triangular/components/Button/Button";
import { Modal, ModalProps } from "triangular/components/Modal/Modal";
import { Typography } from "triangular/components/Typography/Typography";

import css from "./ForgotPasswordModal.module.scss";

interface ForgotPasswordModalProps extends ModalProps {
  email: string;
}

export const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = props => {
  const { email, onClose } = props;

  const { t } = useTranslation();

  return (
    <Modal {...props}>
      <div data-testid="forgotPasswordModal">
        <Typography component="p" className={css.message}>
          {t("passwordRecovery.modalDescription", { email })}
        </Typography>
        <Button onClick={onClose}>Close</Button>
      </div>
    </Modal>
  );
};
