import { AxiosInstance } from "axios";

import { Resource } from "../Resource/Resource";
import { Entity } from "../Resource/utils/types";

export type SubscriptionPaymentMethodAttributes = {
  paymentMethodId: string;
};

export type SubscriptionPaymentMethodEntity = Entity<SubscriptionPaymentMethodAttributes, {}>;

export class SubscriptionPaymentMethodResource extends Resource<SubscriptionPaymentMethodEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "subscription_payment_method"
    });
  }
}
