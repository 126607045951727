import { AxiosInstance } from "axios";

import { Resource } from "../Resource/Resource";
import { Entity, SettingItem } from "../Resource/utils/types";

interface Specialty extends SettingItem {
  specialties: SettingItem[];
}

type ExpertSettingsEntity = Entity<{
  reaches: string[];
  languageLevels: string[];
  languages: string[];
  specialties: Specialty[];
}>;

export class ExpertSettingsResource extends Resource<ExpertSettingsEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "expert_settings",
      prefixes: []
    });
  }
}
