import { useContext } from "react";
import * as Yup from "yup";

import { createPagination, createPaginationContext } from "triangular/utils/pagination/pagination";

export const sortingOptions = ["name", "country_code", "contact_materials"];

export const paginationSchema = Yup.object({
  sortBy: Yup.string()
    .oneOf(sortingOptions)
    .default("name"),
  search: Yup.string().notRequired(),
  processCategoryId: Yup.string()
    .notRequired()
    .nullable(),
  systemCategoryId: Yup.array()
    .of(Yup.string())
    .notRequired()
    .nullable(),
  systemTypeId: Yup.array()
    .of(Yup.string())
    .notRequired()
    .nullable(),
  colorsWhitelist: Yup.array()
    .of(Yup.string())
    .notRequired()
    .nullable(),
  installedPowerKw: Yup.array()
    .of(Yup.number())
    .notRequired()
    .nullable(),
  materialsWhitelist: Yup.array()
    .of(Yup.string())
    .notRequired()
    .nullable(),
  materialsBlacklist: Yup.array()
    .of(Yup.string())
    .notRequired()
    .nullable(),
  countryCode: Yup.string()
    .notRequired()
    .nullable(),
  contactMaterials: Yup.array()
    .of(Yup.string())
    .notRequired()
    .nullable(),
  nonContactMaterials: Yup.array()
    .of(Yup.string())
    .notRequired()
    .nullable(),
  industryIds: Yup.array()
    .of(Yup.string())
    .notRequired()
    .nullable(),
  materialCategoryIds: Yup.array()
    .of(Yup.string())
    .notRequired()
    .nullable()
});

export const pagination = createPagination({
  queryParams: {
    schema: paginationSchema,
    defaults: {
      search: undefined,
      sortBy: "name",
      processCategoryId: undefined,
      systemCategoryId: undefined,
      systemTypeId: undefined,
      colorsWhitelist: undefined,
      installedPowerKw: undefined,
      materialsWhitelist: undefined,
      materialsBlacklist: undefined,
      countryCode: undefined,
      contactMaterials: undefined,
      nonContactMaterials: undefined,
      industryIds: undefined,
      materialCategoryIds: undefined
    }
  }
});

export const usePagination = pagination.usePagination;
export const parsePaginationQuery = pagination.parsePaginationQuery;

export const SystemsPaginationContext = createPaginationContext<Yup.InferType<typeof paginationSchema>>();

export function useSystemsPaginationContext() {
  const { pagination } = useContext(SystemsPaginationContext);

  if (!pagination) {
    throw new Error("Pagination context not provided");
  }

  return pagination;
}
