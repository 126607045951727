import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import usePromise from "react-use/lib/usePromise";

import { useLoadable } from "triangular/utils/components";

import { Button } from "../Button/Button";
import { Modal, ModalProps } from "../Modal/Modal";
import { Typography } from "../Typography/Typography";

import css from "./Prompt.module.scss";

export interface PromptProps extends Omit<ModalProps, "children"> {
  onAgree: () => void | Promise<void>;
  header?: string | React.ReactNode;
  message?: string | React.ReactNode;
}

export const Prompt: React.FC<PromptProps> = ({ onAgree, header, message, onClose, children, ...props }) => {
  const { t } = useTranslation();
  const { loadingState, setLoadable } = useLoadable("idle");
  const resolveWhenMounted = usePromise();

  const handleOnAgree = useCallback(async () => {
    await resolveWhenMounted(Promise.resolve(onAgree()));
    onClose();
  }, [onAgree, onClose, resolveWhenMounted]);

  const handleLoadableOnAgree = useCallback(() => setLoadable(handleOnAgree), [handleOnAgree, setLoadable]);

  return (
    <Modal onClose={onClose} {...props}>
      <div className={css.header}>
        <Typography component="h2" size="big" bold={true}>
          {header}
        </Typography>
      </div>
      <div className={css.message}>
        {typeof message === "string" ? (
          <Typography component="p" size="medium" bold={true}>
            {message}
          </Typography>
        ) : (
          message
        )}
      </div>
      <div className={css.actions}>
        <Button onClick={onClose} transparent={true} variant="dark" disabled={loadingState === "in_progress"}>
          {t("prompt.no")}
        </Button>
        <Button onClick={handleLoadableOnAgree} data-testid="promptYes" showLoader={loadingState === "in_progress"}>
          {t("prompt.yes")}
        </Button>
      </div>
    </Modal>
  );
};
