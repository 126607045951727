import React from "react";

import { Typography } from "triangular/components/Typography/Typography";
import { countries } from "triangular/consts";
import { ReactComponent as Marker } from "triangular/static/images/marker.svg";
import { SystemModelSnapshot } from "triangular/stores/SystemsStore/systemModel";

export const CountryName: React.FC<{ countryCode: SystemModelSnapshot["countryCode"] }> = ({ countryCode }) => {
  const country = countries.find(({ code }) => code === countryCode);

  if (country) {
    return (
      <Typography component="h5" verticalSpacing="small">
        <Marker /> {country.name}
        &nbsp;({countryCode})
      </Typography>
    );
  }

  return null;
};
