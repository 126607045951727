import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import { Link } from "react-router-dom";

import { LogoSmall } from "triangular/components/Layout/LogoSmall/LogoSmall";

import css from "./SearchSelector.module.scss";

export interface SearchSelectorProps {
  className?: string;
  position?: string;
  logoSize?: string;
  dropDownMenu?: string;
}

export const SearchSelector: React.FC<SearchSelectorProps> = ({ className, position, logoSize, dropDownMenu }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const showDropdown = () => {
    setShow(!show);
  };
  const hideDropdown = () => {
    setShow(false);
  };

  function searchDropdown() {
    return (
      <div className={className || css.circle}>
        <Dropdown show={show} onMouseEnter={showDropdown} onMouseLeave={hideDropdown} onToggle={showDropdown}>
          <div className={position || css.position}>
            <Dropdown.Toggle variant="white shadow-none" id="search-dropdown">
              <LogoSmall className={logoSize || css.logosize}></LogoSmall>
              <h2 className={css.searchText}>{t("searchDropdown.search")}</h2>
            </Dropdown.Toggle>
            <Dropdown.Menu className={dropDownMenu || css.dropDownMenu}>
              <Dropdown.Item as={Link} to="/systems" className={css.dropDownText}>
                {t("searchDropdown.system")}
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/experts" className={css.dropDownText}>
                {t("searchDropdown.expert")}
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/materials" disabled className={css.dropDownText}>
                {t("searchDropdown.material")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </div>
        </Dropdown>
      </div>
    );
  }

  return <div>{searchDropdown()}</div>;
};
