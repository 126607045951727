import classNames from "classnames";
import React from "react";

import { PageLoader } from "../PageLoader/PageLoader";

import { Footer } from "./Footer/Footer";
import { Header } from "./Header/Header";
import css from "./Layout.module.scss";

interface LayoutProps {
  withFooter?: boolean;
  withHeader?: boolean;
  withMolecules?: boolean;
  className?: {
    main?: string;
    wrapper?: string;
  };
  loading?: boolean;
  removePadding?: boolean;
}

export const Layout: React.FC<LayoutProps> = ({
  withFooter = true,
  withHeader = true,
  withMolecules = true,
  children,
  className = {},
  loading = false,
  removePadding = false
}) => {
  return (
    <div className={classNames(css.pageWrapper, className.wrapper)}>
      {withHeader && <Header />}
      <main
        className={classNames(css.main, className.main, {
          [css.moleculesBackground]: withMolecules,
          [css.removePadding]: removePadding
        })}
      >
        {loading ? <PageLoader /> : children}
      </main>
      {withFooter && <Footer />}
    </div>
  );
};
