import React from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { LabeledSelect } from "triangular/components/LabeledSelect/LabeledSelect";
import { useStore } from "triangular/stores/StoreContext";
import { settingItemsToSelectOptions } from "triangular/utils/systems";

import { useSystemsPaginationContext } from "../utils/pagination";
import { updateSystemTypeIds } from "../utils/updates";

export const SystemType: React.FC = () => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const { paginationQuery, buildUrl } = useSystemsPaginationContext();
  const { commonsStore } = useStore();

  const systemTypesSettings = commonsStore.getSettings().systemTypes;
  const { systemCategoryId, systemTypeId } = paginationQuery;

  if (!systemCategoryId) {
    return null;
  }

  const options = systemCategoryId.flatMap(eachSystemCategoryId =>
    settingItemsToSelectOptions(t)("systemTypeOptions", systemTypesSettings.get(eachSystemCategoryId))
  );

  if (options.length === 0) {
    return null;
  }

  const handleChange = (selectedValue: string | string[]) =>
    history.push(buildUrl(() => updateSystemTypeIds(selectedValue as string[])));

  return (
    <LabeledSelect
      label={t("systems.systemType")}
      value={systemTypeId || []}
      options={options}
      onChange={handleChange}
      data-testid="systemTypeFilter"
    />
  );
};
