import { connect } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { MaterialCategoryField } from "../MaterialCategoryField";

export const MaterialCategoriesField = connect<
  {},
  {
    targetIndustries?: string[] | null;
    label: string;
  }
>(
  observer(({ formik }) => {
    const { t } = useTranslation();
    const selectedTargetIndustryIds = formik.values.targetIndustries || [];

    return (
      <MaterialCategoryField
        label={t("addSystem.materialCategory")}
        name="materialCategories"
        isMultiple={true}
        selectedTargetIndustryIds={selectedTargetIndustryIds}
      />
    );
  })
);
