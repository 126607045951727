import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { CompanyNameLabel } from "triangular/components/CompanyNameLabel/CompanyNameLabel";
import { DefaultFormButtons } from "triangular/components/form/DefaultFormButtons";
import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { Form } from "triangular/components/form/Form";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { TextArea } from "triangular/components/form/TextArea/TextArea";
import { TextField } from "triangular/components/form/TextField";
import { requiredString } from "triangular/components/form/validators/requiredString";
import { Typography } from "triangular/components/Typography/Typography";
import { countriesSelectOptions } from "triangular/consts";
import { TaxIdField } from "triangular/scenes/Registration/CompanyInfo/CompanyInfoForm/CompanyInfoForm";
import { InvoiceDetailsAttributes } from "triangular/services/Api/resources/InvoiceDetailsResource";
import { useStore } from "triangular/stores/StoreContext";
import { required } from "triangular/utils/language";

import { taxIdValidator } from "../validators/taxIdValidator";

const transformInvoiceDetailsValues = ({
  name,
  taxId,
  city,
  postalCode,
  addressLine1,
  countryCode,
  ...values
}: InvoiceDetailsValues): InvoiceDetailsAttributes => {
  const requiredValues = required({
    name,
    taxId,
    city,
    postalCode,
    addressLine1,
    countryCode
  });

  return {
    name: requiredValues.name,
    taxId: requiredValues.taxId,
    city: requiredValues.city,
    postalCode: requiredValues.postalCode,
    countryCode: requiredValues.countryCode,
    line1: requiredValues.addressLine1,
    line2: values.addressLine2
  };
};

export interface InvoiceDetailsValues {
  name: string | null;
  city: string | null;
  postalCode: string | null;
  countryCode: string | null;
  taxId: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
}

interface Props {
  initialValues: InvoiceDetailsValues | null;
  onSubmit: (values: InvoiceDetailsAttributes) => Promise<void>;
}

export const InvoiceDetailsForm = observer<Props>(({ initialValues, onSubmit }) => {
  const { t } = useTranslation();
  const { userStore } = useStore();

  if (userStore.type === null) {
    throw new Error("Profile type cannot be null!");
  }

  const validationSchema = Yup.object<InvoiceDetailsValues>({
    name: requiredString(t),
    city: requiredString(t),
    countryCode: requiredString(t),
    postalCode: requiredString(t),
    taxId: taxIdValidator[userStore.type](t),
    addressLine1: requiredString(t),
    addressLine2: Yup.string().nullable()
  });

  if (!initialValues) {
    return (
      <FormSection>
        <Typography bold={true} center={true} component="div" verticalSpacing="large">
          {t("invoiceDetails.notAvailable")}
        </Typography>
      </FormSection>
    );
  }

  return (
    <Form
      onSubmit={values => onSubmit(transformInvoiceDetailsValues(values))}
      initialValues={{
        name: null,
        city: null,
        countryCode: null,
        postalCode: null,
        taxId: null,
        addressLine1: null,
        addressLine2: null,
        ...initialValues
      }}
      validationSchema={validationSchema}
      render={({ values }) => {
        const { countryCode } = values;
        return (
          <>
            <FieldsRow>
              <FieldWrapper className="col-12">
                <TextField name="name" label={<CompanyNameLabel />} required={true} data-testid="name" />
              </FieldWrapper>
            </FieldsRow>
            <FieldsRow>
              <FieldWrapper className="col-12">
                <TextArea
                  name="addressLine1"
                  label={`${t("invoiceDetails.addressLine")} 1`}
                  required={true}
                  data-testid="addressLine1"
                />
              </FieldWrapper>
            </FieldsRow>
            <FieldsRow>
              <FieldWrapper className="col-12">
                <TextArea
                  name="addressLine2"
                  label={`${t("invoiceDetails.addressLine")} 2`}
                  data-testid="addressLine2"
                />
              </FieldWrapper>
            </FieldsRow>
            <FieldsRow>
              <FieldWrapper className="col-lg-6 col-sm-6">
                <TextField
                  name="postalCode"
                  label={t("invoiceDetails.postalCode")}
                  required={true}
                  data-testid="postalCode"
                />
              </FieldWrapper>
              <FieldWrapper className="col-lg-6 col-sm-6">
                <TextField name="city" label={t("invoiceDetails.city")} required={true} data-testid="city" />
              </FieldWrapper>
            </FieldsRow>
            <FieldsRow>
              <FieldWrapper className="col-lg-6 col-sm-6">
                <SelectField
                  name="countryCode"
                  label={t("invoiceDetails.country")}
                  selectProps={{
                    options: countriesSelectOptions,
                    autoComplete: true
                  }}
                  required={true}
                  disabled={true}
                />
              </FieldWrapper>
              <FieldWrapper className="col-lg-6 col-sm-6">
                <TaxIdField name="taxId" countryCode={countryCode || ""} t={t} disabled={true} />
              </FieldWrapper>
            </FieldsRow>
            <DefaultFormButtons />
          </>
        );
      }}
    />
  );
});
