import { AxiosInstance } from "axios";

import { Language } from "triangular/scenes/EditProfile/ExpertForm/expertSchema";

import { AvatarEntity } from "../entities/AvatarEntity";
import { ExperienceEntity } from "../entities/ExperienceEntity";
import { Resource } from "../Resource/Resource";
import { Entity } from "../Resource/utils/types";

export type ExpertEntity = Entity<
  {
    state: string | null;
    firstName: string | null;
    lastName: string | null;
    description: string | null;
    showcaseTitle: string | null;
    expertCompany: string | null;
    company: string | null;
    phoneNumber: string | null;
    mobileNumber: string | null;
    countryCode: string | null;
    reach: string | null;
    expertiseDescription: string | null;
    countryCodeBlacklist: string[] | null;
    processCategories: string[] | null;
    languagesKnowledge: Language[] | null;
  },
  {
    experiences: ExperienceEntity[];
    certificates: Entity[];
    avatar: AvatarEntity;
    industries: Entity[];
    specialties: Entity[];
    materialCategories: Entity[];
  }
>;

export class ExpertResource extends Resource<ExpertEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "experts",
      prefixes: [],
      relationships: {
        experiences: {
          type: "experiences"
        },
        certificates: {
          type: "certificates"
        },
        avatar: {
          type: "avatars"
        },
        industries: {
          type: "industries"
        },
        specialties: {
          type: "specialties"
        },
        materialCategories: {
          type: "material_categories"
        }
      }
    });
  }
}
