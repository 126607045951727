import React from "react";
import { useTranslation } from "react-i18next";

import { CheckboxField } from "triangular/components/form/CheckboxField/CheckboxField";

export const SensitiveDataCheckbox: React.FC = () => {
  const { t } = useTranslation();
  return (
    <CheckboxField name="hideSensitiveData" label={t("addSystem.hideSensitiveData")} data-testid="hideSensitiveData" />
  );
};
