import { AxiosInstance } from "axios";

import { Resource } from "../Resource/Resource";
import { Entity } from "../Resource/utils/types";

type TermEntity = Entity<{
  account: string;
  term: string;
  status: string;
}>;

export class TermResource extends Resource<TermEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "terms",
      relationships: {
        termFiles: {
          type: "term_files"
        },
        privacyFiles: {
          type: "privacy_files"
        },
        agreements: {
          type: "agreements"
        }
      }
    });
  }
}
