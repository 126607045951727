import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { ReactNode, useState } from "react";

import { Card } from "triangular/components/Card/Card";
import { FormSectionHeader } from "triangular/components/form/FormSectionHeader/FormSectionHeader";
import { IconButton } from "triangular/components/IconButton/IconButton";
import { LoadingOverlay } from "triangular/components/LoadingOverlay/LoadingOverlay";
import { useModal } from "triangular/components/Modal/Modal";
import { Prompt } from "triangular/components/Prompt/Prompt";
import { useStore } from "triangular/stores/StoreContext";

import { IconDetailList, IconDetailListProps } from "../IconDetailList/IconDetailList";

import css from "./ExpertItem.module.scss";

interface ExpertItemProps {
  title: ReactNode;
  onRemove: () => Promise<any>;
  onEdit: () => void;
  details: IconDetailListProps["details"];
  removePromptHeader: string;
  removePromptMessage: string;
  disabled?: boolean;
}

export const ExpertItem: React.FC<ExpertItemProps> = observer(
  ({ details, onEdit, onRemove, title, removePromptHeader, removePromptMessage, disabled }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { snackbarStore } = useStore();
    const removePrompt = useModal();

    const handleRemove = async () => {
      setIsLoading(true);

      try {
        await onRemove();
      } catch (err) {
        snackbarStore.showGenericError(err);
      }

      setIsLoading(false);
    };

    return (
      <div className="col-sm-12 col-md-6 col-lg-4">
        <Card className={classNames(css.item)} data-testid="expertItemTile" onClick={onEdit}>
          <FormSectionHeader className={classNames(css.detailsHeader)} small={true}>
            <div className={classNames(css.textWrapper, css.fitButtons)}>{title}</div>
            {!disabled && (
              <div className={css.actions}>
                <IconButton onClick={onEdit} icon="edit" />
                <IconButton onClick={removePrompt.open} icon="trash" />
              </div>
            )}
          </FormSectionHeader>
          <IconDetailList details={details} detailClass={css.textWrapper} />
          <LoadingOverlay isVisible={isLoading} />
          <Prompt
            isOpen={removePrompt.isOpen}
            onClose={removePrompt.close}
            onAgree={handleRemove}
            header={removePromptHeader}
            message={removePromptMessage}
          />
        </Card>
      </div>
    );
  }
);
