import assign from "lodash/assign";
import qs from "qs";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import * as Yup from "yup";

import { PaginationBase } from "./types";
import { parseQuery } from "./utils";

const pageNumberMin = 1;
const pageSizeMin = 1;

const derivedDefaults = {
  page: {
    number: 1,
    size: 10
  }
};

export function createPagination<T extends Record<string, unknown>>(options: {
  paginationDefaults?: PaginationBase;
  queryParams?: {
    schema: Yup.ObjectSchema<T>;
    defaults: T;
  };
}) {
  type Query = T & PaginationBase;
  const queryParamsSchema = options.queryParams ? options.queryParams.schema : Yup.object({});
  const paginationDefaults = options.paginationDefaults || derivedDefaults;
  const defaults = options.queryParams
    ? assign({}, paginationDefaults, options.queryParams.defaults)
    : paginationDefaults;

  const schema = queryParamsSchema.shape({
    page: Yup.object({
      number: Yup.number()
        .min(pageNumberMin)
        .default(pageNumberMin)
        .required(),
      size: Yup.number()
        // Currently we don't allow for changing page size
        .oneOf([defaults.page.size])
        .default(pageSizeMin)
    })
  });

  const parsePaginationQuery = (query: string) => {
    // TODO: fix types
    // it should be possible to infer something like PaginationBase & T
    const paginationQuery = (parseQuery<Yup.InferType<typeof schema>>(query, {
      schema,
      defaults
    }) as unknown) as Query;

    return paginationQuery;
  };

  const usePagination = (options: { totalItems: number; location: RouteComponentProps["location"] }) => {
    const totalItems = options.totalItems || 0;
    const location = options.location;
    const paginationQuery = parsePaginationQuery(location.search);

    const prefix = `${location.pathname}?`;
    const maxPageNumber = Math.ceil(totalItems / paginationQuery.page.size);

    const buildUrl = (callback: (prevQuery: Query) => Partial<Query>) => {
      const changes = callback(paginationQuery);
      const shouldResetPageNumber = Object.keys(changes).some(key => key !== "page");

      const nextQuery = shouldResetPageNumber
        ? assign({}, defaults, paginationQuery, changes, {
            page: defaults.page
          })
        : assign({}, defaults, paginationQuery, changes);

      const stringifiedQuery = qs.stringify(nextQuery, {
        skipNulls: true,
        encode: false
      });
      return `${prefix}${stringifiedQuery}`;
    };

    const nextPageUrl = buildUrl(
      prevQuery =>
        ({
          page: {
            ...prevQuery.page,
            number: prevQuery.page.number < maxPageNumber ? prevQuery.page.number + 1 : prevQuery.page.number
          }
        } as Partial<Query>)
    );

    const previousPageUrl = buildUrl(
      prevQuery =>
        ({
          page: {
            ...prevQuery.page,
            number: prevQuery.page.number > pageNumberMin ? prevQuery.page.number - 1 : pageNumberMin
          }
        } as Partial<Query>)
    );

    const isOnLastPage = paginationQuery.page.number >= maxPageNumber;

    const isOnFirstPage = paginationQuery.page.number === pageNumberMin;

    return {
      paginationQuery,
      nextPageUrl,
      previousPageUrl,
      buildUrl,
      maxPageNumber,
      isOnLastPage,
      isOnFirstPage,
      totalItems
    };
  };

  return { parsePaginationQuery, usePagination };
}

export function createPaginationContext<T extends Record<string, unknown>>() {
  type Query = T & PaginationBase;
  return React.createContext<{
    pagination?: {
      paginationQuery: Query;
      buildUrl: (callback: (prevQuery: Query) => Partial<Query>) => string;
      nextPageUrl: string;
      previousPageUrl: string;
      maxPageNumber: number;
      isOnLastPage: boolean;
      isOnFirstPage: boolean;
    };
  }>({});
}
