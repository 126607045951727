import { History } from "history";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { BackButton } from "triangular/components/BackButton/BackButton";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { MaterialForm } from "triangular/components/form/MaterialForm/MaterialForm";
import { useMaterialData } from "triangular/components/form/MaterialForm/useMaterialData";
import { Layout } from "triangular/components/Layout/Layout";
import { PageTitle } from "triangular/components/PageTitle/PageTitle";
import { routes } from "triangular/Routes/routesConfiguration";

import css from "./EditMaterial.module.scss";
interface EditMaterialProps {
  id: string;
  history: History;
}

export const EditMaterial: React.FC<EditMaterialProps> = observer(({ id: materialId, history }) => {
  const { t } = useTranslation();
  const { isLoading, material, handleSubmit, handleSave, handleSavedModalClose } = useMaterialData({ materialId });

  return (
    <Layout loading={isLoading} withMolecules={false}>
      <FormSection>
        <BackButton />
      </FormSection>
      <PageTitle
        title={t("editMaterial.title")}
        description={material ? "" : t("editMaterial.fetchFailed")}
        data-testid="pageTitle"
        className={{
          title: css.title,
          description: css.description
        }}
      />
      {material ? (
        <MaterialForm
          initialValues={material}
          onSubmit={handleSubmit}
          onSave={handleSave}
          onGoBack={() => history.push(routes.myMaterialsList.build())}
          onSavedModalClose={handleSavedModalClose}
        />
      ) : null}
    </Layout>
  );
});
