import isEqual from "lodash/isEqual";
import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";

import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { MultiSelectField } from "triangular/components/form/MultiSelectField/MultiSelectField";
import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { TextField } from "triangular/components/form/TextField";
import { NO_RESTRICTIONS } from "triangular/consts";
import { getAnalyzingProcessId, namesToSelectOptions } from "triangular/utils/systems";

import { DropDownHeader } from "../../DropDownHeader/DropDownHeader";
import { OptionalTextField } from "../../OptionalTextField/OptionalTextField";

import { InstalledPowerField } from "./InstalledPowerField";
import { FormSectionProps } from "./types";

export const AdditionalSystemInfo: React.FC<FormSectionProps> = React.memo(
  ({ systemSettings, commonSettings, formikProps }) => {
    const { t } = useTranslation();
    const materialsWhitelist = formikProps.values.materialsWhitelist || [];
    const materialsBlacklist = formikProps.values.materialsBlacklist || [];

    const isNotAnalyzingProcess = formikProps.values.processCategory !== getAnalyzingProcessId(commonSettings);

    const materialOptions = namesToSelectOptions(t)("materialOptions", commonSettings.materials);
    const contactMaterialOptions = namesToSelectOptions(t)(
      "constructionMaterialOptions",
      systemSettings.contactMaterials
    );
    const nonContactMaterialOptions = namesToSelectOptions(t)(
      "constructionMaterialOptions",
      systemSettings.nonContactMaterials
    );
    const installedPowerOptions = systemSettings.installedPowerKw.map(el => ({
      label: `${el} ${t("addSystem.kW")}`.replace(".", ","),
      value: el
    }));

    const indexNum = "5";
    const [activeKey, setActiveKey] = useState(indexNum);

    const powerRelatedFields = (
      <>
        <FieldWrapper>
          <MultiSelectField
            name="contactMaterials"
            label={t("addSystem.contactMaterials")}
            placeholder={t("selectField.placeholder")}
            selectProps={{
              options: contactMaterialOptions,
              autoComplete: true
            }}
            data-testid="contactMaterials"
          />
        </FieldWrapper>
        <FieldWrapper>
          <MultiSelectField
            name="nonContactMaterials"
            label={t("addSystem.nonContactMaterials")}
            placeholder={t("selectField.placeholder")}
            selectProps={{
              options: nonContactMaterialOptions,
              autoComplete: true
            }}
            data-testid="nonContactMaterials"
          />
        </FieldWrapper>
        <FieldWrapper>
          <InstalledPowerField
            formikProps={formikProps}
            systemSettings={systemSettings}
            commonSettings={commonSettings}
          />
        </FieldWrapper>
        <FieldWrapper>
          <SelectField
            name="installedPowerClassifier"
            label={t("addSystem.installedPowerClassifier")}
            selectProps={{
              options: installedPowerOptions,
              autoComplete: true,
              allowEmpty: true,
              emptyValue: null
            }}
          />
        </FieldWrapper>
      </>
    );

    return (
      <Accordion>
        <FormSection>
          <DropDownHeader
            eventKeyNum={indexNum}
            handleClick={() => {
              if (activeKey === indexNum) {
                setActiveKey("");
              } else {
                setActiveKey(indexNum);
              }
            }}
          >
            {t("addSystem.additionalSystemInfo")}
          </DropDownHeader>
          <Accordion.Collapse eventKey={indexNum}>
            <FieldsRow>
              <FieldWrapper>
                <TextField name="brand" label={t("brand")} data-testid="brand" />
              </FieldWrapper>
              <FieldWrapper>
                <TextField name="model" label={t("model")} data-testid="model" />
              </FieldWrapper>
              <FieldWrapper>
                <MultiSelectField
                  name="materialsWhitelist"
                  label={t("addSystem.materialsWhitelist")}
                  selectProps={{
                    options: materialOptions.filter(
                      option => !materialsBlacklist.includes(option.value) || option.value === NO_RESTRICTIONS
                    ),
                    autoComplete: true
                  }}
                  allowCustomValues={true}
                  data-testid="materialsWhitelist"
                />
              </FieldWrapper>
              <FieldWrapper>
                <MultiSelectField
                  name="materialsBlacklist"
                  label={t("addSystem.materialsBlacklist")}
                  selectProps={{
                    options: materialOptions.filter(
                      option => !materialsWhitelist.includes(option.value) || option.value === NO_RESTRICTIONS
                    ),
                    autoComplete: true
                  }}
                  allowCustomValues={true}
                  data-testid="materialsBlacklist"
                />
              </FieldWrapper>
              {isNotAnalyzingProcess && powerRelatedFields}
              <FieldWrapper>
                <OptionalTextField name="airflow" label={t("addSystem.airflow")} data-testid="airflow" />
              </FieldWrapper>
            </FieldsRow>
          </Accordion.Collapse>
        </FormSection>
      </Accordion>
    );
  },
  ({ formikProps: { values: prevValues } }, { formikProps: { values: nextValues } }) => {
    const prevWhitelist = prevValues.materialsWhitelist;
    const nextWhitelist = nextValues.materialsWhitelist;
    const prevBlacklist = prevValues.materialsBlacklist;
    const nextBlacklist = nextValues.materialsBlacklist;

    return isEqual(
      { whitelist: prevWhitelist, blacklist: prevBlacklist },
      { whitelist: nextWhitelist, blacklist: nextBlacklist }
    );
  }
);
