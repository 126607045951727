import { AxiosInstance } from "axios";

import { FileEntity } from "../entities/FileEntity";
import { Resource } from "../Resource/Resource";
import { Entity } from "../Resource/utils/types";

export type ParticleSize<T> = Array<{ size: T | null; percent: T | null } | null> | null;

export type MaterialEntity = Entity<
  {
    name: string | null;
    materialIndex: string | null;
    indexNumber: string | null;
    deadline: string | null;
    countryCode: string | null;
    processTemperature: string | null;
    hygroscopic: boolean | null;
    flowingProperties: string | null;
    abrasiveness: string | null;
    desiredParticleSize: ParticleSize<string>;
    feedParticleSize: ParticleSize<string>;
    color: string | null;
    explosiveness: string | null;
    corrosiveness: string | null;
    hazardousness: string | null;
    materialAmount: string | null;
    density: string | null;
    bulkDensity: string | null;
    humidity: string | null;
    storageNeeded: string | null;
    safetyMeasures: string | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    phoneNumber: string | null;
    companyName: string | null;
    position: string | null;
    additionalInfo: string | null;
    state: string;
  },
  {
    materialSafetyFiles: FileEntity[];
    materialTechFiles: FileEntity[];
    materialOwner: Entity<{}>;
    processCategory: Entity<{}>;
    systemCategory: Entity<{}>;
    systemType: Entity<{}>;
    designType: Entity<{}>;
    system: Entity<{}>;
    industry: Entity<{}>;
    materialCategory: Entity<{}>;
  }
>;

export class MaterialResource extends Resource<MaterialEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "materials",
      prefixes: ["material_owners"],
      relationships: {
        materialOwner: {
          type: "material_owners"
        },
        processCategory: {
          type: "process_categories"
        },
        systemCategory: {
          type: "system_categories"
        },
        systemType: {
          type: "system_types"
        },
        designType: {
          type: "design_types"
        },
        materialSafetyFiles: {
          type: "material_safety_files"
        },
        materialTechFiles: {
          type: "material_tech_files"
        },
        system: {
          type: "systems"
        },
        industry: {
          type: "industries"
        },
        materialCategory: {
          type: "material_categories"
        }
      }
    });
  }
}
