import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { phoneNumberValidator } from "triangular/components/form/validators/phoneNumberValidator";
import { requiredString } from "triangular/components/form/validators/requiredString";
import { useStore } from "triangular/stores/StoreContext";
import { useLoadingState } from "triangular/utils/components";

export const useOwnerForm = () => {
  const { t } = useTranslation();
  const { ownerStore, userStore, snackbarStore } = useStore();
  const { profile } = ownerStore;
  const { loadingState, setLoadingState } = useLoadingState();

  const loadProfile = useCallback(async () => {
    setLoadingState("in_progress");
    try {
      if (userStore.type === "experts" || userStore.type === null) {
        throw new Error("Invalid user type");
      }

      await ownerStore.getProfile(userStore.type, userStore.profileId);
      setLoadingState("done");
    } catch (err) {
      snackbarStore.showGenericError(err);
      setLoadingState("failed");
    }
  }, [ownerStore, setLoadingState, snackbarStore, userStore.profileId, userStore.type]);

  useEffect(() => {
    loadProfile();
  }, [loadProfile]);

  const schema = Yup.object({
    firstName: requiredString(t).nullable(false),
    lastName: requiredString(t).nullable(false),
    email: Yup.string(),
    phoneNumber: phoneNumberValidator(t).required(),
    countryCode: Yup.string(),
    companyName: Yup.string(),
    companyIdentificationNumber: Yup.string()
  });

  const initialValues = profile
    ? {
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        phoneNumber: profile.phoneNumber,
        countryCode: profile.company.countryCode,
        companyName: profile.company.name,
        companyIdentificationNumber: profile.company.number
      }
    : {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        countryCode: "",
        companyName: "",
        companyIdentificationNumber: ""
      };

  const handleSubmit = async (values: Yup.InferType<typeof schema>) => {
    if (userStore.type !== "system_owners" && userStore.type !== "material_owners") {
      throw new Error("Invalid user type");
    }

    if (!userStore.profileId) {
      throw new Error("Invalid user id");
    }

    await ownerStore.updateProfile(userStore.type, {
      id: userStore.profileId,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber
    });

    snackbarStore.addSnackbar({
      type: "success",
      message: t("editProfile.success")
    });
  };

  return {
    schema,
    initialValues,
    handleSubmit,
    loadingState
  };
};
