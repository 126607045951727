import isEqual from "lodash/isEqual";
import React from "react";
import { useTranslation } from "react-i18next";

import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { OptionalTextField } from "triangular/components/form/OptionalTextField/OptionalTextField";
import { TextField } from "triangular/components/form/TextField";
import { countriesWithGlobalLocation } from "triangular/consts";

import { AltBlockHeader } from "../../AltBlockHeader/AltBlockHeader";
import { SelectField } from "../../SelectField/SelectField";
import { TextArea } from "../../TextArea/TextArea";
import { ProcessCategoryField } from "../ProcessCategoryField";
import { SystemCategoryField } from "../SystemCategoryField";

import css from "./SystemForm.module.scss";
import { TargetIndustriesField } from "./TargetIndustriesField";
import { FormSectionProps } from "./types";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

export const RequiredSystemInfo: React.FC<FormSectionProps> = React.memo(
  ({ systemSettings }) => {
    const { t } = useTranslation();

    return (
      <FormSection>
        <AltBlockHeader>{t("addSystem.requiredInfo")}</AltBlockHeader>
        <FieldsRow>
          <FieldWrapper>
            <TargetIndustriesField />
          </FieldWrapper>
          <FieldWrapper>
            <TextField name="name" label={t("addSystem.systemName")} required={true} data-testid="name" />
          </FieldWrapper>
          <FieldWrapper>
            <ProcessCategoryField label={t("addSystem.processCategory")} />
          </FieldWrapper>
          <FieldWrapper>
            <SystemCategoryField label={t("addSystem.systemCategory")} />
          </FieldWrapper>
          <FieldWrapper>
            <OptionalTextField
              name="minimumMaterialAmount"
              label={t("addSystem.minimumMaterialAmount")}
              required={true}
            />
          </FieldWrapper>
          <FieldWrapper>
            <SelectField
              name="countryCode"
              label={t("addSystem.countryCode")}
              placeholder={t("selectField.placeholder")}
              selectProps={{
                options: countriesWithGlobalLocation(t),
                autoComplete: true
              }}
              data-testid="countryCode"
              required={true}
            />
          </FieldWrapper>
          <FieldWrapper className="col-sm-12 col-md-12 col-lg-12">
            <TextArea
              className={{ wrapper: css.textArea }}
              name="additionalInfo"
              label={t("addSystem.additionalInfo")}
              placeholder={t("addSystem.specify")}
              data-testid="additionalInfo"
            />
          </FieldWrapper>
        </FieldsRow>
      </FormSection>
    );
  },
  (prevProps, nextProps) => {
    const prevValues = prevProps.formikProps.values;
    const nextValues = nextProps.formikProps.values;
    const processCategoriesEqual = prevValues.processCategory === nextValues.processCategory;
    const systemCategoriesEqual = prevValues.systemCategory === nextValues.systemCategory;
    const systemTypeEqual = prevValues.systemType === nextValues.systemType;
    const designTypeEqual = prevValues.designType === nextValues.designType;
    const errorsEqual = isEqual(prevProps.formikProps.errors, nextProps.formikProps.errors);

    return [processCategoriesEqual, systemCategoriesEqual, systemTypeEqual, designTypeEqual, errorsEqual].every(
      condition => condition
    );
  }
);
