import classNames from "classnames";
import React from "react";

import css from "./ItemStatus.module.scss";

export interface ItemStatusProps {
  variant?: "greyishBrown" | "mushroom" | "mint";
  className?: string;
}

export const ItemStatus: React.FC<ItemStatusProps> = ({ variant = "mushroom", className, children }) => (
  <div className={classNames(css.statusWrapper, className)}>
    <div className={classNames(css.status, css[variant])}>{children}</div>
  </div>
);
