import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Switch } from "react-router-dom";

import { AccountConfirmation } from "triangular/scenes/AccountConfirmation/AccountConfirmation";
import { AddMaterial } from "triangular/scenes/AddMaterial/AddMaterial";
import { AddSystem } from "triangular/scenes/AddSystem/AddSystem";
import { EditMaterial } from "triangular/scenes/EditMaterial/EditMaterial";
import { EditProfile } from "triangular/scenes/EditProfile/EditProfile";
import { EditSystem } from "triangular/scenes/EditSystem/EditSystem";
import { ExpertDetails } from "triangular/scenes/ExpertDetails/ExpertDetails";
import { ExpertSearch } from "triangular/scenes/ExpertSearch/ExpertSearch";
import { Login } from "triangular/scenes/Login/Login";
import { MaterialDetails } from "triangular/scenes/MaterialDetails/MaterialDetails";
import { MyExpertProfile } from "triangular/scenes/MyExpertProfile/MyExpertProfile";
import { MyMaterialsList } from "triangular/scenes/MyMaterialsList/MyMaterialsList";
import { MySystemsList } from "triangular/scenes/MySystemsList/MySystemsList";
import { NotFound } from "triangular/scenes/NotFound/NotFound";
import { PasswordRecovery } from "triangular/scenes/PasswordRecovery/PasswordRecovery";
import { Payments } from "triangular/scenes/Payments/Payments";
import { Registration } from "triangular/scenes/Registration/Registration";
import { SystemDetails } from "triangular/scenes/SystemDetails/SystemDetails";
import { SystemRequest } from "triangular/scenes/SystemRequest/SystemRequest";
import { SystemSearch } from "triangular/scenes/SystemSearch/SystemSearch";
import UpdatePassword from "triangular/scenes/UpdatePassword/UpdatePassword";
import { useStore } from "triangular/stores/StoreContext";
import { locationRouteProps } from "triangular/utils/routes/LocationRoute";

import { RouteWrapper } from "./ConditionalRoute";
import { GuestRoute } from "./GuestRoute";
import { PrivateRoute } from "./PrivateRoute";
import { ProfileTypeRoute } from "./ProfileTypeRoute";
import { routes } from "./routesConfiguration";
import { SubscriptionSetupGuard } from "./SubscriptionSetupGuard";
import { useHomeRoute } from "./useHomeRoute";

export const Routes: React.FC = observer(() => {
  const { t } = useTranslation();
  const appliedHomeRoute = useHomeRoute();
  const { paymentsStore, userStore } = useStore();
  const subscriptionIsNotActive = !["active", "active_cancelled"].includes(paymentsStore.subscription.status);

  if (userStore.isAuthenticated && subscriptionIsNotActive) {
    return <SubscriptionSetupGuard />;
  }

  return (
    <Switch>
      <RouteWrapper title={t("systems.sceneTitle")} {...locationRouteProps(routes.systems, SystemSearch)} />

      <ProfileTypeRoute
        userType="material_owners"
        title={t("systems.sceneTitle")}
        {...locationRouteProps(routes.systemsForRequest, SystemSearch)}
      />

      <RouteWrapper
        title={t("systemDetails.sceneTitle")}
        {...locationRouteProps(routes.systemDetails, props => <SystemDetails {...props} isSystemOwner={false} />)}
      />

      <ProfileTypeRoute
        userType="material_owners"
        title={t("systemDetails.sceneTitle")}
        {...locationRouteProps(routes.systemDetailsForRequest, props => (
          <SystemDetails {...props} isSystemOwner={false} />
        ))}
      />

      <GuestRoute title={t("login.sceneTitle")} {...locationRouteProps(routes.login, () => <Login />)} />
      <GuestRoute
        title={t("registration.sceneTitle")}
        {...locationRouteProps(routes.registration.root, Registration, { exact: false })}
      />
      <GuestRoute
        title={t("accountConfirmation.sceneTitle")}
        {...locationRouteProps(routes.accountConfirmation, AccountConfirmation, {
          invalidComponent: NotFound
        })}
      />
      <GuestRoute
        title={t("passwordRecovery.sceneTitle")}
        {...locationRouteProps(routes.passwordRecovery.root, PasswordRecovery, { exact: false })}
      />

      <RouteWrapper title={t("expertsSearch.sceneTitle")} {...locationRouteProps(routes.experts, ExpertSearch)} />
      <RouteWrapper
        title={t("expertDetails.sceneTitle")}
        {...locationRouteProps(routes.expertDetails, ExpertDetails)}
      />

      <ProfileTypeRoute
        title={t("mySystemsList.sceneTitle")}
        userType="experts"
        {...locationRouteProps(routes.myExpertProfile, MyExpertProfile)}
      />
      <ProfileTypeRoute
        title={t("mySystemsList.sceneTitle")}
        userType="system_owners"
        {...locationRouteProps(routes.mySystemsList, MySystemsList)}
      />
      <ProfileTypeRoute
        title={t("editSystem.sceneTitle")}
        userType="system_owners"
        {...locationRouteProps(routes.editSystem, EditSystem)}
      />
      <ProfileTypeRoute
        title={t("addSystem.sceneTitle")}
        userType="system_owners"
        {...locationRouteProps(routes.addSystem, AddSystem)}
      />
      <ProfileTypeRoute
        title={t("addSystem.sceneTitle")}
        userType="system_owners"
        {...locationRouteProps(routes.addSystem, AddSystem)}
      />
      <ProfileTypeRoute
        title={t("systemDetails.sceneTitle")}
        userType="system_owners"
        {...locationRouteProps(routes.mySystemDetails, props => <SystemDetails {...props} isSystemOwner={true} />)}
      />

      <ProfileTypeRoute
        title={t("systemRequest.sceneTitle")}
        userType="material_owners"
        {...locationRouteProps(routes.systemRequest, SystemRequest)}
      />
      <ProfileTypeRoute
        title={t("addMaterial.sceneTitle")}
        userType="material_owners"
        {...locationRouteProps(routes.addMaterial, AddMaterial)}
      />
      <ProfileTypeRoute
        title={t("myMaterialsList.sceneTitle")}
        userType="material_owners"
        {...locationRouteProps(routes.myMaterialsList, MyMaterialsList)}
      />
      <ProfileTypeRoute
        title={t("materialDetails.sceneTitle")}
        userType="material_owners"
        {...locationRouteProps(routes.myMaterialDetails, MaterialDetails)}
      />
      <ProfileTypeRoute
        title={t("editMaterial.sceneTitle")}
        userType="material_owners"
        {...locationRouteProps(routes.editMaterial, EditMaterial)}
      />
      <PrivateRoute
        title={t("passwordRecovery.sceneTitle")}
        {...locationRouteProps(routes.changePassword, UpdatePassword)}
      />
      <PrivateRoute title={t("editProfile.sceneTitle")} {...locationRouteProps(routes.editProfile, EditProfile)} />
      <PrivateRoute title={t("payments.sceneTitle")} {...locationRouteProps(routes.payments, Payments)} />
      <RouteWrapper path={routes.home.build()} component={() => <Redirect to={appliedHomeRoute} />} exact={true} />
      <RouteWrapper component={NotFound} path="*" />
    </Switch>
  );
});
