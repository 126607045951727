import React from "react";

import { Typography } from "triangular/components/Typography/Typography";

import { FieldsRow } from "../FieldsRow/FieldsRow";

import css from "./AltBlockHeader.module.scss";

export const AltBlockHeader: React.FC = ({ children }) => {
  return (
    <FieldsRow className={css.requiredHeader}>
      <Typography bold component="h3" color="white" size="big" upperCase={true}>
        {children}
      </Typography>
    </FieldsRow>
  );
};
