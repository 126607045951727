import React from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { LabeledSelect } from "triangular/components/LabeledSelect/LabeledSelect";
import { useStore } from "triangular/stores/StoreContext";
import { settingItemsToSelectOptions } from "triangular/utils/systems";

import { useSystemsPaginationContext } from "../utils/pagination";
import { updateSystemCategoryIds } from "../utils/updates";

export const SystemCategory: React.FC = () => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const { paginationQuery, buildUrl } = useSystemsPaginationContext();
  const { commonsStore } = useStore();

  const systemCategoryOptions = commonsStore.getSettings().systemCategories;
  const { processCategoryId, systemCategoryId } = paginationQuery;

  if (!processCategoryId) {
    return null;
  }

  const options = settingItemsToSelectOptions(t)("systemCategoryOptions", systemCategoryOptions.get(processCategoryId));

  if (options.length === 0) {
    return null;
  }

  const handleChange = (selectedValue: string | string[]) =>
    history.push(buildUrl(() => updateSystemCategoryIds(selectedValue as string[])));

  return (
    <LabeledSelect
      label={t("systems.systemCategory")}
      value={systemCategoryId || []}
      options={options}
      onChange={handleChange}
      data-testid="systemCategoryFilter"
    />
  );
};
