import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

import { AddSystemIcon } from "triangular/components/AddSystemIcon/AddSystemIcon";
import { Card } from "triangular/components/Card/Card";
import { SearchSelector } from "triangular/components/SearchSelector/SearchSelector";
import { useStore } from "triangular/stores/StoreContext";

import { AddExpertIcon } from "../AddExpertIcon/AddExpertIcon";
import { AddMaterialIcon } from "../AddMaterialIcon/AddMaterialIcon";

import css from "./UserHomeButtons.module.scss";

export const UserHomeButtons: React.FC = () => {
  const { t } = useTranslation();
  const { userStore } = useStore();
  const userType = userStore.type;

  const content: { link?: any; icon?: JSX.Element; title?: string } = {};

  if (userType === "system_owners") {
    content.link = "/add-system";
    content.icon = <AddSystemIcon />;
    content.title = t("mySystemsList.addSystem");
  } else if (userType === "material_owners") {
    content.link = "/add-material";
    content.icon = <AddMaterialIcon />;
    content.title = t("myMaterialsList.addMaterial");
  } else if (userType === "experts") {
    content.link = "/edit-profile";
    content.icon = <AddExpertIcon />;
    content.title = t("expertMyProfileButton");
  }

  return (
    <div className={css.wrapper}>
      <Container fluid className={css.container} data-testid="homeButtons">
        <Row className="justify-content-md-center">
          <Col md={6}>
            <Link to={content.link} className={css.link}>
              <Card className={css.card}>
                <div className={css.pictogram}>{content.icon}</div>
                <div>
                  <h2 className={css.title}>{content.title}</h2>
                </div>
              </Card>
            </Link>
          </Col>
          <Col md={6}>
            <Card className={css.card}>
              <SearchSelector
                className={css.circle}
                position={css.position}
                logoSize={css.logo}
                dropDownMenu={css.dropDownMenu}
              />
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
