import { types } from "mobx-state-tree";

import { fileModel } from "../utils/fileModel";

export const experienceModel = types.model("Experience", {
  id: types.string,
  title: types.maybeNull(types.string),
  company: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  startDate: types.maybeNull(types.string),
  endDate: types.maybeNull(types.string),
  headline: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  experienceFiles: types.array(fileModel)
});
