import React from "react";
import { useTranslation } from "react-i18next";

import { Hr } from "triangular/components/Hr/Hr";
import { TriLink } from "triangular/components/TriLink/TriLink";
import { Typography } from "triangular/components/Typography/Typography";
import { FileEntity } from "triangular/services/Api/entities/FileEntity";
import { FlatRelationshipEntity } from "triangular/services/Api/Resource/utils/types";

import { Section } from "../Section";

import css from "./SystemDocuments.module.scss";

export const SystemDocuments = ({ files }: { files?: FlatRelationshipEntity<FileEntity<{}>>[] }) => {
  const { t } = useTranslation();

  if (!files || files.length === 0) {
    return null;
  }

  return (
    <>
      <Section className={css.wrapper}>
        <Typography component="h3" bold={true} size="big" upperCase={true} color="mint" className={css.header}>
          {t("addSystem.systemDocumentFiles")}
        </Typography>
        <ul className={css.ul}>
          {files.map(({ filename, originalUrl }, index) =>
            originalUrl ? (
              <li key={index} className={css.li}>
                <TriLink external={true} to={originalUrl} className={css.filename}>
                  {filename}
                </TriLink>
              </li>
            ) : null
          )}
        </ul>
      </Section>
      <Hr />
    </>
  );
};
