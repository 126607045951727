import { TFunction } from "i18next";
import * as Yup from "yup";

import { FileListItem } from "triangular/components/form/system/SystemFilesField/FilesList/FilesList";
import { filesValidator } from "triangular/components/form/validators/filesValidator";
import { requiredBoolean } from "triangular/components/form/validators/requiredBoolean";
import { requiredString } from "triangular/components/form/validators/requiredString";

export interface CertificateSchema {
  name: string | null;
  organization: string | null;
  credentialNotExpire: boolean | null;
  expirationDate: string | null;
  credentialId: string | null;
  credentialUrl: string | null;
  certificateFiles: FileListItem[] | null;
}

export const certificateDefaultsValues: CertificateSchema = {
  name: null,
  organization: null,
  credentialNotExpire: false,
  credentialId: null,
  credentialUrl: null,
  expirationDate: new Date(Date.now()).toISOString(),
  certificateFiles: null
};

export const createCertificateSchema = (t: TFunction) =>
  Yup.object<CertificateSchema>({
    name: requiredString(t),
    organization: requiredString(t),
    credentialNotExpire: requiredBoolean(t),
    credentialId: Yup.string().nullable(),
    credentialUrl: Yup.string()
      .url()
      .nullable(),
    expirationDate: Yup.string().nullable(),
    certificateFiles: filesValidator.nullable()
  });
