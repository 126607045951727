import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { Typography } from "triangular/components/Typography/Typography";
import { routes } from "triangular/Routes/routesConfiguration";
import { gaActions, GaCategories, googleAnalytics } from "triangular/services/googleAnalytics";
import { useStore } from "triangular/stores/StoreContext";

import { RegistrationProfileType } from "../ProfileType";

import css from "./ProfileTypeOption.module.scss";

interface ProfileTypeOptionProps {
  type: RegistrationProfileType;
}

type ProfileTypeOptionContent = {
  [key in ProfileTypeOptionProps["type"]]: {
    title: string;
    description: string;
    className: string;
    price: string;
    period: string;
  };
};

export const ProfileTypeOption: React.FC<ProfileTypeOptionProps> = ({ type }) => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const expertPrice = " 0 ";
  const systemOwnerPrice = " 0 ";
  const materialOwnerPrice = " 0 ";
  const masterPrice = " 0 ";

  const content: ProfileTypeOptionContent = {
    expert: {
      title: t("registration.profileType.expertTitle"),
      description: t("registration.profileType.expertDescription"),
      price: t("registration.common.currency") + expertPrice + t("registration.common.monthly"),
      period: t("registration.profileType.billedAnnually"),
      className: css.expert
    },
    systemOwner: {
      title: t("registration.profileType.systemOwnerTitle"),
      description: t("registration.profileType.systemOwnerDescription"),
      price: t("registration.common.currency") + systemOwnerPrice + t("registration.common.monthly"),
      period: t("registration.profileType.billedAnnually"),
      className: css.systemOwner
    },
    materialOwner: {
      title: t("registration.profileType.materialOwnerTitle"),
      description: t("registration.profileType.materialOwnerDescription"),
      price: t("registration.common.currency") + materialOwnerPrice + t("registration.common.monthly"),
      period: t("registration.profileType.billedAnnually"),
      className: css.materialOwner
    },
    master: {
      title: t("registration.profileType.masterTitle"),
      description: t("registration.profileType.masterDescription"),
      price: t("registration.common.currency") + masterPrice + t("registration.common.monthly"),
      period: t("registration.profileType.billedAnnually"),
      className: css.master
    }
  };

  const { registrationStore } = useStore();
  const choseProfile = () => {
    registrationStore.setState({ type });

    googleAnalytics.sendEvent({
      category: GaCategories.registration,
      action: gaActions.registration.goToSecondStep,
      label: type
    });

    history.push(routes.registration.personalInfo.build());
  };

  return (
    <button className={css.wrapper} type="button" onClick={choseProfile}>
      <label htmlFor={type} className={css.label}>
        {/* we use <div> instead <img> here, to easily change img on hover */}
        <div className={classNames(css.icon, content[type].className)} />
        <Typography className={css.title} size="big">
          {content[type].title}
        </Typography>
        <Typography component="p" size="medium" className={css.description}>
          {content[type].description}
        </Typography>
        <Typography component="p" size="medium" className={css.price}>
          {content[type].price}
        </Typography>
        <Typography component="p" size="medium" className={css.period}>
          {content[type].period}
        </Typography>
      </label>
    </button>
  );
};
