import { AxiosInstance } from "axios";

import { Resource } from "../Resource/Resource";
import { Entity } from "../Resource/utils/types";

type PaymentMethodAttributes =
  | {
      last4: string;
      expMonth: string;
      expYear: string;
      methodType: "card";
    }
  | {
      methodType: "manual";
      last4: null;
    }
  | {
      last4: string;
      expMonth: null;
      expYear: null;
      methodType: "sepa_debit";
    };

export type PaymentMethodEntity = Entity<PaymentMethodAttributes, {}>;

export class PaymentMethodResource extends Resource<PaymentMethodEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "payment_methods"
    });
  }
}
