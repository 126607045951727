import get from "lodash/get";
import React from "react";
import { useTranslation } from "react-i18next";

import { BackButton } from "triangular/components/BackButton/BackButton";
import { IndustryList } from "triangular/components/IndustryList/IndustryList";
import { LocationMarker } from "triangular/components/LocationMarker/LocationMarker";
import { SystemTypeIcon } from "triangular/components/SystemTypeIcon/SystemTypeIcon";
import { Typography } from "triangular/components/Typography/Typography";
import { getCountryBy } from "triangular/consts";
import { FetchedCommonSettings } from "triangular/stores/CommonStore/CommonsStore";
import { FetchedSystem } from "triangular/stores/SystemsStore/SystemsStore";
import { useEllipsisTooltip } from "triangular/utils/components";
import { flattenSystemSettings, getSystemSettingTranslation } from "triangular/utils/systems";

import { HeaderProperty } from "./HeaderProperty/HeaderProperty";
import { Section } from "./Section";
import css from "./SystemDetails.module.scss";

interface SystemDetailsHeaderProps {
  system: FetchedSystem;
  commonSettings: FetchedCommonSettings;
  origin?: string | null;
}

export const SystemDetailsHeader: React.FC<SystemDetailsHeaderProps> = ({ system, commonSettings, origin }) => {
  const { t } = useTranslation();
  const getSettingTranslation = getSystemSettingTranslation(t);

  const processAvailableTranslation = getSettingTranslation(
    commonSettings.processes.toJSON(),
    system.processCategory,
    "processCategoryOptions"
  );

  const systemTypeTranslation = getSettingTranslation(
    flattenSystemSettings(commonSettings.systemTypes.toJSON()),
    system.systemType,
    "systemTypeOptions"
  );

  const designTypeTranslation =
    system.designTypes && system.designTypes.length
      ? system.designTypes
          .map(id =>
            getSettingTranslation(flattenSystemSettings(commonSettings.designTypes.toJSON()), id, "designTypeOptions")
          )
          .join(", ")
      : undefined;

  const systemCategoriesSettings = flattenSystemSettings(commonSettings.systemCategories.toJSON());

  const systemCategoryTranslation = getSettingTranslation(
    systemCategoriesSettings,
    system.systemCategory,
    "systemCategoryOptions"
  );

  const systemCategoryName =
    system.systemCategory &&
    get(
      systemCategoriesSettings.find(eachCategory => eachCategory.id === system.systemCategory!.id),
      "name"
    );

  const country = getCountryBy.code(system.countryCode);
  const isSystemBooked = system.state === "booked";
  const ellipsisTooltip = useEllipsisTooltip(system.id);

  const industryNames = (system.industries || []).map(({ name }) => name);

  return (
    <>
      <Section>
        <BackButton />
      </Section>
      {origin === "search" ? (
        <Section>
          <Typography component="h1" bold={true} size="large" className={css.originTitle}>
            {t("systemDetails.searchComment")}
          </Typography>
        </Section>
      ) : null}
      <Section>
        <header className={css.header}>
          <div className={css.iconWrapper}>
            <SystemTypeIcon size="big" type={systemCategoryName} />
          </div>
          <div className={css.contentWrapper}>
            <div className={css.titleContainer}>
              <Typography
                component="h2"
                bold={true}
                size="large"
                className={css.systemName}
                {...ellipsisTooltip.triggerProps}
              >
                {system.name}
              </Typography>
              {ellipsisTooltip.jsx}
              {isSystemBooked && (
                <div className={css.badge}>
                  <Typography component="span" size="big" color="white">
                    {t("systemDetails.booked")}
                  </Typography>
                </div>
              )}
            </div>
            {country && (
              <div className={css.locationWrapper}>
                <LocationMarker>{country.name}</LocationMarker>
              </div>
            )}
            {industryNames && industryNames.length > 0 && (
              <div className={css.industriesWrapper}>
                <HeaderProperty title={t("industry")} value={<IndustryList industryNames={industryNames} />} />
              </div>
            )}
            <div className={css.headerDetails}>
              <HeaderProperty title={t("systemDetails.processAvailable")} value={processAvailableTranslation} />
              <HeaderProperty title={t("systemDetails.systemType")} value={systemTypeTranslation} />
              <HeaderProperty title={t("systemDetails.designType")} value={designTypeTranslation} />
              <HeaderProperty title={t("systemDetails.systemCategory")} value={systemCategoryTranslation} />
            </div>
          </div>
        </header>
      </Section>
    </>
  );
};
