import { TFunction } from "i18next";
import get from "lodash/get";
import uniqueId from "lodash/uniqueId";

import { SettingItem } from "triangular/services/Api/Resource/utils/types";

import { toBase64 } from "./browser";

export const namesToSelectOptions = (t: TFunction) => (key?: string, values?: string[]) =>
  (values || []).map(name => ({
    key: uniqueId(name),
    label: t(`materialSettings.${key}.${name}`),
    value: name
  }));

export const settingItemsToSelectOptions = (t: TFunction, valueOf: "id" | "name" = "id") => (
  key?: string,
  settingItems?: SettingItem[]
) =>
  (settingItems || []).map(({ id, name }) => ({
    key: id,
    label: t(`materialSettings.${key}.${name}`),
    value: valueOf === "id" ? id : name
  }));

export const settingItemsToFilterOptions = (t: TFunction) => (key?: string, settingItems?: SettingItem[]) =>
  (settingItems || []).map(({ id, name }) => ({
    key: id,
    label: t(`materialSettings.${key}.${name}`),
    value: { id, name }
  }));

export const createFileEntity = async (
  file: File
): Promise<{
  filename: string;
  data: string;
}> => ({
  filename: file.name,
  data: await toBase64(file)
});

export const getMaterialSettingTranslation = (t: TFunction) => (
  sourceArray: SettingItem[],
  relation: { id: string } | null | undefined,
  translationKey: string
) => {
  const relationId = get(relation, "id");

  if (Array.from(sourceArray).length === 0 || !relationId) {
    return "";
  }
  const name = get(
    sourceArray.find(element => element.id === relationId),
    "name",
    ""
  );
  return name ? t(`materialSettings.${translationKey}.${name}`) : "";
};
