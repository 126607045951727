import React, { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import usePromise from "react-use/lib/usePromise";

import { i18n } from "triangular/i18next/i18next";
import { createLandingPageRoutes } from "triangular/Routes/routesConfiguration";
import { useStore } from "triangular/stores/StoreContext";
import { useLoadable } from "triangular/utils/components";

import { Button } from "../Button/Button";
import { Modal, ModalProps } from "../Modal/Modal";
import { TriLink } from "../TriLink/TriLink";
import { Typography } from "../Typography/Typography";

import css from "./AgreementModal.module.scss";

export interface AgreementProps extends Omit<ModalProps, "children"> {
  onAgree?: (() => void | Promise<void>) | null;
}

export const AgreementModal: React.FC<AgreementProps> = ({ onClose, children, ...props }) => {
  const { t } = useTranslation();
  const { loadingState, setLoadable } = useLoadable("idle");
  const { agreementStore, snackbarStore } = useStore();
  const resolveWhenMounted = usePromise();

  const onAgree = useCallback(() => {
    try {
      const lastTerm = agreementStore.lastTermId;

      if (lastTerm != null) {
        agreementStore.acceptAgreement(lastTerm);
      } else {
        snackbarStore.addSnackbar({ type: "success", message: t("agreement.accepted") });
      }
    } catch (err) {
      snackbarStore.showGenericError(err);
    }
  }, [agreementStore, snackbarStore, t]);

  const handleOnAgree = useCallback(async () => {
    await resolveWhenMounted(Promise.resolve(onAgree()));
    onClose();
  }, [onAgree, onClose, resolveWhenMounted]);

  const onReject = useCallback(async () => {
    try {
      const lastTerm = await agreementStore.lastTermId;

      if (lastTerm != null) {
        agreementStore.rejectAgreement(lastTerm);
      } else {
        snackbarStore.addSnackbar({ type: "success", message: t("agreement.rejected") });
      }
    } catch (err) {
      snackbarStore.showGenericError(err);
    }
  }, [agreementStore, snackbarStore, t]);

  const handleOnReject = useCallback(async () => {
    await resolveWhenMounted(Promise.resolve(onReject()));
    onClose();
  }, [onReject, onClose, resolveWhenMounted]);

  const handleLoadableOnAgree = useCallback(() => setLoadable(handleOnAgree), [handleOnAgree, setLoadable]);

  const landingPageRoutes = createLandingPageRoutes(i18n.language);

  return (
    <Modal
      onClose={handleOnReject}
      className={{
        modal: css.modal,
        overlay: css.modalOverlay
      }}
      {...props}
    >
      <div className={css.header}>
        <Typography component="h2" size="big" bold={true}>
          {t("agreement.header")}
        </Typography>
      </div>
      <div className={css.message}>
        <label>
          <Trans i18nKey="agreement.message">
            Here you can read the updated
            <TriLink to={landingPageRoutes.termsOfUse} external={true}>
              Terms of Use
            </TriLink>
            and
            <TriLink to={landingPageRoutes.privacyPolicy} external={true}>
              Privacy Policy
            </TriLink>
          </Trans>
        </label>
      </div>
      <div className={css.actions}>
        <Button
          onClick={handleOnReject}
          data-testid="rejectModalButton"
          transparent={true}
          variant="dark"
          disabled={loadingState === "in_progress"}
        >
          {t("agreement.no")}
        </Button>
        <Button
          onClick={handleLoadableOnAgree}
          data-testid="agreeModalButton"
          showLoader={loadingState === "in_progress"}
        >
          {t("agreement.yes")}
        </Button>
      </div>
    </Modal>
  );
};
