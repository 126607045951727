import { AxiosInstance } from "axios";

import { ExperienceEntity } from "../entities/ExperienceEntity";
import { Resource } from "../Resource/Resource";

export class ExperienceResource extends Resource<ExperienceEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "experiences",
      prefixes: [], // optional prefixes added to URL
      relationships: {
        experienceFiles: {
          type: "experience_files"
        },
        expert: {
          type: "experts"
        }
      }
    });
  }
}
