import React from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { LabeledSelect } from "triangular/components/LabeledSelect/LabeledSelect";
import { NO_RESTRICTIONS } from "triangular/consts";
import { useStore } from "triangular/stores/StoreContext";
import { namesToSelectOptions } from "triangular/utils/systems";

import { useSystemsPaginationContext } from "../utils/pagination";
import { updateMaterialsBlacklist } from "../utils/updates";

export const NoGoMaterials: React.FC = () => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const { paginationQuery, buildUrl } = useSystemsPaginationContext();
  const { commonsStore } = useStore();

  const materialOptions = commonsStore.getSettings().materials;
  const materialsBlacklist = paginationQuery.materialsBlacklist || [];
  const materialsWhitelist = paginationQuery.materialsWhitelist || [];

  const options = namesToSelectOptions(t)("materialOptions", materialOptions)
    .filter(eachOption => eachOption.value !== NO_RESTRICTIONS)
    .filter(eachOption => !materialsWhitelist.includes(eachOption.value));

  if (options.length === 0) {
    return null;
  }

  const handleChange = (selectedValue: string | string[]) =>
    history.push(buildUrl(() => updateMaterialsBlacklist(selectedValue as string[])));

  return (
    <LabeledSelect
      label={t("systems.noGoMaterials")}
      value={materialsBlacklist}
      options={options}
      onChange={handleChange}
      autoComplete={true}
    />
  );
};
