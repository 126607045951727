import classNames from "classnames";
import * as React from "react";

export interface TriLoaderProps {
  className?: {
    container?: string;
    spinner?: string;
  };
  size?: number;
}

export const TriLoader: React.FC<TriLoaderProps> = ({ className = {}, size = 40 }) => (
  <div className={className.container}>
    <div
      className={classNames("spinner-border", className.spinner)}
      style={{ width: size, height: size, borderWidth: size / 10 }}
      role="status"
      data-testid="triLoader"
    />
  </div>
);
