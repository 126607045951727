import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { SearchScene } from "triangular/components/SearchSceneLayout/SearchSceneLayout";
import { SearchSelector } from "triangular/components/SearchSelector/SearchSelector";

import { CompatibleMaterials } from "./filters/CompatibleMaterials";
import { ContactMaterials } from "./filters/ContactMaterials";
import { InstalledPower } from "./filters/InstalledPower/InstalledPower";
import { Location } from "./filters/Location";
import { MaterialCategories } from "./filters/MaterialCategories";
import { NoGoMaterials } from "./filters/NoGoMaterials.tsx";
import { NonContactMaterials } from "./filters/NonContactMaterials";
import { PossibleMaterialColor } from "./filters/PossibleMaterialColor";
import { ProcessCategory } from "./filters/ProcessCategory";
import { SystemCategory } from "./filters/SystemCategory";
import { SystemType } from "./filters/SystemType";
import { TargetIndustries } from "./filters/TargetIndustries";
import css from "./MobileViewContent.module.scss";
import { Search } from "./Search";
import { SearchPhrase } from "./SearchPhrase";
import { SortBy } from "./SortBy/SortBy";
import { SystemList } from "./SystemList";
import { SystemsCount } from "./SystemsCount";
import { useFilterBadges } from "./utils/useFilterBadges";

export const MobileViewContent = observer(() => {
  const filterBadges = useFilterBadges();
  return (
    <>
      <Container fluid className={css.container}>
        <Row className={classNames(css.testo, "justify-content-center")}>
          <Col sm={2} md={2} lg={2}>
            <SearchSelector className={css.circle} position={css.position} logoSize={css.logo} />
          </Col>
        </Row>
      </Container>
      <SearchScene.MobileViewContent
        filters={{
          search: <Search />,
          searchPhrase: <SearchPhrase />,
          fields: [
            <ProcessCategory />,
            <SystemCategory />,
            <SystemType />,
            <PossibleMaterialColor />,
            <TargetIndustries />,
            <InstalledPower />,
            <MaterialCategories />,
            <CompatibleMaterials />,
            <NoGoMaterials />,
            <Location />,
            <ContactMaterials />,
            <NonContactMaterials />
          ]
        }}
        sortSection={
          <>
            <SystemsCount />
            <SortBy />
          </>
        }
        searchSection={
          <>
            <Search />
            <SearchPhrase />
          </>
        }
        results={<SystemList />}
        filterBadges={filterBadges}
      />
    </>
  );
});
