import { AxiosInstance } from "axios";

import { Resource } from "../Resource/Resource";
import { Entity, SettingItem } from "../Resource/utils/types";

export interface SystemCategory extends SettingItem {
  systemTypes: SettingItem[];
}

export interface Process {
  id: string;
  name: string;
  systemCategories: SystemCategory[];
  designTypes: SettingItem[];
}

interface Industry extends SettingItem {
  materialCategories: SettingItem[];
}

type CommonSettingsEntity = Entity<{
  colors: string[];
  industries: Industry[];
  materials: string[];
  processes: Process[];
}>;

export class CommonSettingsResource extends Resource<CommonSettingsEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "common_settings",
      prefixes: []
    });
  }
}
