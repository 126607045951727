import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { AgreementCheckbox } from "triangular/components/AgreementCheckbox/AgreementCheckbox";
import { Button } from "triangular/components/Button/Button";
import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { Form } from "triangular/components/form/Form";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { SubscriptionIntervalField } from "triangular/components/form/SubscriptionIntervalField/SubscriptionIntervalField";
import { SubscriptionLevelField } from "triangular/components/form/SubscriptionLevelField/SubscriptionLevelField";
import { PageLoader } from "triangular/components/PageLoader/PageLoader";
import { PlansByInterval } from "triangular/components/SubscriptionLevel/SubscriptionLevel";
import { SubscriptionPlanBlockedTooltip } from "triangular/components/SubscriptionPlanBlockedTooltip/SubscriptionPlanBlockedTooltip";
import { Typography } from "triangular/components/Typography/Typography";
import { useStore } from "triangular/stores/StoreContext";

import { FormButtonsSection } from "../FormButtonsSection/FormButtonsSection";

interface SubscriptionPlanFormValues {
  subscriptionInterval: SubscriptionInterval;
  level: SubscriptionLevel;
  agreement?: boolean;
}

interface SubscriptionPlanFormProps {
  onSubmit: (values: SubscriptionPlanFormValues) => Promise<void> | void;
  isCancelling?: boolean;
  isUpdating?: boolean;
  initialValues?: {
    level: SubscriptionLevel | null;
    subscriptionInterval: SubscriptionInterval | null;
  };
  isSubscriptionUnpaid?: boolean;
  isSubscriptionCancelled?: boolean;
  onCancel?: () => void;
  hideSubmit?: boolean;
  withAgreement?: boolean;
}

export const SubscriptionPlanForm: React.FC<SubscriptionPlanFormProps> = observer(
  ({
    onSubmit,
    isCancelling,
    isUpdating,
    initialValues = {
      level: "basic",
      subscriptionInterval: "year",
      agreement: false
    },
    isSubscriptionUnpaid = false,
    isSubscriptionCancelled = false,
    onCancel,
    hideSubmit = false,
    withAgreement
  }) => {
    const [isLoadingPlans, setIsLoadingPlans] = useState(true);
    const { t } = useTranslation();
    const { userStore, paymentsStore, snackbarStore } = useStore();
    const { subscriptionPlans } = paymentsStore;
    const subscriptionType = userStore.accountType === "master" ? "masters" : userStore.getProfileType();
    const userPlans = subscriptionPlans.filter(eachPlan => eachPlan.subscriptionType === subscriptionType);

    const schema = {
      subscriptionInterval: Yup.mixed().nullable(),
      level: Yup.mixed().nullable(),
      agreement: Yup.boolean().oneOf([true], t("validation.terms"))
    };

    const fetchPlans = useCallback(async () => {
      setIsLoadingPlans(true);

      try {
        await paymentsStore.fetchSubscriptionPlans();
      } catch (err) {
        snackbarStore.showGenericError(err);
      }

      setIsLoadingPlans(false);
    }, [paymentsStore, snackbarStore]);

    if (!userPlans) {
      throw new Error(`There are no subscription plans for profile type "${subscriptionType}"`);
    }

    const plansByInterval = userPlans.reduce((acc, nextPlan) => {
      const planOptions = {
        level: nextPlan.level,
        pricePerMonth: nextPlan.pricePerMonth,
        pricePerYear: nextPlan.pricePerYear,
        discount: nextPlan.discount
      };
      if (Array.isArray(acc[nextPlan.billingInterval])) {
        acc[nextPlan.billingInterval].push(planOptions);
      } else {
        acc[nextPlan.billingInterval] = [planOptions];
      }

      return acc;
    }, {} as PlansByInterval);

    const intervals = Object.keys(plansByInterval);

    if (initialValues.level === null || initialValues.subscriptionInterval === null) {
      return (
        <FormSection>
          <Typography bold={true} center={true} component="div" verticalSpacing="large">
            {t("subscriptionPlans.notAvailable")}
          </Typography>
        </FormSection>
      );
    }

    const subscriptionChangesAreNotAllowed = isSubscriptionCancelled || isSubscriptionUnpaid;

    useEffect(() => {
      fetchPlans();
    }, [fetchPlans]);

    return (
      <Form
        isInitialValid={true}
        initialValues={initialValues as SubscriptionPlanFormValues}
        onSubmit={onSubmit}
        validationSchema={Yup.object(schema)}
        render={props => {
          const { isSubmitting, values } = props;
          return (
            <>
              <FormSection>
                <FieldsRow>
                  <FieldWrapper className="col-12">
                    <SubscriptionIntervalField intervals={intervals} />
                  </FieldWrapper>
                </FieldsRow>
              </FormSection>
              {isLoadingPlans ? (
                <PageLoader />
              ) : (
                <SubscriptionLevelField
                  name="level"
                  interval={values.subscriptionInterval}
                  plansByInterval={plansByInterval}
                  customPlanTitle={userStore.accountType === "master" ? t("payments.masterAccount") : undefined}
                />
              )}
              <FormSection>
                {withAgreement && <AgreementCheckbox />}
                <FormButtonsSection
                  withSpaceBetween={true}
                  buttons={
                    <>
                      {onCancel && (
                        <SubscriptionPlanBlockedTooltip
                          isSubscriptionCancelled={isSubscriptionCancelled}
                          isSubscriptionUnpaid={isSubscriptionCancelled}
                        >
                          <Button
                            showLoader={isCancelling}
                            disabled={subscriptionChangesAreNotAllowed}
                            variant="dark"
                            transparent={true}
                            type="button"
                            onClick={onCancel}
                          >
                            {t("subscriptionPlans.cancelSubscription")}
                          </Button>
                        </SubscriptionPlanBlockedTooltip>
                      )}
                      {!hideSubmit && (
                        <SubscriptionPlanBlockedTooltip
                          isSubscriptionCancelled={isSubscriptionCancelled}
                          isSubscriptionUnpaid={isSubscriptionCancelled}
                        >
                          <Button
                            showLoader={isUpdating || isSubmitting}
                            disabled={subscriptionChangesAreNotAllowed}
                            variant="dark"
                            type="submit"
                          >
                            {t("defaultFormButtons.submit")}
                          </Button>
                        </SubscriptionPlanBlockedTooltip>
                      )}
                    </>
                  }
                />
              </FormSection>
            </>
          );
        }}
      />
    );
  }
);
