import { addDays, addYears } from "date-fns";
import { TFunction } from "i18next";
import * as Yup from "yup";

import { numberValidator } from "triangular/components/form/validators/numberValidator";
import { percentageRange } from "triangular/components/form/validators/numberValidator";
import { rangeValidator } from "triangular/components/form/validators/rangeValidator";
import { requiredNumber } from "triangular/components/form/validators/requiredNumber";
import { requiredString } from "triangular/components/form/validators/requiredString";
import { formatInputDate } from "triangular/utils/dates";

import { phValidator } from "../validators/phValidator";

const particleSizeValidation = (t: TFunction, isRequired: boolean) => {
  const validation = isRequired ? "required" : "notRequired";
  const validationMessage = isRequired ? t("validation.required") : undefined;

  return Yup.array()
    .of(
      Yup.object({
        percent: numberValidator(t)(...percentageRange)
          .nullable()
          [validation](validationMessage),
        size: numberValidator(t)(0.01)
          .nullable()
          [validation](validationMessage)
      }).nullable()
    )
    .nullable();
};

export const materialDefaultValues = {
  name: null,
  materialIndex: "not_available",
  indexNumber: null,
  materialCategory: null,
  processCategory: null,
  systemCategory: null,
  systemType: null,
  designType: null,
  deadline: null,
  countryCode: null,
  processTemperature: null,
  hygroscopic: null,
  flowingProperties: null,
  targetIndustry: null,
  abrasiveness: null,
  feedParticleSize: [
    {
      size: null,
      percent: null
    },
    {
      size: null,
      percent: null
    },
    {
      size: null,
      percent: null
    }
  ],
  desiredParticleSize: [
    {
      size: null,
      percent: null
    },
    {
      size: null,
      percent: null
    },
    {
      size: null,
      percent: null
    }
  ],
  color: null,
  explosiveness: null,
  corrosiveness: null,
  hazardousness: null,
  materialAmount: null,
  density: null,
  bulkDensity: null,
  humidity: null,
  storageNeeded: null,
  safetyMeasures: null,
  additionalInfo: null,
  materialSafetyFiles: [],
  materialTechFiles: null
};

export const getMaterialDeadlineMin = () => formatInputDate(addDays(new Date(), 1));
export const getMaterialDeadlineMax = () => formatInputDate(addYears(new Date(), 10));

export const createMaterialValidationSchema = (t: TFunction) =>
  Yup.object({
    name: requiredString(t),
    materialIndex: requiredString(t),
    indexNumber: Yup.mixed().when("materialIndex", {
      is: "not_available",
      then: Yup.mixed().nullable(),
      otherwise: requiredString(t)
    }),
    materialCategory: Yup.string().nullable(),
    processCategory: requiredString(t),
    systemCategory: requiredString(t),
    systemType: Yup.string().nullable(),
    designType: Yup.string().nullable(),
    deadline: Yup.mixed()
      .test("date", t("validation.invalidDate"), (value: string | Date) => {
        return Yup.date()
          .min(getMaterialDeadlineMin(), t("validation.invalidDate"))
          .max(getMaterialDeadlineMax(), t("validation.invalidDate"))
          .typeError(t("validation.invalidDate"))
          .isValidSync(value);
      })
      .nullable(),
    countryCode: Yup.string().nullable(),
    processTemperature: rangeValidator(t)({
      min: -10000,
      max: 10000,
      allowFractions: true,
      allowEmpty: true
    }).nullable(),
    hygroscopic: Yup.boolean().nullable(),
    flowingProperties: Yup.string().nullable(),
    targetIndustry: requiredString(t),
    abrasiveness: Yup.string().nullable(),
    explosiveness: Yup.string().nullable(),
    corrosiveness: phValidator(t, true).nullable(),
    feedParticleSize: particleSizeValidation(t, false).nullable(),
    desiredParticleSize: particleSizeValidation(t, false),
    color: Yup.string().nullable(),
    hazardousness: Yup.string().nullable(),
    materialAmount: requiredNumber(t)(0.1).nullable(),
    density: requiredNumber(t, true)(0.1).nullable(),
    bulkDensity: requiredNumber(t, true)(0.1).nullable(),
    humidity: requiredNumber(t, true)(...percentageRange),
    storageNeeded: Yup.string().nullable(),
    safetyMeasures: Yup.string().nullable(),
    additionalInfo: Yup.string().nullable(),
    materialSafetyFiles: Yup.array()
      .of(Yup.object({ id: Yup.string(), filename: Yup.string() }))
      .nullable(),
    materialTechFiles: Yup.array()
      .of(Yup.object({ id: Yup.string(), filename: Yup.string() }))
      .nullable()
  });

export type MaterialSchema = Yup.InferType<ReturnType<typeof createMaterialValidationSchema>>;
