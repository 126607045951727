import classNames from "classnames";
import React from "react";

import css from "./ErrorText.module.scss";

interface ErrorMessageProps {
  className?: {
    container?: string;
    message?: string;
  };
  children?: React.ReactNode;
}

export const ErrorText: React.FC<ErrorMessageProps> = ({ className = {}, children = " " }) => {
  return (
    <div className={classNames(css.errorContainer, className.container)}>
      <p className={classNames(css.errorMessage, className.message)}>{children}</p>
    </div>
  );
};
