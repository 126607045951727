import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Button } from "triangular/components/Button/Button";
import { Form } from "triangular/components/form/Form";
import { PasswordField } from "triangular/components/form/PasswordField/PasswordField";
import { passwordValidator } from "triangular/components/form/validators/passwordValidator";
import { passwordTooltip } from "triangular/scenes/Registration/PersonalInfo/PersonalInfoForm/PersonalInfoForm";

import css from "./ChangePasswordForm.module.scss";

export interface ChangePasswordFormValues {
  password: string;
  repeatPassword: string;
}

interface ChangePasswordFormProps {
  onSubmit: (values: ChangePasswordFormValues) => void;
  onCancel: (e: React.MouseEvent) => void;
}

export const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const passwordLabel = (
    <>
      {t("passwordRecovery.newPasswordLabel")} &nbsp;
      {passwordTooltip(t)}
    </>
  );

  return (
    <Form
      initialValues={{
        password: "",
        repeatPassword: ""
      }}
      validationSchema={Yup.object({
        password: passwordValidator(t),
        repeatPassword: Yup.string()
          .required(t("validation.required"))
          .oneOf([Yup.ref("password")], t("validation.repeatPassword"))
      })}
      render={({ isSubmitting, isValid }) => {
        return (
          <>
            <div className={css.passwordField}>
              <PasswordField
                className={{ wrapper: css.input }}
                data-testid="password"
                required={true}
                name="password"
                label={passwordLabel}
              />
            </div>

            <div className={css.passwordField}>
              <PasswordField
                className={{ wrapper: css.input }}
                required={true}
                data-testid="repeatPassword"
                name="repeatPassword"
                label={t("registration.personalInfo.repeatPassword")}
              />
            </div>

            <div className={css.actionsWrapper}>
              <Button
                data-testid="cancel"
                transparent={true}
                onClick={onCancel}
                narrow={true}
                type="button"
                className={css.cancelButton}
              >
                {t("passwordRecovery.cancelButton")}
              </Button>

              <Button data-testid="submit" type="submit" disabled={isSubmitting || !isValid} showLoader={isSubmitting}>
                {t("passwordRecovery.submitButton")}
              </Button>
            </div>
          </>
        );
      }}
      onSubmit={onSubmit}
    />
  );
};
