import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { Avatar } from "triangular/components/Avatar/Avatar";
import {
  createFileUploader,
  FileToUpload
} from "triangular/components/form/system/SystemFilesField/FilesField/FilesField";
import { Icon } from "triangular/components/Icon/Icon";
import { LoadingOverlay } from "triangular/components/LoadingOverlay/LoadingOverlay";
import { useModal } from "triangular/components/Modal/Modal";
import { TriLoader } from "triangular/components/TriLoader/TriLoader";
import { MAX_FILE_SIZE_MB, PHOTO_EXTS } from "triangular/consts";
import { useStore } from "triangular/stores/StoreContext";

import css from "./AvatarUploading.module.scss";
import { EditorModal } from "./EditorModal";

export const AvatarUploading = observer(() => {
  const { t } = useTranslation();
  const { userStore, snackbarStore, expertStore } = useStore();
  const { currentExpert } = expertStore;
  const derivedAvatarUrl = currentExpert.avatar ? currentExpert.avatar.originalUrl : null;
  const [avatarUrl, setAvatarUrl] = useState<string | null>(derivedAvatarUrl);
  const { getInputProps, getRootProps } = useDropzone();
  const [isLoading, setIsLoading] = useState(false);
  const isFormDisabled = expertStore.isConfirmed();
  const editorModal = useModal(true);
  const [avatarToEdit, setAvatarToEdit] = useState<FileToUpload | null>();

  const avatarDisplay = avatarUrl ? (
    <Avatar className={css.avatar} imageUrl={avatarUrl} />
  ) : (
    <div className={css.avatarPlaceholder}>{t("editExpert.avatarPlaceholder")}</div>
  );

  const createFilesToUpload = createFileUploader({ snackbarStore, t, maxSizeMb: MAX_FILE_SIZE_MB });

  const handleAvatarUploading = async (imageData: string) => {
    if (!avatarToEdit) {
      throw new Error("There is no avatar for upload!");
    }

    try {
      setAvatarUrl(imageData);
      await userStore.uploadAvatar({ data: imageData, filename: avatarToEdit.filename });

      snackbarStore.addSnackbar({
        type: "success",
        message: t("editExpert.avatarUploadSuccess")
      });
    } catch (err) {
      snackbarStore.addSnackbar({
        type: "error",
        message: t("editExpert.avatarUploadError")
      });
    }
  };

  const handleFileLoading = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);

    try {
      const { files } = event.target;

      if (!files || files.length === 0) {
        throw new Error("Cannot load file for avatar!");
      }

      const [fileToUpload] = await createFilesToUpload(files);
      setAvatarToEdit(fileToUpload);
      editorModal.open();
    } catch (err) {
      snackbarStore.addSnackbar({
        type: "error",
        message: t("editExpert.avatarUploadError")
      });
    }

    setIsLoading(false);
  };

  const { onClick, ...rootProps } = getRootProps();

  return (
    <div>
      <div className={css.wrapper} {...rootProps}>
        {avatarDisplay}
        <input
          {...getInputProps({
            onChange: handleFileLoading,
            accept: PHOTO_EXTS,
            multiple: false,
            disabled: isLoading
          })}
        />
        {!isFormDisabled && (
          <div className={css.editIconWrapper} onClick={onClick}>
            <Icon className={css.editIcon} size={12} type="edit" />
          </div>
        )}
      </div>
      {editorModal.isOpen && avatarToEdit && (
        <EditorModal
          isOpen={editorModal.isOpen}
          onClose={editorModal.close}
          imageData={avatarToEdit.data}
          onUpload={handleAvatarUploading}
          size={128}
        />
      )}
      <LoadingOverlay isVisible={isLoading}>
        <div className={css.loaderWrapper}>
          <TriLoader />
        </div>
      </LoadingOverlay>
    </div>
  );
});
