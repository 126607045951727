import React from "react";
import StackGrid from "react-stack-grid";

import { useColumnWidth } from "triangular/utils/useColumnsWidth";

export const Masonry: React.FC<{ columns?: number }> = ({ children, columns }) => {
  const columnWidth = useColumnWidth(columns);

  return (
    <StackGrid columnWidth={columnWidth} gutterWidth={20} gutterHeight={20} duration={0} appearDelay={0}>
      {children}
    </StackGrid>
  );
};
