import { observer } from "mobx-react-lite";
import React from "react";

import { MultiSelectField, MultiSelectFieldProps } from "triangular/components/form/MultiSelectField/MultiSelectField";
import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { useStore } from "triangular/stores/StoreContext";
import { useMaterialCategoriesOptions } from "triangular/utils/systems";

interface MaterialCategoryFieldProps {
  label?: string;
  isMultiple?: boolean;
  name: string;
  hasNoRestrictionsOption?: boolean;
  selectedTargetIndustryIds: string[];
  className?: MultiSelectFieldProps["className"];
  disabled?: boolean;
}

export const MaterialCategoryField: React.FC<MaterialCategoryFieldProps> = observer(
  ({ className, label, isMultiple, hasNoRestrictionsOption = true, selectedTargetIndustryIds, name, disabled }) => {
    const { commonsStore } = useStore();
    const commonSettings = commonsStore.getSettings();
    const Component = isMultiple ? MultiSelectField : SelectField;

    const noRestrictionsId = commonSettings.noRestrictionsIds.materialCategory;
    const materialCategoriesOptions = useMaterialCategoriesOptions(selectedTargetIndustryIds);

    return (
      <Component
        className={className}
        label={label}
        name={name}
        selectProps={{
          options: hasNoRestrictionsOption
            ? materialCategoriesOptions
            : materialCategoriesOptions.filter(({ value }) => value !== noRestrictionsId),
          autoComplete: true
        }}
        data-testid="materialCategory"
        noRestrictionsValue={noRestrictionsId}
        disabled={disabled || materialCategoriesOptions.length === 0}
      />
    );
  }
);
