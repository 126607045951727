import { ErrorMessage, ErrorMessageProps } from "formik";
import React from "react";

import { ErrorText } from "./ErrorText/ErrorText";

export interface FieldErrorProps extends Omit<ErrorMessageProps, "className"> {
  name: string;
  className?: {
    container?: string;
    message?: string;
  };
  render?: (message: string) => React.ReactNode;
}

export const FieldError: React.FC<FieldErrorProps> = ({ className = {}, name, render }) => {
  const defaultRender = (message: string) => <ErrorText className={className}>{message}</ErrorText>;

  return <ErrorMessage name={name} render={render || defaultRender} />;
};
