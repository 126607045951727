import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { LanguageFlag } from "triangular/components/LanguageFlag/LanguageFlag";
import { Typography } from "triangular/components/Typography/Typography";
import { AppLanguage, languageOptions } from "triangular/consts";
import { ReactComponent as DropdownSvg } from "triangular/static/images/arrow-down.svg";

import css from "./LanguageSelect.module.scss";

export const LanguageSelect = () => {
  const { i18n } = useTranslation();
  const availableLanguages = languageOptions.filter(({ value }) => value !== i18n.language);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const typographyRef = useRef<HTMLElement>(null);
  const dropdownRef = useRef<SVGSVGElement>(null);
  const flagRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const handleToggleMenu = (event: MouseEvent) => {
      const clickedOnTrigger = [
        wrapperRef.current,
        typographyRef.current,
        dropdownRef.current,
        flagRef.current
      ].includes(event.target as HTMLElement);

      if (clickedOnTrigger) {
        setIsOpen(prevState => !prevState);
      } else {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleToggleMenu);
    return () => window.removeEventListener("click", handleToggleMenu);
  }, []);

  return (
    <>
      <div className={css.wrapper} ref={wrapperRef}>
        <LanguageFlag className={css.flag} language={i18n.language as AppLanguage} ref={flagRef} />
        <Typography bold={true} ref={typographyRef}>
          {i18n.language.toUpperCase()}
        </Typography>
        <DropdownSvg
          className={classNames(css.dropdownSvg, { [css.dropdownSvgOpen]: isOpen })}
          width="10"
          ref={dropdownRef}
        />
      </div>
      {isOpen && (
        <ul className={css.languageList}>
          {availableLanguages.map(({ value }) => (
            <li key={value} className={css.languageItem} onClick={() => i18n.changeLanguage(value)}>
              <LanguageFlag language={value} /> {value}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
