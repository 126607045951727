import createClient from "@bugsnag/js";
import reactPlugin from "@bugsnag/plugin-react";
import React, { ComponentType, FC } from "react";

export const bugsnagClient = process.env.REACT_APP_BUGSNAG_API_KEY
  ? createClient({
      apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
      appVersion: process.env.REACT_APP_VERSION,
      releaseStage: process.env.REACT_APP_ENVIRONMENT,
      filters: [/token/i, /password/i, /passphrase/i],
      collectUserIp: false
    })
  : undefined;

if (bugsnagClient) {
  bugsnagClient.use(reactPlugin, React);

  window.addEventListener("unhandledrejection", event => {
    bugsnagClient.notify(event.reason);
  });
}

const NoBoundary: FC = ({ children }) => <>{children}</>;

export const ErrorBoundary = bugsnagClient ? (bugsnagClient.getPlugin("react") as ComponentType) : NoBoundary;

export const setBugsnagUserId = (id: string) => {
  if (bugsnagClient) {
    bugsnagClient.user = { id };
  }
};

export const notifyBugsnag = (err: any) => {
  if (bugsnagClient) {
    bugsnagClient.notify(err);
  } else {
    // eslint-disable-next-line no-console
    console.warn(err);
  }
};
