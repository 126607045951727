import { TFunction } from "i18next";

import { numberValidator } from "./numberValidator";

export const requiredNumber = (t: TFunction, allowEmpty?: boolean) => (
  minValue: number,
  maxValue: number = Number.MAX_SAFE_INTEGER
) =>
  allowEmpty
    ? numberValidator(t)(minValue, maxValue)
    : numberValidator(t)(minValue, maxValue).required(t("validation.required"));
