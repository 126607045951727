import isEqual from "lodash/isEqual";
import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { DesignTypeField } from "triangular/components/form/system/DesignTypeField";
import { SystemTypeField } from "triangular/components/form/system/SystemTypeField";
import { getAnalyzingProcessId } from "triangular/utils/systems";

import { DropDownHeader } from "../../DropDownHeader/DropDownHeader";

import { MaterialCategoriesField } from "./MaterialCategoriesField";
import { FormSectionProps } from "./types";

export const Technology: React.FC<FormSectionProps> = React.memo(
  ({ commonSettings, formikProps }) => {
    const { t } = useTranslation();

    const indexNum = "2";
    const [activeKey, setActiveKey] = useState(indexNum);

    const isNotAnalyzingProcess = formikProps.values.processCategory !== getAnalyzingProcessId(commonSettings);

    return (
      <Accordion>
        <FormSection>
          <DropDownHeader
            eventKeyNum={indexNum}
            handleClick={() => {
              if (activeKey === indexNum) {
                setActiveKey("");
              } else {
                setActiveKey(indexNum);
              }
            }}
          >
            {t("addSystem.technology")}
          </DropDownHeader>
          <Accordion.Collapse eventKey={indexNum}>
            <FieldsRow>
              <FieldWrapper>
                <MaterialCategoriesField />
              </FieldWrapper>
              <FieldWrapper>
                <SystemTypeField label={t("addSystem.systemType")} />
              </FieldWrapper>
              <FieldWrapper>
                {isNotAnalyzingProcess && <DesignTypeField label={t("addSystem.designType")} isMultiSelect={true} />}
              </FieldWrapper>
            </FieldsRow>
          </Accordion.Collapse>
        </FormSection>
      </Accordion>
    );
  },
  ({ formikProps: { values: prevValues } }, { formikProps: { values: nextValues } }) => {
    const prevWhitelist = prevValues.materialsWhitelist;
    const nextWhitelist = nextValues.materialsWhitelist;
    const prevBlacklist = prevValues.materialsBlacklist;
    const nextBlacklist = nextValues.materialsBlacklist;

    return isEqual(
      { whitelist: prevWhitelist, blacklist: prevBlacklist },
      { whitelist: nextWhitelist, blacklist: nextBlacklist }
    );
  }
);
