import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { FileToUpload } from "triangular/components/form/system/SystemFilesField/FilesField/FilesField";
import { FileListItem } from "triangular/components/form/system/SystemFilesField/FilesList/FilesList";
import { SystemFilesField } from "triangular/components/form/system/SystemFilesField/SystemFilesField";
import { useStore } from "triangular/stores/StoreContext";

import { DropDownHeader } from "../DropDownHeader/DropDownHeader";
import { FieldsRow } from "../FieldsRow/FieldsRow";
import { FieldWrapper } from "../FieldWrapper/FieldWrapper";
import { FormSection } from "../FormSection/FormSection";

import { SensitiveDataCheckbox } from "./SensitiveDataCheckbox";

export const UploadFiles: React.FC = observer(() => {
  const { materialsStore } = useStore();
  const { t } = useTranslation();
  const currentT = (path: string) => t(`materialForm.uploadFilesPart.${path}`);

  const handleAddTechDataSheet = async (
    filesToUpload: FileToUpload[],
    addFiles: (fileEntities: FileListItem[] | null) => void
  ) => {
    const responses = await Promise.all(
      filesToUpload.map(({ filename, data }) => materialsStore.uploadMaterialTechFile(filename, data))
    );

    const addedTechDataSheets = responses
      .flat()
      .map(({ id, filename, originalUrl }) => ({ id, filename, originalUrl }));
    addFiles(addedTechDataSheets);
  };

  const handleRemoveTechDataSheet = (file: FileListItem) => materialsStore.deleteMaterialTechFile(file.id);

  const indexNum = "5";
  const [activeKey, setActiveKey] = useState(indexNum);

  return (
    <Accordion>
      <FormSection>
        <DropDownHeader
          eventKeyNum={indexNum}
          handleClick={() => {
            if (activeKey === indexNum) {
              setActiveKey("");
            } else {
              setActiveKey(indexNum);
            }
          }}
        >
          {t("uploadFiles")}
        </DropDownHeader>
        <Accordion.Collapse eventKey={indexNum}>
          <FieldsRow>
            <FieldWrapper className="col-12">
              <SystemFilesField
                name="materialTechFiles"
                label={currentT("materialTechFiles")}
                onRemoveFile={handleRemoveTechDataSheet}
                onAddFile={handleAddTechDataSheet}
                fileType="document"
              />
              <SensitiveDataCheckbox />
            </FieldWrapper>
          </FieldsRow>
        </Accordion.Collapse>
      </FormSection>
    </Accordion>
  );
});
