import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { useStore } from "triangular/stores/StoreContext";

interface ExplosivenessFieldProps {
  label: string;
}

export const ExplosivenessField: React.FC<ExplosivenessFieldProps> = observer(({ label }) => {
  const { systemsStore } = useStore();
  const { t } = useTranslation();
  const explosivenessSettings = systemsStore.getSettings().explosiveness;

  const explosivenessOptions = explosivenessSettings.map(eachOption => ({
    value: eachOption,
    label: eachOption === "no" ? t("no") : t(`systemSettings.explosivenessOptions.${eachOption}`),
    key: eachOption
  }));

  return (
    <SelectField
      name="explosiveness"
      label={label}
      selectProps={{
        options: explosivenessOptions
      }}
      data-testid="explosiveness"
    />
  );
});
