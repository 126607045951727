import { connect } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { useStore } from "triangular/stores/StoreContext";
import { settingItemsToSelectOptions } from "triangular/utils/systems";

interface SystemTypeFieldProps {
  label: string;
}

export const SystemTypeField = connect<SystemTypeFieldProps, any>(
  observer(({ formik, label }) => {
    const { t } = useTranslation();
    const { commonsStore } = useStore();
    const { commonSettings } = commonsStore;
    const { systemCategory } = formik.values;

    const options = commonSettings
      ? settingItemsToSelectOptions(t)("systemTypeOptions", commonSettings.systemTypes.get(systemCategory || ""))
      : [];

    return (
      <SelectField
        name="systemType"
        label={label}
        selectProps={{
          options,
          allowEmpty: true
        }}
        disabled={options.length === 0}
        data-testid="systemType"
      />
    );
  })
);
