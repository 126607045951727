import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";

import { BackButton } from "triangular/components/BackButton/BackButton";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { MaterialForm } from "triangular/components/form/MaterialForm/MaterialForm";
import { useMaterialData } from "triangular/components/form/MaterialForm/useMaterialData";
import { Layout } from "triangular/components/Layout/Layout";
import { PageTitle } from "triangular/components/PageTitle/PageTitle";
import { routes } from "triangular/Routes/routesConfiguration";

import { NotFound } from "../NotFound/NotFound";

import { SelectMaterial } from "./SelectMaterial/SelectMaterial";
import css from "./SystemRequest.module.scss";

interface SystemRequestProps extends RouteComponentProps {
  systemId: string;
  materialId?: string;
}

export const SystemRequest: React.FC<SystemRequestProps> = observer(({ systemId, history, materialId }) => {
  const { t } = useTranslation();

  const {
    material,
    isSystemForRequestValid,
    personalAndCompanyData,
    handleSubmit,
    handleSave,
    handleSavedModalClose,
    isLoading
  } = useMaterialData({
    initialIsLoading: true,
    addingNewMaterial: true,
    materialId,
    systemToRequestId: systemId
  });

  const notFoundSystem = !systemId || !isSystemForRequestValid;
  const notFoundMaterialData = materialId && !material;
  const initialValues = material || personalAndCompanyData;
  const missingData = notFoundSystem || notFoundMaterialData || !initialValues;

  if (!isLoading && missingData) {
    return <NotFound />;
  }

  return (
    <Layout withMolecules={false} loading={isLoading}>
      <FormSection>
        <BackButton />
      </FormSection>
      <PageTitle
        title={t("systemRequest.systemRequest")}
        description={t("systemRequest.description")}
        className={{
          description: css.pageTitleDescription
        }}
      />
      <SelectMaterial />
      <MaterialForm
        initialValues={material || personalAndCompanyData}
        onSubmit={handleSubmit}
        onSave={handleSave}
        onGoBack={() => history.push(routes.systems.build({}))}
        onSavedModalClose={handleSavedModalClose}
      />
    </Layout>
  );
});
