import React from "react";
import { useTranslation } from "react-i18next";

import { Badge, BadgeProps } from "triangular/components/Badge/Badge";
import { NotSet } from "triangular/components/NotSet/NotSet";
import { translateIfExists } from "triangular/utils/components";

import css from "./MaterialBadges.module.scss";

interface MaterialBadgesProps {
  materials: Array<{
    id: number;
    name: string;
  }>;
  variant?: BadgeProps["variant"];
}

export const MaterialBadges: React.FC<MaterialBadgesProps> = ({ materials, variant = "darkMint" }) => {
  const { i18n } = useTranslation();

  if (materials.length === 0) {
    return <NotSet />;
  }

  return (
    <ul className={css.list}>
      {materials.map(({ id, name }) => (
        <li key={id} className={css.item}>
          <Badge variant={variant}>
            {translateIfExists({ i18n, path: `systemSettings.materialOptions.${name}`, fallback: name })}
          </Badge>
        </li>
      ))}
    </ul>
  );
};
