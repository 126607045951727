import capitalize from "lodash/capitalize";
import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "triangular/components/Avatar/Avatar";
import { Badge } from "triangular/components/Badge/Badge";
import { Card } from "triangular/components/Card/Card";
import { IndustryList } from "triangular/components/IndustryList/IndustryList";
import { Typography } from "triangular/components/Typography/Typography";
import { WithTooltip } from "triangular/components/WithTooltip/WithTooltip";
import { ExpertModelSnapshot } from "triangular/stores/ExpertSearchStore/models";
import { useStore } from "triangular/stores/StoreContext";

import { ExpertDetailsLink } from "./ExpertDetailsLink";
import css from "./ExpertTile.module.scss";

interface ExpertTileProps {
  experiences: ExpertModelSnapshot["experiences"];
  avatar: ExpertModelSnapshot["avatar"];
  firstName: ExpertModelSnapshot["firstName"];
  lastName: ExpertModelSnapshot["lastName"];
  processCategories: ExpertModelSnapshot["processCategories"];
  industries: ExpertModelSnapshot["industries"];
  id: ExpertModelSnapshot["id"];
  origin?: string;
  title: ExpertModelSnapshot["showcaseTitle"];
  company: ExpertModelSnapshot["expertCompany"];
  description: ExpertModelSnapshot["description"];
}

export const ExpertTile: React.FC<ExpertTileProps> = ({
  experiences,
  avatar,
  firstName,
  lastName,
  title,
  company,
  description,
  processCategories,
  industries,
  id,
  origin
}) => {
  const { t } = useTranslation();
  const { userStore } = useStore();

  // const { title, description } = getExpertTitle(experiences);
  const avatarUrl = avatar && avatar.originalUrl;

  const renderLastName = (lastName: string | null) => {
    if (userStore.isAuthenticated) {
      return <span className={css.lastName}>{lastName}</span>;
    }

    const fakeLastName = firstName
      ? capitalize(
          firstName
            .split("")
            .reverse()
            .join("")
            .repeat(2)
            .toLowerCase()
            .slice(0, firstName.length > 6 ? 6 : 12)
        )
      : "Hidden";

    return (
      <WithTooltip tooltipMessage={t("protectedInformation")}>
        <span className={css.blurredLastName}>{fakeLastName}</span>
      </WithTooltip>
    );
  };

  const industryNames = (industries || []).map(({ name }) => name);

  return (
    <ExpertDetailsLink className={css.itemLink} expertId={id} testId="expertTile" origin={origin}>
      <Card data-testid="expertCard" className={css.itemHover}>
        <header className={css.header}>
          <Avatar imageUrl={avatarUrl} />
          <div className={css.details}>
            <Typography bold={true} size="big" className={css.companyName} component="div">
              {company}
            </Typography>
            <Typography bold={true} component="div" className={css.name}>
              {firstName} {renderLastName(lastName)}
            </Typography>
            <Typography className={css.detail} component="div">
              {title}
            </Typography>
            <Typography className={css.detail} color="darkMint" component="div">
              {description}
            </Typography>
          </div>
        </header>
        <div className={css.specializations}>
          {processCategories && processCategories.length > 0 ? (
            <div className={css.area}>
              <Typography upperCase={true} component="div" color="mint" size="small">
                {t("expertsSearch.processExpertise")}
              </Typography>
              {processCategories.map(name => (
                <div className={css.tag} key={name}>
                  <Badge variant="greyishBrown">{t(`systemSettings.processCategoryOptions.${name}`)}</Badge>
                </div>
              ))}
            </div>
          ) : null}

          {industryNames && industryNames.length > 0 ? (
            <div className={css.industry}>
              <Typography upperCase={true} component="div" color="mint" size="small">
                {t("industry")}
              </Typography>
              <div className={css.industries}>
                <IndustryList industryNames={industryNames} />
              </div>
            </div>
          ) : null}
        </div>
      </Card>
    </ExpertDetailsLink>
  );
};
