import classNames from "classnames";
import isNil from "lodash/isNil";
import React, { useContext } from "react";

import { FormAdditionalContext } from "triangular/utils/components";

import { AbstractField, AbstractFieldProps } from "../AbstractField/AbstractField";

import css from "./CheckboxField.module.scss";

export interface CheckboxFieldProps
  extends Omit<AbstractFieldProps, "component" | "render" | "children" | "className" | "onChange"> {
  className?: string;
  ["data-testid"]?: string;
  onChange?: (value: boolean) => void;
  abstractFieldClassName?: AbstractFieldProps["className"];
}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  className,
  onChange: externalOnChange,
  abstractFieldClassName,
  "data-testid": testId,
  ...props
}) => {
  const { isReadOnly } = useContext(FormAdditionalContext);

  if (isReadOnly) {
    props.disabled = true;
  }

  return (
    <AbstractField
      {...props}
      className={{
        wrapper: css.wrapper,
        inputContainer: css.inputContainer,
        errorContainer: css.errorContainer,
        errorMessage: css.errorMessage,
        label: css.label,
        ...abstractFieldClassName
      }}
      render={({ field: { value, onChange, ...field }, form }) => {
        const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            const nextValue = !value;
            form.setFieldValue(field.name, nextValue);

            if (externalOnChange) {
              externalOnChange(nextValue);
            }
          }
        };

        const appliedValue = isNil(value) ? false : value;

        return (
          <label className={classNames(css.label, props.disabled && css.disabled, className)}>
            <input
              id={props.name}
              type="checkbox"
              checked={appliedValue}
              disabled={props.disabled}
              onKeyPress={handleKeyPress}
              value={appliedValue}
              onChange={event => {
                onChange(event);
                if (externalOnChange) {
                  externalOnChange(!value);
                }
              }}
              data-testid={testId}
              {...field}
            />
            <span className={css.checkbox} onKeyPress={handleKeyPress} />
          </label>
        );
      }}
    />
  );
};
