import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { getSnapshot } from "mobx-state-tree";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";

import { DefaultFormButtons } from "triangular/components/form/DefaultFormButtons";
import { Form } from "triangular/components/form/Form";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { PageLoader } from "triangular/components/PageLoader/PageLoader";
import { gaActions, GaCategories, googleAnalytics } from "triangular/services/googleAnalytics";
import { useStore } from "triangular/stores/StoreContext";
import { useLoadingState } from "triangular/utils/components";

import { Availability } from "./Availability";
import { Certificates } from "./Certificates/Certificates";
import { Experiences } from "./Experiences/Experiences";
import css from "./ExpertForm.module.scss";
import { Expertise } from "./Expertise";
import { createExpertSchema, expertDefaultValues, ExpertSchema } from "./expertSchema";
import { Languages } from "./Languages/Languages";
import { PersonalData } from "./PersonalData";

export const ExpertForm: React.FC<RouteComponentProps> = observer(({ history }) => {
  const { t } = useTranslation();
  const expertSchema = createExpertSchema(t);
  const { expertStore, userStore, snackbarStore } = useStore();
  const currentExpert = getSnapshot(expertStore.currentExpert);
  const expertId = userStore.profileId;
  const isFormDisabled = expertStore.isConfirmed();
  const { setLoadingState, loadingState } = useLoadingState();

  const fetchExpert = useCallback(async () => {
    setLoadingState("in_progress");

    try {
      await expertStore.fetchCurrentExpert(expertId);
      setLoadingState("done");
    } catch (err) {
      snackbarStore.showGenericError(err);
      setLoadingState("failed");
    }
    return () => {
      expertStore.cleanUpCurrentExpert();
    };
  }, [expertId, expertStore, setLoadingState, snackbarStore]);

  useEffect(() => {
    fetchExpert();
  }, [fetchExpert]);

  const handleSubmit = async (values: ExpertSchema) => {
    const { email, industries, specialties, materialCategories, ...newExpert } = values;

    await expertStore.updateExpert(expertId!, {
      ...newExpert,
      industries: (industries || []).map(id => ({ id })),
      specialties: (specialties || []).map(id => ({ id })),
      materialCategories: (materialCategories || []).map(id => ({ id }))
    });

    googleAnalytics.sendEvent({
      category: GaCategories.expert,
      action: gaActions.expert.profileSave.succeeded
    });

    snackbarStore.addSnackbar({ type: "success", message: t("editExpert.expertSavedModal.message") });
  };

  if (loadingState !== "done") {
    return <PageLoader loadingState={loadingState} />;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        ...expertDefaultValues,
        ...{
          firstName: currentExpert.firstName,
          lastName: currentExpert.lastName,
          email: userStore.email,
          description: currentExpert.description,
          showcaseTitle: currentExpert.showcaseTitle,
          expertCompany: currentExpert.expertCompany,
          expertiseDescription: currentExpert.expertiseDescription,
          phoneNumber: currentExpert.phoneNumber,
          mobileNumber: currentExpert.mobileNumber,
          countryCode: currentExpert.countryCode,
          reach: currentExpert.reach,
          countryCodeBlacklist: currentExpert.countryCodeBlacklist,
          processCategories: currentExpert.processCategories || [],
          industries: currentExpert.industries || [],
          specialties: currentExpert.specialties || [],
          materialCategories: currentExpert.materialCategories || [],
          languagesKnowledge: currentExpert.languagesKnowledge
        }
      }}
      enableReinitialize={true}
      validationSchema={expertSchema}
      render={() => (
        <>
          <FormSection>
            <div className={classNames("d-flex justify-content-center", css.spacing)}>
              <DefaultFormButtons
                confirmPromptTitle={t("editProfile.submitConfirmation.header")}
                confirmPromptMessage={t("editProfile.submitConfirmation.message")}
                fieldsToValidateBeforeSave={["name"]}
                submitButtonLabel={t("save")}
                isSubmitDisabled={isFormDisabled}
              />
            </div>
          </FormSection>
          <PersonalData />
          <Languages />
          <Availability />
          <Expertise />
          <Experiences />
          <Certificates />
          <FormSection className={css.formButtons}>
            <DefaultFormButtons
              confirmPromptTitle={t("editProfile.submitConfirmation.header")}
              confirmPromptMessage={t("editProfile.submitConfirmation.message")}
              fieldsToValidateBeforeSave={["name"]}
              submitButtonLabel={t("save")}
              isSubmitDisabled={isFormDisabled}
            />
          </FormSection>
        </>
      )}
    />
  );
});
