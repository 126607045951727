import { observer } from "mobx-react-lite";
import React from "react";
import { NavLink } from "react-router-dom";

import { Breakpoint } from "triangular/components/Breakpoint/Breakpoint";
import { routes } from "triangular/Routes/routesConfiguration";
import { ReactComponent as LogoMobile } from "triangular/static/images/logo-triangular-mobile.svg";
import { useStore } from "triangular/stores/StoreContext";

import { Logo } from "../Logo/Logo";

import css from "./Header.module.scss";
import { MenuDesktop } from "./MenuDesktop/MenuDesktop";
import { MenuMobile } from "./MenuMobile/MenuMobile";

export interface MenuItem {
  name: string;
  path: string;
  color?: "mint" | "darkMint";
}

export interface MenuItemsGroup {
  id: string;
  items: MenuItem[];
}

export const Header: React.FC = observer(() => {
  const { userStore } = useStore();
  const userType = userStore.type;

  function logoTarget() {
    if (userType === "system_owners") {
      return routes.mySystemsList.build({});
    } else if (userType === "material_owners") {
      return routes.myMaterialsList.build({});
    } else if (userType === "experts") {
      return routes.myExpertProfile.build({});
    }
    return routes.systems.build({});
  }

  return (
    <header className={css.header}>
      <div className={css.container}>
        <Breakpoint minWidth="small" maxWidth="small">
          <NavLink className={css.logo} to={logoTarget()}>
            <LogoMobile />
          </NavLink>
          <MenuMobile />
        </Breakpoint>
        <Breakpoint minWidth="medium">
          <h1>
            <NavLink className={css.logo} to={logoTarget()} data-testid="logoLink">
              <Logo />
            </NavLink>
          </h1>
          <MenuDesktop />
        </Breakpoint>
      </div>
    </header>
  );
});
