import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FormSection } from "triangular/components/form/FormSection/FormSection";
import {
  InvoiceDetailsForm,
  InvoiceDetailsValues
} from "triangular/components/form/InvoiceDetailsForm/InvoiceDetailsForm";
import { PageLoader } from "triangular/components/PageLoader/PageLoader";
import { useStore } from "triangular/stores/StoreContext";
import { useAppTitle, useLoadable } from "triangular/utils/components";

import { PageTitle } from "./PageTitle/PageTitle";

export const SubscriptionInvoiceDetails: React.FC = () => {
  const { t } = useTranslation();
  useAppTitle(t("subscriptionSetup.invoiceDetails.title"));
  const { paymentsStore } = useStore();
  const { loadingState, setLoadable } = useLoadable();
  const [initialValues, setInitialValues] = useState<InvoiceDetailsValues>({
    name: null,
    taxId: null,
    city: null,
    countryCode: null,
    postalCode: null,
    addressLine1: null,
    addressLine2: null
  });

  const fetchInitialValues = useCallback(
    () =>
      setLoadable(async () => {
        const data = await paymentsStore.fetchInvoiceDetails();
        setInitialValues(() => ({
          name: data.name,
          taxId: data.taxId,
          city: data.city,
          countryCode: data.countryCode,
          postalCode: data.postalCode,
          addressLine1: data.line1,
          addressLine2: data.line2
        }));
      }),
    [paymentsStore, setLoadable]
  );

  useEffect(() => {
    fetchInitialValues();
  }, [fetchInitialValues]);

  if (loadingState === "done") {
    return (
      <FormSection>
        <PageTitle
          title={t("subscriptionSetup.invoiceDetails.title")}
          description={t("subscriptionSetup.invoiceDetails.description")}
        />
        <InvoiceDetailsForm
          initialValues={initialValues}
          onSubmit={async values => {
            await paymentsStore.updateInvoiceDetails(values);
            await paymentsStore.fetchSubscription();
          }}
        />
      </FormSection>
    );
  }

  return <PageLoader loadingState={loadingState} />;
};
