import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";

import { SearchScene } from "triangular/components/SearchSceneLayout/SearchSceneLayout";
import { useStore } from "triangular/stores/StoreContext";
import { CancelToken, CancelTokenType } from "triangular/utils/api";

import { DesktopViewContent } from "./DesktopViewContent";
import { MobileViewContent } from "./MobileViewContent";
import { parsePaginationQuery, SystemsPaginationContext, usePagination } from "./utils/pagination";

export const SystemSearch = observer<RouteComponentProps>(({ location }) => {
  const { systemSearchStore, snackbarStore } = useStore();

  const { totalItems } = systemSearchStore;

  const pagination = usePagination({
    totalItems,
    location
  });

  const loadSystems = useCallback(
    async (cancelToken: CancelTokenType) => {
      try {
        await systemSearchStore.loadSystems(parsePaginationQuery(location.search), cancelToken);
        window.scroll({ top: 0, left: 0 });
      } catch (err) {
        snackbarStore.showGenericError(err);
      }
    },
    [location.search, systemSearchStore, snackbarStore]
  );

  useEffect(() => {
    const cancellation = CancelToken.source();
    loadSystems(cancellation.token);
    return () => cancellation.cancel();
  }, [loadSystems]);

  useEffect(
    () => () => {
      systemSearchStore.setSystems([]);
      systemSearchStore.setIsLoading(true);
      systemSearchStore.setTotalItems(0);
    },
    [systemSearchStore]
  );

  return (
    <SystemsPaginationContext.Provider
      value={{
        pagination
      }}
    >
      <SearchScene.Layout mobileView={<MobileViewContent />} desktopView={<DesktopViewContent />} />
    </SystemsPaginationContext.Provider>
  );
});
