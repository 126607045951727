import { types } from "mobx-state-tree";

import { FlatEntity } from "triangular/services/Api/Resource/utils/types";
import { ExpertEntity } from "triangular/services/Api/resources/ExpertResource";
import { expertModel } from "triangular/stores/ExpertStore/expertModel";
import { CancelTokenType, isCancel } from "triangular/utils/api";
import { PaginationBase } from "triangular/utils/pagination/types";

import { createStore } from "../utils/createStore";

type ExpertFilters = {
  languages: string[] | null | undefined;
  processCategories: string[] | null | undefined;
  industryIds: string[] | null | undefined;
  materialCategoryIds: string[] | null | undefined;
};

export const ExpertSearchStore = createStore(storeDeps =>
  types
    .model("ExpertSearchStore", {
      totalItems: 0,
      experts: types.array(expertModel),
      isLoading: types.boolean
    })
    .actions(self => ({
      async fetchExperts({
        filter,
        pagination
      }: {
        filter: {
          q?: string;
        } & ExpertFilters;
        pagination: {
          size: number;
          number: number;
        };
        cancelToken: CancelTokenType;
      }) {
        const response = await storeDeps.api.expert.find({
          filter,
          pagination,
          sort: [["firstName", "ASC"]],
          include: {
            avatar: true,
            experiences: true,
            industries: true
          }
        });

        return {
          data: response.data,
          meta: response.meta as {
            pageCount: number;
            recordCount: number;
          }
        };
      },
      setExperts(experts: FlatEntity<ExpertEntity>[]) {
        self.experts.clear();
        experts.forEach(eachSystem => self.experts.push(eachSystem));
      },
      setTotalItems(totalItems: number) {
        self.totalItems = totalItems;
      },
      setIsLoading(isLoading: boolean) {
        self.isLoading = isLoading;
      },
      sendHelpRequest(cancelToken: CancelTokenType) {
        return storeDeps.api.expertHelpRequest.create({}, { cancelToken });
      }
    }))
    .actions(self => ({
      async loadExperts(
        {
          page,
          search,
          languages,
          processCategories,
          industryIds,
          materialCategoryIds
        }: {
          page: PaginationBase["page"];
          search?: string;
        } & ExpertFilters,
        cancelToken: CancelTokenType
      ) {
        self.setIsLoading(true);

        try {
          const { data, meta } = await self.fetchExperts({
            filter: {
              q: search && search.length > 0 ? search : undefined,
              languages,
              processCategories,
              industryIds,
              materialCategoryIds
            },
            pagination: {
              size: page.size,
              number: page.number
            },
            cancelToken
          });
          self.setTotalItems(meta.recordCount);
          self.setExperts(data);
          return self.setIsLoading(false);
        } catch (err) {
          if (isCancel(err)) {
            return;
          }
          self.setIsLoading(false);

          throw err;
        }
      }
    }))
);

export const expertSearchStoreInitialState = {
  totalItems: 0,
  experts: [],
  isLoading: true
};
