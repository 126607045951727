import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";

import { Alert } from "triangular/components/Alert/Alert";
import { BackButton } from "triangular/components/BackButton/BackButton";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { SystemForm } from "triangular/components/form/system/SystemForm/SystemForm";
import { useSystemForm } from "triangular/components/form/system/SystemForm/useSystemForm";
import { Layout } from "triangular/components/Layout/Layout";
import { SystemErrorPage } from "triangular/components/SystemErrorPage/SystemErrorPage";
import { Typography } from "triangular/components/Typography/Typography";
import { routes } from "triangular/Routes/routesConfiguration";
import { useFetchedSystem } from "triangular/utils/systems";

import css from "./EditSystem.module.scss";

interface EditSystemProps extends RouteComponentProps {
  id: string;
}
export const EditSystem: React.FC<EditSystemProps> = observer(({ history, id: systemId }) => {
  const { t } = useTranslation();
  const { system, fetchSystem } = useFetchedSystem(systemId);
  const [isLoading, setLoading] = useState(true);
  const [errorOccured, setErrorOccurred] = useState(false);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      await fetchSystem();
    } catch (err) {
      setErrorOccurred(true);
    }

    setLoading(false);
  }, [fetchSystem]);

  const { handleSave, handleSubmit, handleSavedModalClose } = useSystemForm(systemId);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const isPublished = system && system.state === "published";

  return (
    <Layout withMolecules={false} loading={isLoading}>
      {errorOccured ? (
        <SystemErrorPage />
      ) : (
        <>
          <FormSection>
            <BackButton />
          </FormSection>
          <div className={css.wrapper}>
            <Typography component="h2" size="large" bold={true} center={true} className={css.header}>
              {t("editSystem.editSystem")}
            </Typography>
            {isPublished && <Alert>{t("editSystem.isPublishedAlert")}</Alert>}
            <SystemForm
              initialValues={system}
              handleSave={handleSave}
              handleSubmit={handleSubmit}
              onGoBack={() => history.push(routes.mySystemsList.build())}
              onSavedModalClose={handleSavedModalClose}
            />
          </div>
        </>
      )}
    </Layout>
  );
});
