import React from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { Badge } from "triangular/components/Badge/Badge";
import { TriLink } from "triangular/components/TriLink/TriLink";

import css from "./CurrentFilterBadges.module.scss";

export interface CurrentFilterBadgesProps {
  badges: Array<{ value: string; removingUrl: string; label: React.ReactNode }>;
}

export const CurrentFilterBadges = ({ badges }: CurrentFilterBadgesProps) => {
  const { history } = useRouter();
  const { t } = useTranslation();

  if (badges.length === 0) {
    return null;
  }

  return (
    <div className={css.wrapper}>
      {badges.map(({ value, removingUrl, label }) => (
        <Badge
          key={value}
          variant="darkMint"
          margins={true}
          onDelete={() => history.push(removingUrl)}
          testId={`filterBadge:${value}`}
        >
          {label}
        </Badge>
      ))}
      {badges.length > 1 && (
        <TriLink to={"#"}>
          <Badge variant="mint">{t("clearAll")}</Badge>
        </TriLink>
      )}
    </div>
  );
};
