import { History } from "history";
import { useEffect } from "react";
import ReactGA from "react-ga";

export enum GaCategories {
  systemSearch = "systemSearch",
  expertSearch = "expertSearch",
  user = "user",
  registration = "registration",
  systemOwner = "systemOwner",
  materialOwner = "materialOwner",
  expert = "expert"
}

export const gaActions = {
  systemSearch: {
    search: "systemSearch.search"
  },
  expertSearch: {
    search: "expertSearch.search"
  },
  user: {
    login: {
      succeeded: "user.login.succeeded",
      failed: "user.login.failed"
    },
    logout: "user.logout"
  },
  registration: {
    firstStep: "registration.firstStep",
    goToSecondStep: "registration.goToSecondStep",
    goToThirdStep: "registration.goToThirdStep",
    accountConfirmation: {
      succeeded: "registration.accountConfirmation.succeeded",
      failed: "registration.accountConfirmation.failed"
    },
    succeeded: "registration.succeeded",
    failed: "registration.failed"
  },
  systemOwner: {
    systemSubmit: {
      succeeded: "systemOwner.systemSubmit.succeeded",
      failed: "systemOwner.systemSubmit.failed"
    },
    systemSave: {
      succeeded: "systemOwner.systemSave.succeeded",
      failed: "systemOwner.systemSave.failed"
    }
  },
  materialOwner: {
    materialSubmit: {
      succeeded: "materialOwner.materialSubmit.succeeded",
      failed: "materialOwner.materialSubmit.failed"
    },
    materialSave: {
      succeeded: "materialOwner.materialSave.succeeded",
      failed: "materialOwner.materialSave.failed"
    }
  },
  expert: {
    profileSave: {
      succeeded: "expert.profileSave.succeeded",
      failed: "expert.profileSave.failed"
    }
  }
};

interface EventParams {
  category: GaCategories;
  action: string;
  label?: string;
  value?: number;
}

export const googleAnalytics = {
  initialize(history: History) {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, { debug: false });

    history.listen(location => {
      ReactGA.pageview(location.pathname + location.search);
    });
  },
  sendEvent(params: EventParams) {
    ReactGA.event(params);
  },
  useEventOnMount(params: EventParams) {
    useEffect(() => {
      ReactGA.event(params);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  }
};
