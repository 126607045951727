import { TFunction } from "i18next";
import { isNumber } from "lodash";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import * as Yup from "yup";

import { numberValidator } from "./numberValidator";

export const rangeValidator = (t: TFunction) => (params: {
  min: number;
  max: number;
  allowFractions: boolean;
  allowEmpty?: boolean;
}) =>
  Yup.string()
    .test("rangeValidator", t("validation.invalidValue"), validateRange(t)(params))
    .transform(value => (isString(value) ? value.trim() : value));

export const validateRange = (t: TFunction) => (params: {
  min: number;
  max: number;
  allowFractions: boolean;
  allowEmpty?: boolean;
}) => (value: any) => {
  try {
    if (!params.allowFractions && value.match(/[,.]/g)) {
      return false;
    }

    const [fromStr, toStr, ...rest] = Array.from(
      value.replace("--", "- -").match(/((^-[\d.,]+)|( -[\d.,]+)|([\d.,]+))/g) as string[]
    );

    if (isEmpty(toStr)) {
      numberValidator(t)(params.min, params.max).validateSync(fromStr);
      return true;
    }

    if (isEmpty(fromStr) || !isEmpty(rest)) {
      return false;
    }

    const fromNumber = numberValidator(t)(params.min, params.max).cast(fromStr) as number;
    const toNumber = numberValidator(t)(params.min, params.max).cast(toStr) as number;

    const correctRange = fromNumber < toNumber;

    numberValidator(t)(params.min, params.max).validateSync(fromStr);
    numberValidator(t)(params.min, params.max).validateSync(toStr);

    return correctRange;
  } catch (err) {
    // Allow empty field handling
    if (err instanceof TypeError) {
      if (!params.allowEmpty || isString(value) || isNumber(value)) {
        return false;
      } else {
        value = null;
        return true;
      }
    } else {
      return false;
    }
  }
};
