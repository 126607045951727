import React from "react";
import { useTranslation } from "react-i18next";

import { SelectableField } from "triangular/components/form/SelectableField/SelectableField";
import { SelectableFieldProps } from "triangular/components/form/SelectableField/SelectableField";
import { TextField } from "triangular/components/form/TextField";
import { TextFieldProps } from "triangular/components/form/TextField";

import css from "./OptionalTextField.module.scss";

interface OptionalTextFieldProps extends Omit<SelectableFieldProps, "options" | "defaultOption"> {
  textFieldProps?: Partial<TextFieldProps>;
  defaultOption?: boolean;
  "data-testid"?: string;
}

export const OptionalTextField: React.FC<OptionalTextFieldProps> = ({
  name,
  textFieldProps = {},
  defaultOption,
  selectFieldProps = {},
  "data-testid": testId,
  placeholder,
  ...props
}) => {
  const { t } = useTranslation();
  const { className: selectClassName = {}, ...selectPropsRest } = selectFieldProps;
  const { input: selectInputClass, ...selectClassNameRest } = selectClassName;
  const { className: textFieldClassName = {}, ...textFieldPropsRest } = textFieldProps;
  const { input: textFieldInputClass, ...textFieldClassNameRest } = textFieldClassName;

  return (
    <SelectableField
      name={`${name}Enabled`}
      defaultOption={defaultOption}
      options={[
        {
          label: t("optionalTextField.yes"),
          value: true,
          reactNode: (
            <TextField
              name={name}
              className={{ wrapper: css.textFieldWrapper, ...textFieldClassNameRest }}
              {...textFieldProps}
              {...textFieldPropsRest}
              data-testid={`${testId}OptionalTextField`}
              placeholder={placeholder}
            />
          )
        },
        {
          label: t("optionalTextField.no"),
          value: false,
          reactNode: null
        }
      ]}
      selectFieldProps={{
        className: {
          input: selectInputClass,
          ...selectClassNameRest
        },
        "data-testid": testId,
        ...selectPropsRest
      }}
      {...props}
    />
  );
};
