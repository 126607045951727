import { observer } from "mobx-react-lite";
import React from "react";

import { Hr } from "triangular/components/Hr/Hr";
import { Layout } from "triangular/components/Layout/Layout";

import { DeactivateAccount } from "./DeactivateAccount/DeactivateAccount";
import { InvoiceDetails } from "./InvoiceDetails/InvoiceDetails";
import { PaymentMethods } from "./PaymentMethods/PaymentMethods";
import css from "./Payments.module.scss";
import { SubscriptionInfo } from "./SubscriptionInfo/SubscriptionInfo";
import { SubscriptionPlan } from "./SubscriptionPlan/SubscriptionPlan";

export const Payments = observer(() => {
  return (
    <Layout withMolecules={false} className={{ main: css.main }}>
      <div className={css.sectionWrapper}>
        <PaymentMethods />
      </div>
      <Hr className={css.hr} />
      <div className={css.sectionWrapper}>
        <SubscriptionInfo />
      </div>
      <Hr className={css.hr} />
      <div className={css.sectionWrapper}>
        <SubscriptionPlan />
      </div>
      <Hr className={css.hr} />
      <div className={css.sectionWrapper}>
        <InvoiceDetails />
      </div>
      <div className={css.sectionWrapper}>
        <DeactivateAccount />
      </div>
    </Layout>
  );
});
