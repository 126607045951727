import classNames from "classnames";
import React from "react";

import css from "./FormSection.module.scss";

export const FormSection: React.FC<{ className?: string }> = ({ children, className }) => {
  return (
    <div className={classNames(css.section, className)}>
      <div className="container">{children}</div>
    </div>
  );
};
