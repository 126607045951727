import React from "react";

import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";

export interface PaymentMethodListProps {
  left: React.ReactNode;
  middle?: React.ReactNode;
  methodType: string;
}

export const PaymentMethodList: React.FC<PaymentMethodListProps> = ({ left, middle, methodType }) => {
  function showMethods() {
    const isManual = methodType === "manual";
    return (
      <>
        <FieldWrapper className={isManual ? "col-7 col-sm-8 col-md-8 col-lg-8 col-xl-8" : "col-7 col-sm-4"}>
          {left}
        </FieldWrapper>
        {!isManual && <FieldWrapper className="d-none d-sm-block col-sm-4">{middle}</FieldWrapper>}
      </>
    );
  }

  return <>{showMethods()}</>;
};
