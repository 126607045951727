import { AxiosInstance } from "axios";

import { Resource } from "../Resource/Resource";
import { Entity } from "../Resource/utils/types";

type ExpertHelpRequestEntity = Entity<
  {},
  {
    expert: Entity;
  }
>;

export class ExpertHelpRequestResource extends Resource<ExpertHelpRequestEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "expert_help_requests",
      prefixes: [],
      relationships: {
        expert: {
          type: "experts"
        }
      }
    });
  }
}
