import classNames from "classnames";
import React from "react";

import css from "./FieldWrapper.module.scss";

export const FieldWrapper: React.FC<{ className?: string }> = ({ children, className }) => (
  <div
    className={
      className ? classNames(className, css.fieldWrapper) : classNames("col-sm-12 col-md-6 col-lg-4", css.fieldWrapper)
    }
  >
    {children}
  </div>
);
