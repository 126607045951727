import { types } from "mobx-state-tree";

const particleSize = types.maybeNull(
  types.array(
    types.maybeNull(
      types.model({
        size: types.maybeNull(types.string),
        percent: types.maybeNull(types.string)
      })
    )
  )
);

export const materialModel = types.model("Material", {
  abrasiveness: types.maybeNull(types.string),
  bulkDensity: types.maybeNull(types.string),
  color: types.maybeNull(types.string),
  companyName: types.maybeNull(types.string),
  corrosiveness: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  deadline: types.maybeNull(types.string),
  density: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  explosiveness: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  flowingProperties: types.maybeNull(types.string),
  hazardousness: types.maybeNull(types.string),
  humidity: types.maybeNull(types.string),
  hygroscopic: types.maybeNull(types.boolean),
  id: types.string,
  indexNumber: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  materialAmount: types.maybeNull(types.string),
  materialCategory: types.maybeNull(types.string),
  materialIndex: types.maybeNull(types.string),
  processCategory: types.maybeNull(
    types.model({
      id: types.string,
      type: types.string
    })
  ),
  systemCategory: types.maybeNull(
    types.model({
      id: types.string,
      type: types.string
    })
  ),
  systemType: types.maybeNull(
    types.model({
      id: types.string,
      type: types.string
    })
  ),
  designType: types.maybeNull(
    types.model({
      id: types.string,
      type: types.string
    })
  ),
  name: types.maybeNull(types.string),
  desiredParticleSize: particleSize,
  feedParticleSize: particleSize,
  phoneNumber: types.maybeNull(types.string),
  position: types.maybeNull(types.string),
  processTemperature: types.maybeNull(types.string),
  safetyMeasures: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  storageNeeded: types.maybeNull(types.string),
  targetIndustry: types.maybeNull(types.string)
});
