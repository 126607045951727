import classNames from "classnames";
import React from "react";

import { ReactComponent as AlertSvg } from "triangular/static/images/alert.svg";

import css from "./Alert.module.scss";

export const Alert: React.FC<{ className?: string }> = ({ className, children }) => {
  return (
    <div className={classNames(css.alert, className)}>
      <AlertSvg className={css.alertSvg} /> {children}
    </div>
  );
};
