import classNames from "classnames";
import React from "react";

import { TriLink } from "triangular/components/TriLink/TriLink";

import css from "./FilesList.module.scss";
import { ReactComponent as RemoveIcon } from "./images/delete-icon.svg";

export interface FileListItem {
  filename: string;
  id: string;
  originalUrl?: string;
}

export interface FilesListProps {
  files: FileListItem[];
  className?: {
    ul?: string;
    li?: string;
    fileItem?: string;
    removeIcon?: string;
  };
  onRemoveFile?: (file: FileListItem) => void;
  "data-testid"?: string;
}

export const FilesList: React.FC<FilesListProps> = ({ files, className = {}, onRemoveFile, "data-testid": testId }) => (
  <ul className={classNames(css.ul, className.ul, { [css.empty]: files.length === 0 })} data-testid={testId}>
    {files.map(eachFile => {
      const filename = eachFile.originalUrl ? (
        <TriLink className={css.fileName} to={eachFile.originalUrl} external={true}>
          {eachFile.filename}
        </TriLink>
      ) : (
        <span className={css.fileName}>{eachFile.filename}</span>
      );

      return (
        <li className={classNames(css.li, className.li)} key={eachFile.id} data-testid={`${testId}File`}>
          <div className={classNames(css.fileItem, className.fileItem)}>
            {filename}
            {onRemoveFile && (
              <RemoveIcon
                className={classNames(css.removeIcon, className.removeIcon)}
                onClick={() => onRemoveFile(eachFile)}
              />
            )}
          </div>
        </li>
      );
    })}
  </ul>
);
