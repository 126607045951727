import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { useStore } from "triangular/stores/StoreContext";
import { namesToSelectOptions } from "triangular/utils/systems";

interface ColorFieldProps {
  label: string;
}

export const ColorField: React.FC<ColorFieldProps> = observer(({ label }) => {
  const { t } = useTranslation();
  const { commonsStore } = useStore();
  const { commonSettings } = commonsStore;

  const colorOptions = commonSettings ? namesToSelectOptions(t)("colorOptions", commonSettings.colors) : [];

  return (
    <SelectField
      name="color"
      label={label}
      selectProps={{
        options: colorOptions
      }}
      data-testid="color"
    />
  );
});
