import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";

import { SearchScene } from "triangular/components/SearchSceneLayout/SearchSceneLayout";
import { useStore } from "triangular/stores/StoreContext";
import { CancelToken, CancelTokenType } from "triangular/utils/api";

import { DesktopViewContent } from "./DesktopViewContent";
import { MobileViewContent } from "./MobileViewContent";
import { ExpertsPaginationContext, parsePaginationQuery, usePagination } from "./utils/pagination";

export const ExpertSearch = observer<RouteComponentProps>(({ location }) => {
  const { expertSearchStore, snackbarStore } = useStore();

  const pagination = usePagination({
    location,
    totalItems: expertSearchStore.totalItems
  });

  const loadExperts = useCallback(
    async (cancelToken: CancelTokenType) => {
      try {
        await expertSearchStore.loadExperts(parsePaginationQuery(location.search), cancelToken);
        window.scroll({ top: 0, left: 0 });
      } catch (err) {
        snackbarStore.showGenericError(err);
      }
    },
    [expertSearchStore, location.search, snackbarStore]
  );

  useEffect(() => {
    const cancellation = CancelToken.source();
    loadExperts(cancellation.token);
    return () => cancellation.cancel();
  }, [loadExperts]);

  useEffect(
    () => () => {
      expertSearchStore.setExperts([]);
      expertSearchStore.setIsLoading(true);
      expertSearchStore.setTotalItems(0);
    },
    [expertSearchStore]
  );

  return (
    <ExpertsPaginationContext.Provider
      value={{
        pagination
      }}
    >
      <SearchScene.Layout mobileView={<MobileViewContent />} desktopView={<DesktopViewContent />} />
    </ExpertsPaginationContext.Provider>
  );
});
