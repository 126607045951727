import classNames from "classnames";
import { Field, FieldConfig, FieldProps } from "formik";
import { TFunction } from "i18next";
import isEqual from "lodash/isEqual";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { WithTooltip } from "triangular/components/WithTooltip/WithTooltip";
import { FormAdditionalContext } from "triangular/utils/components";

import { ErrorText } from "../ErrorText/ErrorText";
import { FieldError } from "../FieldError";

import css from "./AbstractField.module.scss";

type InputProps = Omit<React.InputHTMLAttributes<HTMLElement>, keyof FieldConfig | "className">;

export type FieldRenderProps<V = any> = FieldProps<V> & {
  css: {
    wrapper?: string;
    label?: string;
    input?: string;
    inputContainer?: string;
    errorContainer?: string;
    errorMessage?: string;
  };
};

export interface AbstractFieldProps extends InputProps, Omit<FieldConfig, "render" | "component" | "children"> {
  label?: React.ReactNode;
  ["data-testid"]?: string;
  required?: boolean;
  className?: {
    wrapper?: string;
    label?: string;
    input?: string;
    inputContainer?: string;
    errorContainer?: string;
    errorMessage?: string;
  };
  errorComponent?: React.ComponentType | string;
  render?: (props: FieldRenderProps) => React.ReactNode;
  renderError?: (message: string) => React.ReactNode;
  customError?: React.ReactNode;
}

export const RequiredAnnotation = ({ t, name }: { t: TFunction; name: string }) => {
  return (
    <WithTooltip className={css.asterisk} tooltipMessage={t("thisFieldIsRequired")}>
      *
    </WithTooltip>
  );
};

export const AbstractField: React.FC<AbstractFieldProps> = React.memo(
  ({
    name,
    required = false,
    label,
    placeholder,
    errorComponent = "p",
    className = {},
    style,
    render,
    "data-testid": testId,
    renderError,
    customError,
    ...fieldProps
  }) => {
    const { isReadOnly } = useContext(FormAdditionalContext);
    const { t } = useTranslation();

    if (isReadOnly) {
      fieldProps.disabled = true;
    }

    const labelClassName = fieldProps.disabled
      ? classNames(className.label, css.label, css.labelDisabled)
      : classNames(className.label, css.label);

    const renderField = render
      ? (props: FieldProps<unknown>) => render({ ...props, css })
      : ({ field }: FieldProps<unknown>) => (
          <input
            {...fieldProps}
            {...field}
            className={classNames(css.input, className.input)}
            value={field.value === null || field.value === undefined ? "" : field.value}
            placeholder={placeholder}
            data-testid={testId}
            id={name}
            title={fieldProps.disabled ? field.value : ""}
          />
        );

    const requiredAnnotation = required && !fieldProps.disabled ? <RequiredAnnotation name={name} t={t} /> : null;

    return (
      <div className={classNames(className.wrapper, css.wrapper)} style={style}>
        {label && (
          <div className={css.labelWrapper}>
            <label className={labelClassName} htmlFor={name}>
              {label}&nbsp;{requiredAnnotation}
            </label>
          </div>
        )}
        <div className={classNames(className.inputContainer, css.inputContainer)}>
          <Field
            className={classNames(className.input, css.input)}
            id={name}
            data-testid={testId}
            name={name}
            placeholder={placeholder}
            render={renderField}
            {...fieldProps}
          />
          {customError ? (
            <ErrorText
              className={{
                message: className.errorMessage,
                container: className.errorContainer
              }}
            >
              {customError}
            </ErrorText>
          ) : (
            <FieldError
              name={name}
              className={{
                message: className.errorMessage,
                container: className.errorContainer
              }}
              component={errorComponent}
              render={renderError}
            />
          )}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps)
);
