import React from "react";
import { Trans } from "react-i18next";

import { i18n } from "triangular/i18next/i18next";
import { createLandingPageRoutes } from "triangular/Routes/routesConfiguration";

import { CheckboxField } from "../form/CheckboxField/CheckboxField";
import { TriLink } from "../TriLink/TriLink";

import css from "./AgreementCheckbox.module.scss";

export interface AgreementProps {
  className?: string | undefined;
}

export const AgreementCheckbox: React.FC<AgreementProps> = ({ className }) => {
  const landingPageRoutes = createLandingPageRoutes(i18n.language);

  return (
    <>
      <div className={className}>
        <CheckboxField
          data-testid="agreement"
          name="agreement"
          label={
            <Trans i18nKey="registration.personalInfo.agreement">
              I agree to the
              <TriLink to={landingPageRoutes.termsOfUse} external={true}>
                Terms of Use
              </TriLink>
              and
              <TriLink to={landingPageRoutes.privacyPolicy} external={true}>
                Privacy Policy
              </TriLink>
            </Trans>
          }
          abstractFieldClassName={{
            errorContainer: css.agreementError
          }}
          required={true}
        />
      </div>
    </>
  );
};
