import classNames from "classnames";
import { FormikProps } from "formik";
import { TFunction } from "i18next";
import get from "lodash/get";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Select, SelectProps } from "triangular/components/Select/Select";
import { FormAdditionalContext } from "triangular/utils/components";

import { AbstractField, AbstractFieldProps } from "../AbstractField/AbstractField";

import css from "./SelectField.module.scss";

export interface SelectFieldProps<Value>
  extends Omit<AbstractFieldProps, "component" | "render" | "children" | "onChange" | "className" | "value"> {
  selectProps: Omit<SelectProps<Value>, "onChange" | "onBlur" | "value" | "placeholder" | "disabled">;
  onChangeCallback?: (form: FormikProps<any>, selectedValue: Value) => void;
  className?: AbstractFieldProps["className"];
  tabIndex?: number;
  "data-testid"?: string;
  value?: string;
}

export const createBooleanOptions = (t: TFunction) => [
  {
    label: t("selectField.booleanOptions.yes"),
    value: true
  },
  {
    label: t("selectField.booleanOptions.no"),
    value: false
  }
];

export function SelectField<Value>({
  selectProps,
  placeholder,
  required,
  onChangeCallback,
  className = {},
  tabIndex,
  "data-testid": testId,
  name,
  label,
  ...props
}: SelectFieldProps<Value>) {
  const { t } = useTranslation();
  const { options } = selectProps;
  const { isReadOnly } = useContext(FormAdditionalContext);

  if (isReadOnly) {
    props.disabled = true;
  }

  const appliedClassName: AbstractFieldProps["className"] = {
    ...className,
    wrapper: classNames({ [css.withLabelWrapper]: !!label }, className.wrapper)
  };

  const defaultPlaceholder = options.length === 0 ? t("selectField.notAvailable") : t("selectField.placeholder");

  return (
    <AbstractField
      required={required}
      name={name}
      label={label}
      disabled={props.disabled}
      className={appliedClassName}
      render={({
        form,
        form: { setFieldTouched },
        field: { onChange, value: fieldValue, name: fieldName },
        css: fieldCss
      }) => (
        <Select
          {...selectProps}
          tabIndex={tabIndex}
          data-testid={testId}
          disabled={props.disabled}
          placeholder={placeholder ? placeholder : defaultPlaceholder}
          onChange={value => {
            if (onChangeCallback) {
              onChangeCallback(form, value);
            }

            onChange({
              target: {
                name,
                value
              }
            });

            setFieldTouched(name);
          }}
          value={get(props, "value", fieldValue)}
          name={fieldName}
          className={{
            container: classNames(fieldCss.inputContainer, className.inputContainer),
            input: classNames(fieldCss.input, className.input)
          }}
        />
      )}
      {...props}
    />
  );
}
