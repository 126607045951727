import { AxiosInstance } from "axios";

import { FileEntity } from "../entities/FileEntity";
import { Resource } from "../Resource/Resource";

export class CertificateFileResource extends Resource<FileEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "certificate_files",
      relationships: {},
      prefixes: ["experts"]
    });
  }
}
