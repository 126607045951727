import { Area } from "react-easy-crop/types";

function createImage(
  src: string,
  {
    width,
    height
  }: {
    width?: number;
    height?: number;
  } = {}
) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", error => reject(error));

    if (width) {
      image.width = width;
    }

    if (height) {
      image.height = height;
    }

    image.src = src;
  });
}

function createCanvas() {
  const element = document.createElement("canvas");
  const context = element.getContext("2d");

  if (!context) {
    throw new Error("Cannot create canvas' context");
  }

  return {
    element,
    context
  };
}

export async function scaleImage({
  image,
  targetWidth,
  targetHeight,
  quality
}: {
  image: HTMLImageElement;
  targetWidth: number;
  targetHeight: number;
  quality: number;
}): Promise<HTMLImageElement> {
  const canvas = createCanvas();

  if (image.width / targetWidth > 2 || image.height / targetHeight > 2) {
    // In case of images much bigger (more than twice times) than target dimensions
    // we should not do resizing at once.
    // That would cause sharp edges in the resultant picture.
    // Instead, we're going to shrink image incrementally.
    const decreasedImageWidth = image.width / 2;
    const decreasedImageHeight = image.height / 2;

    canvas.element.width = decreasedImageWidth;
    canvas.element.height = decreasedImageHeight;

    canvas.context.drawImage(image, 0, 0, image.width, image.height, 0, 0, decreasedImageWidth, decreasedImageHeight);

    return scaleImage({
      image: await createImage(canvas.element.toDataURL("image/jpeg", quality), {
        width: decreasedImageWidth,
        height: decreasedImageHeight
      }),
      targetWidth,
      targetHeight,
      quality
    });
  }

  canvas.element.width = targetWidth;
  canvas.element.height = targetHeight;

  canvas.context.drawImage(image, 0, 0, image.height, image.height, 0, 0, targetWidth, targetHeight);

  return createImage(canvas.element.toDataURL("image/jpeg", quality), {
    width: targetWidth,
    height: targetHeight
  });
}

export function cutImage({
  image,
  sx,
  sy,
  width,
  height,
  quality
}: {
  image: HTMLImageElement;
  sx: number;
  sy: number;
  width: number;
  height: number;
  quality: number;
}): Promise<HTMLImageElement> {
  const canvas = createCanvas();

  canvas.element.width = width;
  canvas.element.height = height;

  canvas.context.drawImage(image, sx, sy, width, height, 0, 0, width, height);

  return createImage(canvas.element.toDataURL("image/jpeg", quality), { width, height });
}

export async function cropImage({
  imageSrc,
  pixelCrop,
  quality,
  size
}: {
  imageSrc: string;
  pixelCrop: Area;
  quality: number;
  size: number;
}) {
  const image = await createImage(imageSrc);
  const canvas = createCanvas();

  canvas.element.width = size;
  canvas.element.height = size;

  const cuttedImage = await cutImage({
    image,
    sx: pixelCrop.x,
    sy: pixelCrop.y,
    width: pixelCrop.width,
    height: pixelCrop.height,
    quality
  });

  const scaledImage = await scaleImage({
    image: cuttedImage,
    targetWidth: size,
    targetHeight: size,
    quality
  });

  canvas.context.drawImage(scaledImage, 0, 0, scaledImage.width, scaledImage.height);

  return canvas.element.toDataURL("image/jpeg", quality);
}
