import React from "react";

import { AppLanguage } from "triangular/consts/languages";

import deFlag from "./assets/de-flag.png";
import esFlag from "./assets/es-flag.png";
import gbFlag from "./assets/gb-flag.png";
import plFlag from "./assets/pl-flag.png";

interface LanguageFlagProps {
  language: AppLanguage;
  className?: string;
  ref?: React.Ref<HTMLImageElement>;
}

export const LanguageFlag: React.FC<LanguageFlagProps> = React.forwardRef(({ language, className }, ref) => {
  const flagSrcs: { [Key in typeof language]: string } = {
    en: gbFlag,
    de: deFlag,
    es: esFlag,
    pl: plFlag
  };

  return <img ref={ref} src={flagSrcs[language]} alt="" width="16" height="12" className={className} />;
});
