import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import useReactRouter from "use-react-router";

import { Typography } from "triangular/components/Typography/Typography";
import { routes } from "triangular/Routes/routesConfiguration";
import { useStore } from "triangular/stores/StoreContext";

import { Breakpoint } from "../../../components/Breakpoint/Breakpoint";
import { Segment } from "../../../components/Segment/Segment";
import { RegistrationProfileType } from "../ProfileType/ProfileType";

import { BreadCrumbs } from "./BreadCrumbs/BreadCrumbs";
import { BreadCrumbsMobile, StepsState, StepTypes } from "./BreadCrumbsMobile/BreadCrumbsMobile";
import css from "./RegistrationWrapper.module.scss";

interface RegistrationTitleProps {
  type?: RegistrationProfileType;
}

export const registrationRoutes: { [key in StepTypes]: string } = {
  profileType: routes.registration.profileType.build(),
  personalInfo: routes.registration.personalInfo.build(),
  companyInfo: routes.registration.companyInfo.build()
};

const RegistrationTitle: React.FC<RegistrationTitleProps> = ({ type }) => {
  const { t } = useTranslation();

  const profileTypes: { [Key in RegistrationProfileType]: string } = {
    systemOwner: t("registration.profileType.systemOwner"),
    expert: t("registration.profileType.expert"),
    materialOwner: t("registration.profileType.materialOwner"),
    master: t("registration.profileType.master")
  };

  const registerAs = type && profileTypes[type] ? profileTypes[type] : null;

  return (
    <div className={css.registrationHeader}>
      <Typography component="h2" size="large" bold={true}>
        {t("registration.title")}
      </Typography>
      {registerAs && (
        <Typography component="h3" color="black" size="big" className={css.h3} data-testid="registerAs">
          {registerAs}
        </Typography>
      )}
    </div>
  );
};

export const RegistrationWrapper: React.FC = ({ children }) => {
  const { registrationStore } = useStore();
  const { history } = useReactRouter();
  const { pathname } = history.location;
  const profileType = registrationStore.type || undefined;
  const isExpert = profileType === "expert";

  const steps: StepsState = {
    profileType: {
      isActive: pathname === registrationRoutes.profileType,
      isFinished: registrationStore.type !== ""
    },
    personalInfo: {
      isActive: pathname === registrationRoutes.personalInfo,
      isFinished: registrationStore.hasPersonalInfo
    },
    companyInfo: {
      isActive: pathname === registrationRoutes.companyInfo,
      isFinished: false
    }
  };

  return (
    <div className={css.pageWrapper}>
      <Breakpoint minWidth="small" maxWidth="small">
        <BreadCrumbsMobile steps={steps} isExpert={isExpert} />
      </Breakpoint>

      <Breakpoint minWidth="small" maxWidth="medium">
        <RegistrationTitle type={profileType} />
      </Breakpoint>

      {/* Because "Profile type" step requires different styling for mobile, specific class is added */}
      <Segment className={classNames(css.wrapper, steps.profileType.isActive && css.profileType)}>
        <div className={css.menu}>
          <Breakpoint minWidth="large">
            <div className={css.headlineWrapper}>
              <RegistrationTitle type={profileType} />
            </div>
          </Breakpoint>
          <Breakpoint minWidth="medium">
            <BreadCrumbs steps={steps} isExpert={isExpert} />
          </Breakpoint>
        </div>
        <div className={css.step}>{children}</div>
      </Segment>
    </div>
  );
};
