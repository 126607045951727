import { connect } from "formik";
import React from "react";

import { PlansByInterval, SubscriptionLevel } from "triangular/components/SubscriptionLevel/SubscriptionLevel";

interface SubscriptionLevelFieldProps {
  name: string;
  interval: SubscriptionInterval;
  plansByInterval: PlansByInterval;
  customPlanTitle?: string;
}

export const SubscriptionLevelField = connect<SubscriptionLevelFieldProps, any>(
  ({ formik, name, interval, plansByInterval, customPlanTitle }) => (
    <SubscriptionLevel
      value={formik.values[name]}
      onChange={type => formik.setFieldValue(name, type)}
      interval={interval}
      plansByInterval={plansByInterval}
      customPlanTitle={customPlanTitle}
    />
  )
);
