import React from "react";
import { Redirect, Route, Switch } from "react-router";

import { Layout } from "triangular/components/Layout/Layout";
import { Segment } from "triangular/components/Segment/Segment";
import { routes } from "triangular/Routes/routesConfiguration";
import { locationRouteProps } from "triangular/utils/routes/LocationRoute";

import { ChangePassword } from "./ChangePassword/ChangePassword";
import { ForgotPassword } from "./ForgotPassword/ForgotPassword";
import css from "./PasswordRecovery.module.scss";

export const PasswordRecovery: React.FC = () => {
  return (
    <Layout>
      <Segment className={css.segment}>
        <Switch>
          <Route {...locationRouteProps(routes.passwordRecovery.root, ForgotPassword)} />
          <Route {...locationRouteProps(routes.passwordRecovery.changePassword, ChangePassword)} />
          <Route render={() => <Redirect to={routes.home.build()} />} />
        </Switch>
      </Segment>
    </Layout>
  );
};
