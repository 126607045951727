import { connect } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

import { Checkbox } from "triangular/components/Checkbox/Checkbox";

import css from "./SubscriptionIntervalField.module.scss";
import { SubscriptionIntervalLabel } from "./SubscriptionIntervalLabel";

export const SubscriptionIntervalField = connect<
  { intervals: string[] },
  { subscriptionInterval: SubscriptionInterval }
>(({ formik, intervals }) => {
  const name = "subscriptionInterval";
  const { t } = useTranslation();
  const value = formik.values[name];
  const hasYearInterval = intervals.some(eachInterval => eachInterval === "year");
  const hasMonthInterval = intervals.some(eachInterval => eachInterval === "month");

  return (
    <div className={css.wrapper}>
      {intervals.length > 1 && <SubscriptionIntervalLabel />}
      <div className={css.checkboxesWrapper}>
        {hasYearInterval && (
          <Checkbox
            data-testid="billedAnnually"
            className={{
              label: css.checkboxLabel
            }}
            label={t("registration.subscriptionInterval.labelAnnually")}
            checked={value === "year"}
            onChange={() => formik.setFieldValue(name, "year")}
          />
        )}
        {hasMonthInterval && (
          <Checkbox
            data-testid="billedMonthly"
            className={{
              label: css.checkboxLabel
            }}
            label={t("registration.subscriptionInterval.labelMonthly")}
            checked={value === "month"}
            onChange={() => formik.setFieldValue(name, "month")}
          />
        )}
      </div>
    </div>
  );
});
