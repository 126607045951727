import { addDays, addYears } from "date-fns";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { ReadOnlyField } from "triangular/components/form/ReadOnlyField/ReadOnlyField";
import { PageLoader } from "triangular/components/PageLoader/PageLoader";
import { SectionTitle } from "triangular/components/SectionTitle/SectionTitle";
import { useStore } from "triangular/stores/StoreContext";
import { formatDate } from "triangular/utils/dates";

import css from "./SubscriptionInfo.module.scss";

export const SubscriptionInfo = observer(() => {
  const { paymentsStore, userStore, snackbarStore } = useStore();
  const { subscription } = paymentsStore;
  const [isLoadingInfo, setIsLoadingInfo] = useState(true);
  const { t } = useTranslation();
  const fetchInfo = useCallback(async () => {
    setIsLoadingInfo(true);
    try {
      await paymentsStore.fetchSubscription();
    } catch (err) {
      snackbarStore.showGenericError(err);
    }

    setIsLoadingInfo(false);
  }, [paymentsStore, snackbarStore]);

  function printRows(startDate: string, endDate: string) {
    return (
      <>
        <FieldWrapper className="col-12">
          <ReadOnlyField label={t("payments.accountInfo.billingStart")}>{startDate}</ReadOnlyField>
        </FieldWrapper>
        <FieldWrapper className="col-12">
          <ReadOnlyField label={t("payments.accountInfo.subscriptionEnd")}>{endDate}</ReadOnlyField>
        </FieldWrapper>
      </>
    );
  }

  function checkTrial() {
    const currentPeriodStart = formatDate(subscription.currentPeriodStart || "", false);
    const trialEndAt = formatDate(subscription.trialEndAt || "", false);
    const createdAtPlus10DTrial = formatDate(addDays(subscription.currentPeriodStart || "", 10) || "", false);
    const currentPeriodEnd = formatDate(subscription.currentPeriodEnd || "", false);
    const periodEndChangedTrial = formatDate(addYears(subscription.trialEndAt || "", 1) || "", false);

    try {
      if (createdAtPlus10DTrial === trialEndAt) {
        return (
          <FieldWrapper className="col-12">
            <ReadOnlyField label="">{t("payments.accountInfo.paymentValidation")}</ReadOnlyField>
          </FieldWrapper>
        );
      } else if (trialEndAt < formatDate(Date.now(), false)) {
        return printRows(currentPeriodStart, currentPeriodEnd);
      } else if (createdAtPlus10DTrial !== trialEndAt) {
        return printRows(trialEndAt, periodEndChangedTrial);
      }
      return (
        <>
          <p className={css.information}>{t("payments.accountInfo.genericError")}</p>
        </>
      );
    } catch (err) {
      snackbarStore.showGenericError(err);
    }
  }

  function renderCard() {
    return (
      <div>
        <SectionTitle>{t("payments.accountInfo.sectionTitle")}</SectionTitle>

        <FieldsRow key="testing">
          <FieldWrapper className="col-12">
            <ReadOnlyField label={t("payments.accountInfo.accountCreationDate")}>
              {formatDate(userStore.createdAt || "", false)}
            </ReadOnlyField>
          </FieldWrapper>
          {checkTrial()}
        </FieldsRow>
      </div>
    );
  }

  useEffect(() => {
    fetchInfo();
  }, [fetchInfo]);
  return <>{isLoadingInfo ? <PageLoader /> : <FormSection>{renderCard()}</FormSection>}</>;
});
