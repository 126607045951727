import { CancelToken } from "axios";
import * as humps from "humps";
import { SnapshotIn, types } from "mobx-state-tree";

import { AccountAttributes } from "triangular/services/Api/resources/AccountResource";

import { createStore } from "./utils/createStore";

export const registrationStoreInitialState = {
  type: "" as const,
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  agreement: false,
  prefixNumber: "",
  countryCode: "",
  vatNumber: "",
  company: {
    name: "",
    number: "",
    countryCode: ""
  },
  isSubmitted: false
};

const registrationStoreModel = types.model({
  type: types.enumeration(["systemOwner" as const, "materialOwner" as const, "expert" as const, "master" as const, ""]),
  firstName: types.string,
  lastName: types.string,
  email: types.string,
  phoneNumber: types.string,
  agreement: types.boolean,
  prefixNumber: types.string,
  countryCode: types.string,
  vatNumber: types.maybeNull(types.string),
  company: types.model({
    name: types.string,
    number: types.string,
    countryCode: types.string
  }),
  isSubmitted: types.boolean
});

export type RegistrationStoreModel = typeof registrationStoreModel["SnapshotType"];

export const RegistrationStore = createStore(({ api }) =>
  registrationStoreModel
    .actions(self => ({
      setState(snapshot: DeepPartial<SnapshotIn<typeof self>>) {
        Object.assign(self, snapshot);
      }
    }))
    .actions(self => ({
      async createAccount(cancelToken: CancelToken) {
        const {
          type,
          firstName,
          lastName,
          email,
          phoneNumber,
          agreement,
          prefixNumber,
          countryCode,
          company,
          vatNumber
        } = self;

        const requestPayload = {
          accountType: type === "master" ? ("master" as const) : ("regular" as const),
          currentProfileType:
            type === "master" ? ("system_owners" as const) : (`${humps.decamelize(type)}s` as ProfileType),
          firstName,
          lastName,
          email,
          phoneNumber: `${prefixNumber}${phoneNumber}`,
          agreement,
          countryCode,
          company,
          vatNumber
        };

        if (type === "expert") {
          delete requestPayload.company;
        } else {
          delete requestPayload.vatNumber;
        }

        await api.account.create<Omit<AccountAttributes, "currentProfileId">>(requestPayload, {
          cancelToken
        });

        self.setState({ isSubmitted: true });
      },
      cleanUp() {
        self.setState(registrationStoreInitialState);
      }
    }))
    .views(self => ({
      get hasPersonalInfo() {
        const { firstName, lastName, phoneNumber, countryCode } = self;
        const conditions = [firstName.length > 0, lastName.length > 0, phoneNumber.length > 0, countryCode.length > 0];

        return conditions.every(eachCondition => eachCondition);
      }
    }))
);
