import { observer } from "mobx-react-lite";
import React from "react";
import { Redirect } from "react-router-dom";

import { useStore } from "triangular/stores/StoreContext";

import { ConditionalRoute, ConditionalRouteProps } from "./ConditionalRoute";
import { privateRouteFallback } from "./routesConfiguration";

type Props = Omit<ConditionalRouteProps, "condition"> & {
  userType: ProfileType;
};

export const ProfileTypeRoute = observer<Props>(({ userType, ...props }) => {
  const { userStore } = useStore();
  const { isAuthenticated } = userStore;
  const hasCorrectProfileType = userStore.type === userType;
  const shouldRenderRoute = isAuthenticated && hasCorrectProfileType;

  return (
    <ConditionalRoute
      condition={shouldRenderRoute}
      fallback={<Redirect to={privateRouteFallback.build()} />}
      {...props}
    />
  );
});
