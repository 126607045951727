import { useTranslation } from "react-i18next";

import { useStore } from "triangular/stores/StoreContext";

export function useProfileTypeSwitches() {
  const { t } = useTranslation();
  const { userStore, switchProfileType, snackbarStore, appLoadingState } = useStore();

  return userStore.accountType === "master" && userStore.availableProfileTypes
    ? userStore.availableProfileTypes.map(type => ({
        label: t(`userMenuItems.profileTypeSwitches.${type}`),
        onClick: async () => {
          if (appLoadingState === "in_progress") {
            return;
          }

          try {
            await switchProfileType(type);
            snackbarStore.addSnackbar({
              type: "success",
              message: t("userMenuItems.profileTypeSwitches.successfulSwitch")
            });
          } catch (err) {
            snackbarStore.showGenericError(err);
          }
        },
        isCurrent: type === userStore.type
      }))
    : null;
}
