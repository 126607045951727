import React from "react";
import { useTranslation } from "react-i18next";

import { useStore } from "triangular/stores/StoreContext";

import { IndustryPictogram } from "../IndustryPictogram/IndustryPictogram";

export const IndustryFilterBadgeLabel = ({ industryId }: { industryId: string }) => {
  const { t } = useTranslation();
  const { commonsStore } = useStore();
  const { industries } = commonsStore.getSettings();

  const industry = industries.find(eachIndustry => eachIndustry.id === industryId);

  if (!industry) {
    return null;
  }

  return (
    <>
      <IndustryPictogram industry={industry.name} withBorder={true} />
      {t(`systemSettings.targetIndustryOptions.${industry.name}`)}
    </>
  );
};
