import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../Button/Button";
import { Modal, ModalProps } from "../Modal/Modal";
import { Typography } from "../Typography/Typography";

interface InfoModalProps extends ModalProps {
  buttonContent?: ReactNode;
  titleContent: ReactNode;
  messageContent: ReactNode;
  onCloseAlt?: () => void;
}

export const InfoModal: React.FC<InfoModalProps> = ({
  onClose,
  onCloseAlt,
  buttonContent,
  titleContent,
  messageContent,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Modal onClose={onClose} {...props}>
      <Typography size="large" component="h2">
        {titleContent}
      </Typography>
      <Typography size="big" verticalSpacing="large" component="p">
        {messageContent}
      </Typography>
      <div>
        <Button onClick={onCloseAlt || onClose} data-testid="infoModalButton">
          {buttonContent || t("infoModal.ok")}
        </Button>
      </div>
    </Modal>
  );
};
