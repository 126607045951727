import React from "react";

import { SearchSelectorProps } from "triangular/components/SearchSelector/SearchSelector";
import { ReactComponent as LogoImage } from "triangular/static/images/logo-triangular-home-search.svg";

export interface LogoSmallProps {
  className?: string;
}

export const LogoSmall: React.FC<SearchSelectorProps> = ({ className }) => <LogoImage className={className} />;
