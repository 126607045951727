import { TFunction } from "i18next";
import { uniqueId } from "lodash";

import { SettingItem } from "triangular/services/Api/Resource/utils/types";

export const namesToSelectOptions = (t: TFunction) => (key?: string, values?: string[]) =>
  (values || []).map(name => ({
    key: uniqueId(name),
    label: t(`expertSettings.${key}.${name}`),
    value: name
  }));

export const settingItemsToSelectOptions = (t: TFunction, valueOf: "id" | "name" = "id") => (
  key?: string,
  settingItems?: SettingItem[]
) =>
  (settingItems || []).map(({ id, name }) => ({
    key: id,
    label: t(`expertSettings.${key}.${name}`),
    value: valueOf === "id" ? id : name
  }));

export const settingItemsToFilterOptions = (t: TFunction) => (key?: string, settingItems?: SettingItem[]) =>
  (settingItems || []).map(({ id, name }) => ({
    key: id,
    label: t(`expertSettings.${key}.${name}`),
    value: { id, name }
  }));
