import * as React from "react";
import { useTranslation } from "react-i18next";

import { PageTitle } from "../PageTitle/PageTitle";
import { TriLoader } from "../TriLoader/TriLoader";

import css from "./PageLoader.module.scss";

export const PageLoader: React.FC<{ loadingState?: "failed" | "in_progress" }> = ({ loadingState }) => {
  const { t } = useTranslation();

  if (loadingState === "failed") {
    return <PageTitle title={t("errors.networkError")} />;
  }

  return (
    <div className={css.container}>
      <TriLoader className={{ spinner: css.loader }} size={60} />
    </div>
  );
};
