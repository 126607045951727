import { TFunction } from "i18next";
import * as Yup from "yup";

export const numberValidator = (t: TFunction) => (minValue: number, maxValue: number = Number.MAX_SAFE_INTEGER) =>
  Yup.number()
    .transform((__, originalValue) => {
      if (originalValue === "") {
        return null;
      }

      if (typeof originalValue !== "string") {
        return originalValue;
      }

      return Number(originalValue.replace(",", "."));
    })
    .nullable()
    .min(minValue, t("validation.minValue", { minValue }))
    .max(maxValue, t("validation.maxValue", { maxValue }))
    .typeError(t("validation.mustBeNumber"));

export const percentageRange = [0, 99.999] as [0, 99.999];
