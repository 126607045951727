import React from "react";

import { Search as BaseSearch } from "triangular/components/Search/Search";

import { useSystemsPaginationContext } from "./utils/pagination";

export const Search: React.FC = () => {
  const { buildUrl } = useSystemsPaginationContext();
  return <BaseSearch buildUrl={buildUrl} />;
};
