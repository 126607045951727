import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import css from "./TriLink.module.scss";

export interface TriLinkProps {
  className?: string;
  external?: boolean;
  targetBlank?: boolean;
  to: string;
  testId?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

/**
 * Wrapper for links that includes both: `<a>` and `<Link>` components.
 * If you need to forward an user to the external resource set `external={true}`.
 * External links will open new tab by default, set `targetBlank={false}` if you don't need this.
 */
export const TriLink: React.FC<TriLinkProps> = ({
  children,
  external = false,
  targetBlank = true,
  to,
  className,
  testId,
  onClick
}) => {
  const classes = classNames(className, css.triLink);

  if (external) {
    return (
      <a
        className={classes}
        href={to}
        rel="noreferrer noopener"
        {...(targetBlank ? { target: "_blank" } : {})}
        data-testid={testId}
        onClick={onClick}
      >
        {children}
      </a>
    );
  }

  return (
    <Link className={classes} to={to} data-testid={testId} onClick={onClick}>
      {children}
    </Link>
  );
};
