import difference from "lodash/difference";
import { RouteComponentProps } from "react-router";
import * as yup from "yup";

import { getObjectFields, pick } from "triangular/utils/yup";

import { buildMethod } from "./common";
import { Path } from "./Path";
import { Search } from "./Search";

type MatchParams<Params extends {}> = { [K in keyof Params]?: string };

export class Location<Params extends {} = {}> {
  private readonly path: Path<Partial<Params>>;
  private readonly search: Search<Partial<Params>>;

  get pattern() {
    return this.path.pattern;
  }

  constructor(pattern: string, schema: yup.ObjectSchema<Params> = yup.object()) {
    this.path = new Path<Partial<Params>>(pattern, schema);

    const paramNames = Object.keys(getObjectFields(schema)) as Array<keyof Params>;
    const searchParamNames = difference(paramNames, this.path.paramNames);
    this.search = new Search<Partial<Params>>(pick(schema, ...searchParamNames));
  }

  // tslint:disable-next-line: member-ordering
  build = buildMethod((params: UndefinedToOptional<Params>) => {
    const [path, search] = combineErrors(
      () => this.path.build(params),
      () => this.search.build(params)
    );
    return search ? `${path}?${search}` : path;
  });

  // only used for testing to simulate react-router's logic which produces
  // match.params and location.search
  match(url: string): Params | undefined {
    const [path, search = ""] = url.split("?", 2);

    let pathParams: Partial<Params> | undefined;
    let pathError: yup.ValidationError | undefined;
    try {
      pathParams = this.path.match(path);

      if (!pathParams) {
        return undefined;
      }
    } catch (error) {
      pathError = error;
    }

    const [, searchParams] = combineErrors(
      () => {
        if (pathError) {
          throw pathError;
        }
      },
      () => this.search.match(search)
    );

    return { ...pathParams, ...searchParams } as Params;
  }

  matchProps(routeProps: RouteComponentProps<MatchParams<Params>>): Params {
    const rawMatchParams = routeProps.match.params;
    const search = routeProps.location.search.replace(/^\?/, "");

    const [pathParams, searchParams] = combineErrors(
      () => this.path.matchParams(rawMatchParams),
      () => this.search.match(search)
    );

    return { ...pathParams, ...searchParams } as Params;
  }

  nested<NestedParams extends {} = {}>(
    pattern: string,
    schema: yup.ObjectSchema<NestedParams> = yup.object()
  ): Location<Params & NestedParams> {
    return new Location(
      this.pattern + pattern,
      this.path.schema.concat(this.search.schema).concat(schema) as yup.ObjectSchema<Params & NestedParams>
    );
  }
}

function combineErrors<Blocks extends Array<() => any>>(...blocks: Blocks): ReturnTypes<Blocks> {
  const values = [] as any[];
  const errors = [] as yup.ValidationError[];

  blocks.forEach(block => {
    try {
      values.push(block());
    } catch (error) {
      errors.push(error);
    }
  });

  if (errors.length) {
    const { value, path, type } = errors[0];
    throw new yup.ValidationError(errors as any, value, path, type);
  }

  return values as ReturnTypes<Blocks>;
}
