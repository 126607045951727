import React from "react";
import { useTranslation } from "react-i18next";

import { ItemStatus, ItemStatusProps } from "triangular/components/ItemStatus/ItemStatus";
import { SystemModelSnapshot } from "triangular/stores/SystemsStore/systemModel";

export const SystemState: React.FC<{
  systemState: SystemModelSnapshot["state"];
  isMySystem: boolean;
  isSystemStateVisible: boolean;
}> = ({ systemState, isMySystem, isSystemStateVisible }) => {
  const { t } = useTranslation();
  const possibleStates = {
    draft: { message: t("mySystemsList.draft") },
    submitted: { message: t("mySystemsList.submitted") },
    published: { message: t("mySystemsList.published"), variant: "greyishBrown" as const },
    booked: { message: t("mySystemsList.booked"), variant: "mint" as const }
  } as { [Key: string]: { message: string; variant?: ItemStatusProps["variant"] } };

  const currentState = possibleStates[systemState as keyof typeof possibleStates];

  const shouldShowStatus = (systemState === "booked" && !isMySystem) || (isMySystem && isSystemStateVisible);

  if (!shouldShowStatus || !currentState) {
    return null;
  }

  return <ItemStatus variant={currentState.variant}>{currentState.message}</ItemStatus>;
};
