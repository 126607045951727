import bytes from "bytes";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { Button } from "triangular/components/Button/Button";
import { AbstractField } from "triangular/components/form/AbstractField/AbstractField";
import { AbstractFieldProps } from "triangular/components/form/AbstractField/AbstractField";
import { i18n } from "triangular/i18next/i18next";
import { SnackbarStoreType } from "triangular/stores/SnackbarStore";
import { useStore } from "triangular/stores/StoreContext";
import { toBase64 } from "triangular/utils/browser";

import { FileListItem } from "../FilesList/FilesList";

import css from "./FilesField.module.scss";

export interface FileToUpload {
  filename: string;
  data: string;
}

export interface FileFieldProps
  extends Omit<AbstractFieldProps, "component" | "render" | "children" | "onChange" | "className" | "type"> {
  className?: {
    wrapper?: string;
    label?: string;
    input?: string;
    inputContainer?: string;
    errorContainer?: string;
    errorMessage?: string;
    dropZone?: string;
    button?: string;
  };
  "data-testid"?: string;
  maxSizeMb: number;
  onAddFile(filesToUpload: FileToUpload[], addFiles: (files: FileListItem[] | null) => void): void;
}

export function createFileUploader({
  snackbarStore,
  maxSizeMb,
  t,
  accept
}: {
  snackbarStore: SnackbarStoreType;
  maxSizeMb: number;
  t: typeof i18n.t;
  accept?: string;
}) {
  return async (nativeFileList: FileList) => {
    const processedFiles = await Promise.all(
      Array.from(nativeFileList).map(async eachFile => {
        const sizeInMb = Number(bytes(eachFile.size, { unit: "mb", unitSeparator: "" }).replace("mb", ""));
        const isSizeTooBig = sizeInMb > maxSizeMb;
        const extension = eachFile.name.split(".").slice(-1)[0] || "";
        const acceptedExtensions = accept ? accept.split(",") : null;
        const isInvalidExtension = acceptedExtensions
          ? !acceptedExtensions
              .map(eachExtension => eachExtension.replace(".", ""))
              .some(eachExtension => extension === eachExtension)
          : false;

        if (isSizeTooBig) {
          snackbarStore.addSnackbar({
            type: "error",
            message: t("filesField.fileTooBig", { maxSizeMb, filename: eachFile.name })
          });

          return null;
        }

        if (isInvalidExtension) {
          snackbarStore.addSnackbar({
            type: "error",
            message: t("filesField.invalidExtension", {
              filename: eachFile.name
            })
          });

          return null;
        }

        return {
          filename: eachFile.name,
          data: await toBase64(eachFile)
        };
      }, [])
    );

    return processedFiles.filter((eachFile): eachFile is FileToUpload => eachFile !== null);
  };
}

export const FilesField: React.FC<FileFieldProps> = observer(
  ({
    required,
    name,
    label,
    disabled,
    className = {},
    accept,
    onAddFile,
    "data-testid": testId,
    maxSizeMb,
    ...props
  }) => {
    const { t } = useTranslation();
    const { getInputProps, getRootProps, isDragActive } = useDropzone();
    const { wrapper, button, dropZone, ...restClassName } = className;
    const { snackbarStore } = useStore();
    const createFilesToUpload = createFileUploader({ snackbarStore, t, maxSizeMb, accept });

    return (
      <AbstractField
        required={required}
        name={name}
        label={label}
        disabled={disabled}
        className={{
          wrapper: classNames(css.inputWrapper, wrapper),
          errorContainer: css.errorContainer,
          ...restClassName
        }}
        render={({ field, form }) => {
          const { onDrop, ...rootProps } = getRootProps();

          const handleAddFiles = (fileEntities: FileListItem[] | null) => {
            if (fileEntities) {
              const prevFiles = field.value || [];
              form.setFieldValue(name, fileEntities.concat(prevFiles));
            }
          };

          const hasError = !!form.errors[field.name] && !!form.touched[field.name];

          return (
            <div
              className={classNames(css.dropZone, dropZone, { [css.dropZoneErrored]: hasError })}
              {...rootProps}
              onDrop={async event => {
                event.persist();
                const { files } = event.dataTransfer;

                await onAddFile(await createFilesToUpload(files), handleAddFiles);
                if (onDrop && !disabled) {
                  onDrop(event);
                }
              }}
            >
              <Button
                className={button}
                transparent={!isDragActive}
                variant="dark"
                narrow={true}
                type="button"
                disabled={disabled}
              >
                {t("uploadFiles")}
              </Button>
              <input
                {...getInputProps({
                  name,
                  onChange: async event => {
                    const { files } = event.target;

                    if (files) {
                      onAddFile(await createFilesToUpload(files), handleAddFiles);
                    }
                  },
                  accept
                })}
                data-testid={testId}
              />
            </div>
          );
        }}
        {...props}
      />
    );
  }
);
