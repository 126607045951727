import { Elements as StripeElements } from "@stripe/react-stripe-js";
import { Stripe, StripeConstructorOptions } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import React from "react";

export const createStripe = (language: string) => {
  if (!process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
    throw new Error("Stripe public key not provided!");
  }
  return loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
    locale: language as StripeConstructorOptions["locale"]
  });
};

const stripeOptions = {
  fonts: [
    {
      cssSrc:
        "https://fonts.googleapis.com/css?family=" +
        "Rubik:400,400i,500,500i,700,700i|Karla:400,400i,700,700i:&display=swap"
    }
  ]
};

export const StripeProvider: React.FC<{ stripe: Stripe | null }> = ({ stripe, children }) => (
  <StripeElements stripe={stripe} options={stripeOptions}>
    {children}
  </StripeElements>
);
