import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { useStore } from "triangular/stores/StoreContext";

import { StripeForm } from "./StripeForm/StripeForm";
import css from "./StripeForm/StripeForm.module.scss";
import { PaymentMethodFormProps } from "./types";

export const StripeInvoiceForm: React.FC<PaymentMethodFormProps> = ({ onCancel, handlePayByInvoice }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { paymentsStore } = useStore();
  const { snackbarStore } = useStore();

  return (
    <StripeForm
      handlePayByInvoice={handlePayByInvoice}
      onSubmit={async () => {
        setIsLoading(true);
        try {
          await paymentsStore.setupManualPayment();
        } catch (err) {
          snackbarStore.showGenericError(err);
        }
        setIsLoading(false);
      }}
      render={() => (
        <FieldsRow>
          <span className={css.invoiceMessage}>{t("subscriptionSetup.payByInvoiceModal.message")}</span>
        </FieldsRow>
      )}
      isLoading={isLoading}
      onCancel={onCancel}
    />
  );
};
