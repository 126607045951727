import React, { FC, ReactNode } from "react";
import { Route, RouteProps } from "react-router";

import { renderComponent, useAppTitle } from "triangular/utils/components";

export interface ConditionalRouteProps extends RouteProps {
  condition: boolean;
  title?: string;
  fallback?: ReactNode;
  fallbackRender?: RouteProps["render"];
  fallbackComponent?: RouteProps["component"];
}

interface RouteWrapperProps extends RouteProps {
  title?: string;
}

export const RouteWrapper: FC<RouteWrapperProps> = ({ title, ...props }) => {
  useAppTitle(title);
  return <Route {...props} />;
};

export const ConditionalRoute: FC<ConditionalRouteProps> = ({
  condition,
  fallback,
  fallbackComponent,
  fallbackRender,
  component,
  render,
  children,
  ...routeProps
}) => (
  <RouteWrapper
    {...routeProps}
    render={props =>
      condition
        ? renderComponent(props, {
            render,
            component,
            children
          })
        : renderComponent(props, {
            render: fallbackRender,
            component: fallbackComponent,
            children: fallback
          })
    }
  />
);
