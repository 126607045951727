import get from "lodash/get";
import isNil from "lodash/isNil";
import { useCallback, useEffect, useState } from "react";

import { ParticleSize } from "triangular/services/Api/resources/MaterialResource";
import { useStore } from "triangular/stores/StoreContext";
import { createNumberOrNull } from "triangular/utils/api";

import { MaterialSchema } from "./materialSchema";
import { useMaterialForm } from "./useMaterialForm";

export const useMaterialData = ({
  materialId,
  addingNewMaterial = false,
  systemToRequestId,
  initialIsLoading = false
}: {
  initialIsLoading?: boolean;
  materialId?: string;
  addingNewMaterial?: boolean;
  systemToRequestId?: string;
}) => {
  const [isLoading, setIsLoading] = useState(initialIsLoading || !!materialId);
  const [material, setMaterial] = useState<MaterialSchema | null>(null);
  const [isSystemForRequestValid, setIsSystemIdValid] = useState(false);

  const { materialsStore, snackbarStore, systemsStore } = useStore();
  const { handleSubmit, handleSave, personalAndCompanyData, handleSavedModalClose } = useMaterialForm({
    derivedMaterialId: materialId,
    derivedSystemId: systemToRequestId,
    isCopy: addingNewMaterial
  });

  const checkSystemIdValidity = useCallback(async () => {
    if (systemToRequestId) {
      await systemsStore.getSystem(systemToRequestId, { isSystemOwner: false });
    }

    setIsSystemIdValid(true);
  }, [systemToRequestId, systemsStore]);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    try {
      await checkSystemIdValidity();

      if (materialId) {
        const {
          id: materialDerivedId,
          type,
          links,
          processCategory,
          systemCategory,
          systemType,
          designType,
          feedParticleSize,
          desiredParticleSize,
          materialAmount,
          density,
          bulkDensity,
          humidity,
          materialSafetyFiles,
          materialTechFiles,
          deadline,
          industry,
          materialCategory,
          ...materialData
        } = await materialsStore.getMaterial(materialId, {
          include: {
            processCategory: true,
            designType: true,
            systemCategory: true,
            systemType: true,
            materialSafetyFiles: true,
            materialTechFiles: true,
            industry: true,
            materialCategory: true
          }
        });

        const formatParticleSize = (particleSize: ParticleSize<string>) =>
          !isNil(particleSize)
            ? particleSize.map(eachSize => ({
                size: createNumberOrNull(eachSize && eachSize.size),
                percent: createNumberOrNull(eachSize && eachSize.percent)
              }))
            : null;

        setMaterial({
          ...personalAndCompanyData,
          ...materialData,
          deadline: deadline ? deadline.split("T")[0] : null,
          processCategory: get(processCategory, "id", null),
          systemCategory: get(systemCategory, "id", null),
          systemType: get(systemType, "id", null),
          designType: get(designType, "id", null),
          feedParticleSize: formatParticleSize(feedParticleSize),
          desiredParticleSize: formatParticleSize(desiredParticleSize),
          materialAmount: createNumberOrNull(materialAmount),
          density: createNumberOrNull(density),
          bulkDensity: createNumberOrNull(bulkDensity),
          humidity: createNumberOrNull(humidity),
          abrasiveness: materialData.abrasiveness,
          corrosiveness: materialData.corrosiveness,
          explosiveness: materialData.explosiveness,
          hazardousness: materialData.hazardousness,
          materialSafetyFiles: materialSafetyFiles
            ? materialSafetyFiles.map(({ id, filename, originalUrl }) => ({
                id,
                filename: filename || "",
                originalUrl
              }))
            : [],
          materialTechFiles: materialTechFiles
            ? materialTechFiles.map(({ id, filename, originalUrl }) => ({ id, filename: filename || "", originalUrl }))
            : null,
          targetIndustry: get(industry, "id", null),
          materialCategory: get(materialCategory, "id", null)
        });
      } else {
        setMaterial(null);
      }
    } catch (e) {
      snackbarStore.showGenericError(e);
    } finally {
      setIsLoading(false);
    }
  }, [checkSystemIdValidity, materialId, materialsStore, personalAndCompanyData, snackbarStore]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return {
    material,
    isLoading,
    handleSubmit,
    handleSave,
    handleSavedModalClose,
    personalAndCompanyData,
    isSystemForRequestValid
  };
};
