import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as ChemicalsSvg } from "triangular/static/images/industry-chemicals.svg";
import { ReactComponent as FoodSvg } from "triangular/static/images/industry-food.svg";
import { ReactComponent as MineralsSvg } from "triangular/static/images/industry-minerals.svg";
import { ReactComponent as PharmaSvg } from "triangular/static/images/industry-pharma.svg";
import { ReactComponent as RecyclingSvg } from "triangular/static/images/industry-recycling.svg";

import { WithTooltip } from "../WithTooltip/WithTooltip";

import css from "./IndustryPictogram.module.scss";

const pictograms = {
  no_restrictions: null,
  chemicals: ChemicalsSvg,
  minerals: MineralsSvg,
  food: FoodSvg,
  pharma: PharmaSvg,
  recycling: RecyclingSvg
};

export const IndustryPictogram = ({ industry, withBorder = false }: { industry: string; withBorder?: boolean }) => {
  const { t } = useTranslation();
  const Pictogram = pictograms[industry as keyof typeof pictograms] || null;

  if (!Pictogram) {
    return null;
  }

  return (
    <WithTooltip tooltipMessage={t(`systemSettings.targetIndustryOptions.${industry}`)} className={css.withTooltip}>
      <Pictogram
        className={classNames({ [css.withBorder]: withBorder })}
        onClick={e => {
          // The only way to show tooltip on the mobile device is clicking.
          // So we ensure we won't propagate click event up the tree.
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    </WithTooltip>
  );
};
