import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { DisconnectedTextField } from "triangular/components/form/TextField";
import { IconLink } from "triangular/components/IconLink/IconLink";

import css from "./Search.module.scss";

export const Search = ({ buildUrl }: { buildUrl(callback: () => { search: string }): string }) => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const [searchPhrase, setSearchPhrase] = useState("");
  const urlWithSearch = buildUrl(() => ({ search: searchPhrase }));

  return (
    <div className={css.wrapper}>
      <DisconnectedTextField
        name="search"
        value={searchPhrase}
        placeholder={t("systems.search")}
        isConnectedWithFormik={false}
        className={{
          wrapper: css.textFieldWrapper
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          setSearchPhrase(value);
        }}
        onKeyPress={e => {
          if (e.key === "Enter") {
            e.preventDefault();
            history.push(urlWithSearch);
            setSearchPhrase("");
          }
        }}
        data-testid="searchInput"
      />
      <IconLink
        className={css.iconLink}
        icon="search"
        to={urlWithSearch}
        onClick={() => setSearchPhrase("")}
        testId="searchIcon"
      />
    </div>
  );
};
