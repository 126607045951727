import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { IndustriesField } from "../IndustriesField";

export const TargetIndustriesField = observer(() => {
  const { t } = useTranslation();
  return <IndustriesField label={t("addSystem.targetIndustries")} name="targetIndustries" />;
});
