import { types } from "mobx-state-tree";

import { fileModel } from "../utils/fileModel";
import { settingItemModel } from "../utils/settingItemModel";

import { certificateModel } from "./certificateModel";
import { experienceModel } from "./experienceModel";
import { languageModel } from "./languageModel";

export const expertModel = types.model("Expert", {
  id: types.string,
  state: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  showcaseTitle: types.maybeNull(types.string),
  expertCompany: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  reach: types.maybeNull(types.string),
  countryCodeBlacklist: types.maybeNull(types.array(types.string)),
  processCategories: types.maybeNull(types.array(types.string)),
  industries: types.maybeNull(types.array(settingItemModel)),
  specialties: types.maybeNull(types.array(settingItemModel)),
  materialCategories: types.maybeNull(types.array(settingItemModel)),
  languagesKnowledge: types.maybeNull(types.array(languageModel)),
  avatar: types.maybeNull(fileModel),
  experiences: types.maybeNull(types.array(experienceModel)),
  expertiseDescription: types.maybeNull(types.string),
  certificates: types.maybeNull(types.array(certificateModel))
});

export type ExpertModelSnapshot = typeof expertModel["SnapshotType"];
