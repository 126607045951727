import * as _ from "lodash";
import * as yup from "yup";

export function getObjectFields<T extends {}>(schema: yup.ObjectSchema<T>) {
  interface UndocumentedObjectSchema extends yup.ObjectSchema<T> {
    fields: yup.ObjectSchemaDefinition<T>;
  }

  const undocumentedSchema = schema as UndocumentedObjectSchema;
  return undocumentedSchema.fields;
}

export function pick<T extends {}, Keys extends Array<keyof T>>(
  schema: yup.ObjectSchema<T>,
  ...keys: Keys
): yup.ObjectSchema<Pick<T, Keys[number]>> {
  const fields = getObjectFields(schema);
  return yup.object(_.pick(fields, keys));
}
