import React from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { LabeledSelect } from "triangular/components/LabeledSelect/LabeledSelect";
import { useStore } from "triangular/stores/StoreContext";
import { settingItemsToSelectOptions } from "triangular/utils/systems";

import { useSystemsPaginationContext } from "../utils/pagination";
import { updateProcessCategoryId } from "../utils/updates";

export const ProcessCategory: React.FC = () => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const { paginationQuery, buildUrl } = useSystemsPaginationContext();
  const { commonsStore } = useStore();

  const processCategoryOptions = commonsStore.getSettings().processes;

  const options = settingItemsToSelectOptions(t)("processCategoryOptions", processCategoryOptions);

  const handleChange = (selectedValue: string | string[]) =>
    history.push(buildUrl(() => updateProcessCategoryId(selectedValue as string)));

  return (
    <LabeledSelect
      label={t("systems.processCategory")}
      value={paginationQuery.processCategoryId || ""}
      options={options}
      onChange={handleChange}
      data-testid="processCategoryFilter"
    />
  );
};
