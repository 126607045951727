import { types } from "mobx-state-tree";

import { settingItemModel } from "../utils/settingItemModel";

export const expertSettingsModel = types.model("ExpertSettings", {
  reaches: types.array(types.string),
  languageLevels: types.array(types.string),
  languages: types.array(types.string),
  specialties: types.array(settingItemModel)
});
