import { types } from "mobx-state-tree";

export const systemSettingsModel = types.model("SystemSettings", {
  contactMaterials: types.array(types.string),
  nonContactMaterials: types.array(types.string),
  abrasiveness: types.array(types.string),
  explosiveness: types.array(types.string),
  installedPowerKw: types.array(types.string),
  materialCategories: types.array(types.string),
  targetIndustries: types.array(types.string)
});
