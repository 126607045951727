import React from "react";
import { Link } from "react-router-dom";
import useRouter from "use-react-router";

import { RouteParameters, routes } from "triangular/Routes/routesConfiguration";
import { SystemModelSnapshot } from "triangular/stores/SystemsStore/systemModel";

type MatchParams = Pick<RouteParameters<typeof routes.systemDetailsForRequest>, "materialId">;

export const SystemDetailsLink: React.FC<{
  className?: string;
  isMySystem: boolean;
  systemId: SystemModelSnapshot["id"];
  testId?: string;
  origin?: string;
}> = ({ children, className, isMySystem, systemId, testId, origin }) => {
  const { match } = useRouter<MatchParams>();

  const materialIdForSystemRequest = match.params.materialId;

  const publicSystemDetailsRoute = materialIdForSystemRequest
    ? routes.systemDetailsForRequest.build({ id: systemId, materialId: materialIdForSystemRequest })
    : routes.systemDetails.build({ id: systemId });

  const systemDetailsRoute = isMySystem ? routes.mySystemDetails.build({ id: systemId }) : publicSystemDetailsRoute;

  const originQueryParam = origin && !isMySystem ? "?origin=" + origin : "";
  return (
    <Link to={systemDetailsRoute + originQueryParam} className={className} data-testid={testId}>
      {children}
    </Link>
  );
};
