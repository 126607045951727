import { observer } from "mobx-react-lite";
import React from "react";
import { Redirect } from "react-router-dom";

import { useStore } from "triangular/stores/StoreContext";

import { ConditionalRoute, ConditionalRouteProps } from "./ConditionalRoute";
import { GuestRouteFallback } from "./routesConfiguration";

export const GuestRoute = observer<Omit<ConditionalRouteProps, "condition">>(props => {
  const { userStore } = useStore();

  return (
    <ConditionalRoute
      condition={!userStore.isAuthenticated}
      fallback={<Redirect to={GuestRouteFallback.build()} />}
      {...props}
    />
  );
});
