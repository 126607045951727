import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";

import { useModal } from "triangular/components/Modal/Modal";
import { Typography } from "triangular/components/Typography/Typography";
import { routes } from "triangular/Routes/routesConfiguration";
import { useStore } from "triangular/stores/StoreContext";

import css from "./ForgotPassword.module.scss";
import { ForgotPasswordForm, ForgotPasswordFormValues } from "./ForgotPasswordForm/ForgotPasswordForm";
import { ForgotPasswordModal } from "./ForgotPasswordModal/ForgotPasswordModal";

export const ForgotPassword: React.FC<RouteComponentProps> = props => {
  const { t } = useTranslation();
  const { userStore, snackbarStore } = useStore();

  const modal = useModal();
  const [email, setEmail] = useState("");

  const handleSubmit = async ({ email: userEmail }: ForgotPasswordFormValues) => {
    try {
      await userStore.forgotPassword(userEmail);
      modal.open();
      setEmail(userEmail);
    } catch (e) {
      snackbarStore.addSnackbar({ type: "error", message: t("errors.generic") });
    }
  };

  const handleCancel = (e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }

    props.history.push(routes.login.build());
  };

  return (
    <>
      <Typography component="h2" size="large" bold={true}>
        {t("passwordRecovery.forgotPasswordTitle")}
      </Typography>

      <p className={css.description}>{t("passwordRecovery.forgotPasswordDescription")}</p>

      <div className={css.formWrapper}>
        <ForgotPasswordForm onSubmit={handleSubmit} onCancel={handleCancel} />
      </div>
      <ForgotPasswordModal isOpen={modal.isOpen} onClose={handleCancel} email={email} />
    </>
  );
};
