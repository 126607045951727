import noop from "lodash/noop";
import { observer } from "mobx-react-lite";
import React from "react";
import { useMediaQuery } from "react-responsive";

import { Carousel } from "triangular/components/Carousel/Carousel";
import { FormSection } from "triangular/components/form/FormSection/FormSection";

import css from "./SubscriptionLevel.module.scss";
import { SubscriptionLevelCard } from "./SubscriptionLevelCard/SubscriptionLevelCard";

export type PlansByInterval = Record<
  string,
  Array<{
    level: SubscriptionLevel;
    pricePerMonth: number;
    pricePerYear: number;
    discount: {
      pricePerMonth: number;
      pricePerYear: number;
      percent: number;
    } | null;
  }>
>;

export interface SubscriptionLevelProps {
  value: SubscriptionLevel;
  onChange?: (value: SubscriptionLevel) => void;
  interval: SubscriptionInterval;
  plansByInterval: PlansByInterval;
  customPlanTitle?: string;
}

const SubscriptionLevelCards: React.FC<{ items: React.ReactElement[]; startIndex: number }> = ({
  items,
  startIndex
}) => {
  const isSmallBreakpoint = useMediaQuery({ maxWidth: 900 });

  if (isSmallBreakpoint) {
    return <Carousel startIndex={startIndex} items={items} />;
  }

  return <>{items}</>;
};

export const SubscriptionLevel: React.FC<SubscriptionLevelProps> = observer(
  ({ value, onChange = noop, interval, plansByInterval, customPlanTitle }) => {
    const currentIntervalPlans = plansByInterval[interval];

    if (!currentIntervalPlans) {
      // eslint-disable-next-line no-console
      console.info(`No plans available for the "${interval}" interval.`);
      return null;
    }

    const plans = currentIntervalPlans.sort((a, b) => Number(a.pricePerMonth) - Number(b.pricePerMonth));
    const currentPlan = plans.map(({ level }, index) => ({ level, index })).find(({ level }) => level === value);

    return (
      <FormSection className={css.formSection}>
        <div className={css.wrapper}>
          <SubscriptionLevelCards
            startIndex={currentPlan ? currentPlan.index : 0}
            items={plans.map(({ level, pricePerMonth, pricePerYear, discount }) => (
              <SubscriptionLevelCard
                key={level}
                isCurrent={value === level}
                level={level}
                pricePerMonth={pricePerMonth}
                pricePerYear={pricePerYear}
                discount={discount}
                onClick={() => onChange(level)}
                interval={interval}
                isSingle={plans.length === 1}
                customPlanTitle={customPlanTitle}
              />
            ))}
          />
        </div>
      </FormSection>
    );
  }
);
