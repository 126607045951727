import React from "react";

import css from "./ReadOnlyField.module.scss";

interface Props {
  label: string;
}

export const ReadOnlyField: React.FC<Props> = ({ children, label }) => {
  return (
    <div>
      <div className={css.labelWrapper}>
        <div className={css.label}>{label}</div>
      </div>
      <div className={css.input}>{children}</div>
    </div>
  );
};
