import React from "react";

import { SystemModelSnapshot } from "triangular/stores/SystemsStore/systemModel";

import { MaterialBadges } from "./MaterialBadges";

export const MaterialWhitelist: React.FC<{ materialsWhiteList: SystemModelSnapshot["materialsWhitelist"] }> = ({
  materialsWhiteList
}) => {
  const materials = materialsWhiteList
    ? materialsWhiteList
        .map((materialName, id) => ({
          id,
          name: materialName
        }))
        .slice(0, 3)
    : [];

  return <MaterialBadges materials={materials} />;
};
