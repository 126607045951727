import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "triangular/components/Button/Button";
import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { Modal, useModal } from "triangular/components/Modal/Modal";

import css from "./AdvancedFilters.module.scss";

export const AdvancedFilters = ({ fields }: { fields: React.ReactNode[] }) => {
  const { isOpen, close, open } = useModal();
  const { t } = useTranslation();

  return (
    <>
      <div className={css.openButtonWrapper}>
        <Button onClick={open} transparent={true}>
          {t("search.moreFilters")}
        </Button>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={close}
        className={{
          modal: css.modal,
          overlay: css.modalOverlay
        }}
        hasCloseIcon={false}
      >
        <FormSection className={css.modalContent}>
          <FieldsRow>
            {fields.map((eachField, i) => (
              <FieldWrapper key={i}>{eachField}</FieldWrapper>
            ))}
          </FieldsRow>
          <FieldsRow className={css.buttonSection}>
            <FieldWrapper>
              <Button onClick={close} transparent={true}>
                {t("search.fewerFilters")}
              </Button>
            </FieldWrapper>
          </FieldsRow>
        </FormSection>
      </Modal>
    </>
  );
};
