import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import css from "./ParticleSize.module.scss";

export interface ParticleSizeProps {
  percent?: number | null;
  size?: number | null;
  className?: string;
}

export const ParticleSize: React.FC<ParticleSizeProps> = ({ percent, size, className }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(css.wrapper, className)}>
      <span className={css.value}>{percent ? percent : "-"}</span>
      <span className={css.separator}> {"% <"} </span>
      <span className={css.value}>{size ? size : "-"}</span>
      <span className={css.unit}>{t("particleSize.unit")}</span>
    </div>
  );
};
