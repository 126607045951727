import React from "react";

import { Typography } from "triangular/components/Typography/Typography";

import css from "./FormSectionHeader.module.scss";

const smallHeaderProps = {
  component: "h3" as "h3",
  size: "medium" as "medium",
  color: "black" as "black",
  className: css.smallHeader
};

const bigHeaderProps = {
  component: "h3" as "h3",
  size: "big" as "big",
  color: "mint" as "mint"
};

interface FormSectionHeaderProps {
  small?: boolean;
  className?: string;
}

export const FormSectionHeader: React.FC<FormSectionHeaderProps> = ({ children, small, className }) => {
  const props = small ? smallHeaderProps : bigHeaderProps;
  return (
    <Typography {...props} upperCase={true} bold={true} className={className}>
      {children}
    </Typography>
  );
};
