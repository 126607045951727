import classNames from "classnames";
import React from "react";

import { FontSizes, Typography } from "triangular/components/Typography/Typography";

import css from "./IconText.module.scss";

interface IconTextProps {
  className?: string;
  icon: React.ReactNode;
  fontSize?: FontSizes;
}

export const IconText: React.FC<IconTextProps> = ({ children, className, icon, fontSize = "small" }) => (
  <div className={classNames(css.iconWrapper, className)}>
    {icon}
    <Typography component="span" size={fontSize} className={css.content}>
      {children}
    </Typography>
  </div>
);
