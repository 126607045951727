import { History } from "history";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { Router } from "react-router-dom";

import { Layout } from "triangular/components/Layout/Layout";
import { PageTitle } from "triangular/components/PageTitle/PageTitle";
import { Snackbars } from "triangular/components/Snackbars/Snackbars";
import { Routes } from "triangular/Routes/Routes";
import { ErrorBoundary } from "triangular/services/bugsnag";
import { StripeProvider } from "triangular/services/stripe";
import { RootStoreType } from "triangular/stores/RootStore";
import { StoreContext } from "triangular/stores/StoreContext";

import { useInitialData } from "./useInitialData";

interface Props {
  store: RootStoreType;
  history: History<any>;
}

export const Providers: React.FC<Props> = observer(({ store, history, children }) => {
  const { t } = useTranslation();

  const { appLoadingState, stripe } = useInitialData(store);

  const renderContent = () => {
    if (appLoadingState === "in_progress") {
      return <Layout withMolecules={false} loading={true} />;
    }

    if (appLoadingState === "failed") {
      return (
        <Layout withMolecules={false}>
          <PageTitle title={t("errors.networkError")} description={t("errors.generic")} />
        </Layout>
      );
    }

    return children;
  };

  return (
    <ErrorBoundary>
      <StoreContext.Provider value={store}>
        <StripeProvider stripe={stripe}>
          <Router history={history}>
            <Snackbars />
            {renderContent()}
          </Router>
        </StripeProvider>
      </StoreContext.Provider>
    </ErrorBoundary>
  );
});

export const App: React.FC<Props> = observer(props => {
  return (
    <Providers {...props}>
      <Routes />
    </Providers>
  );
});
