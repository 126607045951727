import React from "react";
import { useTranslation } from "react-i18next";

import { PageTitle } from "../PageTitle/PageTitle";

export const SystemErrorPage: React.FC = () => {
  const { t } = useTranslation();

  return <PageTitle title={t("systemError.title")} description={t("systemError.message")} />;
};
