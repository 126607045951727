import * as i18next from "i18next";
import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { RouteComponentProps, RouteProps } from "react-router";
import useTitle from "react-use/lib/useTitle";
import useRouter from "use-react-router";

import { TooltipMessage } from "triangular/components/WithTooltip/TooltipMessage";
import { APP_TITLE } from "triangular/consts/globals";
import { i18n } from "triangular/i18next/i18next";
import { SnackbarStoreType } from "triangular/stores/SnackbarStore";
import { useStore } from "triangular/stores/StoreContext";

export type RenderOptions = Pick<RouteProps, "children" | "component" | "render">;

export function renderComponent(props: RouteComponentProps<any>, options: RenderOptions): ReactNode {
  const { children, component: Component, render } = options;

  if (Component) {
    return <Component {...props} />;
  }
  if (render) {
    return render(props);
  }
  if (children) {
    return typeof children === "function" ? children(props) : children;
  }

  return null;
}

export const showValidationErrorSnackbar = (snackbarStore: SnackbarStoreType) =>
  snackbarStore.addSnackbar({ type: "error", message: i18n.t("validation.formInvalid") });

export const useAutoScrollOnChange = (
  deps: any[],
  options: ScrollToOptions = { top: 0, left: 0, behavior: "auto" }
) => {
  useEffect(() => {
    window.scroll(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);
};

export const useOnHistoryBack = (callback: () => void, deps: any[]) => {
  const { history } = useRouter();

  useEffect(() => {
    const unsubscribe = history.listen((__, action) => {
      if (action === "POP") {
        callback();
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useOnEmptySearch = (callback: () => void, deps: any[]) => {
  const { history, location } = useRouter();

  useEffect(() => {
    const unsubscribe = history.listen(({ pathname, search }) => {
      if (search === "" && pathname === location.pathname) {
        callback();
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

// tslint:disable:next:line no-shadowed-variable
export const translateIfExists = ({ i18n, path, fallback }: { i18n: i18next.i18n; path: string; fallback: string }) =>
  i18n.exists(path) ? i18n.t(path) : fallback;

export const FormAdditionalContext = React.createContext({
  isReadOnly: false
});

export const useLoadingState = <T extends string = "in_progress">(initialState?: T) => {
  const [loadingState, setLoadingState] = useState<LoadingState | T>(initialState || "in_progress");
  return { loadingState, setLoadingState };
};

export const useLoadable = <T extends string = "in_progress">(initialState?: T) => {
  const { loadingState, setLoadingState } = useLoadingState(initialState);
  const { snackbarStore } = useStore();
  const [promise, setPromise] = useState<Promise<void> | null>(null);

  const setLoadable = useCallback((asyncFunction: () => Promise<void>) => {
    const promise = asyncFunction();
    setPromise(promise);
    return promise;
  }, []);

  const awaitPromise = useCallback(async () => {
    if (promise) {
      setLoadingState("in_progress");

      try {
        await promise;
        setLoadingState("done");
      } catch (err) {
        setLoadingState("failed");
        snackbarStore.showGenericError(err);
      }

      setPromise(null);
    }
  }, [promise, setLoadingState, snackbarStore]);

  useEffect(() => {
    awaitPromise();
  }, [awaitPromise]);

  return { loadingState, setLoadable };
};

export const useAppTitle = (title?: string) => {
  useTitle(title ? `${title}: ${APP_TITLE}` : APP_TITLE);
};

export const useClickOutside = (ref: React.RefObject<any>, callback: () => void) => {
  const handleClick = (e: Event) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  React.useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export function useEllipsisTooltip(id: string) {
  const ref = useRef<HTMLElement>(null);
  const [ellipsisTooltipContent, setEllipsisTooltipContent] = useState<string | null>(null);
  const [maxWidth, setMaxWidth] = useState<number | undefined>();

  useEffect(() => {
    if (ref.current) {
      const { offsetWidth = 0, scrollWidth = 0, textContent = "" } = ref.current;
      if (offsetWidth < scrollWidth) {
        setEllipsisTooltipContent(textContent);
        setMaxWidth(ref.current.getBoundingClientRect().width);
      } else {
        setEllipsisTooltipContent(null);
      }
    }
  }, []);

  const jsx = ellipsisTooltipContent ? (
    <TooltipMessage
      id={id}
      style={{
        maxWidth
      }}
    >
      {ellipsisTooltipContent}
    </TooltipMessage>
  ) : null;

  return {
    jsx,
    triggerProps: {
      ref,
      "data-tip": true,
      "data-for": id
    }
  };
}
