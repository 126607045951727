import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { Alert } from "triangular/components/Alert/Alert";
import { BackButton } from "triangular/components/BackButton/BackButton";
import { Layout } from "triangular/components/Layout/Layout";
import { Segment } from "triangular/components/Segment/Segment";
import { TriLink } from "triangular/components/TriLink/TriLink";
import { Typography } from "triangular/components/Typography/Typography";
import { routes } from "triangular/Routes/routesConfiguration";

import css from "./Login.module.scss";
import { LoginForm } from "./LoginForm/LoginForm";

export const Login = ({ isForProtectedResource }: { isForProtectedResource?: boolean }) => {
  const { t } = useTranslation();

  return (
    <Layout className={{ main: css.layoutMain }}>
      <div className={css.login}>
        <Segment>
          {isForProtectedResource && <Alert className={css.alert}>{t("login.protectedResource")}</Alert>}
          <div className={css.segment}>
            <div className={css.firstColumn}>
              <Typography size="veryLarge" color="white" component="p" font="rubik" className={css.heading}>
                <Trans i18nKey="login.heading">
                  <span className={css.headingUnderline}>Great business</span>
                  <span>begins with good</span>
                  <span>connections. We are</span>
                  <span className={css.headingUnderline}>capacity connectors.</span>
                </Trans>
              </Typography>
            </div>
            <div className={css.secondColumn}>
              <div className={css.titleWrapper}>
                {isForProtectedResource && <BackButton />}
                <Typography component="h2" size="large" verticalSpacing="big" bold={true} className={css.title}>
                  {t("login.title")}
                </Typography>
              </div>
              <LoginForm />
              <Typography component="div" verticalSpacing="big">
                <TriLink to={routes.passwordRecovery.root.build()}>{t("login.forgotPassword")}</TriLink>
              </Typography>
              <Typography component="div" verticalSpacing="big">
                <Trans i18nKey="login.notRegistered">
                  You are not registered yet?&nbsp;
                  <TriLink to={routes.registration.root.build()}>Register now here!</TriLink>
                </Trans>
              </Typography>
            </div>
          </div>
        </Segment>
      </div>
    </Layout>
  );
};
