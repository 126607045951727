import { useContext } from "react";
import * as Yup from "yup";

import { createPagination, createPaginationContext } from "triangular/utils/pagination/pagination";

export const paginationSchema = Yup.object({
  search: Yup.string().notRequired(),
  languages: Yup.array()
    .of(Yup.string())
    .notRequired()
    .nullable(),
  processCategories: Yup.array()
    .of(Yup.string())
    .notRequired()
    .nullable(),
  industryIds: Yup.array()
    .of(Yup.string())
    .notRequired()
    .nullable(),
  specialtyIds: Yup.array()
    .of(Yup.string())
    .notRequired()
    .nullable(),
  materialCategoryIds: Yup.array()
    .of(Yup.string())
    .notRequired()
    .nullable()
});

export const pagination = createPagination({
  queryParams: {
    schema: paginationSchema,
    defaults: {
      search: undefined,
      languages: undefined,
      processCategories: undefined,
      industryIds: undefined,
      specialtyIds: undefined,
      materialCategoryIds: undefined
    }
  }
});

export const usePagination = pagination.usePagination;
export const parsePaginationQuery = pagination.parsePaginationQuery;

export const ExpertsPaginationContext = createPaginationContext<Yup.InferType<typeof paginationSchema>>();

export function useExpertsPaginationContext() {
  const { pagination } = useContext(ExpertsPaginationContext);

  if (!pagination) {
    throw new Error("Pagination context not provided");
  }

  return pagination;
}
