export type BuildMethod<Params extends {}> = HasRequiredKeys<Params> extends true
  ? (params: UndefinedToOptional<Params>) => string
  : (params?: UndefinedToOptional<Params>) => string;

export function buildMethod<Params extends {}>(
  method: (params: UndefinedToOptional<Params>) => string
): BuildMethod<Params> {
  return ((params: UndefinedToOptional<Params> = {} as UndefinedToOptional<Params>) =>
    method(params || {})) as BuildMethod<Params>;
}
