import React from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { LabeledSelect } from "triangular/components/LabeledSelect/LabeledSelect";
import { useStore } from "triangular/stores/StoreContext";
import { namesToSelectOptions } from "triangular/utils/experts";

import { useExpertsPaginationContext } from "../utils/pagination";
import { updateLanguages } from "../utils/updates";

export const Languages: React.FC = () => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const { paginationQuery, buildUrl } = useExpertsPaginationContext();
  const { expertStore } = useStore();
  const languageOptions = expertStore.getSettings().languages;
  const languages = paginationQuery.languages || [];

  const options = namesToSelectOptions(t)("languageOptions", languageOptions);

  if (options.length === 0) {
    return null;
  }

  const handleChange = (selectedValue: string | string[]) =>
    history.push(buildUrl(() => updateLanguages(selectedValue as string[])));

  return (
    <LabeledSelect
      label={t("languages")}
      value={languages}
      options={options}
      onChange={handleChange}
      autoComplete={true}
    />
  );
};
