import { IMSTArray, SnapshotOut, types } from "mobx-state-tree";

import { FlatEntity } from "triangular/services/Api/Resource/utils/types";
import { AvailableSubscriptionPlanEntity } from "triangular/services/Api/resources/AvailableSubscriptionPlanResource";
import { InvoiceDetailsAttributes } from "triangular/services/Api/resources/InvoiceDetailsResource";
import { SubscriptionEntity } from "triangular/services/Api/resources/SubscriptionResource";

import { createStore } from "./utils/createStore";

export const PaymentsStore = createStore(({ api }) =>
  types
    .model("PaymentsStore", {
      subscription: types.model({
        id: types.maybeNull(types.string),
        status: types.enumeration<SubscriptionStatus>(["inactive", "active", "unpaid", "active_cancelled"]),
        paymentMethodId: types.maybeNull(types.string),
        billingInterval: types.maybeNull(
          types.enumeration<SubscriptionInterval>(["year", "month"])
        ),
        level: types.maybeNull(
          types.enumeration<SubscriptionLevel>(["basic", "advanced", "professional"])
        ),
        manualPayment: types.maybeNull(types.boolean),
        currentPeriodStart: types.maybeNull(types.string),
        currentPeriodEnd: types.maybeNull(types.string),
        trialEndAt: types.maybeNull(types.string)
      }),
      deactivatedRequestSent: types.boolean,
      invoiceDetailId: types.maybeNull(types.string),
      subscriptionPlans: types.array(
        types.model({
          subscriptionType: types.enumeration(["experts", "system_owners", "material_owners", "masters"]),
          level: types.enumeration<SubscriptionLevel>(["basic", "advanced", "professional"]),
          billingInterval: types.string,
          pricePerMonth: types.number,
          pricePerYear: types.number,
          discount: types.maybeNull(
            types.model({
              pricePerMonth: types.number,
              pricePerYear: types.number,
              percent: types.number
            })
          ),
          isCurrent: types.boolean
        })
      )
    })
    .actions(self => ({
      setState(snapshot: Partial<SnapshotOut<typeof self>>) {
        Object.assign(self, snapshot);
      },
      setSubscription(
        subscription: Omit<Partial<FlatEntity<SubscriptionEntity>>, "invoiceDetail"> & { invoiceDetailId?: string }
      ) {
        self.subscription = {
          ...self.subscription,
          ...subscription
        };

        if (subscription.invoiceDetailId) {
          self.invoiceDetailId = subscription.invoiceDetailId;
        }
      },
      getSubscriptionId() {
        if (!self.subscription.id) {
          throw new Error("Subscription id is not available");
        }

        return self.subscription.id;
      },
      getInvoiceDetailId() {
        if (!self.invoiceDetailId) {
          throw new Error("Invoice detail id is not available");
        }

        return self.invoiceDetailId;
      },
      setDeactivateRequestSent(value: boolean) {
        self.deactivatedRequestSent = value;
      },
      setSubscriptionPlans(plans: Array<FlatEntity<AvailableSubscriptionPlanEntity>>) {
        // IMSTArray is complicated type to deal with.
        // TODO: find a way to cast regular array to IMSTArray
        self.subscriptionPlans = plans as IMSTArray<any>;
      }
    }))
    .actions(self => ({
      getSubscriptionPrefixes() {
        return ["subscriptions", self.getSubscriptionId()];
      }
    }))
    .actions(self => ({
      async fetchPaymentMethods() {
        const { data } = await api.paymentMethod.find();
        return data;
      },
      async createPaymentMethod(id: string) {
        await api.paymentMethod.create({ id });
      },
      async deletePaymentMethod(id: string) {
        await api.paymentMethod.delete(id);
      },
      async updateSubscriptionPaymentMethod(paymentMethodId?: string) {
        await api.subscriptionPaymentMethod.create(
          { paymentMethodId },
          {},
          {
            prefixes: self.getSubscriptionPrefixes()
          }
        );

        self.setSubscription({
          paymentMethodId
        });
      },
      updateInvoiceDetails(attributes: InvoiceDetailsAttributes) {
        return api.invoiceDetails.update(
          self.getInvoiceDetailId(),
          {
            name: attributes.name,
            line1: attributes.line1,
            line2: attributes.line2,
            city: attributes.city,
            postalCode: attributes.postalCode
          },
          {},
          {
            prefixes: self.getSubscriptionPrefixes()
          }
        );
      },
      async fetchInvoiceDetails() {
        const { data } = await api.invoiceDetails.findById(self.getInvoiceDetailId(), {
          prefixes: self.getSubscriptionPrefixes()
        });
        return data;
      },
      async fetchSubscription() {
        const { data } = await api.subscription.findById(self.getSubscriptionId());

        self.setSubscription({
          id: data.id,
          status: data.status,
          paymentMethodId: data.paymentMethodId,
          billingInterval: data.billingInterval,
          level: data.level,
          manualPayment: data.manualPayment,
          currentPeriodStart: data.currentPeriodStart,
          currentPeriodEnd: data.currentPeriodEnd,
          trialEndAt: data.trialEndAt,
          invoiceDetailId: data.invoiceDetail && data.invoiceDetail.id
        });
      },
      async updateSubscriptionPlan({
        level,
        billingInterval,
        accountType
      }: {
        level: SubscriptionLevel;
        billingInterval: SubscriptionInterval;
        accountType: AccountType;
      }) {
        await api.subscriptionPlan.create(
          {
            level,
            billingInterval,
            accountType
          },
          {},
          { prefixes: self.getSubscriptionPrefixes() }
        );

        self.setSubscription({ level, billingInterval });
      },
      async cancelSubscription() {
        if (!self.subscription.id) {
          throw new Error("Subscription id is not available");
        }

        await api.subscription.delete(self.subscription.id);
      },
      async upgradeToMaster() {
        await api.subscriptionPlan.create(
          {
            level: "basic" as const,
            billingInterval: "year" as const,
            accountType: "master" as const
          },
          {},
          { prefixes: self.getSubscriptionPrefixes() }
        );
      },
      async setupManualPayment() {
        await api.subscription.update(self.getSubscriptionId(), {
          manualPayment: true
        });

        self.setSubscription({
          manualPayment: true
        });
      },
      async deactivateAccount(accountId: string | null) {
        if (!accountId) {
          throw new Error("User's account id has been not provided!");
        }

        await api.account.delete(accountId);
        self.setDeactivateRequestSent(true);
      },
      async fetchSubscriptionPlans() {
        const { data } = await api.availableSubscriptionPlan.find();
        self.setSubscriptionPlans(data);
      }
    }))
);

type PaymentsStoreType = ReturnType<typeof PaymentsStore>["Type"];
type PaymentsStoreSnapshot = ReturnType<typeof PaymentsStore>["SnapshotType"];

export type PaymentMethodItems = Await<PaymentsStoreType["fetchPaymentMethods"]>;

export const paymentsStoreInitialState: PaymentsStoreSnapshot = {
  subscription: {
    id: null,
    status: "inactive",
    billingInterval: null,
    paymentMethodId: null,
    level: null,
    manualPayment: null,
    currentPeriodStart: null,
    currentPeriodEnd: null,
    trialEndAt: null
  },
  deactivatedRequestSent: false,
  invoiceDetailId: null,
  subscriptionPlans: []
};
