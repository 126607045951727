import classNames from "classnames";
import noop from "lodash/noop";
import React, { InputHTMLAttributes } from "react";

import css from "./Checkbox.module.scss";

export interface CheckboxProps extends Omit<InputHTMLAttributes<any>, "value" | "className" | "size"> {
  className?: {
    check?: string;
    wrapper?: string;
    label?: string;
  };
  "data-testid"?: string;
  label: string;
  checked: boolean;
  size?: "big";
}

export const Checkbox: React.FC<CheckboxProps> = ({
  disabled,
  className = {},
  "data-testid": testId,
  label,
  checked,
  onChange,
  size,
  ...props
}) => {
  if (disabled) {
    onChange = noop;
  }

  return (
    <div className={classNames(css.wrapper, { [css.disabled]: disabled }, className.wrapper)}>
      <div className={css.inputContainer}>
        <label className={classNames(css.label, className.check)}>
          <input
            {...props}
            onChange={onChange}
            checked={checked}
            type="checkbox"
            tabIndex={0}
            data-testid={testId}
            disabled={disabled}
          />
          <span className={css.checkbox} />
        </label>
      </div>
      <span onClick={onChange} className={classNames(css.label, className.label)}>
        {label}
      </span>
    </div>
  );
};
