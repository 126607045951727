import mapValues from "lodash/mapValues";
import qs, { IParseOptions, IStringifyOptions } from "qs";
import * as yup from "yup";

import { buildMethod } from "./common";

const parseOptions: IParseOptions = {};
const stringifyOptions: IStringifyOptions = {
  strictNullHandling: true,
  arrayFormat: "brackets",
  encodeValuesOnly: true
};

export class Search<Params extends {}> {
  constructor(public readonly schema: yup.ObjectSchema<Params>) {}

  // tslint:disable-next-line: member-ordering
  build = buildMethod((params: UndefinedToOptional<Params>) => {
    this.schema.validateSync(params, { strict: true, abortEarly: false });

    const rawParams = stringifyValue(params, this.schema.describe());
    return qs.stringify(rawParams, stringifyOptions);
  });

  match(search: string): Params {
    const rawParams = qs.parse(search, parseOptions);
    return this.schema.validateSync(parseValue(rawParams, this.schema.describe()), { abortEarly: false });
  }

  nested<NestedParams extends {}>(schema: yup.ObjectSchema<NestedParams>): Search<Params & NestedParams> {
    const searchSchema: yup.ObjectSchema<Params & NestedParams> = this.schema.concat(schema);
    return new Search(searchSchema);
  }
}

function parseValue(value: any, description: yup.SchemaDescription): any {
  const { type } = description;

  switch (type) {
    case "boolean": {
      return value !== undefined;
    }

    case "array": {
      if (value === undefined) {
        return [];
      }

      return value.map((item: any) => parseValue(item, (description as any).innerType));
    }
    case "object": {
      const object = value === undefined ? {} : value;
      const fieldDescriptions = description.fields as Dictionary<yup.SchemaDescription>;
      return mapValues(fieldDescriptions, (fieldDescription, key) => parseValue(object[key], fieldDescription));
    }
    default:
      // let yup handle the rest
      return value;
  }
}

function stringifyValue(value: any, description: yup.SchemaDescription): any {
  if (value === undefined) {
    return undefined;
  }

  const { type } = description;

  switch (type) {
    case "string":
    case "number": {
      return value.toString();
    }
    case "boolean": {
      return value ? null : undefined;
    }
    case "date": {
      return value.toISOString();
    }
    case "array": {
      const inner: yup.SchemaDescription = (description as any).innerType;
      return value.map((item: any) => stringifyValue(item, inner));
    }
    case "object": {
      const fieldDescriptions = description.fields as Dictionary<yup.SchemaDescription>;
      return mapValues(fieldDescriptions, (fieldDescription, key) => stringifyValue(value[key], fieldDescription));
    }
    default:
      throw new Error(`unexpected type ${type} of ${JSON.stringify(value)}`);
  }
}
