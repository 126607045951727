import { AxiosInstance } from "axios";

import { Resource } from "../Resource/Resource";
import { Entity } from "../Resource/utils/types";

type SessionEntity = Entity<{
  refreshToken: string;
  accessToken: string;
}>;

export class SessionResource extends Resource<SessionEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "sessions",
      prefixes: ["auth"]
    });
  }
}
