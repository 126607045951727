import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { MultiSelectField } from "triangular/components/form/MultiSelectField/MultiSelectField";
import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { Typography } from "triangular/components/Typography/Typography";
import { countriesSelectOptions } from "triangular/consts";
import { useStore } from "triangular/stores/StoreContext";
import { namesToSelectOptions } from "triangular/utils/experts";

import css from "./ExpertForm.module.scss";

export const Availability: React.FC = observer(() => {
  const { t } = useTranslation();
  const { expertStore } = useStore();
  const { reaches } = expertStore.getSettings();
  const isFormDisabled = expertStore.isConfirmed();
  const reachOptions = namesToSelectOptions(t)("reachOptions", reaches);

  return (
    <FormSection>
      <FieldsRow className={css.altHeader}>
        <Typography bold component="h3" color="white" size="big" upperCase={true}>
          {t("editExpert.availability")}
        </Typography>
      </FieldsRow>
      <FieldsRow>
        <FieldWrapper>
          <SelectField
            name="reach"
            label={t("editExpert.reach")}
            selectProps={{
              options: reachOptions
            }}
            required={true}
            data-testid="reach"
            disabled={isFormDisabled}
          />
        </FieldWrapper>
        <FieldWrapper>
          <MultiSelectField
            name="countryCodeBlacklist"
            label={t("editExpert.noGoRegions")}
            selectProps={{
              options: countriesSelectOptions,
              autoComplete: true
            }}
            data-testid="countryCodeBlacklist"
            disabled={isFormDisabled}
          />
        </FieldWrapper>
      </FieldsRow>
    </FormSection>
  );
});
