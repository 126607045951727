import classNames from "classnames";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Button } from "triangular/components/Button/Button";
import { CheckboxField } from "triangular/components/form/CheckboxField/CheckboxField";
import { Form } from "triangular/components/form/Form";
import { PasswordField } from "triangular/components/form/PasswordField/PasswordField";
import { TextField } from "triangular/components/form/TextField";
import { gaActions, GaCategories, googleAnalytics } from "triangular/services/googleAnalytics";
import { useStore } from "triangular/stores/StoreContext";
import { getErrorStatus } from "triangular/utils/api";

import css from "./LoginForm.module.scss";

const createValidationSchema = (t: TFunction) =>
  Yup.object({
    email: Yup.string()
      .email(t("login.errors.emailInvalid"))
      .required(t("login.errors.emailRequired")),
    password: Yup.string().required(t("login.errors.passwordRequired")),
    rememberMe: Yup.boolean()
  });

export const LoginForm: React.FC<{ isCentered?: boolean }> = ({ isCentered }) => {
  const { t } = useTranslation();
  const validationSchema = createValidationSchema(t);
  const { signIn, snackbarStore } = useStore();

  const loginErrors: Dictionary<string> = {
    "401": t("login.errors.invalidCredentials"),
    "403": t("login.errors.accountUnconfirmed"),
    "404": t("login.errors.invalidCredentials")
  };

  return (
    <Form
      initialValues={{
        email: "",
        password: "",
        rememberMe: true
      }}
      validationSchema={validationSchema}
      render={({ isSubmitting }) => (
        <>
          <div className={classNames(css.loginForm, { [css.isCentered]: isCentered })}>
            <TextField
              data-testid="email"
              className={{
                wrapper: css.textField
              }}
              name="email"
              label={t("email")}
            />
            <PasswordField
              className={{
                wrapper: css.textField
              }}
              name="password"
              data-testid="password"
              label={t("login.form.password")}
            />
            <CheckboxField data-testid="rememberMe" name="rememberMe" label={t("login.form.rememberMe")} />
            <Button
              data-testid="submit"
              className={css.buttonWrapper}
              type="submit"
              disabled={isSubmitting}
              showLoader={isSubmitting}
            >
              {t("login.form.login")}
            </Button>
          </div>
        </>
      )}
      onSubmit={async formValues => {
        try {
          await signIn(formValues);

          googleAnalytics.sendEvent({
            category: GaCategories.user,
            action: gaActions.user.login.succeeded
          });
        } catch (err) {
          const status = getErrorStatus(err);
          const error = loginErrors[status];

          if (!loginErrors[status]) {
            throw err;
          }

          snackbarStore.addSnackbar({ type: "error", message: error });

          googleAnalytics.sendEvent({
            category: GaCategories.user,
            action: gaActions.user.login.failed
          });
        }
      }}
    />
  );
};
