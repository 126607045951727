import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import usePromise from "react-use/lib/usePromise";
import useRouter from "use-react-router";

import { Masonry } from "triangular/components/Masonry/Masonry";
import { Modal, ModalProps } from "triangular/components/Modal/Modal";
import { PageLoader } from "triangular/components/PageLoader/PageLoader";
import { PageTitle } from "triangular/components/PageTitle/PageTitle";
import { getPaginationProps, Pagination } from "triangular/components/Pagination/Pagination";
import { RouteParameters, routes } from "triangular/Routes/routesConfiguration";
import { MaterialItem } from "triangular/scenes/MyMaterialsList/MaterialItem/MaterialItem";
import { useStore } from "triangular/stores/StoreContext";
import { useCancellation } from "triangular/utils/api";
import { useAutoScrollOnChange, useOnHistoryBack } from "triangular/utils/components";

import css from "./SelectMaterialModal.module.scss";

type MatchParams = Pick<RouteParameters<typeof routes.systemRequest>, "systemId">;

export const SelectMaterialModal: React.FC<Pick<ModalProps, "isOpen" | "onClose">> = observer(({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { match, history } = useRouter<MatchParams>();
  const systemToRequestId = match.params["systemId"];
  const { myMaterialsStore, snackbarStore } = useStore();
  const { pagination } = myMaterialsStore;
  const materials = Array.from(myMaterialsStore.pagination.currentItems);
  const { cancel, cancelToken } = useCancellation();
  const resolveWhenMounted = usePromise();

  useEffect(() => () => cancel(), [cancel]);

  useAutoScrollOnChange([pagination.currentItems]);

  const loadMyMaterials = useCallback(async () => {
    if (isOpen && myMaterialsStore.pagination.loading === false) {
      try {
        await myMaterialsStore.fetchMyMaterials({ pushHistory: false });
      } catch (e) {
        snackbarStore.addSnackbar({ type: "error", message: t("errors.generic") });
      }
    }
  }, [isOpen, myMaterialsStore, snackbarStore, t]);

  useEffect(() => {
    resolveWhenMounted(loadMyMaterials());
  }, [loadMyMaterials, resolveWhenMounted]);

  useOnHistoryBack(() => {
    onClose();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className={prevCss => ({
        modal: classNames(prevCss.modal, css.modal)
      })}
    >
      {(() => {
        if (myMaterialsStore.pagination.loading) {
          return <PageLoader />;
        }

        if (materials.length === 0) {
          return (
            <PageTitle
              title={t("systemRequest.selectMaterialModal.title")}
              description={t("systemRequest.selectMaterialModal.noMaterialsToSelectFrom")}
            />
          );
        }

        return (
          <>
            <PageTitle
              title={t("systemRequest.selectMaterialModal.title")}
              description={t("systemRequest.selectMaterialModal.description")}
            />
            <Masonry>
              {materials.map(eachMaterial => (
                <MaterialItem
                  {...eachMaterial}
                  key={eachMaterial.id}
                  showIconButtons={false}
                  onClick={() =>
                    history.replace(
                      routes.systemRequest.build({
                        materialId: eachMaterial.id,
                        systemId: systemToRequestId
                      })
                    )
                  }
                />
              ))}
            </Masonry>
            <Pagination
              {...getPaginationProps({ pagination, config: { cancelToken }, refetch: true, pushHistory: false })}
            />
          </>
        );
      })()}
    </Modal>
  );
});
