import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { useStore } from "triangular/stores/StoreContext";
import { settingItemsToSelectOptions } from "triangular/utils/systems";

interface TargetIndustryFieldProps {
  label: string;
}

export const TargetIndustryField: React.FC<TargetIndustryFieldProps> = observer(({ label }) => {
  const { t } = useTranslation();
  const { commonsStore } = useStore();
  const commonSettings = commonsStore.getSettings();

  const noRestrictionsId = commonSettings.noRestrictionsIds.industry;
  const targetIndustryOptions = settingItemsToSelectOptions(t)(
    "targetIndustryOptions",
    commonSettings.industries
  ).filter(({ value }) => value !== noRestrictionsId);

  return (
    <SelectField
      label={label}
      name="targetIndustry"
      selectProps={{
        options: targetIndustryOptions
      }}
      onChangeCallback={form => {
        form.setFieldValue("materialCategory", null);
      }}
      required={true}
      data-testid="targetIndustry"
    />
  );
});
