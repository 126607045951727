import classNames from "classnames";
import React from "react";

import { Typography } from "../Typography/Typography";

import css from "./SectionTitle.module.scss";

interface Props {
  title?: string;
  className?: string;
}

export const SectionTitle: React.FC<Props> = ({ title, className, children }) => (
  <Typography
    component="h2"
    bold={true}
    size="big"
    color="mint"
    upperCase={true}
    className={classNames(css.sectionTitle, className)}
  >
    {title}
    {children}
  </Typography>
);
