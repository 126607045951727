import { observer } from "mobx-react-lite";
import React from "react";
import { Redirect } from "react-router-dom";

import { useStore } from "triangular/stores/StoreContext";

import { ConditionalRoute, ConditionalRouteProps } from "./ConditionalRoute";
import { privateRouteFallback } from "./routesConfiguration";

export type PrivateRouteProps = Omit<ConditionalRouteProps, "condition">;

export const PrivateRoute = observer<PrivateRouteProps>(props => {
  const { userStore } = useStore();
  return (
    <ConditionalRoute
      condition={userStore.isAuthenticated}
      fallback={<Redirect to={privateRouteFallback.build()} />}
      {...props}
    />
  );
});
