import React from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { LabeledSelect } from "triangular/components/LabeledSelect/LabeledSelect";
import { countriesWithGlobalLocation } from "triangular/consts";

import { useSystemsPaginationContext } from "../utils/pagination";
import { updateCountryCode } from "../utils/updates";

export const Location: React.FC = () => {
  const { paginationQuery, buildUrl } = useSystemsPaginationContext();
  const { t } = useTranslation();
  const { history } = useRouter();
  const { countryCode } = paginationQuery;

  const options = countriesWithGlobalLocation(t);

  if (options.length === 0) {
    return null;
  }

  const handleChange = (selectedValue: string | string[]) =>
    history.push(buildUrl(() => updateCountryCode(selectedValue as string)));

  return (
    <LabeledSelect
      label={t("systems.location")}
      value={countryCode || ""}
      options={options}
      onChange={handleChange}
      autoComplete={true}
    />
  );
};
