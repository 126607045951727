import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { NotSet } from "triangular/components/NotSet/NotSet";

import css from "./HeaderProperty.module.scss";

interface HeaderPropertyProps {
  title: React.ReactNode;
  value?: React.ReactNode;
  translationPath?: string;
}

export const HeaderProperty = ({ title, value, translationPath }: HeaderPropertyProps) => {
  const isEmpty = value === null || value === undefined || value === "";
  const { t } = useTranslation();
  const renderedValue = translationPath ? t(`${translationPath}.${value}`) : value;
  const displayedValue = value === "no" ? t("systemDetails.no") : renderedValue;

  return (
    <dl className={classNames(css.wrapper)}>
      <dt>{title}</dt>
      <dd>{isEmpty ? <NotSet /> : displayedValue}</dd>
    </dl>
  );
};
