import React, { ComponentType, FC } from "react";
import { RouteComponentProps, RouteProps } from "react-router";

import { Location } from "./Location";

export interface LocationRouteOptions {
  invalidComponent?: ComponentType<InvalidComponentProps>;
  exact?: boolean;
}

export function locationRouteProps<P extends {}>(
  location: Location<P>,
  component: ComponentType<P & RouteComponentProps<{}>>,
  options: LocationRouteOptions = {}
): RouteProps {
  const Valid = component;
  const Invalid = options.invalidComponent || DefaultInvalidComponent;

  return {
    path: location.pattern,
    exact: options.exact === false ? false : true,
    render: renderProps => {
      let componentProps;

      try {
        componentProps = location.matchProps(renderProps);
      } catch (error) {
        return <Invalid {...renderProps} error={error} />;
      }

      return <Valid {...renderProps} {...componentProps} />;
    }
  };
}

export interface InvalidComponentProps extends RouteComponentProps<{}> {
  error: any;
}

export const DefaultInvalidComponent: FC<InvalidComponentProps> = ({ error }) => <>{error && error.toString()}</>;
