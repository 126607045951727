import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";

import { routes } from "triangular/Routes/routesConfiguration";
import { useStore } from "triangular/stores/StoreContext";

import { HomeButton } from "../../HomeButton/HomeButton";
import { useMenuTypes } from "../menuItemsGroups";

import { LanguageSelect } from "./LanguageSelect/LanguageSelect";
import css from "./MenuDesktop.module.scss";
import { ToggleableMenu } from "./ToggleableMenu/ToggleableMenu";

export const MenuDesktop: React.FC = observer(() => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { userStore, appLoadingState } = useStore();
  const authStatus = userStore.isAuthenticated;
  const userType = userStore.type;
  const menuTypes = useMenuTypes();
  const menuItemsGroups = menuTypes[userStore.type || "default"];
  const menuItems = menuItemsGroups.map(({ items }) => items).reduce((acc, next) => [...acc, ...next], []);

  useEffect(() => {
    const handleToggleMenu = (event: MouseEvent) => {
      if (menuRef.current === event.target) {
        setIsMenuVisible(prevState => prevState);
      } else {
        setIsMenuVisible(false);
      }
    };

    window.addEventListener("click", handleToggleMenu);
    return () => window.removeEventListener("click", handleToggleMenu);
  }, [isMenuVisible, userType]);

  if (appLoadingState === "in_progress") {
    return <nav className={css.nav} />;
  }

  function homeTarget() {
    if (userType === "system_owners") {
      return routes.mySystemsList.build({});
    } else if (userType === "material_owners") {
      return routes.myMaterialsList.build({});
    } else if (userType === "experts") {
      return routes.myExpertProfile.build({});
    }
    return routes.systems.build({});
  }

  function showHome() {
    if (authStatus) {
      return (
        <li className={css.homeButton}>
          <NavLink to={homeTarget()}>
            <HomeButton />
          </NavLink>
        </li>
      );
    } else if (!authStatus) {
      return (
        <nav className={css.nav}>
          <ul className={css.menu}>
            {menuItems.map(({ path, name, color }, index) => (
              <li key={index}>
                <NavLink to={path} className={classNames(css.item, css[`item--${color}`])}>
                  {name}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      );
    }
  }

  return (
    <nav className={css.nav}>
      <ul className={css.menu}>
        {authStatus && (
          <li>
            <ToggleableMenu isNav={true} />
          </li>
        )}
        {showHome()}
        <li className={css.languageSelect}>
          <LanguageSelect />
        </li>
      </ul>
    </nav>
  );
});
