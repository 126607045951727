import { connect } from "formik";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";

import { useHomeRoute } from "triangular/Routes/useHomeRoute";
import { useStore } from "triangular/stores/StoreContext";
import { FormAdditionalContext } from "triangular/utils/components";
import { isTruthy } from "triangular/utils/language";

import { InfoModal } from "../InfoModal/InfoModal";
import { useModal } from "../Modal/Modal";
import { Prompt } from "../Prompt/Prompt";

import { FormButtonsSection } from "./FormButtonsSection/FormButtonsSection";

interface DefaultFormButtonsProps {
  onGoBack?: () => void;
  onSave?: (values: any) => void;
  confirmPromptTitle?: string;
  confirmPromptMessage?: string;
  savedModalTitle?: string;
  savedModalMessage?: string;
  fieldsToValidateBeforeSave?: string[];
  onSavedModalClose?: () => void;
  submitButtonLabel?: React.ReactNode;
  isSubmitDisabled?: boolean;
  backButtonLabel?: React.ReactNode;
  isLoading?: boolean;
}

export const DefaultFormButtons = connect<DefaultFormButtonsProps>(
  observer(
    ({
      onGoBack,
      onSave,
      confirmPromptMessage,
      confirmPromptTitle,
      savedModalMessage,
      savedModalTitle,
      formik,
      fieldsToValidateBeforeSave,
      onSavedModalClose,
      submitButtonLabel,
      isSubmitDisabled,
      backButtonLabel,
      isLoading
    }) => {
      const { isReadOnly } = useContext(FormAdditionalContext);
      const { isSubmitting, values, setErrors, setTouched } = formik;
      const validationSchema = formik.validationSchema as Yup.ObjectSchema;
      const { t } = useTranslation();
      const currentT = (path: string) => t(`defaultFormButtons.${path}`);
      const submitConfirmation = useModal();
      const savedSuccessModal = useModal();
      const cancelConfirmation = useModal();
      const { snackbarStore } = useStore();
      const [isSaving, setIsSaving] = useState(false);
      const [exit, setExit] = useState(false);
      const appliedHomeRoute = useHomeRoute();

      const isSaveValid = async () => {
        const validations = fieldsToValidateBeforeSave
          ? fieldsToValidateBeforeSave.map(eachName =>
              validationSchema
                .validateAt(eachName, values)
                .then(() => null)
                .catch((validationError: Yup.ValidationError) => [eachName, validationError.errors[0]])
            )
          : [];

        const validationErrors = (await Promise.all(validations)).filter(isTruthy) as string[][];

        validationErrors.forEach(([eachName, eachError]) => {
          formik.setFieldError(eachName, eachError);
          formik.setFieldTouched(eachName);
        });

        return validationErrors.length === 0;
      };

      const handleSave = onSave
        ? async () => {
            setErrors({});
            setTouched({});
            setIsSaving(true);
            if (await isSaveValid()) {
              try {
                await onSave(values);
                savedSuccessModal.open();
              } catch (err) {
                snackbarStore.showGenericError(err);
              }
            } else {
              snackbarStore.showValidationError();
            }

            setIsSaving(false);
          }
        : null;

      const handleSubmit = async () => {
        try {
          formik.submitForm();
          await validationSchema.validate(values);
        } catch (err) {
          if (err instanceof Yup.ValidationError) {
            snackbarStore.showValidationError();
          } else {
            snackbarStore.showGenericError(err);
          }
        }
      };

      const closeSavedModal = () => {
        savedSuccessModal.close();

        if (onSavedModalClose) {
          onSavedModalClose();
        }
      };

      const exitForm = () => {
        setExit(true);
      };

      const cancelButton = onGoBack
        ? [
            {
              type: "button" as const,
              transparent: true,
              children: backButtonLabel ? backButtonLabel : currentT("back"),
              disabled: isSubmitting || isSaving || isLoading,
              onClick: isReadOnly ? onGoBack : cancelConfirmation.open,
              "data-testid": "cancel"
            }
          ]
        : [];

      const saveButton = onSave
        ? [
            {
              type: "button" as const,
              children: currentT("saveDraft"),
              disabled: isSubmitting || isSaving || isReadOnly || isLoading,
              showLoader: isSaving,
              onClick: handleSave!,
              "data-testid": "save"
            }
          ]
        : [];

      const showConfirmation = !!(confirmPromptMessage || confirmPromptTitle);

      const submitButton = !isReadOnly
        ? [
            {
              type: "button" as const,
              variant: "dark" as const,
              children: submitButtonLabel || currentT("submit"),
              disabled: isSubmitting || isSubmitDisabled || isSaving || isReadOnly || isLoading,
              onClick: showConfirmation ? submitConfirmation.open : handleSubmit,
              showLoader: isSubmitting || isLoading,
              "data-testid": "submit"
            }
          ]
        : [];

      return (
        <>
          <FormButtonsSection buttons={[...cancelButton, ...saveButton, ...submitButton]} />
          {onGoBack && (
            <Prompt
              isOpen={cancelConfirmation.isOpen}
              onClose={cancelConfirmation.close}
              onAgree={onGoBack}
              header={currentT("cancelConfirmation.header")}
              message={currentT("cancelConfirmation.message")}
            />
          )}
          <Prompt
            isOpen={submitConfirmation.isOpen}
            onClose={submitConfirmation.close}
            onAgree={handleSubmit}
            header={confirmPromptTitle || ""}
            message={confirmPromptMessage || ""}
          />
          <InfoModal
            isOpen={savedSuccessModal.isOpen}
            onClose={closeSavedModal}
            onCloseAlt={exitForm}
            titleContent={savedModalTitle}
            messageContent={savedModalMessage}
          />
          {exit && <Redirect to={appliedHomeRoute} />}
        </>
      );
    }
  )
);
