import { Dictionary, get, merge, omit } from "lodash";

import { DocumentBase } from "./types";

export function mergeWithIncluded(
  includedByIdAndType: Dictionary<any>,
  resource: {
    relationships?: {
      [key: string]: {
        data: DocumentBase | DocumentBase[];
      };
    };
  }
) {
  const relationships = get(resource, "relationships", null);

  const relationshipsWithIncluded =
    relationships &&
    Object.entries(relationships).reduce((acc: any, [relationName, relationResource]: any) => {
      const relationData = get(relationResource, "data", null);

      if (!relationData) {
        return Object.assign(acc, {
          [relationName]: null
        });
      }

      if (Array.isArray(relationData)) {
        const dataArray = relationData.map((eachResource: any) => {
          if (!eachResource) {
            return null;
          }

          return get(includedByIdAndType, [eachResource.id + eachResource.type], eachResource);
        });

        return Object.assign(acc, {
          [relationName]: dataArray
        });
      }

      const data = get(includedByIdAndType, [relationData.id + relationData.type], null);

      return Object.assign(acc, {
        [relationName]: data ? merge(data, relationData) : relationData
      });
    }, {});

  return {
    ...omit(resource, ["relationships"]),
    relationships: relationshipsWithIncluded
  };
}
