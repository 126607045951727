import classNames from "classnames";
import React from "react";

import css from "./Card.module.scss";

type CardProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Card: React.FC<CardProps> = ({ children, className, ...props }) => {
  return (
    <div className={classNames(css.card, className)} {...props}>
      {children}
    </div>
  );
};
