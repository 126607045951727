import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PageTitle } from "triangular/components/PageTitle/PageTitle";
import { Prompt, PromptProps } from "triangular/components/Prompt/Prompt";
import { PlansByInterval, SubscriptionLevel } from "triangular/components/SubscriptionLevel/SubscriptionLevel";
import { useStore } from "triangular/stores/StoreContext";

import css from "./UpgradeToMasterPrompt.module.scss";

export const UpgradeToMasterPrompt: React.FC<Omit<PromptProps, "message" | "header">> = observer(props => {
  const { t } = useTranslation();
  const { paymentsStore } = useStore();
  const { subscriptionPlans } = paymentsStore;
  const masterAccountPlans = subscriptionPlans.filter(eachPlan => eachPlan.subscriptionType === "masters");
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (props.isOpen) {
      // Render just after prompt has been opened
      setTimeout(() => setShowContent(true));
    } else {
      setShowContent(false);
    }
  }, [props.isOpen]);

  if (!masterAccountPlans) {
    throw new Error("Cannot find plans for master account");
  }

  const plansByInterval = masterAccountPlans.reduce((acc, nextPlan) => {
    const planOptions = {
      level: nextPlan.level,
      pricePerMonth: nextPlan.pricePerMonth,
      pricePerYear: nextPlan.pricePerYear,
      discount: nextPlan.discount
    };
    if (Array.isArray(acc[nextPlan.billingInterval])) {
      acc[nextPlan.billingInterval].push(planOptions);
    } else {
      acc[nextPlan.billingInterval] = [planOptions];
    }

    return acc;
  }, {} as PlansByInterval);

  return (
    <Prompt
      className={prevCss => ({
        modal: classNames(prevCss.modal, css.prompt),
        overlay: classNames(prevCss.overlay, css.overlay)
      })}
      message={
        showContent ? (
          <>
            <PageTitle
              title={t("payments.upgradeToMasterPrompt.title")}
              description={t("payments.upgradeToMasterPrompt.message")}
            />
            <SubscriptionLevel
              plansByInterval={plansByInterval}
              value="basic"
              interval="year"
              customPlanTitle={t("payments.masterAccount")}
            />
          </>
        ) : null
      }
      {...props}
    />
  );
});
