import React from "react";
import { useTranslation } from "react-i18next";

import { IndustryFilterBadgeLabel } from "triangular/components/IndustryFilterBadgeLabel/IndustryFilterBadgeLabel";
import { countriesWithGlobalLocation } from "triangular/consts";
import { useStore } from "triangular/stores/StoreContext";
import { getSystemSettingTranslation, useMaterialCategoriesOptions } from "triangular/utils/systems";

import { useSystemsPaginationContext } from "./pagination";
import {
  updateColorsWhitelist,
  updateContactMaterials,
  updateCountryCode,
  updateIndustryIds,
  updateInstalledPowerKw,
  updateMaterialCategoryIds,
  updateMaterialsBlacklist,
  updateMaterialsWhitelist,
  updateNonContactMaterials,
  updateProcessCategoryId,
  updateSystemCategoryIds,
  updateSystemTypeIds
} from "./updates";

export const useFilterBadges = () => {
  const { commonsStore } = useStore();
  const settings = commonsStore.getSettings();
  const { t } = useTranslation();
  const { paginationQuery, buildUrl } = useSystemsPaginationContext();
  const getOptionTranslation = getSystemSettingTranslation(t);
  const processCategoryId = paginationQuery.processCategoryId;
  const systemCategoryId = paginationQuery.systemCategoryId || [];
  const systemTypeId = paginationQuery.systemTypeId || [];
  const colorsWhitelist = paginationQuery.colorsWhitelist || [];
  const installedPowerKw = paginationQuery.installedPowerKw;
  const materialsWhitelist = paginationQuery.materialsWhitelist || [];
  const materialsBlacklist = paginationQuery.materialsBlacklist || [];
  const countryCode = paginationQuery.countryCode;
  const contactMaterials = paginationQuery.contactMaterials || [];
  const nonContactMaterials = paginationQuery.nonContactMaterials || [];
  const targetIndustries = paginationQuery.industryIds || [];
  const materialCategories = paginationQuery.materialCategoryIds || [];
  const materialCategoryOptions = useMaterialCategoriesOptions(targetIndustries);
  const getMaterialCategoryLabel = (categoryId: string) => {
    const foundCategory = materialCategoryOptions.find(({ value }) => value === categoryId);

    if (foundCategory) {
      return foundCategory.label;
    }

    return null;
  };

  const processCategoryBadgeProps = processCategoryId
    ? [
        {
          value: processCategoryId,
          removingUrl: buildUrl(() => updateProcessCategoryId(null)),
          label: getOptionTranslation(settings.processes, { id: String(processCategoryId) }, "processCategoryOptions")
        }
      ]
    : [];

  const systemCategoryBadgeProps = systemCategoryId.map(eachSystemCategoryId => ({
    value: eachSystemCategoryId,
    removingUrl: buildUrl(({ systemCategoryId }) =>
      updateSystemCategoryIds(
        systemCategoryId ? systemCategoryId.filter(eachId => eachId !== eachSystemCategoryId) : null
      )
    ),
    label: getOptionTranslation(
      settings.systemCategories.get(processCategoryId || "") || [],
      { id: String(eachSystemCategoryId) },
      "systemCategoryOptions"
    )
  }));

  const systemTypeBadgeProps = systemTypeId.map(eachSystemTypeId => ({
    value: eachSystemTypeId,
    removingUrl: buildUrl(({ systemTypeId }) =>
      updateSystemTypeIds(systemTypeId ? systemTypeId.filter(eachId => eachId !== eachSystemTypeId) : null)
    ),
    label: getOptionTranslation(
      systemCategoryId.flatMap(eachSystemCategoryId =>
        Array.from(settings.systemTypes.get(eachSystemCategoryId) || [])
      ),
      { id: String(eachSystemTypeId) },
      "systemTypeOptions"
    )
  }));

  const colorsWhitelistProps = colorsWhitelist.map(eachColor => ({
    value: eachColor,
    label: t(`systemSettings.colorOptions.${eachColor}`),
    removingUrl: buildUrl(prevQuery =>
      prevQuery.colorsWhitelist
        ? updateColorsWhitelist(prevQuery.colorsWhitelist.filter(prevColor => prevColor !== eachColor))
        : prevQuery
    )
  }));

  const installedPowerKwProps = installedPowerKw
    ? [
        {
          value: installedPowerKw.toString(),
          label: `${t("installedPower")} ${installedPowerKw[0]} - ${installedPowerKw[1]} [kW]`,
          removingUrl: buildUrl(() => updateInstalledPowerKw(null))
        }
      ]
    : [];

  const materialsWhitelistProps = materialsWhitelist.map(eachMaterial => ({
    value: eachMaterial,
    label: `${t(`systemSettings.materialOptions.${eachMaterial}`)} ${t("systems.badgeSuffix.materialsWhitelist")}`,
    removingUrl: buildUrl(prevQuery =>
      prevQuery.materialsWhitelist
        ? updateMaterialsWhitelist(prevQuery.materialsWhitelist.filter(prevMaterial => prevMaterial !== eachMaterial))
        : prevQuery
    )
  }));

  const materialsBlacklistProps = materialsBlacklist.map(eachMaterial => ({
    value: eachMaterial,
    label: `${t(`systemSettings.materialOptions.${eachMaterial}`)} ${t("systems.badgeSuffix.materialsBlacklist")}`,
    removingUrl: buildUrl(prevQuery =>
      prevQuery.materialsBlacklist
        ? updateMaterialsBlacklist(prevQuery.materialsBlacklist.filter(prevMaterial => prevMaterial !== eachMaterial))
        : prevQuery
    )
  }));

  const country = countryCode
    ? countriesWithGlobalLocation(t).find(eachOption => eachOption.value === countryCode)
    : null;

  const locationProps = country
    ? [
        {
          value: country.value,
          label: country.label,
          removingUrl: buildUrl(() => updateCountryCode(null))
        }
      ]
    : [];

  const contactMaterialsProps = contactMaterials.map(eachMaterial => ({
    value: `${eachMaterial}:contact`,
    label: `${t(`systemSettings.constructionMaterialOptions.${eachMaterial}`)} ${t(
      "systems.badgeSuffix.contactMaterials"
    )}`,
    removingUrl: buildUrl(prevQuery =>
      prevQuery.contactMaterials
        ? updateContactMaterials(prevQuery.contactMaterials.filter(prevMaterial => prevMaterial !== eachMaterial))
        : prevQuery
    )
  }));

  const nonContactMaterialsProps = nonContactMaterials.map(eachMaterial => ({
    key: `${eachMaterial}:nonContact`,
    value: eachMaterial,
    label: `${t(`systemSettings.constructionMaterialOptions.${eachMaterial}`)} ${t(
      "systems.badgeSuffix.nonContactMaterials"
    )}`,
    removingUrl: buildUrl(prevQuery =>
      prevQuery.nonContactMaterials
        ? updateNonContactMaterials(prevQuery.nonContactMaterials.filter(prevMaterial => prevMaterial !== eachMaterial))
        : prevQuery
    )
  }));

  const targetIndustriesProps = targetIndustries.map(eachTargetIndustryId => ({
    key: eachTargetIndustryId,
    value: eachTargetIndustryId,
    label: <IndustryFilterBadgeLabel industryId={eachTargetIndustryId} />,
    removingUrl: buildUrl(prevQuery =>
      prevQuery.industryIds
        ? updateIndustryIds(
            prevQuery.industryIds.filter(prevTargetIndustry => prevTargetIndustry !== eachTargetIndustryId)
          )
        : prevQuery
    )
  }));

  const materialCategoriesProps = materialCategories.map(eachMaterialCategory => ({
    key: eachMaterialCategory,
    value: eachMaterialCategory,
    label: getMaterialCategoryLabel(eachMaterialCategory),
    removingUrl: buildUrl(prevQuery =>
      prevQuery.materialCategoryIds
        ? updateMaterialCategoryIds(
            prevQuery.materialCategoryIds.filter(prevMaterialCategory => prevMaterialCategory !== eachMaterialCategory)
          )
        : prevQuery
    )
  }));

  return [
    ...processCategoryBadgeProps,
    ...systemCategoryBadgeProps,
    ...systemTypeBadgeProps,
    ...colorsWhitelistProps,
    ...installedPowerKwProps,
    ...materialsWhitelistProps,
    ...materialsBlacklistProps,
    ...locationProps,
    ...contactMaterialsProps,
    ...nonContactMaterialsProps,
    ...targetIndustriesProps,
    ...materialCategoriesProps
  ];
};
