import React from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { ReactComponent as ArrowSvg } from "triangular/static/images/arrow-down.svg";

import { LinkButton } from "../LinkButton/LinkButton";

import css from "./BackButton.module.scss";

export const BackButton = () => {
  const { history } = useRouter();
  const { t } = useTranslation();
  return (
    <LinkButton onClick={() => history.goBack()} className={css.wrapper} type="button">
      <ArrowSvg className={css.arrowSvg} />
      {t("defaultFormButtons.back")}
    </LinkButton>
  );
};
