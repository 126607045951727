import Axios, { AxiosAdapter, AxiosError, AxiosInstance, AxiosRequestConfig, AxiosTransformer } from "axios";
import * as humps from "humps";
import jwtDecode from "jwt-decode";
import get from "lodash/get";

import { getErrorStatus } from "triangular/utils/api";

import { AccountConfirmationResource } from "./resources/AccountConfirmationResource";
import { AccountResource } from "./resources/AccountResource";
import { AgreementResource } from "./resources/AgreementResource";
import { AvailableSubscriptionPlanResource } from "./resources/AvailableSubscriptionPlanResource";
import { AvatarResource } from "./resources/AvatarResource";
import { CertificateFileResource } from "./resources/CertificateFileResource";
import { CertificateResource } from "./resources/CertificateResource";
import { CommonSettingsResource } from "./resources/CommonSettingsResource";
import { ExperienceFileResource } from "./resources/ExperienceFileResource";
import { ExperienceResource } from "./resources/ExperienceResource";
import { ExpertHelpRequestResource } from "./resources/ExpertHelpRequestResource";
import { ExpertResource } from "./resources/ExpertResource";
import { ExpertSettingsResource } from "./resources/ExpertSettingsResource";
import { InvoiceDetailsResource } from "./resources/InvoiceDetailsResource";
import { MaterialResource } from "./resources/MaterialResource";
import { MaterialSafetyFile } from "./resources/MaterialSafetyFileResource";
import { MaterialSettingsResource } from "./resources/MaterialSettingsResource";
import { MaterialTechFile } from "./resources/MaterialTechFileResource";
import { OwnerResource } from "./resources/OwnerResource";
import { PasswordRecoveryResource } from "./resources/PasswordRecoveryResource";
import { PaymentMethodResource } from "./resources/PaymentMethodResource";
import { SessionResource } from "./resources/SessionResource";
import { SubscriptionPaymentMethodResource } from "./resources/SubscriptionPaymentMethodResource";
import { SubscriptionPlanResource } from "./resources/SubscriptionPlanResource";
import { SubscriptionResource } from "./resources/SubscriptionResource";
import { SystemDocumentResource } from "./resources/SystemDocumentResource";
import { SystemPhotoResource } from "./resources/SystemPhotoResource";
import { SystemResource } from "./resources/SystemResource";
import { SystemSettingsResource } from "./resources/SystemSettingsResource";
import { TermResource } from "./resources/TermsResource";

export interface Credentials {
  email: string;
  password: string;
}

export class Api {
  account: AccountResource;
  accountConfirmation: AccountConfirmationResource;
  agreement: AgreementResource;
  systemSettings: SystemSettingsResource;
  expertSettings: ExpertSettingsResource;
  materialSettings: MaterialSettingsResource;
  commonSettings: CommonSettingsResource;
  system: SystemResource;
  systemOwnerSystem: SystemResource;
  passwordRecovery: PasswordRecoveryResource;
  session: SessionResource;
  systemPhoto: SystemPhotoResource;
  systemDocument: SystemDocumentResource;
  material: MaterialResource;
  materialOwnerMaterial: MaterialResource;
  materialSafetyFile: MaterialSafetyFile;
  materialTechFile: MaterialTechFile;
  expert: ExpertResource;
  avatar: AvatarResource;
  experience: ExperienceResource;
  experienceFile: ExperienceFileResource;
  certificate: CertificateResource;
  certificateFile: CertificateFileResource;
  expertHelpRequest: ExpertHelpRequestResource;
  systemOwner: OwnerResource;
  materialOwner: OwnerResource;
  paymentMethod: PaymentMethodResource;
  subscription: SubscriptionResource;
  invoiceDetails: InvoiceDetailsResource;
  subscriptionPaymentMethod: SubscriptionPaymentMethodResource;
  subscriptionPlan: SubscriptionPlanResource;
  availableSubscriptionPlan: AvailableSubscriptionPlanResource;
  term: TermResource;

  onRefreshTokenExpired?: () => void;
  onSubscriptionExpired?: () => void;
  private accessToken: string;
  private refreshToken: string;
  private reauthenticatePromise: Promise<void> | null;
  private axios: AxiosInstance;

  constructor(adapter?: AxiosAdapter) {
    if (!process.env.REACT_APP_API_URL) {
      throw new Error("REACT_APP_API_URL not provided");
    }

    this.axios = Axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      transformRequest: [
        data => (data instanceof FormData ? data : humps.decamelizeKeys(data)),
        ...(Axios.defaults.transformRequest as AxiosTransformer[])
      ],
      transformResponse: [
        ...(Axios.defaults.transformResponse as AxiosTransformer[]),
        data => (data instanceof Blob ? data : humps.camelizeKeys(data))
      ],
      headers: {
        "Content-Type": "application/vnd.api+json"
      },
      adapter
    });

    this.axios.interceptors.request.use(this.addAccessToken);

    this.axios.interceptors.response.use(
      response => response,
      async (error: AxiosError) => {
        if (Axios.isCancel(error)) {
          throw error;
        }

        if (this.isAccessForbidden(error) && this.onSubscriptionExpired) {
          return this.onSubscriptionExpired();
        }

        const isSessionRequest = error.config.url && error.config.url.includes("sessions");
        if (this.isAccessTokenExpired(error) && !isSessionRequest) {
          if (!this.reauthenticatePromise) {
            this.reauthenticatePromise = new Promise((resolve, reject) => {
              this.reauthenticate()
                .then(() => resolve())
                .catch(err => reject(err))
                .finally(() => {
                  this.reauthenticatePromise = null;
                });
            });
          }

          await this.reauthenticatePromise;
          return this.reattemptRequest(error.config);
        }

        throw error;
      }
    );

    this.accessToken = "";
    this.refreshToken = localStorage.getItem("refreshToken") || "";
    this.reauthenticatePromise = null;

    this.account = new AccountResource(this.axios);
    this.accountConfirmation = new AccountConfirmationResource(this.axios);
    this.agreement = new AgreementResource(this.axios);
    this.systemSettings = new SystemSettingsResource(this.axios);
    this.expertSettings = new ExpertSettingsResource(this.axios);
    this.materialSettings = new MaterialSettingsResource(this.axios);
    this.commonSettings = new CommonSettingsResource(this.axios);
    this.system = new SystemResource(this.axios, []);
    this.systemOwnerSystem = new SystemResource(this.axios, ["system_owners"]);
    this.passwordRecovery = new PasswordRecoveryResource(this.axios);
    this.session = new SessionResource(this.axios);
    this.systemPhoto = new SystemPhotoResource(this.axios);
    this.systemDocument = new SystemDocumentResource(this.axios);
    this.material = new MaterialResource(this.axios);
    this.materialSafetyFile = new MaterialSafetyFile(this.axios);
    this.materialTechFile = new MaterialTechFile(this.axios);
    this.materialOwnerMaterial = new MaterialResource(this.axios);
    this.expert = new ExpertResource(this.axios);
    this.avatar = new AvatarResource(this.axios);
    this.experienceFile = new ExperienceFileResource(this.axios);
    this.experience = new ExperienceResource(this.axios);
    this.certificate = new CertificateResource(this.axios);
    this.certificateFile = new CertificateFileResource(this.axios);
    this.expertHelpRequest = new ExpertHelpRequestResource(this.axios);
    this.systemOwner = new OwnerResource(this.axios, "system_owners");
    this.materialOwner = new OwnerResource(this.axios, "material_owners");
    this.paymentMethod = new PaymentMethodResource(this.axios);
    this.subscription = new SubscriptionResource(this.axios);
    this.invoiceDetails = new InvoiceDetailsResource(this.axios);
    this.subscriptionPaymentMethod = new SubscriptionPaymentMethodResource(this.axios);
    this.subscriptionPlan = new SubscriptionPlanResource(this.axios);
    this.availableSubscriptionPlan = new AvailableSubscriptionPlanResource(this.axios);
    this.term = new TermResource(this.axios);
  }

  signIn = async (credentials: Credentials, options: { rememberMe: boolean }) => {
    this.cleanUpTokens();

    const [data] = await this.session.create<Credentials>(credentials);
    this.accessToken = data.accessToken;
    this.refreshToken = data.refreshToken;

    const { sub: accountId } = jwtDecode(this.accessToken);

    if (options.rememberMe) {
      localStorage.setItem("refreshToken", data.refreshToken);
    } else {
      localStorage.removeItem("refreshToken");
    }

    return String(accountId);
  };

  reauthenticate = async () => {
    this.accessToken = "";

    try {
      const { data } = await this.session.find(null, {
        headers: {
          Authorization: `Bearer ${this.refreshToken}`
        }
      });

      const { refreshToken, accessToken } = data[0];

      this.refreshToken = refreshToken;
      this.accessToken = accessToken;

      const { sub: accountId } = jwtDecode(this.accessToken);
      return String(accountId);
    } catch (error) {
      if (getErrorStatus(error) === 401) {
        if (this.onRefreshTokenExpired) {
          this.onRefreshTokenExpired();
        }

        localStorage.removeItem("refreshToken");
      }
      throw error;
    }
  };

  confirmAccount = async (token: string) => {
    this.cleanUpTokens();
    await this.accountConfirmation.create({}, { headers: { Authorization: `Bearer ${token}` } });
  };

  isRefreshTokenAvailable() {
    return !!localStorage.getItem("refreshToken");
  }

  cleanUpTokens() {
    this.refreshToken = "";
    this.accessToken = "";
    localStorage.removeItem("refreshToken");
  }

  private addAccessToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
    if (this.accessToken) {
      config.headers.Authorization = `Bearer ${this.accessToken}`;
    }

    return config;
  };

  private reattemptRequest(requestConfig: AxiosRequestConfig) {
    return this.axios.request(requestConfig);
  }

  private isAccessTokenExpired(error: AxiosError) {
    if (Axios.isCancel(error)) {
      throw error;
    }

    const is401 = getErrorStatus(error) === 401;
    return !!this.refreshToken && is401;
  }

  private isAccessForbidden(error: AxiosError) {
    if (Axios.isCancel(error)) {
      throw error;
    }

    if (getErrorStatus(error) === 402) {
      return true;
    }

    const url: string = get(error, "config.url", "");
    const isSubscriptionRelatedRequest = /subscription(s)?/i.test(url);
    const isGetRequest = error.config.method && error.config.method.toLowerCase() === "get";
    const hasNotFoundStatus = getErrorStatus(error) === 404;

    if (isSubscriptionRelatedRequest && hasNotFoundStatus && isGetRequest) {
      return true;
    }

    return false;
  }
}
