import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { NotSet } from "triangular/components/NotSet/NotSet";

import css from "./Property.module.scss";

interface PropertyProps {
  title: React.ReactNode;
  value?: React.ReactNode;
  translationPath?: string;
  scrollList?: boolean;
}

export const Property = ({ title, value, translationPath, scrollList }: PropertyProps) => {
  const isEmpty = value === null || value === undefined || value === "";
  const { t } = useTranslation();
  const renderedValue = translationPath ? t(`${translationPath}.${value}`) : value;
  const displayedValue = value === "no" ? t("systemDetails.no") : renderedValue;

  return (
    <dl className={classNames(css.wrapper)}>
      <dt>{title}</dt>
      <dd className={scrollList ? css.itemList : ""}>{isEmpty ? <NotSet /> : displayedValue}</dd>
    </dl>
  );
};
