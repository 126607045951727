import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { useStore } from "triangular/stores/StoreContext";
import { namesToSelectOptions } from "triangular/utils/materials";

interface FlowingPropertiesFieldProps {
  label: string;
}

export const FlowingPropertiesField: React.FC<FlowingPropertiesFieldProps> = observer(({ label }) => {
  const { materialSettings } = useStore().materialsStore;
  const { t } = useTranslation();
  const flowingPropertiesOptions = materialSettings
    ? namesToSelectOptions(t)("flowingPropertyOptions", materialSettings.flowingProperties)
    : [];

  return (
    <SelectField
      label={label}
      name="flowingProperties"
      selectProps={{
        options: flowingPropertiesOptions
      }}
      data-testid="flowingProperties"
    />
  );
});
