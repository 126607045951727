import classNames from "classnames";
import React from "react";

import { ReactComponent as Hourglass } from "triangular/static/images/hourglass.svg";
import { ReactComponent as Id } from "triangular/static/images/id.svg";
import { ReactComponent as Job } from "triangular/static/images/job.svg";
import { ReactComponent as Location } from "triangular/static/images/location.svg";

import css from "./IconDetailList.module.scss";

const icons = {
  Id,
  Hourglass,
  Job,
  Location
};

interface IconDetail {
  icon: keyof typeof icons;
  detail: React.ReactNode;
}

export interface IconDetailListProps {
  details: IconDetail[];
  detailClass?: string;
}

export const IconDetailList: React.FC<IconDetailListProps> = ({ details, detailClass }) => {
  return (
    <dl>
      {details.map(({ icon, detail }, index) => {
        const IconComponent = icons[icon];

        return (
          <div className={css.detail} key={index}>
            <dt className={css.dt}>
              <IconComponent />
            </dt>
            <dd className={detailClass ? classNames(css.dd, detailClass) : classNames(css.dd)}>{detail}</dd>
          </div>
        );
      })}
    </dl>
  );
};
