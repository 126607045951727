import classNames from "classnames";
import range from "lodash/range";
import React from "react";

import { TriLink } from "triangular/components/TriLink/TriLink";
import { Typography } from "triangular/components/Typography/Typography";
import { ReactComponent as ArrowDown } from "triangular/static/images/arrow-down.svg";
import { PaginationBase } from "triangular/utils/pagination/types";

import css from "./Pagination.module.scss";

export type PaginationProps = {
  paginationQuery: PaginationBase;
  isOnFirstPage: boolean;
  isOnLastPage: boolean;
  maxPageNumber: number;
  buildUrl: (callback: (prevQuery: PaginationBase) => Partial<PaginationBase>) => string;
  previousPageUrl: string;
  nextPageUrl: string;
  totalItems: number;
};

const paginationNeighbors = 3;
const maxNotDividedNumbers = 9;

export const Pagination: React.FC<PaginationProps> = ({
  isOnFirstPage,
  isOnLastPage,
  maxPageNumber,
  paginationQuery,
  previousPageUrl,
  nextPageUrl,
  buildUrl,
  totalItems
}) => {
  if (totalItems === 0) {
    return null;
  }

  const currentPageNumber = paginationQuery.page.number;
  const numbersEnd = maxPageNumber + 1;
  const allNumbers = range(1, numbersEnd);
  const isInLastPart = currentPageNumber >= numbersEnd - paginationNeighbors;
  const exceedLastPart = currentPageNumber + paginationNeighbors >= numbersEnd + 1;

  function countMiddlePart() {
    if (currentPageNumber < paginationNeighbors || exceedLastPart) {
      return [];
    }

    if (currentPageNumber === paginationNeighbors) {
      return allNumbers.slice(
        currentPageNumber - Math.ceil(paginationNeighbors / 2) + 1,
        currentPageNumber + Math.floor(paginationNeighbors / 2) + 1
      );
    }

    if (!isInLastPart) {
      return allNumbers.slice(
        currentPageNumber - Math.ceil(paginationNeighbors / 2),
        currentPageNumber + Math.floor(paginationNeighbors / 2)
      );
    }

    return allNumbers.slice(
      currentPageNumber - Math.ceil(paginationNeighbors / 2) - 2,
      currentPageNumber + Math.floor(paginationNeighbors / 2) - 2
    );
  }

  const firstPart =
    currentPageNumber < paginationNeighbors
      ? allNumbers.slice(0, paginationNeighbors)
      : allNumbers.slice(0, paginationNeighbors - 1);

  const middlePart = countMiddlePart();

  const lastPart = isInLastPart
    ? allNumbers.slice(numbersEnd - (paginationNeighbors + 1), numbersEnd)
    : allNumbers.slice(numbersEnd - paginationNeighbors, numbersEnd);

  const renderNumbers = (numbers: number[]) => {
    return (
      <Typography size="big">
        <ul className={css.numbersList}>
          {numbers.map(eachNumber => {
            return (
              <li key={eachNumber} className={css.numbersListItem}>
                <TriLink
                  className={classNames(css.button, { [css.activeNumber]: eachNumber === currentPageNumber })}
                  to={buildUrl(({ page }) => ({
                    page: {
                      number: eachNumber,
                      size: page.size
                    }
                  }))}
                >
                  {eachNumber}
                </TriLink>
              </li>
            );
          })}
        </ul>
      </Typography>
    );
  };

  const ellipsis = <>&nbsp; ... &nbsp;</>;

  const dividedPagination = (
    <>
      {renderNumbers(firstPart)}
      {middlePart.length > 0 ? ellipsis : null}
      {renderNumbers(middlePart)}
      {ellipsis}
      {renderNumbers(lastPart)}
    </>
  );

  return (
    <div className={css.container}>
      <TriLink
        className={classNames(css.button, css.previousIcon, { [css.buttonDisabled]: isOnFirstPage })}
        to={previousPageUrl}
      >
        <ArrowDown width={14} height={10} />
      </TriLink>
      {allNumbers.length > maxNotDividedNumbers ? dividedPagination : renderNumbers(allNumbers)}
      <TriLink
        className={classNames(css.button, css.nextIcon, { [css.buttonDisabled]: isOnLastPage })}
        to={nextPageUrl}
      >
        <ArrowDown width={14} height={10} />
      </TriLink>
    </div>
  );
};
