import React from "react";

import { ReactComponent as add } from "triangular/static/images/button-add.svg";
import { ReactComponent as edit } from "triangular/static/images/pencil.svg";
import { ReactComponent as trash } from "triangular/static/images/trash.svg";

const icons = {
  add,
  trash,
  edit
};

interface Props {
  type: keyof typeof icons;
  size?: number;
  className?: string;
}

export const Icon: React.FC<Props> = ({ type, size = 23, className }) => {
  const IconType = icons[type];
  return <IconType width={size} height={size} className={className} />;
};
