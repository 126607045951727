import { connect } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DropDownHeader } from "triangular/components/form/DropDownHeader/DropDownHeader";
import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { MultiSelectField } from "triangular/components/form/MultiSelectField/MultiSelectField";
import { IndustriesField } from "triangular/components/form/system/IndustriesField";
import { MaterialCategoryField } from "triangular/components/form/system/MaterialCategoryField";
import { TextArea } from "triangular/components/form/TextArea/TextArea";
import { HelpIconTooltip } from "triangular/components/HelpIconTooltip/HelpIconTooltip";
import { useStore } from "triangular/stores/StoreContext";
import { settingItemsToSelectOptions } from "triangular/utils/systems";

import css from "./ExpertForm.module.scss";

export const Expertise = connect<
  {},
  {
    industries?: string[] | null;
    label: string;
  }
>(
  observer(({ formik }) => {
    const { t } = useTranslation();
    const { commonsStore, expertStore } = useStore();
    const commonSettings = commonsStore.getSettings();
    const isFormDisabled = expertStore.isConfirmed();

    const indexNum = "1";
    const [activeKey, setActiveKey] = useState(indexNum);

    const options = commonSettings
      ? settingItemsToSelectOptions(t, "name")("processCategoryOptions", commonSettings.processes)
      : [];
    const selectedTargetIndustryIds = formik.values.industries || [];
    const expertiseDescriptionTip = (
      <>
        {t("editExpert.expertiseDescription")}&nbsp;
        <HelpIconTooltip>{t("editExpert.expertiseDescriptionTip")}</HelpIconTooltip>
      </>
    );

    return (
      <Accordion>
        <div className={css.areaOfExpertise}>
          <FormSection className={css.spacing}>
            <DropDownHeader
              eventKeyNum={indexNum}
              handleClick={() => {
                if (activeKey === indexNum) {
                  setActiveKey("");
                } else {
                  setActiveKey(indexNum);
                }
              }}
            >
              {t("expertDetails.expertise")}
            </DropDownHeader>
            <Accordion.Collapse eventKey={indexNum}>
              <div>
                <FieldsRow>
                  <FieldWrapper>
                    <label>{t("expertDetails.industryExpertise")}</label>
                    <IndustriesField
                      className={{ wrapper: css.fullWidthSelect }}
                      name="industries"
                      disabled={isFormDisabled}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <label>{t("expertDetails.processExpertise")}</label>
                    <MultiSelectField
                      name="processCategories"
                      selectProps={{ options, autoComplete: true }}
                      className={{ wrapper: css.fullWidthSelect }}
                      disabled={isFormDisabled}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <label>{t("expertDetails.materialExpertise")}</label>
                    <MaterialCategoryField
                      name="materialCategories"
                      className={{ wrapper: css.fullWidthSelect }}
                      isMultiple={true}
                      selectedTargetIndustryIds={selectedTargetIndustryIds}
                      disabled={isFormDisabled}
                    />
                  </FieldWrapper>
                </FieldsRow>
                <FieldsRow>
                  <FieldWrapper className="col-12">
                    <TextArea
                      label={expertiseDescriptionTip}
                      name="expertiseDescription"
                      data-testid="expertiseDescription"
                      disabled={isFormDisabled}
                    />
                  </FieldWrapper>
                </FieldsRow>
              </div>
            </Accordion.Collapse>
          </FormSection>
        </div>
      </Accordion>
    );
  })
);
