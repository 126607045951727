import { observer } from "mobx-react-lite";
import React from "react";

import { Layout } from "triangular/components/Layout/Layout";
import { useStore } from "triangular/stores/StoreContext";

import { SubscriptionInvoiceDetails } from "./SubscriptionInvoiceDetails";
import { SubscriptionPaymentMethod } from "./SubscriptionPaymentMethod";
import { SubscriptionPlan } from "./SubscriptionPlan/SubscriptionPlan";
import css from "./SubscriptionSetup.module.scss";

export const SubscriptionSetup: React.FC = observer(() => {
  const { paymentsStore } = useStore();
  const { paymentMethodId, billingInterval, level, manualPayment } = paymentsStore.subscription;
  const paymentMethodIsMissing = !paymentMethodId && !manualPayment;
  const subscriptionPlanIsMissing = !billingInterval || !level;

  const renderSetupStep = () => {
    if (subscriptionPlanIsMissing) {
      return <SubscriptionPlan />;
    }

    if (paymentMethodIsMissing) {
      return <SubscriptionPaymentMethod />;
    }

    return <SubscriptionInvoiceDetails />;
  };

  return (
    <Layout withMolecules={false} className={{ main: css.main }}>
      {renderSetupStep()}
    </Layout>
  );
});
