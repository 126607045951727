import { types } from "mobx-state-tree";

import { fileModel } from "../utils/fileModel";

export const certificateModel = types.model("Certificate", {
  id: types.string,
  name: types.maybeNull(types.string),
  organization: types.maybeNull(types.string),
  credentialId: types.maybeNull(types.string),
  credentialUrl: types.maybeNull(types.string),
  expirationDate: types.maybeNull(types.string),
  certificateFiles: types.array(fileModel)
});
