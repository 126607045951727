import classNames from "classnames";
import React from "react";

import { TriLink, TriLinkProps } from "../TriLink/TriLink";
import { TriLoader } from "../TriLoader/TriLoader";

import css from "./Button.module.scss";

export type Variants = "light" | "dark";

type HTMLButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

interface CustomButtonProps {
  variant?: Variants;
  narrow?: boolean;
  transparent?: boolean;
  showLoader?: boolean;
  "data-testid"?: string;
  size?: "big";
  flat?: boolean;
}

export type ButtonProps = CustomButtonProps & HTMLButtonProps;

export const Button: React.FC<ButtonProps> = ({
  children,
  variant = "light",
  narrow = false,
  transparent = false,
  showLoader = false,
  className,
  disabled = false,
  size = "regular",
  flat = false,
  ...buttonProps
}) => {
  const classes = classNames(
    css.button,
    css[variant],
    { [css.transparent]: transparent, [css.narrow]: narrow, [css[size]]: !!size, [css.flat]: flat },
    className
  );
  return (
    <button className={classes} disabled={disabled || showLoader} {...buttonProps}>
      {children}
      {showLoader && <TriLoader className={{ container: css.loader }} size={20} />}
    </button>
  );
};

export const ButtonLink: React.FC<ButtonProps & TriLinkProps> = ({
  children,
  variant = "light",
  narrow = false,
  transparent = false,
  showLoader = false,
  className,
  size = "regular",
  flat = false,
  ...buttonProps
}) => {
  const classes = classNames(
    css.button,
    css[variant],
    { [css.transparent]: transparent, [css.narrow]: narrow, [css[size]]: !!size, [css.flat]: flat },
    css.link,
    className
  );
  return (
    <TriLink className={classes} {...buttonProps}>
      {children}
      {showLoader && <TriLoader className={{ container: css.loader }} size={20} />}
    </TriLink>
  );
};
