import { AxiosInstance } from "axios";

import { Resource } from "../Resource/Resource";
import { Entity } from "../Resource/utils/types";

type MaterialSettingsEntity = Entity<{
  hazardousness: string[];
  materialIndexes: string[];
  flowingProperties: string[];
  storages: string[];
}>;

export class MaterialSettingsResource extends Resource<MaterialSettingsEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "material_settings",
      prefixes: []
    });
  }
}
