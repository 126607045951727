import { observer } from "mobx-react-lite";
import * as React from "react";

import { Typography } from "triangular/components/Typography/Typography";
import { useStore } from "triangular/stores/StoreContext";

import css from "./Snackbars.module.scss";

export const Snackbars: React.FC = observer(() => {
  const { snackbarStore } = useStore();
  const snackbars = Array.from(snackbarStore.snackbars.entries());

  return (
    <ul className={css.list} data-testid="snackbarList">
      {snackbars.map(([id, snackbar]) => (
        <li key={id} className={css.wrapper}>
          <div key={id} className={css[snackbar.type]} data-testid={`${snackbar.type}Snackbar`}>
            <Typography bold={true}>{snackbar.message}</Typography>
            <button
              type="button"
              className={css.button}
              onClick={() => {
                snackbarStore.deleteSnackbar(id);
              }}
            />
          </div>
        </li>
      ))}
    </ul>
  );
});
