import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import { SearchScene } from "triangular/components/SearchSceneLayout/SearchSceneLayout";
import { SearchSelector } from "triangular/components/SearchSelector/SearchSelector";

import { ExpertList } from "./ExpertList";
import { ExpertsCount } from "./ExpertsCount";
import { Industries } from "./filters/Industries";
import { Languages } from "./filters/Languages";
import { MaterialCategories } from "./filters/MaterialCategories";
import { ProcessCategories } from "./filters/ProcessCategories";
import css from "./MobileViewContent.module.scss";
import { Search } from "./Search";
import { SearchPhrase } from "./SearchPhrase";
import { useFilterBadges } from "./utils/useFilterBadges";

export const MobileViewContent = observer(() => {
  const filterBadges = useFilterBadges();
  return (
    <>
      <Container fluid className={css.container}>
        <Row className={classNames("justify-content-center")}>
          <Col sm={2} md={2} lg={2}>
            <SearchSelector className={css.circle} position={css.position} logoSize={css.logo} />
          </Col>
        </Row>
      </Container>
      <SearchScene.MobileViewContent
        filters={{
          search: <Search />,
          searchPhrase: <SearchPhrase />,
          fields: [<Languages />, <ProcessCategories />, <Industries />, <MaterialCategories />]
        }}
        sortSection={<ExpertsCount />}
        searchSection={null}
        results={<ExpertList />}
        filterBadges={filterBadges}
      />
    </>
  );
});
