import React from "react";
import { useTranslation } from "react-i18next";

import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { useStore } from "triangular/stores/StoreContext";
import { namesToSelectOptions } from "triangular/utils/materials";

interface StorageNeededFieldProps {
  label: string;
}

export const StorageNeededField: React.FC<StorageNeededFieldProps> = ({ label }) => {
  const { materialSettings } = useStore().materialsStore;
  const { t } = useTranslation();
  const storageOptions = materialSettings ? namesToSelectOptions(t)("storageOptions", materialSettings.storages) : [];
  const sortedStorageOptions = storageOptions.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <SelectField
      name="storageNeeded"
      label={label}
      selectProps={{
        options: sortedStorageOptions
      }}
      data-testid="storageNeeded"
    />
  );
};
