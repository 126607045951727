import React from "react";
import { useTranslation } from "react-i18next";

import { NotSet } from "triangular/components/NotSet/NotSet";
import { useStore } from "triangular/stores/StoreContext";
import { SystemModelSnapshot } from "triangular/stores/SystemsStore/systemModel";
import { flattenSystemSettings } from "triangular/utils/systems";

export const SystemType: React.FC<{ systemType: SystemModelSnapshot["systemType"] }> = ({ systemType }) => {
  const { t } = useTranslation();
  const { commonsStore } = useStore();
  const commonSettings = commonsStore.getSettings();
  const systemTypeSettings = flattenSystemSettings(commonSettings.systemTypes.toJSON());

  const systemTypeSetting = systemType ? systemTypeSettings.find(eachType => eachType.id === systemType.id) : null;
  const systemTypeName = systemTypeSetting && systemTypeSetting.name;
  return systemTypeName ? t(`systemSettings.systemTypeOptions.${systemTypeName}`) : <NotSet />;
};
