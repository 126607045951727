import React from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { DisconnectedAbstractField } from "triangular/components/form/AbstractField/DisconnectedAbstractField";
import { Slider } from "triangular/components/Slider/Slider";
import { useStore } from "triangular/stores/StoreContext";

import { useSystemsPaginationContext } from "../../utils/pagination";
import { updateInstalledPowerKw } from "../../utils/updates";

import css from "./InstalledPower.module.scss";

export const InstalledPower: React.FC = () => {
  const { paginationQuery, buildUrl } = useSystemsPaginationContext();
  const { systemsStore } = useStore();
  const { t } = useTranslation();
  const { history } = useRouter();
  const installedPowerSettings = systemsStore.getSettings().installedPowerKw;
  const { installedPowerKw } = paginationQuery;

  const maxInstalledPower = Number(installedPowerSettings[installedPowerSettings.length - 1]);
  const range = [0, maxInstalledPower];

  return (
    <DisconnectedAbstractField
      label={`${t("installedPower")} [kW]`}
      render={() => (
        <div className={css.sliderWrapper}>
          <Slider
            testId="installedPowerFilter"
            mode="allowCrossing"
            step={1}
            domain={range}
            values={installedPowerKw || range}
            onChange={values => {
              const [min, max] = values;
              history.push(buildUrl(() => updateInstalledPowerKw([min, max])));
            }}
          />
        </div>
      )}
    />
  );
};
