import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { MultiSelectField, MultiSelectFieldProps } from "triangular/components/form/MultiSelectField/MultiSelectField";
import { useStore } from "triangular/stores/StoreContext";
import { settingItemsToSelectOptions } from "triangular/utils/systems";

interface IndustriesFieldProps {
  className?: MultiSelectFieldProps["className"];
  name: string;
  label?: string;
  disabled?: boolean;
}

export const IndustriesField: React.FC<IndustriesFieldProps> = observer(({ className, name, label, disabled }) => {
  const { t } = useTranslation();
  const { commonsStore } = useStore();
  const commonSettings = commonsStore.getSettings();

  const noRestrictionsIndustryId = commonSettings.noRestrictionsIds.industry;
  const industriesOptions = settingItemsToSelectOptions(t)("targetIndustryOptions", commonSettings.industries);

  return (
    <MultiSelectField
      className={className}
      label={label}
      name={name}
      disabled={disabled}
      selectProps={{
        options: industriesOptions
      }}
      onChangeCallback={(form, updatedTargetIndustries) => {
        const materialCategories = form.values.materialCategories || [];
        form.setFieldValue(
          "materialCategories",
          materialCategories.filter((eachCategoryId: string) =>
            updatedTargetIndustries.some(selectedIndustryId => {
              const availableCategories = commonSettings.materialCategoriesByIndustries.get(selectedIndustryId);

              if (availableCategories) {
                return availableCategories.map(({ id }) => id).includes(eachCategoryId);
              }

              return false;
            })
          )
        );
      }}
      required={true}
      data-testid="targetIndustries"
      noRestrictionsValue={noRestrictionsIndustryId}
    />
  );
});
