import { AxiosInstance } from "axios";

import { Resource } from "../Resource/Resource";
import { Entity } from "../Resource/utils/types";

type AgreementAttributes = {
  accountId: string;
  termId: string;
  status: AgrementType;
};

export type AgreementEntity = Entity<AgreementAttributes, {}>;

export class AgreementResource extends Resource<AgreementEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "agreements",
      prefixes: []
    });
  }
}
