import { types } from "mobx-state-tree";

import { fileModel } from "../utils/fileModel";

import { certificateModel } from "./certificateModel";
import { experienceModel } from "./experienceModel";
import { languageModel } from "./languageModel";

export const currentExpertModel = types.model("CurrentExpert", {
  id: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  showcaseTitle: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  expertCompany: types.maybeNull(types.string),
  phoneNumber: types.maybeNull(types.string),
  mobileNumber: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  reach: types.maybeNull(types.string),
  countryCodeBlacklist: types.maybeNull(types.array(types.string)),
  processCategories: types.maybeNull(types.array(types.string)),
  industries: types.maybeNull(types.array(types.string)),
  specialties: types.maybeNull(types.array(types.string)),
  materialCategories: types.maybeNull(types.array(types.string)),
  languagesKnowledge: types.maybeNull(types.array(languageModel)),
  avatar: types.maybeNull(fileModel),
  experiences: types.maybeNull(types.array(experienceModel)),
  expertiseDescription: types.maybeNull(types.string),
  certificates: types.maybeNull(types.array(certificateModel)),
  vatNumber: types.maybeNull(types.string)
});
