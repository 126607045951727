import React from "react";

import { FloatingLoader } from "./FloatingLoader/FloatingLoader";
import css from "./LoadingOverlay.module.scss";

interface LoadingOverlayProps {
  isVisible: boolean;
  withFloatingLoader?: boolean;
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ isVisible, children, withFloatingLoader = false }) => {
  if (isVisible) {
    return (
      <div className={css.loadingOverlay}>
        {children || " "}
        {withFloatingLoader && <FloatingLoader />}
      </div>
    );
  }

  return null;
};
