import { useBreakpoint } from "triangular/components/Breakpoint/Breakpoint";

const MAX_COLUMNS = 3;

export function useColumnWidth(columns = MAX_COLUMNS) {
  if (columns > MAX_COLUMNS) {
    columns = MAX_COLUMNS;
  }

  const sizes = [
    {
      width: "33%",
      state: useBreakpoint({ minWidth: "large" })
    },
    {
      width: "50%",
      state: useBreakpoint({ minWidth: "medium" })
    },
    {
      width: "100%",
      state: useBreakpoint({ minWidth: "small" })
    }
  ].splice(MAX_COLUMNS - columns);

  return (sizes.find(size => size.state) || sizes[sizes.length - 1]).width;
}
