import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DropDownHeader } from "triangular/components/form/DropDownHeader/DropDownHeader";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { useModal } from "triangular/components/Modal/Modal";
import { getCountryBy } from "triangular/consts";
import { ExpertExperienceItem } from "triangular/stores/ExpertStore/ExpertStore";
import { useStore } from "triangular/stores/StoreContext";
import { formatPeriod } from "triangular/utils/dates";

import css from "../ExpertForm.module.scss";
import { ExpertItem } from "../ExpertItem/ExpertItem";
import { ExpertItemList } from "../ExpertItemList/ExpertItemList";

import { ExperienceModal } from "./ExperienceModal";

export const Experiences: React.FC = observer(() => {
  const { t } = useTranslation();
  const experienceModal = useModal();
  const [experienceId, setExperienceId] = useState<string | null>(null);
  const { expertStore } = useStore();
  const isFormDisabled = expertStore.isConfirmed();

  const expertExperiences: ExpertExperienceItem[] = expertStore.currentExpert.experiences || [];

  const indexNum = "2";
  const [activeKey, setActiveKey] = useState(indexNum);

  const handleExperienceModalClose = () => {
    experienceModal.close();
    setExperienceId(null);
  };

  return (
    <Accordion>
      <ExperienceModal
        isOpen={experienceModal.isOpen}
        onClose={handleExperienceModalClose}
        experienceId={experienceId}
      />
      <FormSection className={css.spacing}>
        <DropDownHeader
          eventKeyNum={indexNum}
          handleClick={() => {
            if (activeKey === indexNum) {
              setActiveKey("");
            } else {
              setActiveKey(indexNum);
            }
          }}
        >
          {t("editExpert.experiences")}
        </DropDownHeader>
        <Accordion.Collapse eventKey={indexNum}>
          <ExpertItemList
            header={t("expertDetails.addExperience")}
            onAdd={experienceModal.open}
            disabled={isFormDisabled}
          >
            {expertExperiences.map(({ title, countryCode, id, company, startDate, endDate }) => {
              const country = countryCode && getCountryBy.code(countryCode);
              const appliedLocation = country
                ? [
                    {
                      icon: "Location" as const,
                      detail: country.name
                    }
                  ]
                : [];

              const appliedPeriod = startDate
                ? [
                    {
                      icon: "Hourglass" as const,
                      detail: startDate ? formatPeriod(startDate, endDate) : null
                    }
                  ]
                : [];

              return (
                <ExpertItem
                  key={id}
                  title={title}
                  details={[
                    {
                      icon: "Job",
                      detail: company
                    },
                    ...appliedPeriod,
                    ...appliedLocation
                  ]}
                  onEdit={() => {
                    setExperienceId(id);
                    experienceModal.open();
                  }}
                  onRemove={() => expertStore.removeExperience(id)}
                  removePromptHeader={t("editExpert.removeExperiencePromptHeader")}
                  removePromptMessage={t("editExpert.removeExperiencePromptMessage")}
                  disabled={isFormDisabled}
                />
              );
            })}
          </ExpertItemList>
        </Accordion.Collapse>
      </FormSection>
    </Accordion>
  );
});
