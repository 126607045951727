import React from "react";
import AliceCarousel from "react-alice-carousel";

import css from "./Carousel.module.scss";

export const Carousel: React.FC<{ items: React.ReactElement[]; startIndex?: number }> = ({ items, startIndex = 0 }) => {
  return (
    <AliceCarousel
      stagePadding={{
        paddingRight: 15,
        paddingLeft: 15
      }}
      infinite={false}
      buttonsDisabled={true}
      startIndex={startIndex}
      dotsDisabled={items.length <= 1}
    >
      {items.map((element, index) => (
        <div key={index} className={css.carouselBox}>
          {element}
        </div>
      ))}
    </AliceCarousel>
  );
};
