import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { getSnapshot } from "mobx-state-tree";
import React from "react";
import { useTranslation } from "react-i18next";

import { Modal, ModalProps } from "triangular/components/Modal/Modal";
import { PageTitle } from "triangular/components/PageTitle/PageTitle";
import { ExpertCertificateItem } from "triangular/stores/ExpertStore/ExpertStore";
import { useStore } from "triangular/stores/StoreContext";

import css from "../ExpertForm.module.scss";

import { CertificateForm } from "./CertificateForm";

type CertificateModalProps = Omit<ModalProps, "className"> & {
  certificateId?: string | null;
};

export const CertificateModal: React.FC<CertificateModalProps> = observer(({ certificateId, ...props }) => {
  const { t } = useTranslation();
  const currentT = (path: string) => t(`editExpert.certificateModal.${path}`);

  const title = certificateId ? currentT("editCertificate") : currentT("addCertificate");
  const { expertStore } = useStore();
  const { currentExpert } = getSnapshot(expertStore);
  const certificates: ExpertCertificateItem[] = currentExpert.certificates || [];
  const currentCertificate = certificates.find(eachCertificate => eachCertificate.id === certificateId);

  return (
    <Modal
      className={modalCss => ({
        modal: classNames(css.modalForm, modalCss.modal),
        overlay: classNames(css.modalFormOverlay, modalCss.overlay)
      })}
      {...props}
    >
      <PageTitle title={title} />
      <CertificateForm certificate={currentCertificate} onClose={props.onClose} />
    </Modal>
  );
});
