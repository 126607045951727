import React from "react";

import { CurrentFilterBadges, CurrentFilterBadgesProps } from "../CurrentFilterBadges/CurrentFilterBadges";

import { DesktopFilters } from "./DesktopFilters/DesktopFilters";
import css from "./DesktopViewContent.module.scss";

export interface DesktopViewProps {
  searchSection: React.ReactNode;
  searchPhraseSection: React.ReactNode;
  sortSection: React.ReactNode;
  filterBadges: CurrentFilterBadgesProps["badges"];
  results: React.ReactNode;
  basicFilters: React.ReactNode;
  advancedFilters?: React.ReactNode[];
  helpButton?: React.ReactNode;
}

export const DesktopViewContent: React.FC<DesktopViewProps> = ({
  searchSection,
  searchPhraseSection,
  filterBadges,
  results,
  basicFilters,
  advancedFilters,
  sortSection,
  helpButton
}) => {
  return (
    <div className={css.wrapper}>
      <DesktopFilters advancedFilters={advancedFilters}>{basicFilters}</DesktopFilters>
      <div className={css.content}>
        <div className={css.searchSection}>
          {searchSection} {helpButton}
        </div>
        <div className={css.searchPhraseSection}>
          {searchPhraseSection}
          <div className={css.sortSection}>{sortSection}</div>
        </div>
        <div className={css.filterBadgesSection}>
          <CurrentFilterBadges badges={filterBadges} />
        </div>
        {results}
      </div>
    </div>
  );
};
