import React, { useState } from "react";

import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";

import { StripeField } from "./StripeField";
import { StripeForm } from "./StripeForm/StripeForm";
import { PaymentMethodFormProps } from "./types";

export const StripeCardForm: React.FC<PaymentMethodFormProps> = ({ onCancel, afterSubmit, handlePayByInvoice }) => {
  const [readyElements, setReadyElements] = useState({
    CardNumberElement: false,
    CardCvcElement: false,
    CardExpiryElement: false
  });

  const setIsReady = (type: string) =>
    setReadyElements(prev => ({
      ...prev,
      [type]: true
    }));

  const isLoading = !Object.values(readyElements).every(v => v);

  return (
    <StripeForm
      handlePayByInvoice={handlePayByInvoice}
      onSubmit={async ({ stripe, elements }) => {
        const cardNumber = elements.getElement("cardNumber");

        if (!cardNumber) {
          throw new Error("Card number element not available");
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardNumber
        });

        if (error || !paymentMethod) {
          throw error;
        }

        if (!paymentMethod) {
          throw new Error("Payment method not available");
        }

        await afterSubmit({ paymentMethod });
      }}
      render={() => (
        <FieldsRow>
          <FieldWrapper className="col-12">
            <StripeField type="CardNumberElement" onReady={setIsReady} options={{ showIcon: true }} />
          </FieldWrapper>
          <FieldWrapper className="col-12">
            <StripeField type="CardCvcElement" onReady={setIsReady} />
          </FieldWrapper>
          <FieldWrapper className="col-12">
            <StripeField type="CardExpiryElement" onReady={setIsReady} />
          </FieldWrapper>
        </FieldsRow>
      )}
      isLoading={isLoading}
      onCancel={onCancel}
    />
  );
};
