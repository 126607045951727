import { TFunction } from "i18next";
import * as Yup from "yup";

import { numberValidator } from "triangular/components/form/validators/numberValidator";
import { requiredBoolean } from "triangular/components/form/validators/requiredBoolean";
import { requiredString } from "triangular/components/form/validators/requiredString";

import { phValidator } from "../../validators/phValidator";

import { SystemSchema } from "./types";

export const createSystemSchema = (t: TFunction) =>
  Yup.object<SystemSchema>({
    name: requiredString(t),
    brand: Yup.string().nullable(),
    model: Yup.string().nullable(),
    processCategory: requiredString(t),
    systemCategory: Yup.string().nullable(),
    systemType: Yup.string().nullable(),
    designType: Yup.array()
      .of(Yup.string())
      .nullable(),
    countryCode: requiredString(t),
    contactMaterials: Yup.mixed().when("installedPowerMillSelect", {
      is: null,
      then: Yup.mixed().nullable(),
      otherwise: Yup.array()
        .of(Yup.string())
        .nullable()
    }),
    nonContactMaterials: Yup.mixed().when("installedPowerMillSelect", {
      is: null,
      then: Yup.mixed().nullable(),
      otherwise: Yup.array()
        .of(Yup.string())
        .nullable()
    }),
    installedPowerMillSelect: Yup.mixed().nullable(),
    installedPowerKw: Yup.mixed().when("installedPowerMillSelect", {
      is: "kw",
      then: Yup.string().nullable()
    }),
    installedPowerFlowRate: Yup.mixed().when("installedPowerMillSelect", {
      is: "m3/h@bar",
      then: Yup.mixed().nullable(),
      otherwise: numberValidator(t)(0.01)
    }),
    installedPowerBar: Yup.mixed().when("installedPowerMillSelect", {
      is: "m3/h@bar",
      then: Yup.mixed().nullable(),
      otherwise: numberValidator(t)(0.01)
    }),
    installedPowerClassifier: Yup.mixed().when("installedPowerMillSelect", {
      is: null,
      then: Yup.mixed().nullable(),
      otherwise: numberValidator(t)(0.01)
    }),
    materialsWhitelist: Yup.array()
      .of(Yup.string())
      .nullable(),
    materialsBlacklist: Yup.array()
      .of(Yup.string())
      .nullable(),
    colorsWhitelist: Yup.array()
      .of(Yup.string())
      .nullable(),
    abrasiveness: Yup.string().nullable(),
    explosiveness: Yup.string().nullable(),
    corrosiveness: phValidator(t, true).nullable(),
    hazardousness: Yup.boolean().nullable(),
    safetyMeasures: Yup.string().nullable(),
    additionalInfo: Yup.string().nullable(),
    systemPhotoFiles: Yup.array()
      .of(Yup.object({ id: Yup.string(), filename: Yup.string() }))
      .nullable(),
    systemDocumentFiles: Yup.array()
      .of(Yup.object({ id: Yup.string(), filename: Yup.string() }))
      .nullable(),
    hideSensitiveData: requiredBoolean(t),
    materialCategories: Yup.array()
      .of(Yup.string())
      .nullable(),
    airflow: Yup.mixed()
      .nullable()
      .when("airflowEnabled", {
        is: true,
        then: Yup.mixed().nullable(),
        otherwise: numberValidator(t)(0.01)
      }),
    airflowEnabled: Yup.boolean().nullable(),
    targetIndustries: Yup.array()
      .of(Yup.string())
      .min(1, t("validation.minArrayElements", { minElements: 1 }))
      .nullable()
      .required(t("validation.required")),
    minimumMaterialAmount: Yup.mixed()
      .nullable()
      .when("minimumMaterialAmountEnabled", {
        is: true,
        then: numberValidator(t)(0.001).required(t("validation.required"))
      }),
    minimumMaterialAmountEnabled: requiredBoolean(t)
  });

export const defaultValues: SystemSchema = {
  name: null,
  brand: null,
  model: null,
  processCategory: null,
  systemCategory: null,
  systemType: null,
  designType: null,
  countryCode: null,
  contactMaterials: null,
  nonContactMaterials: null,
  installedPowerMillSelect: "empty",
  installedPowerKw: null,
  installedPowerFlowRate: null,
  installedPowerBar: null,
  installedPowerClassifier: null,
  materialsWhitelist: null,
  materialsBlacklist: null,
  colorsWhitelist: null,
  systemDocumentFiles: null,
  systemPhotoFiles: null,
  abrasiveness: null,
  explosiveness: null,
  corrosiveness: null,
  hazardousness: null,
  safetyMeasures: null,
  additionalInfo: null,
  hideSensitiveData: false,
  materialCategories: null,
  airflow: null,
  airflowEnabled: false,
  targetIndustries: null,
  minimumMaterialAmount: null,
  minimumMaterialAmountEnabled: null
};
