import classNames from "classnames";
import React from "react";

import { FormSectionHeader } from "triangular/components/form/FormSectionHeader/FormSectionHeader";

import css from "./SectionHeader.module.scss";

export const SectionHeader: React.FC<{ contained?: boolean; withBottomSpace?: boolean }> = ({
  children,
  withBottomSpace
}) => {
  return (
    <div className={classNames(css.wrapper, { [css.bottomSpace]: withBottomSpace })}>
      <FormSectionHeader>{children}</FormSectionHeader>
    </div>
  );
};
