export function toBase64(file: File) {
  return new Promise<string>(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", () => resolve(reader.result as string));
  });
}

export function onPressEnter(callback: (e: React.KeyboardEvent<any>) => void) {
  return (e: React.KeyboardEvent<any>) => {
    if (e.key === "Enter" && e.target === e.currentTarget) {
      callback(e);
    }
  };
}

export function onTrigger(callback: () => void) {
  return {
    onClick: callback,
    onKeyPress: onPressEnter(callback),
    onTouchEnd: (e: React.TouchEvent) => {
      e.preventDefault(); // prevents from firing click event
      callback();
    }
  };
}
