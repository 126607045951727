import { History } from "history";
import get from "lodash/get";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { Badge } from "triangular/components/Badge/Badge";
import { Button } from "triangular/components/Button/Button";
import { Hr } from "triangular/components/Hr/Hr";
import { useModal } from "triangular/components/Modal/Modal";
import { Prompt } from "triangular/components/Prompt/Prompt";
import { SectionTitle } from "triangular/components/SectionTitle/SectionTitle";
import { Typography } from "triangular/components/Typography/Typography";
import { i18n } from "triangular/i18next/i18next";
import { useStore } from "triangular/stores/StoreContext";

import { ExpertCertificateItem, ExpertExperienceItem } from "../ExpertDetailItem/ExpertDetailItem";
import { ExpertPublicData } from "../ExpertDetails";
import { ExpertDetailsHeader } from "../ExpertDetailsHeader";

import css from "./ExpertDetailsContent.module.scss";

const Language: React.FC<{ name: string; level: string }> = ({ name: language, level }) => {
  return (
    <div className={css.language}>
      <Typography bold={true} upperCase={true}>
        {i18n.t(`expertSettings.languageOptions.${language}`)}:
      </Typography>

      <span className={css.languageLevel}>{i18n.t(`expertSettings.languageLevelOptions.${level}`)}</span>
    </div>
  );
};

function getCurrentOrigin(history: History) {
  const { search } = history.location;
  return new URLSearchParams(search).get("origin");
}

export const getExpertTitle = (
  experiences?: Array<{
    title?: string | null;
    description?: string | null;
  }> | null
) => {
  const emptyExperience = {
    title: null,
    description: null
  };

  const currentExperience = experiences && experiences[0];

  return currentExperience
    ? {
        ...emptyExperience,
        title: currentExperience.title,
        description: currentExperience.description
      }
    : emptyExperience;
};

interface ExpertDetailsContentProps {
  expert: ExpertPublicData;
}

export const ExpertDetailsContent: React.FC<ExpertDetailsContentProps> = observer(({ expert }) => {
  const { t } = useTranslation();
  const { expertStore, snackbarStore, userStore } = useStore();
  const requestPrompt = useModal();
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const expertId = expert.id;
  const expertiseDescription = expert.expertiseDescription;
  const { isAuthenticated, type } = userStore;
  const { history } = useRouter();

  const origin = getCurrentOrigin(history);

  const requestExpert = (e: React.MouseEvent) => {
    e.preventDefault();
    requestPrompt.open();
  };

  const requestAction = async () => {
    setIsRequestLoading(true);

    try {
      await expertStore.sendHelpRequest({ expert: { id: expertId } });
      snackbarStore.addSnackbar({ type: "success", message: t("expertDetails.request.confirmation") });
    } catch (err) {
      snackbarStore.showGenericError(err);
    }

    setIsRequestLoading(false);
  };

  return (
    <div className={css.wrapper}>
      <ExpertDetailsHeader expert={expert} origin={origin} />
      <Hr />
      {get(expert, "industries.length") ? (
        <section className={css.section}>
          <SectionTitle title={t("expertDetails.industryExpertise")} />
          {expert.industries.map(industry => (
            <Badge className={css.tag} key={industry.id} variant="greyishBrown">
              {t(`systemSettings.targetIndustryOptions.${industry.name}`)}
            </Badge>
          ))}
        </section>
      ) : null}
      {get(expert, "specialties.length") ? (
        <section className={css.section}>
          <SectionTitle title={t("specialty")} />
          {expert.specialties.map(specialty => (
            <Badge className={css.tag} key={specialty.id} variant="greyishBrown">
              {t(`expertSettings.specialtyOptions.${specialty.name}`)}
            </Badge>
          ))}
        </section>
      ) : null}

      {get(expert, "materialCategories.length") ? (
        <section className={css.section}>
          <SectionTitle title={t("expertDetails.materialExpertise")} />

          {expert.materialCategories.map(category => (
            <Badge className={css.tag} key={category.id} variant="greyishBrown">
              {t(`systemSettings.materialCategoryOptions.${category.name}`)}
            </Badge>
          ))}
        </section>
      ) : null}

      {get(expert, "processCategories.length") ? (
        <section className={css.section}>
          <SectionTitle title={t("expertDetails.processExpertise")} />

          {expert.processCategories!.map((name: string) => (
            <Badge key={name} className={css.tag} variant="greyishBrown">
              {t(`systemSettings.processCategoryOptions.${name}`)}
            </Badge>
          ))}
        </section>
      ) : null}

      {expertiseDescription ? (
        <section className={css.section}>
          <SectionTitle title={t("editExpert.expertiseDescription")} />
          <div>{expertiseDescription}</div>
        </section>
      ) : null}

      {get(expert, "languagesKnowledge.length") ? (
        <section className={css.section}>
          <SectionTitle title={t("languages")} />

          {expert.languagesKnowledge!.map((language: { name: string; level: string }, index: number) => (
            <Language key={index} {...language} />
          ))}
        </section>
      ) : null}

      {get(expert, "experiences.length") ? (
        <>
          <section className={css.section}>
            <SectionTitle title={t("expertDetails.experiences")} />

            {expert.experiences.map((experience, index: number) => (
              <ExpertExperienceItem key={index} detail={experience} />
            ))}
          </section>
          <Hr />
        </>
      ) : null}

      {get(expert, "certificates.length") ? (
        <>
          <section className={css.section}>
            <SectionTitle title={t("expertDetails.certificates")} />

            {expert.certificates.map((certificate, index: number) => (
              <ExpertCertificateItem key={index} detail={certificate} />
            ))}
          </section>
          <Hr />
        </>
      ) : null}

      {isAuthenticated && type !== "experts" ? (
        <section className={css.requestWrapper}>
          <Button onClick={requestExpert} showLoader={isRequestLoading}>
            {t("expertDetails.request.action")}
          </Button>
        </section>
      ) : null}

      <Prompt
        isOpen={requestPrompt.isOpen}
        onClose={requestPrompt.close}
        onAgree={requestAction}
        header={t("expertDetails.request.header")}
        message={t("expertDetails.request.message", {
          fullName: `${expert.firstName} ${expert.lastName}`
        })}
      />
    </div>
  );
});
