import React from "react";

import { SystemTypeIcon } from "triangular/components/SystemTypeIcon/SystemTypeIcon";
import { useStore } from "triangular/stores/StoreContext";
import { SystemModelSnapshot } from "triangular/stores/SystemsStore/systemModel";
import { flattenSystemSettings } from "triangular/utils/systems";

export const Pictogram: React.FC<{
  systemCategory: SystemModelSnapshot["systemCategory"];
}> = ({ systemCategory }) => {
  const { commonsStore } = useStore();
  const commonSettings = commonsStore.getSettings();
  const systemCategoriesSettings = flattenSystemSettings(commonSettings.systemCategories.toJSON());

  const systemCategorySetting =
    systemCategory && systemCategoriesSettings.find(eachCategory => eachCategory.id === systemCategory.id);

  const systemCategoryName = systemCategorySetting && systemCategorySetting.name;

  return <SystemTypeIcon size="small" type={systemCategoryName} />;
};
