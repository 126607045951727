import React from "react";
import { useTranslation } from "react-i18next";

import { IconLink } from "triangular/components/IconLink/IconLink";
import { Typography } from "triangular/components/Typography/Typography";

import css from "./SearchPhrase.module.scss";

interface SearchPhraseProps {
  urlWithoutSearchPhrase: string;
  value?: string;
}

export const SearchPhrase: React.FC<SearchPhraseProps> = ({ urlWithoutSearchPhrase, value }) => {
  const { t } = useTranslation();

  return value ? (
    <div className={css.wrapper}>
      <Typography size="big" bold={true} className={css.label}>
        {t("systems.searchResultsFor")}&nbsp;
      </Typography>
      <Typography size="big" bold={true} ellipsis={true} data-testid="searchPhraseValue">
        {value}
      </Typography>
      <IconLink icon="cross" to={urlWithoutSearchPhrase} className={css.iconLink} testId="clearSearchPhrase" />
    </div>
  ) : null;
};
