import React from "react";
import { Link } from "react-router-dom";

import { routes } from "triangular/Routes/routesConfiguration";
import { ExpertModelSnapshot } from "triangular/stores/ExpertSearchStore/models";

export const ExpertDetailsLink: React.FC<{
  className?: string;
  expertId: ExpertModelSnapshot["id"];
  testId?: string;
  origin?: string;
}> = ({ children, className, expertId, testId, origin }) => {
  const expertDetailsRoute = routes.expertDetails.build({ id: expertId });
  const originQueryParam = origin ? "?origin=" + origin : "";
  return (
    <Link to={expertDetailsRoute + originQueryParam} className={className} data-testid={testId}>
      {children}
    </Link>
  );
};
