import { AxiosInstance } from "axios";

import { Resource } from "../Resource/Resource";
import { Entity } from "../Resource/utils/types";

type SubscriptionAttributes = {
  paymentMethodId: string;
  billingInterval: SubscriptionInterval;
  status: SubscriptionStatus;
  level: SubscriptionLevel;
  manualPayment: boolean;
  currentPeriodStart: string;
  currentPeriodEnd: string;
  trialEndAt: string;
};

type SubscriptionRelationships = {
  invoiceDetail?: Entity;
};

export type SubscriptionEntity = Entity<SubscriptionAttributes, SubscriptionRelationships>;

export class SubscriptionResource extends Resource<SubscriptionEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "subscriptions",
      relationships: {
        invoiceDetail: {
          type: "invoice_details"
        }
      }
    });
  }
}
