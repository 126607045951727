import React from "react";
import { useTranslation } from "react-i18next";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

import css from "./UserGreeting.module.scss";

export const UserGreeting: React.FC = () => {
  const { t } = useTranslation();

  function showGreeting() {
    return (
      <div>
        <div className={css.greetingWrapper}>
          <p className={css.greeting}>{t("userHomeGreeting")}</p>
        </div>
        <br />
      </div>
    );
  }

  return <div className={css.wrapper}>{showGreeting()}</div>;
};
