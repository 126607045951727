import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { Alert } from "triangular/components/Alert/Alert";
import { Button } from "triangular/components/Button/Button";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { PaymentMethodForm } from "triangular/components/form/stripe/PaymentMethodForm/PaymentMethodForm";
import { Modal } from "triangular/components/Modal/Modal";
import { useModal } from "triangular/components/Modal/Modal";
import { PageLoader } from "triangular/components/PageLoader/PageLoader";
import { PageTitle } from "triangular/components/PageTitle/PageTitle";
import { Prompt } from "triangular/components/Prompt/Prompt";
import { SectionTitle } from "triangular/components/SectionTitle/SectionTitle";
import { useStore } from "triangular/stores/StoreContext";
import { formatDate } from "triangular/utils/dates";

import { MethodList } from "./MethodList/MethodList";
import { usePaymentMethods } from "./usePaymentMethods";

export const PaymentMethods = observer(() => {
  const { t } = useTranslation();
  const { paymentsStore, snackbarStore } = useStore();
  const createMethodModal = useModal();
  const deleteMethodModal = useModal<{ methodId: string }>();
  const defaultMethodModal = useModal<{ methodId: string }>();
  const {
    loadingState,
    paymentMethods,
    createPaymentMethod,
    deletePaymentMethod,
    setDefaultPaymentMethod
  } = usePaymentMethods();

  const getSubscriptionAlert = () => {
    if (paymentsStore.subscription.status === "active") {
      return null;
    }

    return (
      <Alert>
        {(() => {
          switch (paymentsStore.subscription.status) {
            case "unpaid":
              return paymentsStore.subscription.manualPayment
                ? t("payments.unpaidByInvoiceSubscriptionAlert")
                : t("payments.unpaidAutochargeSubscriptionAlert");
            case "active_cancelled":
              // TODO: add currentPeriodEnd to the translation when it starts to work as expected
              return t("payments.cancelledSubscriptionAlert", {
                currentPeriodEnd: formatDate(paymentsStore.subscription.currentPeriodEnd || "")
              });
          }
        })()}
      </Alert>
    );
  };

  return (
    <FormSection>
      {getSubscriptionAlert()}
      <SectionTitle>{t("payments.paymentMethods")}</SectionTitle>
      {loadingState === "in_progress" ? (
        <PageLoader />
      ) : (
        <>
          <MethodList
            defaultMethodId={paymentsStore.subscription.paymentMethodId}
            invoicing={paymentsStore.subscription.manualPayment ? true : false}
            onDelete={methodId => deleteMethodModal.open({ methodId })}
            methods={paymentMethods}
            onSetDefault={methodId => defaultMethodModal.open({ methodId })}
            loadingState={loadingState}
          />
        </>
      )}

      <Modal isOpen={createMethodModal.isOpen} onClose={createMethodModal.close} centerText={false}>
        <PageTitle
          title={t("payments.createMethodModal.title")}
          description={t("payments.createMethodModal.description")}
        />
        <PaymentMethodForm
          onCancel={createMethodModal.close}
          withSubscriptionInterval={false}
          afterSubmit={async ({ paymentMethod }) => {
            await createPaymentMethod(paymentMethod.id);
            createMethodModal.close();

            snackbarStore.addSnackbar({
              type: "success",
              message: t("payments.paymentMethodAddedSuccessfully")
            });
          }}
        />
      </Modal>
      <Prompt
        onAgree={async () => {
          const { params } = deleteMethodModal;
          if (!params) {
            throw new Error("Delete method modal params not available!");
          }

          await deletePaymentMethod(params.methodId);

          snackbarStore.addSnackbar({
            type: "success",
            message: t("payments.paymentMethodDeletedSuccessfully")
          });
        }}
        onClose={deleteMethodModal.close}
        isOpen={deleteMethodModal.isOpen}
        header={t("payments.deleteMethodPrompt.header")}
        message={t("payments.deleteMethodPrompt.message")}
      />
      <Prompt
        onAgree={async () => {
          const { params } = defaultMethodModal;

          if (!params) {
            throw new Error("Default method modal params are not available");
          }

          await setDefaultPaymentMethod(params.methodId);

          snackbarStore.addSnackbar({
            type: "success",
            message: t("payments.defaultPaymentMethodChangedSuccessfully")
          });
        }}
        onClose={defaultMethodModal.close}
        isOpen={defaultMethodModal.isOpen}
        header={t("payments.defaultMethodPrompt.header")}
        message={t("payments.defaultMethodPrompt.message")}
      />
      <Button onClick={createMethodModal.open} disabled={loadingState === "in_progress"} data-testid="addMethodButton">
        {t("payments.addPaymentMethod")}
      </Button>
    </FormSection>
  );
});
