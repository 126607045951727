import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { Select } from "triangular/components/Select/Select";
import { Typography } from "triangular/components/Typography/Typography";
import { ReactComponent as DropdownSvg } from "triangular/static/images/arrow-down.svg";

import { sortingOptions, useSystemsPaginationContext } from "../utils/pagination";

import css from "./SortBy.module.scss";

export const SortBy: React.FC = () => {
  const { history } = useRouter();
  const { t } = useTranslation();
  const { paginationQuery, buildUrl } = useSystemsPaginationContext();

  const options = sortingOptions.map(value => ({
    value: value,
    key: value,
    label: t(`systems.sortLabels.${value}`)
  }));

  return (
    <div className={css.wrapper}>
      <Typography upperCase={true} size="small" className={css.label} verticalSpacing="medium">
        {t("systems.sort")}
      </Typography>
      <Select
        options={options}
        value={paginationQuery.sortBy}
        onChange={selectedValue =>
          history.push(
            buildUrl(() => ({
              sortBy: selectedValue
            }))
          )
        }
        renderSelectedValue={({ selectedValue, isOpen }) => (
          <div className={css.selectedValue}>
            <Typography bold={true} upperCase={true} size="small">
              {selectedValue}
            </Typography>
            <DropdownSvg
              className={classNames(css.dropdownIcon, {
                [css.dropdownIconOpen]: isOpen
              })}
            />
          </div>
        )}
      />
    </div>
  );
};
