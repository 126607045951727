import uniqueId from "lodash/uniqueId";
import { types } from "mobx-state-tree";

import { SubscriptionExpiredError } from "triangular/utils/errors";

import { createStore } from "./utils/createStore";

export interface Snackbar {
  type: "error" | "success";
  message: string;
}

const SNACKBAR_DURATION = 5000; // time of snackbar presence in ms

const SnackbarsStoreModel = types.model("SnackbarsStore", {
  snackbars: types.map(
    types.model("snackbar", {
      type: types.enumeration(["error", "success"]),
      message: types.string
    })
  )
});

export const SnackbarStore = createStore(({ i18n }) =>
  SnackbarsStoreModel.actions(self => ({
    addPermanentSnackbar(snackbar: Snackbar) {
      const id = uniqueId();

      self.snackbars.set(id, snackbar);

      return id;
    },
    deleteSnackbar(id: string) {
      self.snackbars.delete(id);
    }
  }))
    .actions(self => ({
      addSnackbar(snackbar: Snackbar) {
        const snackbars = Array.from(self.snackbars.values());

        if (snackbars.some(({ message }) => message === snackbar.message)) {
          // Avoid adding snackbar with the same message again
          return;
        }

        const id = self.addPermanentSnackbar(snackbar);

        setTimeout(() => {
          self.deleteSnackbar(id);
        }, SNACKBAR_DURATION);
      }
    }))
    .actions(self => ({
      showGenericError(err: any) {
        const isAxiosError = err.config;

        if (err instanceof SubscriptionExpiredError) {
          return self.addSnackbar({ type: "error", message: i18n.t("errors.subscriptionExpiredError") });
        }

        if (!isAxiosError) {
          // eslint-disable-next-line no-console
          console.error(err);
        }

        self.addSnackbar({ type: "error", message: i18n.t("errors.generic") });
      },
      showValidationError() {
        self.addSnackbar({ type: "error", message: i18n.t("validation.formInvalid") });
      }
    }))
);

export type SnackbarStoreType = ReturnType<typeof SnackbarStore>["Type"];

export const snackbarStoreInitialState = {
  snackbars: {}
};
