import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { Card } from "triangular/components/Card/Card";
import { useModal } from "triangular/components/Modal/Modal";
import { Prompt } from "triangular/components/Prompt/Prompt";
import { Typography } from "triangular/components/Typography/Typography";
import { routes } from "triangular/Routes/routesConfiguration";
import { SystemModelSnapshot } from "triangular/stores/SystemSearchStore/models";
import { useEllipsisTooltip } from "triangular/utils/components";

import { IndustryList } from "../IndustryList/IndustryList";

import { ActionIcons } from "./ActionIcons";
import { CountryName } from "./CountryName";
import { MaterialBlacklist } from "./MaterialBlacklist";
import { MaterialWhitelist } from "./MaterialWhitelist";
import { Pictogram } from "./Pictogram";
import { SystemDetailsLink } from "./SystemDetailsLink";
import { SystemState } from "./SystemState";
import css from "./SystemTile.module.scss";
import { SystemType } from "./SystemType";

export interface SystemTileProps {
  className?: string;
  id: SystemModelSnapshot["id"];
  state: SystemModelSnapshot["state"];
  systemCategory: SystemModelSnapshot["systemCategory"];
  name: SystemModelSnapshot["name"];
  countryCode: SystemModelSnapshot["countryCode"];
  systemType: SystemModelSnapshot["systemType"];
  materialsWhitelist: SystemModelSnapshot["materialsWhitelist"];
  materialsBlacklist: SystemModelSnapshot["materialsBlacklist"];
  industries: SystemModelSnapshot["industries"];
  onRemove?: (id: string, isDraft: boolean) => Promise<void>;
  isSystemStateVisible?: boolean;
  isMySystem?: boolean;
  origin?: string;
}

export const SystemTile: React.FC<SystemTileProps> = ({
  className,
  onRemove,
  isSystemStateVisible = true,
  isMySystem = false,
  id,
  state,
  systemCategory,
  name,
  countryCode,
  systemType,
  industries,
  materialsWhitelist,
  materialsBlacklist,
  origin
}) => {
  const { history } = useRouter();
  const { t } = useTranslation();
  const removalConfirmation = useModal();
  const ellipsisTooltip = useEllipsisTooltip(id);

  const handleRemove = () => onRemove && onRemove(id, state === "draft");

  const openRemovalConfirmation = (e: React.MouseEvent) => {
    e.preventDefault();
    removalConfirmation.open();
  };

  const handleEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    history.push(routes.editSystem.build({ id: id }));
  };

  const industryNames = (industries || []).map(({ name }) => name);

  return (
    <>
      <SystemDetailsLink
        className={css.itemLink}
        systemId={id}
        isMySystem={isMySystem}
        testId="systemTile"
        origin={origin}
      >
        <Card className={classNames(className, css.container)}>
          <SystemState systemState={state} isSystemStateVisible={isSystemStateVisible} isMySystem={isMySystem} />
          <div className={css.systemHeader}>
            <div className={css.pictogram}>
              <Pictogram systemCategory={systemCategory} />
            </div>
            <div className={css.texts}>
              <Typography component="h4" bold={true} className={css.systemName} {...ellipsisTooltip.triggerProps}>
                {name}
              </Typography>
              {ellipsisTooltip.jsx}
              <CountryName countryCode={countryCode} />
            </div>
            <div>
              <ActionIcons
                systemState={state}
                handleEdit={handleEdit}
                openRemovalConfirmation={openRemovalConfirmation}
                isMySystem={isMySystem}
              />
            </div>
          </div>
          <dl>
            <div className={classNames("row", css.dataSection)}>
              <div className="col-6">
                <dt className={css.label}>{t("systemItem.systemType")}</dt>
                <dd>
                  <SystemType systemType={systemType} />
                </dd>
              </div>
              <div className="col-6">
                <dt className={css.label}>{t("industry")}</dt>
                <dd>
                  <IndustryList industryNames={industryNames} />
                </dd>
              </div>
            </div>
            <dt className={css.label}>{t("materials")}</dt>
            <div className="row">
              <div className="col-6">
                <Typography bold={true} component="p" className={css.materialsLabel}>
                  {t("systemItem.possible")}
                </Typography>
                <dd>
                  <MaterialWhitelist materialsWhiteList={materialsWhitelist} />
                </dd>
              </div>
              <div className={classNames("col-6", css.noGoMaterials)}>
                <Typography bold={true} component="p" className={css.materialsLabel}>
                  {t("systemItem.noGo")}
                </Typography>
                <dd>
                  <MaterialBlacklist materialsBlacklist={materialsBlacklist} />
                </dd>
              </div>
            </div>
          </dl>
        </Card>
      </SystemDetailsLink>
      <Prompt
        isOpen={removalConfirmation.isOpen}
        onClose={removalConfirmation.close}
        onAgree={handleRemove}
        header={t("mySystemsList.removeItem.header")}
        message={t("mySystemsList.removeItem.message")}
      />
    </>
  );
};
