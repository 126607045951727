import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { SubscriptionPlanForm } from "triangular/components/form/SubscriptionPlanForm/SubscriptionPlanForm";
import { useStore } from "triangular/stores/StoreContext";
import { useAppTitle } from "triangular/utils/components";

import { PageTitle } from "../PageTitle/PageTitle";

import css from "./SubscriptionPlan.module.scss";

export const SubscriptionPlan: React.FC = observer(() => {
  const { t } = useTranslation();
  useAppTitle(t("subscriptionSetup.subscriptionPlan.title"));
  const { paymentsStore, userStore } = useStore();

  return (
    <div className={css.wrapper}>
      <PageTitle title={t("subscriptionSetup.subscriptionPlan.title")} description="" />

      <div className={css.formWrapper}>
        <SubscriptionPlanForm
          withAgreement={true}
          onSubmit={values => {
            if (!userStore.accountType) {
              throw new Error("Account type not available");
            }
            return paymentsStore.updateSubscriptionPlan({
              level: values.level,
              billingInterval: values.subscriptionInterval,
              accountType: userStore.accountType
            });
          }}
        />
      </div>
    </div>
  );
});
