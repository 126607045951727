import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { getSnapshot, types } from "mobx-state-tree";

import { CertificateEntity } from "triangular/services/Api/entities/CertificateEntity";
import { ExperienceEntity } from "triangular/services/Api/entities/ExperienceEntity";
import { EntityBlueprint, SettingItem, UrlConfig } from "triangular/services/Api/Resource/utils/types";
import { ExpertEntity } from "triangular/services/Api/resources/ExpertResource";
import { gaActions, GaCategories, googleAnalytics } from "triangular/services/googleAnalytics";
import { pickNotEmpty } from "triangular/utils/language";

import { createPagination, PaginationRequestConfig } from "../utils/createPagination";
import { createSortModel } from "../utils/createSort";
import { createStore, StoreDeps } from "../utils/createStore";
import { createStateAssigner, createStateMerger } from "../utils/state";

import { currentExpertModel } from "./currentExpertModel";
import { expertModel } from "./expertModel";
import { expertSettingsModel } from "./expertSettingsModel";
import { ExpertFiltersModelSnapshot, filtersModel } from "./filtersModel";

export type ExpertSortingAttributes = "name" | "language";
export type ExpertStoreModel = ReturnType<typeof createExpertStoreModel>;
export const createExpertStoreModel = (storeDeps: StoreDeps) =>
  types.model("ExpertStore", {
    currentExpert: currentExpertModel,
    expertSettings: types.maybeNull(expertSettingsModel),
    sort: createSortModel(["name", "language"] as ExpertSortingAttributes[]),
    filters: filtersModel,
    loadingCurrentExpert: types.boolean,
    search: types.string,
    appliedSearch: types.maybeNull(types.string),
    pagination: createPagination({
      storeDeps,
      model: expertModel,
      onChange: async params => {
        const expertFilters: ExpertFiltersModelSnapshot = getSnapshot(params.parent.filters);
        const appliedSearch = params.parent.appliedSearch as string | null;

        const requestFilter = pickNotEmpty({
          q: appliedSearch,
          ...expertFilters
        });

        if (requestFilter.q) {
          googleAnalytics.sendEvent({
            category: GaCategories.expertSearch,
            action: gaActions.expertSearch.search,
            label: requestFilter.q as string
          });
        }

        const response = await storeDeps.api.expert.find({
          filter: requestFilter,
          pagination: params.pagination,
          include: {
            avatar: true,
            experiences: true
          }
        });

        const data = response.data;
        const meta = response.meta as { recordCount: number; pageCount: number };

        return {
          totalItems: meta.recordCount,
          items: data,
          querySearch: {
            pagination: params.pagination,
            filters: pickNotEmpty(expertFilters),
            appliedSearch
          }
        };
      }
    })
  });

export const ExpertStore = createStore(storeDeps =>
  createExpertStoreModel(storeDeps)
    .actions(self => ({
      assignToState: createStateAssigner(self),
      mergeWithState: createStateMerger(self),
      mergeSettings: createStateMerger(self.expertSettings),
      mergeWithFilters: createStateMerger(self.filters),
      assignToCurrentExpert: createStateAssigner(self.currentExpert),
      isConfirmed() {
        // By now expert should be always able to change his data,
        // however it might change later.
        // return self.currentExpert.state === "confirmed";
        return false;
      },
      createExpertRelationshipRequestConfig() {
        const expertId = self.currentExpert.id!;
        return { prefixes: ["experts", expertId] };
      }
    }))
    .actions(self => ({
      mergeWithState: createStateMerger(self),
      getSettings() {
        if (!self.expertSettings) {
          throw new Error("Settings not available!");
        }

        return self.expertSettings;
      }
    }))
    .actions(self => ({
      async loadSettings() {
        if (self.expertSettings) {
          return;
        }

        const {
          data: [fetchedSettings]
        } = await storeDeps.api.expertSettings.find();

        const { specialties = [], reaches = [], languageLevels = [], languages = [] } = fetchedSettings;

        const flatSpecialties: SettingItem[] = [];

        specialties.forEach(eachSpecialty => {
          flatSpecialties.push({ name: eachSpecialty.name, id: eachSpecialty.id });
        });

        self.mergeWithState({
          expertSettings: {
            reaches,
            languageLevels,
            languages,
            specialties: flatSpecialties
          }
        });
      }
    }))
    .actions(self => ({
      async fetchCurrentExpert(id: string | null) {
        if (!id) {
          throw new Error("Expert id not available");
        }

        const { data } = await storeDeps.api.expert.findById(id, {
          include: {
            experiences: {
              experienceFiles: true
            },
            certificates: {
              certificateFiles: true
            },
            avatar: true,
            materialCategories: true,
            industries: true,
            specialties: true
          }
        });
        const { type, links, industries, specialties, materialCategories, ...currentExpert } = data;

        self.assignToState({
          currentExpert: {
            ...currentExpert,
            industries: (industries || []).map(({ id }) => id),
            specialties: (specialties || []).map(({ id }) => id),
            materialCategories: (materialCategories || []).map(({ id }) => id)
          }
        });
      },
      async fetchExpert(id: string, config?: UrlConfig<ExpertEntity>) {
        return (await storeDeps.api.expert.findById(id, config)).data;
      },
      async fetchExperts(config?: PaginationRequestConfig) {
        const query = self.pagination.getQueryParams();
        const [sortAttribute] = get(query, "params.sort", [[]]);
        const querySearch = get(query, "params.appliedSearch");

        try {
          self.mergeWithState({
            pagination: query.pagination,
            filters: {
              ...expertStoreInitialState.filters,
              ...get(query, "params.filters", {})
            },
            search: "",
            appliedSearch: self.search || querySearch || null,
            sort: isEmpty(sortAttribute)
              ? undefined
              : {
                  attributes: [
                    {
                      name: sortAttribute[0],
                      order: sortAttribute[1]
                    }
                  ]
                }
          });
          await self.pagination.refresh(config);
        } catch (err) {
          self.pagination.setState({ loading: false });
        }
      },
      cleanUpCurrentExpert() {
        self.assignToState({ currentExpert: expertStoreInitialState.currentExpert });
      },
      uploadExperienceFile(filename: string, data: string) {
        return storeDeps.api.experienceFile.create({ filename, data });
      },
      removeExperienceFile(id: string) {
        return storeDeps.api.experienceFile.delete(id);
      },
      async fetchExperiences() {
        const { data } = await storeDeps.api.experience.find({
          ...self.createExpertRelationshipRequestConfig(),
          include: {
            experienceFiles: true
          }
        });
        self.assignToCurrentExpert({
          experiences: data
        });
      },
      uploadCertificateFile(filename: string, data: string) {
        return storeDeps.api.certificateFile.create({ filename, data });
      },
      removeCertificateFile(id: string) {
        return storeDeps.api.certificateFile.delete(id);
      },
      async fetchCertificates() {
        const { data } = await storeDeps.api.certificate.find({
          ...self.createExpertRelationshipRequestConfig(),
          include: {
            certificateFiles: true
          }
        });
        self.assignToCurrentExpert({
          certificates: data
        });
      },
      updateExpert(expertId: string, newExpert: Partial<EntityBlueprint<ExpertEntity>>) {
        return storeDeps.api.expert.update(expertId, newExpert);
      },
      sendHelpRequest(config = {}) {
        return storeDeps.api.expertHelpRequest.create(config);
      }
    }))
    .actions(self => ({
      async createExperience(newExperience: EntityBlueprint<ExperienceEntity>) {
        await storeDeps.api.experience.create(newExperience, {}, self.createExpertRelationshipRequestConfig());
        await self.fetchExperiences();
      },
      async updateExperience(id: string, newExperience: EntityBlueprint<ExperienceEntity>) {
        await storeDeps.api.experience.update(id, newExperience, {}, self.createExpertRelationshipRequestConfig());
        await self.fetchExperiences();
      },
      async removeExperience(id: string) {
        await storeDeps.api.experience.delete(id, {}, self.createExpertRelationshipRequestConfig());
        await self.fetchExperiences();
      },
      async createCertificate(newCertificate: EntityBlueprint<CertificateEntity>) {
        await storeDeps.api.certificate.create(newCertificate, {}, self.createExpertRelationshipRequestConfig());
        await self.fetchCertificates();
      },
      async updateCertificate(id: string, newCertificate: EntityBlueprint<CertificateEntity>) {
        await storeDeps.api.certificate.update(id, newCertificate, {}, self.createExpertRelationshipRequestConfig());
        await self.fetchCertificates();
      },
      async removeCertificate(id: string) {
        await storeDeps.api.certificate.delete(id, {}, self.createExpertRelationshipRequestConfig());
        await self.fetchCertificates();
      },
      updateFilters(...param: Parameters<typeof self["mergeWithFilters"]>) {
        self.mergeWithFilters(...param);
      },
      updateFiltersAndFetchExperts(...param: Parameters<typeof self["mergeWithFilters"]>) {
        self.mergeWithFilters(...param);
        self.pagination.reset({ pushHistory: false });
      }
    }))
);

export type ExpertStoreSnapshot = ReturnType<typeof ExpertStore>["SnapshotType"];

export const expertStoreInitialState: ExpertStoreSnapshot = {
  filters: {
    languages: [],
    processCategories: [],
    industries: [],
    specialties: [],
    materialCategories: []
  },
  search: "",
  appliedSearch: null,
  sort: {
    attributes: [{ name: "name" as const, order: "ASC" as const }]
  },
  pagination: {
    size: 9,
    number: 1,
    totalItems: 0,
    pages: {},
    loading: true
  },
  currentExpert: {
    id: null,
    state: null,
    firstName: null,
    lastName: null,
    description: null,
    showcaseTitle: null,
    expertCompany: null,
    phoneNumber: null,
    mobileNumber: null,
    countryCode: null,
    reach: null,
    countryCodeBlacklist: null,
    processCategories: null,
    industries: null,
    specialties: null,
    materialCategories: null,
    languagesKnowledge: null,
    avatar: null,
    experiences: null,
    expertiseDescription: null,
    certificates: null,
    vatNumber: null
  },
  expertSettings: null,
  loadingCurrentExpert: true
};

export type ExpertStoreType = ReturnType<typeof ExpertStore>;
export type CurrentExpertSnapshot = ExpertStoreType["SnapshotType"]["currentExpert"];
export type ExpertExperienceItem = ArrayElement<NonNullable<CurrentExpertSnapshot["experiences"]>>;
export type ExpertCertificateItem = ArrayElement<NonNullable<CurrentExpertSnapshot["certificates"]>>;
