import classNames from "classnames";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { AgreementCheckbox } from "triangular/components/AgreementCheckbox/AgreementCheckbox";
import { Button } from "triangular/components/Button/Button";
import { CompanyNameLabel } from "triangular/components/CompanyNameLabel/CompanyNameLabel";
import { RequiredAnnotation } from "triangular/components/form/AbstractField/AbstractField";
import abstractFieldCss from "triangular/components/form/AbstractField/AbstractField.module.scss";
import { Form, SubmitForm } from "triangular/components/form/Form";
import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { TextField, TextFieldProps } from "triangular/components/form/TextField";
import { requiredString } from "triangular/components/form/validators/requiredString";
import { taxIdValidator } from "triangular/components/form/validators/taxIdValidator";
import { HelpIconTooltip } from "triangular/components/HelpIconTooltip/HelpIconTooltip";
import { Typography } from "triangular/components/Typography/Typography";
import { countries, countriesSelectOptions, Country } from "triangular/consts";
import { useStore } from "triangular/stores/StoreContext";

import css from "../CompanyInfo.module.scss";

const COMPANY_NAME_MIN_LENGTH = 2;

const createValidationSchema = (t: TFunction, profileType: ProfileType) =>
  Yup.object({
    name: Yup.string()
      .min(COMPANY_NAME_MIN_LENGTH, t("validation.minLength", { minLength: COMPANY_NAME_MIN_LENGTH }))
      .required(t("validation.required")),
    countryCode: requiredString(t),
    number: taxIdValidator[profileType](t),
    agreement: Yup.mixed().oneOf([true], t("validation.terms"))
  });

export interface CompanyInfoFormProps {
  onSubmit: SubmitForm<Yup.InferType<ReturnType<typeof createValidationSchema>>>;
  onGoBack: (event: React.MouseEvent) => void;
  initialValues: {
    name: string;
    countryCode: string;
    number: string;
    subscriptionInterval: string | null;
    agreement: boolean;
  };
}

export const getCompanyIdentificationLabel = (countryCode: string, t: TFunction) => {
  const country: Country | undefined = countries.find(c => c.code === countryCode);

  if (!country) {
    return t("registration.companyIdentificationLabel.other");
  }

  if (country.EU) {
    return t("registration.companyIdentificationLabel.EU");
  }

  const labels: { [key: string]: string } = {
    US: t("registration.companyIdentificationLabel.US"),
    CA: t("registration.companyIdentificationLabel.CA"),
    MX: t("registration.companyIdentificationLabel.MX"),
    CO: t("registration.companyIdentificationLabel.CO"),
    BR: t("registration.companyIdentificationLabel.BR"),
    CL: t("registration.companyIdentificationLabel.CL"),
    PE: t("registration.companyIdentificationLabel.PE"),
    AR: t("registration.companyIdentificationLabel.AR")
  };

  return labels[countryCode] || t("registration.companyIdentificationLabel.other");
};

export const getIdentificationNumberPrefix = (countryCode: string) => {
  const country = countries.find(c => c.code === countryCode);

  if (country) {
    return country.vatPrefix;
  }
};

export const TaxIdField: React.FC<{
  countryCode: string;
  t: TFunction;
  name: string;
  className?: TextFieldProps["className"];
  disabled?: boolean;
}> = ({ countryCode, t, name, className, disabled }) => {
  const vatPrefix = getIdentificationNumberPrefix(countryCode);

  const tooltip = <HelpIconTooltip>{t("registration.companyIdentificationLabel.tooltip")}</HelpIconTooltip>;
  const annotations = disabled ? null : (
    <>
      {tooltip} <RequiredAnnotation name={name} t={t} />
    </>
  );

  if (vatPrefix) {
    return (
      <div className={css.field}>
        <div className={classNames(css.identificationLabel, { [css.disabledIdentificationLabel]: disabled })}>
          {getCompanyIdentificationLabel(countryCode, t)} {annotations}
        </div>
        <div className={css.identificationWrapper}>
          <div className={classNames(abstractFieldCss.input, css.prefix)}>{vatPrefix}</div>
          <TextField
            required={!disabled}
            name={name}
            className={{ wrapper: css.identification }}
            data-testid="identificationNumber"
            disabled={disabled}
          />
        </div>
      </div>
    );
  }

  return (
    <TextField
      name={name}
      label={getCompanyIdentificationLabel(countryCode, t)}
      required={!disabled}
      className={className}
      data-testid="identificationNumber"
      disabled={disabled}
    />
  );
};

export const CompanyInfoForm: React.FC<CompanyInfoFormProps> = ({ onSubmit, onGoBack, initialValues }) => {
  const { t } = useTranslation();
  const { userStore } = useStore();

  return (
    <Form
      validateOnChange={true}
      validateOnBlur={true}
      initialValues={{ ...initialValues }}
      validationSchema={createValidationSchema(t, userStore.type || "system_owners")}
      render={props => {
        const {
          isSubmitting,
          values: { countryCode }
        } = props;

        return (
          <>
            <div className={css.fieldsSection}>
              <TextField
                data-testid="companyName"
                autoFocus={true}
                name="name"
                label={<CompanyNameLabel />}
                required={true}
                className={{ wrapper: css.field }}
              />
            </div>
            <div className={css.fieldsSection}>
              <SelectField
                name="countryCode"
                data-testid="countryCode"
                label={t("registration.companyInfo.companyCountry")}
                selectProps={{
                  options: countriesSelectOptions,
                  autoComplete: true
                }}
                required={true}
                className={{ wrapper: css.field }}
              />
            </div>
            <div className={css.fieldsSection}>
              <TaxIdField countryCode={countryCode || ""} t={t} name="number" className={{ wrapper: css.field }} />
            </div>
            <AgreementCheckbox className={css.agreement} />
            <Typography size="small" verticalSpacing="large" component="div">
              {t("requiredField")}
            </Typography>
            <div className={css.buttons}>
              <Button
                data-testid="companyInfo:back"
                className={css.backButton}
                type="button"
                disabled={isSubmitting}
                transparent={true}
                variant="light"
                onClick={onGoBack}
              >
                {t("defaultFormButtons.back")}
              </Button>
              <Button type="submit" disabled={isSubmitting} showLoader={isSubmitting} data-testid="submit">
                {t("registration.common.createAccount")}
              </Button>
            </div>
          </>
        );
      }}
      onSubmit={onSubmit}
    />
  );
};
