import classNames from "classnames";
import React, { useContext } from "react";

import { AbstractField } from "triangular/components/form/AbstractField/AbstractField";
import { FormAdditionalContext } from "triangular/utils/components";

import { AbstractFieldProps } from "../AbstractField/AbstractField";

import css from "./DoubleField.module.scss";

export interface DoubleFieldProps extends Omit<AbstractFieldProps, "name"> {
  label?: React.ReactNode;
  leftField: React.ReactNode;
  rightField: React.ReactNode;
  className?: AbstractFieldProps["className"];
}

export const DoubleField: React.FC<DoubleFieldProps> = ({ label, leftField, rightField, className = {}, ...props }) => {
  const { errorContainer, ...classNameRest } = className;

  const { isReadOnly } = useContext(FormAdditionalContext);

  if (isReadOnly) {
    props.disabled = true;
  }

  return (
    <AbstractField
      name="@@doubleField"
      label={label}
      className={{
        errorContainer: classNames(css.errorContainer, className.errorContainer),
        ...classNameRest
      }}
      render={() => {
        return (
          <div className={css.doubleField}>
            {leftField}
            {rightField}
          </div>
        );
      }}
      {...props}
    />
  );
};
