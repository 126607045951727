import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { PaymentMethodForm } from "triangular/components/form/stripe/PaymentMethodForm/PaymentMethodForm";
import { useStore } from "triangular/stores/StoreContext";
import { useAppTitle } from "triangular/utils/components";

import { PageTitle } from "./PageTitle/PageTitle";

export const SubscriptionPaymentMethod = observer(() => {
  const { t } = useTranslation();
  useAppTitle(t("subscriptionSetup.paymentMethodSetup.title"));
  const { paymentsStore } = useStore();

  return (
    <FormSection>
      <PageTitle
        title={t("subscriptionSetup.paymentMethodSetup.title")}
        description={t("subscriptionSetup.paymentMethodSetup.description")}
      />
      <PaymentMethodForm
        withSubscriptionInterval={true}
        afterSubmit={async ({ paymentMethod }) => {
          await paymentsStore.updateSubscriptionPaymentMethod(paymentMethod.id);
        }}
        handlePayByInvoice={async () => {
          await paymentsStore.setupManualPayment();
        }}
      />
    </FormSection>
  );
});
