import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { SearchResultsList } from "triangular/components/SearchResultsList/SearchResultsList";
import { SystemTile } from "triangular/components/SystemTile/SystemTile";
import { useStore } from "triangular/stores/StoreContext";
import { SystemModelSnapshot } from "triangular/stores/SystemSearchStore/models";

import { useSystemsPaginationContext } from "./utils/pagination";

export const SystemList: React.FC = observer(() => {
  const { t } = useTranslation();
  const { systemSearchStore } = useStore();
  const { systems, isLoading } = systemSearchStore;
  const systemsPagination = useSystemsPaginationContext();

  return (
    <SearchResultsList
      items={systems}
      isLoading={isLoading}
      noResultsMessage={t("systems.noResults")}
      totalItems={systemSearchStore.totalItems}
      {...systemsPagination}
    >
      {(eachSystem: SystemModelSnapshot) => (
        <SystemTile
          key={eachSystem.id}
          id={eachSystem.id}
          state={eachSystem.state}
          systemCategory={eachSystem.systemCategory}
          systemType={eachSystem.systemType}
          isSystemStateVisible={false}
          name={eachSystem.name}
          countryCode={eachSystem.countryCode}
          materialsBlacklist={eachSystem.materialsBlacklist}
          materialsWhitelist={eachSystem.materialsWhitelist}
          industries={eachSystem.industries}
          isMySystem={false}
          origin="search"
        />
      )}
    </SearchResultsList>
  );
});
