import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel, { Modal, ModalGateway } from "react-images";

import { useModal } from "triangular/components/Modal/Modal";
import { Typography } from "triangular/components/Typography/Typography";
import { FetchedSystem } from "triangular/stores/SystemsStore/SystemsStore";

import { Section } from "./Section";
import css from "./SystemDetails.module.scss";

interface SystemDetailsPhotosProps {
  system: FetchedSystem;
}

export const SystemDetailsPhotos: React.FC<SystemDetailsPhotosProps> = ({ system }) => {
  const photoModal = useModal();
  const [photoIndex, setPhotoIndex] = useState(0);
  const { t } = useTranslation();

  const systemPhotos = system.systemPhotos;

  if (!systemPhotos || systemPhotos.length === 0) {
    return null;
  } else if (!systemPhotos[0].originalUrl) {
    return null;
  }

  const images = systemPhotos.map(photo => ({
    src: photo.originalUrl
  }));

  const handleImageOpen = (index: number) => () => {
    photoModal.open();
    setPhotoIndex(index);
  };

  const toggleModal = () => (photoModal.isOpen ? photoModal.close() : photoModal.open());

  return (
    <Section>
      <div>
        <Typography component="h3" bold={true} size="big" upperCase={true} color="mint">
          {t("systemDetails.photos")}
        </Typography>

        <div className={css.photosWrapper}>
          {images.map((image, index) => (
            <img key={image.src} src={image.src} alt="System" onClick={handleImageOpen(index)} />
          ))}
        </div>

        <ModalGateway>
          {photoModal.isOpen ? (
            <Modal onClose={toggleModal}>
              <Carousel allowFullscreen={false} views={images} currentIndex={photoIndex} />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </Section>
  );
};
