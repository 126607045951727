import React from "react";
import { useTranslation } from "react-i18next";

import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { TextField } from "triangular/components/form/TextField";
import { formatInputDate } from "triangular/utils/dates";

import { AltBlockHeader } from "../../AltBlockHeader/AltBlockHeader";
import { FormSection } from "../../FormSection/FormSection";
import { ProcessCategoryField } from "../../system/ProcessCategoryField";
import { SystemCategoryField } from "../../system/SystemCategoryField";
import { TextArea } from "../../TextArea/TextArea";
import { getMaterialDeadlineMax, getMaterialDeadlineMin } from "../materialSchema";

import { TargetIndustryField } from "./TargetIndustryField/TargetIndustryField";
import { UploadSafetyFileField } from "./UploadSafetyFileField";

export const RequiredMaterialInfo = React.memo(() => {
  const { t } = useTranslation();
  const currentT = (path: string) => t(`materialForm.materialInfo.${path}`);
  const materialAmountField = (
    <TextField name="materialAmount" label={currentT("materialAmount")} required={true} data-testid="materialAmount" />
  );
  return (
    <>
      <FormSection>
        <AltBlockHeader>{t("materialForm.materialInfo.requiredInfo")}</AltBlockHeader>
        <FieldsRow>
          <FieldWrapper>
            <TargetIndustryField label={t("materialForm.materialInfo.targetIndustry")} />
          </FieldWrapper>
          <FieldWrapper>
            <TextField name="name" label={currentT("name")} required={true} autoFocus={true} data-testid="name" />
          </FieldWrapper>
          <FieldWrapper>
            <TextField
              name="deadline"
              label={currentT("deadline")}
              type="date"
              required={true}
              min={getMaterialDeadlineMin()}
              max={getMaterialDeadlineMax()}
              placeholder={formatInputDate(new Date())}
              data-testid="deadline"
            />
          </FieldWrapper>
          <FieldWrapper>{materialAmountField}</FieldWrapper>
          <FieldWrapper>
            <ProcessCategoryField label={currentT("processNeeded")} />
          </FieldWrapper>
          <FieldWrapper>
            <SystemCategoryField label={currentT("systemCategory")} />
          </FieldWrapper>
          <FieldWrapper className="col-sm-12 col-md-12">
            <UploadSafetyFileField />
          </FieldWrapper>
          <FieldWrapper className="col-sm-12 col-md-12">
            <TextArea name="additionalInfo" label={currentT("additionalInfo")} data-testid="additionalInfo" />
          </FieldWrapper>
        </FieldsRow>
      </FormSection>
    </>
  );
});
