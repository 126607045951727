import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "triangular/components/Card/Card";
import { IconButton } from "triangular/components/IconButton/IconButton";
import { ItemStatus } from "triangular/components/ItemStatus/ItemStatus";
import { useModal } from "triangular/components/Modal/Modal";
import { NotSet } from "triangular/components/NotSet/NotSet";
import { Prompt } from "triangular/components/Prompt/Prompt";
import { TriLink } from "triangular/components/TriLink/TriLink";
import { Typography } from "triangular/components/Typography/Typography";
import { routes } from "triangular/Routes/routesConfiguration";
import { DocumentIdentifier } from "triangular/services/Api/Resource/utils/types";
import { ParticleSize } from "triangular/services/Api/resources/MaterialResource";
import { useStore } from "triangular/stores/StoreContext";

import { ParticleSize as ParticleSizeComponent } from "../ParticleSize/ParticleSize";

import css from "./MaterialItem.module.scss";
import { useSystemsSearchUrl } from "./useSystemsSearchUrl";

interface MaterialItemProps {
  id: string;
  materialIndex: string | null;
  indexNumber: string | null;
  name: string | null;
  state: string | null;
  feedParticleSize: ParticleSize<string>;
  processCategory?: DocumentIdentifier | null;
  systemCategory?: DocumentIdentifier | null;
  systemType?: DocumentIdentifier | null;
  designType?: DocumentIdentifier | null;
  generateItemUrl?: (id: string) => string;
  showIconButtons?: boolean;
  onClick?: () => void;
}

const Property: React.FC<{ label: string | null; value?: string }> = ({ label, value }) => (
  <li>
    <Typography component="div" size="small" upperCase={true} color="mint" className={css.propertyTitle}>
      {label}
    </Typography>
    {value && (
      <Typography component="div" size="small" ellipsis={true}>
        {value}
      </Typography>
    )}
  </li>
);

export const MaterialItem: React.FC<MaterialItemProps> = ({
  id,
  materialIndex,
  indexNumber,
  name,
  state,
  feedParticleSize,
  processCategory,
  systemCategory,
  systemType,
  generateItemUrl,
  showIconButtons = true,
  onClick
}) => {
  const { t } = useTranslation();
  const { commonsStore, myMaterialsStore, snackbarStore } = useStore();
  const commonSettings = commonsStore.getSettings();

  const systemsSearchUrl = useSystemsSearchUrl(id, {
    processCategory,
    systemCategory,
    systemType
  });

  const removePrompt = useModal();
  const isRequestSent = state !== "draft";

  const handleRemove = async () => {
    try {
      await myMaterialsStore.deleteMyMaterial(id);

      if (state === "draft") {
        await myMaterialsStore.fetchMyMaterials();
      } else {
        snackbarStore.addSnackbar({ type: "success", message: t("materialsList.removeItem.requestSent") });
      }
    } catch (err) {
      snackbarStore.showGenericError(err);
    }
  };

  const renderItemStatus = () => {
    if (!state) {
      return null;
    }

    return (
      <ItemStatus variant={isRequestSent ? "greyishBrown" : undefined}>{t(`materialItem.state.${state}`)}</ItemStatus>
    );
  };

  const particlesSizes = () => {
    if (!feedParticleSize || feedParticleSize.length === 0) {
      return <NotSet />;
    }

    return (
      <>
        {feedParticleSize.map((particle, index) =>
          particle ? (
            <ParticleSizeComponent
              key={index}
              className={css.particle}
              size={Number(particle.size)}
              percent={Number(particle.percent)}
            />
          ) : null
        )}
      </>
    );
  };

  const productNumber = (type: MaterialItemProps["materialIndex"], value?: MaterialItemProps["indexNumber"]) => {
    if (!type || !value || type === "not_available") {
      return null;
    }

    return <Property label={t(`materialItem.materialIndexLabel.${type}`)} value={value} />;
  };

  const processCategoryProperty = () => {
    if (!processCategory) {
      return;
    }
    const { processes } = commonSettings;
    const { id: processId } = processCategory;

    const translationKey = processes.find(process => process.id === processId);

    if (translationKey) {
      return (
        <Property
          label={t("materialItem.processNeeded")}
          value={t(`systemSettings.processCategoryOptions.${translationKey.name}`)}
        />
      );
    } else {
      return null;
    }
  };

  const openRemovePrompt = (e: React.MouseEvent) => {
    e.stopPropagation();
    removePrompt.open();
  };

  return (
    <>
      <Card className={css.item} data-testid="materialItemTile" onClick={onClick}>
        {!onClick && (
          <TriLink
            className={css.detailsLinkOverlay}
            to={generateItemUrl ? generateItemUrl(id) : routes.myMaterialDetails.build({ id })}
            testId="materialDetailsLink"
          />
        )}
        <div className={css.itemContent}>
          {renderItemStatus()}
          <div className={css.header}>
            <Typography className={css.title} component="div" size="medium" bold={true} ellipsis={true}>
              {name}
            </Typography>
            <div className={css.actionsWrapper}>
              {showIconButtons && (
                <>
                  <TriLink to={systemsSearchUrl} testId="searchLink">
                    <IconButton
                      icon="search"
                      iconClassName={css.searchIcon}
                      tooltipMessage={t("materialItem.searchIconTooltip")}
                    />
                  </TriLink>
                  <TriLink to={routes.addMaterial.build({ copyFrom: id })} testId="copyLink">
                    <IconButton icon="copy" />
                  </TriLink>
                  {!isRequestSent && (
                    <TriLink to={routes.editMaterial.build({ id })} testId="editLink">
                      <IconButton icon="edit" />
                    </TriLink>
                  )}
                  <IconButton onClick={openRemovePrompt} icon="trash" testId="trashIcon" />
                  <Prompt
                    isOpen={removePrompt.isOpen}
                    onClose={removePrompt.close}
                    onAgree={handleRemove}
                    header={t("materialsList.removeItem.header")}
                    message={t("materialsList.removeItem.message")}
                  />
                </>
              )}
            </div>
          </div>
          <div>
            <ul className={css.properties}>
              {productNumber(materialIndex, indexNumber)}
              {processCategoryProperty()}
            </ul>
          </div>
          {feedParticleSize && feedParticleSize.length && (
            <div>
              <div className={css.particleSizesWrapper}>
                <Typography component="div" size="small" bold={true} ellipsis={true}>
                  {t(`materialItem.particleSize`)}
                </Typography>
                {particlesSizes()}
              </div>
            </div>
          )}
        </div>
      </Card>
    </>
  );
};
