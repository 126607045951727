import React from "react";

import { ReactComponent as Coating } from "./images/coating.svg";
import { ReactComponent as Confectioning } from "./images/confectioning.svg";
import { ReactComponent as Crushing } from "./images/crushing.svg";
import { ReactComponent as JetMill } from "./images/jet-mill.svg";

type IconSize = "big" | "small";

interface SystemTypeIconProps {
  type?: string | null;
  size?: IconSize;
}

export const SystemTypeIcon: React.FC<SystemTypeIconProps> = ({ type, size = "big" }) => {
  const sizes: { [key in IconSize]: number } = {
    big: 111,
    small: 50
  };

  const iconSize = sizes[size];

  switch (type) {
    case "milling":
    case "micronizing":
    case "agglomerating":
    case "compacting":
      return <JetMill width={iconSize} height={iconSize} />;
    case "crushing":
    case "particle_size":
    case "bulk_density":
      return <Crushing width={iconSize} height={iconSize} />;
    case "sieving":
    case "classifying":
    case "drying":
    case "coating":
      return <Coating width={iconSize} height={iconSize} />;
    case "tap_density":
    case "powder_characteristics":
    case "confectioning":
    case "mixing":
    default:
      return <Confectioning width={iconSize} height={iconSize} />;
  }
};
