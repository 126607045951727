import * as axios from "axios";
import get from "lodash/get";
import { useCallback, useMemo, useState } from "react";

const Axios = axios.default;
export const { CancelToken, isCancel } = Axios;
export type CancelTokenType = axios.CancelToken;

export function useCancellation() {
  const [source] = useState(CancelToken.source());
  const cancel = useCallback(() => source.cancel(), [source]);
  const cancelToken = useMemo(() => source.token, [source]);
  return { cancel, cancelToken };
}

/**
 * Returns error's status code if possible (-1 otherwise).
 */
export function getErrorStatus(error: unknown) {
  const errorStatusCode = Number(get(error, "response.status", -1));
  return errorStatusCode;
}

export interface ApiError {
  code: string;
  detail: string;
  source: unknown;
  status: string;
  title: string;
}

export function getApiErrors(error: unknown) {
  const errors = get(error, "response.data.errors", []);
  return errors.map((eachError: Partial<ApiError>) => ({
    code: "",
    detail: "",
    source: {},
    status: "",
    title: "",
    ...eachError
  })) as ApiError[];
}

export function isCancelledError(error: unknown) {
  return Axios.isCancel(error);
}

export const createRelationOrNull = (id: string | null) => (id ? { id } : null);
export const createNumericStringOrNull = (value: string | number | null) =>
  value === null || value === undefined ? null : String(value).replace(",", ".");
export const createNumberOrNull = (value: string | null) => (value === null ? null : Number(value));
