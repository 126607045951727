import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { FileToUpload } from "triangular/components/form/system/SystemFilesField/FilesField/FilesField";
import { FileListItem } from "triangular/components/form/system/SystemFilesField/FilesList/FilesList";
import { SystemFilesField } from "triangular/components/form/system/SystemFilesField/SystemFilesField";
import { useStore } from "triangular/stores/StoreContext";

export const UploadSafetyFileField: React.FC = observer(() => {
  const { materialsStore } = useStore();
  const { t } = useTranslation();
  const currentT = (path: string) => t(`materialForm.uploadFilesPart.${path}`);

  const handleAddSafetyDataSheet = async (
    filesToUpload: FileToUpload[],
    addFiles: (fileEntities: FileListItem[] | null) => void
  ) => {
    const responses = await Promise.all(
      filesToUpload.map(({ filename, data }) => materialsStore.uploadMaterialSafetyFile(filename, data))
    );

    const addedSafetyDataSheets = responses
      .flat()
      .map(({ id, filename, originalUrl }) => ({ id, filename, originalUrl }));
    addFiles(addedSafetyDataSheets);
  };

  const handleRemoveSafetyDataSheet = (file: FileListItem) => materialsStore.deleteMaterialSafetyFile(file.id);

  return (
    <>
      <SystemFilesField
        name="materialSafetyFiles"
        label={currentT("materialSafetyFiles")}
        onRemoveFile={handleRemoveSafetyDataSheet}
        onAddFile={handleAddSafetyDataSheet}
        fileType="document"
        data-testid="materialSafetyFiles"
        noMarginTop={true}
      />
    </>
  );
});
