import classNames from "classnames";
import React from "react";

import { ReactComponent as AddIcon } from "triangular/static/images/button-add.svg";
import { ReactComponent as CopyIcon } from "triangular/static/images/copy.svg";
import { ReactComponent as CrossIcon } from "triangular/static/images/cross.svg";
import { ReactComponent as EditIcon } from "triangular/static/images/pencil.svg";
import { ReactComponent as SearchIcon } from "triangular/static/images/search.svg";
import { ReactComponent as TrashIcon } from "triangular/static/images/trash.svg";

import { TriLink, TriLinkProps } from "../TriLink/TriLink";

import css from "./IconLink.module.scss";

const icons = {
  edit: EditIcon,
  copy: CopyIcon,
  trash: TrashIcon,
  search: SearchIcon,
  add: AddIcon,
  cross: CrossIcon
};

export interface IconLinkProps {
  className?: string;
  iconClassName?: string;
  icon: keyof typeof icons;
  iconSize?: number;
  type?: "button" | "submit";
  disabled?: boolean;
  to: string;
  testId?: string;
  onClick?: TriLinkProps["onClick"];
}

export const IconLink = ({
  iconClassName,
  className,
  icon,
  disabled,
  to,
  testId,
  iconSize = 16,
  onClick
}: IconLinkProps) => {
  const Icon = icons[icon];

  return (
    <TriLink
      className={classNames(css.button, { [css.disabled]: disabled }, className)}
      to={to}
      testId={testId}
      onClick={onClick}
    >
      <Icon className={iconClassName} width={iconSize} height={iconSize} />
    </TriLink>
  );
};
