import { TFunction } from "i18next";
import * as Yup from "yup";

import { phoneNumberValidator } from "triangular/components/form/validators/phoneNumberValidator";
import { requiredString } from "triangular/components/form/validators/requiredString";

export interface Language {
  name: string;
  level: string;
}

export interface ExpertSchema {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  description?: string | null;
  showcaseTitle?: string | null;
  expertCompany?: string | null;
  expertiseDescription?: string | null;
  phoneNumber: string | null;
  mobileNumber?: string | null;
  countryCode: string | null;
  reach: string | null;
  countryCodeBlacklist: string[] | null;
  processCategories: string[] | null;
  industries: string[] | null;
  specialties: string[] | null;
  materialCategories: string[] | null;
  languagesKnowledge: Language[] | null;
}

const expertiseValidator = (t: TFunction) => (min = 0) =>
  Yup.array()
    .of(Yup.string())
    .min(min, t("editExpert.expertiseInvalid"));

export const createExpertSchema = (t: TFunction) =>
  Yup.object<ExpertSchema>({
    firstName: requiredString(t),
    lastName: requiredString(t),
    email: requiredString(t).email(),
    description: Yup.string().nullable(),
    showcaseTitle: Yup.string().nullable(),
    expertCompany: Yup.string().nullable(),
    expertiseDescription: Yup.string().nullable(),
    phoneNumber: phoneNumberValidator(t)
      .required()
      .nullable(),
    mobileNumber: Yup.lazy(value => {
      if (value !== undefined && value !== "" && value !== null) {
        return phoneNumberValidator(t);
      } else {
        return Yup.string()
          .notRequired()
          .nullable();
      }
    }),
    countryCode: Yup.string().nullable(),
    reach: requiredString(t),
    countryCodeBlacklist: Yup.array()
      .of(Yup.string())
      .nullable(),
    processCategories: expertiseValidator(t)(0),
    industries: expertiseValidator(t)(0),
    specialties: Yup.array()
      .of(Yup.string())
      .required(t("editExpert.specialtyRequired")),
    materialCategories: expertiseValidator(t)(0),
    languagesKnowledge: Yup.array()
      .of(
        Yup.object({
          name: Yup.string(),
          level: Yup.string()
        })
      )
      .min(1, t("editExpert.languageRequired"))
      .required(t("editExpert.languageRequired"))
      .nullable()
  });

export const expertDefaultValues: ExpertSchema = {
  firstName: null,
  lastName: null,
  email: null,
  description: null,
  showcaseTitle: null,
  expertCompany: null,
  expertiseDescription: null,
  phoneNumber: null,
  mobileNumber: null,
  countryCode: null,
  reach: null,
  countryCodeBlacklist: null,
  processCategories: [],
  industries: [],
  specialties: [],
  materialCategories: [],
  languagesKnowledge: null
};
