import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";

import { AgreementModal } from "triangular/components/AgreementModal/AgreementModal";
import { Layout } from "triangular/components/Layout/Layout";
import { useModal } from "triangular/components/Modal/Modal";
import { PageTitle } from "triangular/components/PageTitle/PageTitle";
import { UserGreeting } from "triangular/components/UserGreeting/UserGreeting";
import { UserHomeButtons } from "triangular/components/UserHomeButtons/UserHomeButtons";
import { useStore } from "triangular/stores/StoreContext";

import { MaterialsList } from "./MaterialsList/MaterialsList";

export const MyMaterialsList: React.FC<RouteComponentProps> = observer(({ history }) => {
  const { agreementStore } = useStore();
  const agreementConfirmation = useModal();

  const closeAgreementModal = () => {
    agreementConfirmation.close();
  };

  useEffect(() => {
    if (agreementStore.openConfirmation) {
      agreementConfirmation.open();
    }
  }, [agreementConfirmation, agreementStore]);
  const { t } = useTranslation();
  return (
    <Layout withMolecules={false}>
      <UserGreeting />
      <UserHomeButtons />
      <AgreementModal isOpen={agreementConfirmation.isOpen} onClose={closeAgreementModal} />
      <PageTitle title={t("myMaterialsList.title")} data-testid="myMaterialsPageTitle" />
      <MaterialsList />
    </Layout>
  );
});
