import React from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { LabeledSelect } from "triangular/components/LabeledSelect/LabeledSelect";
import { useStore } from "triangular/stores/StoreContext";
import { settingItemsToSelectOptions } from "triangular/utils/systems";

import { useExpertsPaginationContext } from "../utils/pagination";
import { updateProcessCategories } from "../utils/updates";

export const ProcessCategories: React.FC = () => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const { paginationQuery, buildUrl } = useExpertsPaginationContext();
  const { commonsStore } = useStore();
  const processSettings = commonsStore.getSettings().processes;

  const options = settingItemsToSelectOptions(t)("processCategoryOptions", processSettings);

  const handleChange = (selectedValue: string | string[]) =>
    history.push(buildUrl(() => updateProcessCategories(selectedValue as string[])));

  return (
    <LabeledSelect
      label={t("expertsSearch.processExpertise")}
      value={paginationQuery.processCategories || []}
      options={options}
      onChange={handleChange}
      autoComplete={true}
    />
  );
};
