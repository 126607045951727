import React from "react";
import MediaQuery, { useMediaQuery } from "react-responsive";

import variables from "./Breakpoint.module.scss";

const sizes: { [key: string]: number } = {};

Object.keys(variables).forEach(key => {
  sizes[key] = Number(variables[key]);
});

const minScreenSizes = {
  small: 0,
  medium: sizes.small,
  large: sizes.medium
};

const maxScreenSizes = {
  small: sizes.small - 1,
  medium: sizes.medium - 1,
  large: undefined
};

interface BreakpointProps {
  minWidth: "small" | "medium" | "large";
  maxWidth?: "small" | "medium" | "large";
}

export const Breakpoint: React.FC<BreakpointProps> = ({ minWidth, maxWidth, children }) => {
  const { minWidthValue, maxWidthValue } = calculateWidthValues({ minWidth, maxWidth });

  return (
    <MediaQuery minWidth={minWidthValue} maxWidth={maxWidthValue}>
      {children}
    </MediaQuery>
  );
};

export const useBreakpoint = (breakpoint: BreakpointProps) => {
  const { minWidthValue, maxWidthValue } = calculateWidthValues(breakpoint);

  return useMediaQuery({ minWidth: minWidthValue, maxWidth: maxWidthValue });
};

function calculateWidthValues(breakpoint: BreakpointProps): { minWidthValue: number; maxWidthValue?: number } {
  const { minWidth, maxWidth } = breakpoint;
  const minWidthValue = minScreenSizes[minWidth];
  const maxWidthValue = maxWidth ? maxScreenSizes[maxWidth] : undefined;

  return {
    minWidthValue,
    maxWidthValue
  };
}
