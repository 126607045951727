import classNames from "classnames";
import Downshift, { DownshiftState, StateChangeOptions } from "downshift";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Manager, Popper, Reference } from "react-popper";

import { ReactComponent as DropdownSvg } from "triangular/static/images/arrow-down.svg";

import css from "./Select.module.scss";
export interface SelectOption<Value> {
  key?: string;
  value: Value;
  label: string;
  disabled?: boolean;
}

export interface SelectProps<Value> {
  options: Array<SelectOption<Value>>;
  value: Value | Value[];
  onChange: (value: Value) => void;
  name?: string;
  className?: {
    container?: string;
    input?: string;
  };
  autoComplete?: boolean;
  disabled?: boolean;
  placeholder?: string;
  initialOption?: SelectOption<Value>;
  tabIndex?: number;
  "data-testid"?: string;
  allowEmpty?: boolean;
  emptyValue?: any;
  keepOpen?: boolean;
  allowCustomValues?: boolean;
  renderSelectedValue?: (props: { selectedValue: string; isOpen: boolean }) => React.ReactNode;
}

export function Select<Value>({
  options,
  value,
  onChange,
  name,
  tabIndex,
  className = {},
  autoComplete = false,
  disabled = false,
  placeholder,
  "data-testid": testId,
  initialOption,
  allowEmpty = false,
  emptyValue = "",
  keepOpen = false,
  allowCustomValues = false,
  renderSelectedValue
}: SelectProps<Value>) {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");

  const appliedInitialValue = initialOption || null;
  const selectedItem = options.find(eachItem => isEqual(eachItem.value, value)) || appliedInitialValue;

  const filteredOptions = options.filter(eachOption =>
    eachOption.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );

  const emptyOption = allowEmpty
    ? [
        {
          label: t("selectField.emptyOption"),
          key: "emptyOption",
          value: emptyValue
        } as SelectOption<typeof emptyValue>
      ]
    : [];

  const customValue =
    allowCustomValues && filteredOptions.length === 0
      ? [
          {
            label: search,
            key: search,
            value: search
          } as SelectOption<typeof search>
        ]
      : [];

  const appliedOptions = [...emptyOption, ...customValue, ...filteredOptions];

  const stateReducer = (state: DownshiftState<unknown>, changes: StateChangeOptions<any>) => {
    const clickedItem = changes.type === Downshift.stateChangeTypes.clickItem;
    const selectedWithKeyboard = changes.type === Downshift.stateChangeTypes.keyDownEnter && changes.selectedItem;
    const itemSelection = clickedItem || selectedWithKeyboard;
    const selectCustomValue = appliedOptions.length === 1 && allowCustomValues;

    if (changes.type === Downshift.stateChangeTypes.clickButton) {
      setSearch("");
      return changes;
    }

    if (itemSelection && !keepOpen) {
      setSearch("");
    }

    if (itemSelection && keepOpen) {
      if (selectCustomValue) {
        setSearch("");
      }

      return {
        ...changes,
        highlightedIndex: state.highlightedIndex,
        isOpen: !selectCustomValue
      };
    }

    // Handle auto focus on selected item in menu
    if (changes.hasOwnProperty("isOpen")) {
      return {
        ...changes,
        highlightedIndex: changes.isOpen ? options.indexOf(state.selectedItem as SelectOption<Value>) : null
      };
    }

    return changes;
  };

  return (
    <Manager>
      <Popper
        placement="bottom-start"
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent"
          }
        }}
        positionFixed={false}
      >
        {popperProps => (
          <Downshift
            itemToString={item => (item ? item.label : "")}
            selectedItem={selectedItem}
            onChange={item => item && onChange(item.value)}
            initialSelectedItem={appliedInitialValue}
            labelId={name}
            stateReducer={stateReducer}
          >
            {({
              getInputProps,
              getToggleButtonProps,
              getItemProps,
              getMenuProps,
              highlightedIndex,
              setHighlightedIndex,
              isOpen,
              openMenu
            }) => {
              const selectedValue = selectedItem ? selectedItem.label : "";

              const toggleButtonProps = getToggleButtonProps();
              const { onKeyDown, onBlur, ...inputProps } = getInputProps();

              const inputValue = isOpen && autoComplete ? search : selectedValue;

              const firstMatchIndex = appliedOptions.findIndex(opt =>
                opt.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
              );

              const handleInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
                popperProps.scheduleUpdate();
                setSearch(target.value);
                if (firstMatchIndex !== -1) {
                  setHighlightedIndex(firstMatchIndex);
                }
              };

              const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key !== "Tab" && !isOpen) {
                  openMenu();
                  setSearch("");
                }

                if (onKeyDown) {
                  onKeyDown(e);
                }
              };

              return (
                <div className={classNames(css.container, className.container)}>
                  <Reference>
                    {referenceProps =>
                      renderSelectedValue ? (
                        <div
                          {...toggleButtonProps}
                          onKeyDown={handleKeyDown}
                          tabIndex={tabIndex}
                          ref={referenceProps.ref}
                          data-testid={testId}
                          className={classNames(css.selectedValue)}
                          // fix for Safari (it prevent adding default styles for button)
                          type=""
                        >
                          {renderSelectedValue({ selectedValue, isOpen })}
                        </div>
                      ) : (
                        <>
                          <input
                            {...inputProps}
                            {...toggleButtonProps}
                            onKeyDown={handleKeyDown}
                            readOnly={!autoComplete}
                            tabIndex={tabIndex}
                            type="select"
                            className={classNames(css.selectedValue, css.input, className.input)}
                            value={inputValue}
                            onChange={handleInputChange}
                            disabled={disabled}
                            placeholder={selectedValue || placeholder}
                            ref={referenceProps.ref}
                            data-testid={testId}
                          />
                          {!disabled && (
                            <DropdownSvg
                              className={classNames({
                                [css.iconUp]: isOpen,
                                [css.iconDown]: !isOpen,
                                [css.iconDisabled]: disabled
                              })}
                              onClick={disabled ? undefined : toggleButtonProps.onClick}
                            />
                          )}
                        </>
                      )
                    }
                  </Reference>
                  {isOpen && (
                    <ul
                      className={css.list}
                      {...getMenuProps({
                        ref: popperProps.ref,
                        style: popperProps.style
                      })}
                      data-placement={popperProps.placement}
                    >
                      {appliedOptions.map((eachOption, index) => {
                        const isHighlighted = highlightedIndex === index;
                        const isSelected = Array.isArray(value)
                          ? value.includes(eachOption.value)
                          : get(selectedItem, "value", null) === eachOption.value;

                        const baseOptionClassNames = isHighlighted
                          ? classNames(css.option, css.hoveredOption, { [css.selectedHoveredOption]: isSelected })
                          : classNames(
                              css.option,
                              { [css.emptyOption]: eachOption.value === emptyValue },
                              { [css.selectedOption]: isSelected }
                            );

                        return (
                          <li
                            key={eachOption.key || eachOption.value}
                            className={classNames(baseOptionClassNames, {
                              [css.optionDisabled]: eachOption.disabled
                            })}
                            {...getItemProps({ item: eachOption, disabled: eachOption.disabled })}
                            data-testid={`${testId}Option:${eachOption.value}`}
                          >
                            {eachOption.label}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              );
            }}
          </Downshift>
        )}
      </Popper>
    </Manager>
  );
}
