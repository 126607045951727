import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { DisconnectedAbstractField } from "triangular/components/form/AbstractField/DisconnectedAbstractField";
import { Select, SelectOption, SelectProps } from "triangular/components/Select/Select";
import { NO_RESTRICTIONS } from "triangular/consts";

import css from "./LabeledSelect.module.scss";

type LabeledSelectProps<T extends string> = Omit<SelectProps<T>, "placeholder" | "value" | "onChange"> & {
  label: string;
  options: SelectOption<T>[];
  value: T | T[];
  onChange: (selectedValue: T | T[]) => void;
  noRestrictionsValue?: string;
};

export function LabeledSelect<T extends string>({
  label,
  onChange,
  options,
  noRestrictionsValue = NO_RESTRICTIONS,
  ...selectProps
}: LabeledSelectProps<T>) {
  const { t } = useTranslation();

  const defaultPlaceholder = options.length === 0 ? t("selectField.notAvailable") : t("selectField.placeholder");

  const allowsMultipleValues = Array.isArray(selectProps.value);
  const isNotEmpty = selectProps.value.length > 0;

  const appliedDefaultPlaceholder =
    allowsMultipleValues && isNotEmpty
      ? options
          .filter(({ value }) => (selectProps.value as T[]).includes(value))
          .map(({ label }) => label)
          .join(", ")
      : defaultPlaceholder;

  const noRestrictionsEnabledOptions = options.map(eachOption =>
    eachOption.value !== noRestrictionsValue
      ? {
          ...eachOption,
          disabled: true
        }
      : eachOption
  );

  const noRestrictionsDisabledOptions =
    allowsMultipleValues && isNotEmpty
      ? options.map(eachOption =>
          eachOption.value === noRestrictionsValue
            ? {
                ...eachOption,
                disabled: true
              }
            : eachOption
        )
      : options;

  const noRestrictionsHandledOptions =
    allowsMultipleValues && selectProps.value.includes(noRestrictionsValue as T)
      ? noRestrictionsEnabledOptions
      : noRestrictionsDisabledOptions;

  const sortedOptions = noRestrictionsHandledOptions.sort(({ value }) => (value === noRestrictionsValue ? -1 : 0));

  const handleChange = useCallback(
    (selectedValue: T) => {
      if (selectProps.value === null) {
        return onChange([selectedValue]);
      }

      if (Array.isArray(selectProps.value)) {
        if (selectProps.value.includes(selectedValue)) {
          return onChange(selectProps.value.filter(eachValue => eachValue !== selectedValue));
        }

        return onChange([...selectProps.value, selectedValue]);
      }

      return onChange(selectedValue);
    },
    [onChange, selectProps.value]
  );

  return (
    <DisconnectedAbstractField
      label={label}
      render={() => (
        <Select
          placeholder={appliedDefaultPlaceholder}
          className={
            allowsMultipleValues && isNotEmpty
              ? {
                  input: css.multiSelectFilledInput
                }
              : {}
          }
          onChange={handleChange}
          options={sortedOptions}
          {...selectProps}
        />
      )}
    />
  );
}
