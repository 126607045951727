import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";

import { Typography } from "triangular/components/Typography/Typography";
import { routes } from "triangular/Routes/routesConfiguration";
import { useStore } from "triangular/stores/StoreContext";

import { ChangePasswordForm, ChangePasswordFormValues } from "./ChangePasswordForm/ChangePasswordForm";

interface ChangePasswordProps extends RouteComponentProps {
  token: string;
}

export const ChangePassword: React.FC<ChangePasswordProps> = ({ history, token }) => {
  const { t } = useTranslation();
  const { userStore, snackbarStore } = useStore();

  const handleSubmit = async ({ password }: ChangePasswordFormValues) => {
    try {
      await userStore.resetPassword({ password, token });
      snackbarStore.addSnackbar({ type: "success", message: t("passwordRecovery.successMessage") });
      history.push(routes.login.build());
    } catch (err) {
      snackbarStore.addSnackbar({ type: "error", message: t("errors.generic") });
    }
  };

  const handleOnCancel = () => {
    history.push(routes.login.build());
  };

  return (
    <>
      <Typography component="h2" size="large" bold={true}>
        {t("passwordRecovery.setNewPasswordTitle")}
      </Typography>

      <ChangePasswordForm onSubmit={handleSubmit} onCancel={handleOnCancel} />
    </>
  );
};
