import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Layout } from "triangular/components/Layout/Layout";
import { PageTitle } from "triangular/components/PageTitle/PageTitle";
import { FlatEntity } from "triangular/services/Api/Resource/utils/types";
import { ExpertEntity } from "triangular/services/Api/resources/ExpertResource";
import { useStore } from "triangular/stores/StoreContext";

import css from "./ExpertDetails.module.scss";
import { ExpertDetailsContent } from "./ExpertDetailsContent/ExpertDetailsContent";

interface ExpertDetailsProps {
  id: string;
}

export type ExpertPublicCertificate = ExpertPublicData["certificates"];
export type ExpertPublicExperiences = ExpertPublicData["experiences"];

export type ExpertPublicData = FlatEntity<ExpertEntity>;

const ErrorMessage: React.FC = () => {
  const { t } = useTranslation();
  return <PageTitle title={t("expertDetails.errorTitle")} description={t("expertDetails.errorMessage")} />;
};

export const ExpertDetails: React.FC<ExpertDetailsProps> = observer(({ id }) => {
  const { i18n } = useTranslation();
  const landingPageUrl = process.env.REACT_APP_LP_URL;
  const howItWorksUrl =
    i18n.language !== "en" && i18n.language !== "pl"
      ? landingPageUrl + "/" + i18n.language + "/how-it-works"
      : landingPageUrl + "/how-it-works";
  const { userStore, expertStore, snackbarStore } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);

  const [expert, setExpert] = useState<ExpertPublicData | null>(null);

  const fetchExpert = useCallback(async () => {
    setIsLoading(true);
    try {
      const fetchedExpert = await expertStore.fetchExpert(id, {
        include: {
          experiences: true,
          certificates: true,
          avatar: true,
          industries: true,
          specialties: true,
          materialCategories: true
        }
      });
      setExpert(fetchedExpert);
    } catch (err) {
      snackbarStore.showGenericError(err);
      setErrorOccurred(true);
    }
    setIsLoading(false);

    return () => {
      expertStore.cleanUpCurrentExpert();
    };
  }, [expertStore, id, snackbarStore]);

  useEffect(() => {
    if (userStore.isAuthenticated) {
      fetchExpert();
    }
  }, [fetchExpert, userStore.isAuthenticated]);

  if (userStore.isAuthenticated) {
    return (
      <Layout withMolecules={false} loading={isLoading} className={{ main: css.main }}>
        {errorOccurred ? <ErrorMessage /> : null}
        {expert ? <ExpertDetailsContent expert={expert} /> : null}
      </Layout>
    );
  }

  window.location.replace(howItWorksUrl);
  return null;
});
