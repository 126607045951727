import React from "react";

import { useBreakpoint } from "triangular/components/Breakpoint/Breakpoint";
import { Layout } from "triangular/components/Layout/Layout";

import { DesktopViewContent } from "./DesktopViewContent/DesktopViewContent";
import { MobileViewContent } from "./MobileViewContent/MobileViewContent";

interface SearchSceneLayoutProps {
  mobileView: React.ReactNode;
  desktopView: React.ReactNode;
}

const SearchSceneLayout = ({ mobileView, desktopView }: SearchSceneLayoutProps) => {
  const isMobileView = useBreakpoint({
    minWidth: "small",
    maxWidth: "small"
  });

  return (
    <Layout withMolecules={false} removePadding={true}>
      {isMobileView ? mobileView : desktopView}
    </Layout>
  );
};

export const SearchScene = {
  Layout: SearchSceneLayout,
  MobileViewContent,
  DesktopViewContent
};
