import React from "react";
import { useTranslation } from "react-i18next";

import { Layout } from "triangular/components/Layout/Layout";
import { PageTitle } from "triangular/components/PageTitle/PageTitle";
import { TriLink } from "triangular/components/TriLink/TriLink";
import { Typography } from "triangular/components/Typography/Typography";
import { routes } from "triangular/Routes/routesConfiguration";

export const NotFound: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Layout withMolecules={false}>
      <PageTitle title={t("notFound.title")} description={t("notFound.description")} />
      <Typography center={true} component="div">
        <TriLink to={routes.home.build()}>{t("notFound.backToHomePage")}</TriLink>
      </Typography>
    </Layout>
  );
};
