import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Variants } from "../Button/Button";
import { Typography } from "../Typography/Typography";

import css from "./ReadMore.module.scss";

interface ReadMoreProps {
  content?: string;
  variant?: Variants;
  limit?: number;
  className?: {
    p?: string;
    buttonWrapper?: string;
  };
}

export const ReadMore: React.FC<ReadMoreProps> = ({ content = "", limit = 0, className = {}, variant }) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isTooLong, setIsTooLong] = useState(false);

  useEffect(() => {
    if (limit) {
      setIsTooLong(content.length > limit);
    }
  }, [content, limit, setIsTooLong]);

  const renderContent = () => {
    if (!content) {
      return "";
    }

    return isTooLong && !isExpanded ? `${content.slice(0, limit)}... ` : `${content} `;
  };

  const renderExpandLink = () => {
    if (!isTooLong) {
      return;
    }

    const handleClick = (e: React.MouseEvent) => {
      if (e) {
        e.preventDefault();
      }

      setIsExpanded(prevState => !prevState);
    };

    return (
      <div className={classNames(css.actionWrapper, className.buttonWrapper)}>
        <Button variant={variant} transparent={true} onClick={handleClick}>
          {isExpanded ? t("ReadMore.hide") : t("ReadMore.more")}
        </Button>
      </div>
    );
  };

  return (
    <div className={className.p}>
      {/* https://medium.com/@kevinsimper/react-newline-to-break-nl2br-a1c240ba746 */}
      {renderContent()
        .split("\n")
        .map((text, index, array) => (
          <Typography component="p" key={index}>
            {text} {index === array.length - 1 ? null : <br />}
          </Typography>
        ))}
      {renderExpandLink()}
    </div>
  );
};
