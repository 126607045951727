import classNames from "classnames";
import React, { RefObject } from "react";

import css from "./Typography.module.scss";

type Sizes = "tiny" | "small" | "medium" | "big" | "mediumlarge" | "large";
export type FontSizes = Sizes | "veryLarge";

interface TypographyProps {
  size?: FontSizes;
  verticalSpacing?: Sizes | "none";
  horizontalSpacing?: Sizes | "none";
  upperCase?: boolean;
  bold?: boolean;
  component?: string | React.ComponentType<{ className?: string }>;
  color?: "black" | "white" | "mint" | "darkMint" | "red" | "mushroom";
  underline?: boolean;
  center?: boolean;
  font?: "rubik" | "karla";
  ellipsis?: boolean;
  "data-testid"?: string;
  className?: string;
  ref?: RefObject<HTMLElement>;
}

export const Typography: React.FC<TypographyProps> = React.forwardRef(
  (
    {
      children,
      size = "medium",
      upperCase = false,
      component,
      bold,
      className,
      color = "black",
      verticalSpacing = "none",
      horizontalSpacing = "none",
      center = false,
      underline = false,
      ellipsis = false,
      font = "rubik",
      "data-testid": testId,
      ...props
    },
    ref
  ) => {
    const Component = component || "span";
    const classes = classNames(
      ...[
        className,
        css[size],
        css[color],
        css[`${verticalSpacing}VerticalSpacing`],
        css[`${horizontalSpacing}HorizontalSpacing`],
        css[`${font}Font`],
        {
          [css.center]: center,
          [css.bold]: bold,
          [css.underline]: underline,
          [css.upperCase]: upperCase,
          [css.ellipsis]: ellipsis
        }
      ]
    );
    return (
      <Component data-testid={testId} className={classes} ref={ref} {...props}>
        {children}
      </Component>
    );
  }
);
