import classNames from "classnames";
import React, { useState } from "react";
import ResponsiveModal from "react-responsive-modal";
import ReactTooltip from "react-tooltip";

import css from "./Modal.module.scss";

export const useModal = <Params extends {} | null>(isInitiallyOpen = false) => {
  const [isOpen, setIsOpen] = useState(isInitiallyOpen);
  const [params, setParams] = useState<Params | null>(null);
  return {
    isOpen,
    open: (params: React.SetStateAction<Params | null> = null) => {
      setParams(params);
      return setIsOpen(true);
    },
    close: () => {
      setParams(null);
      return setIsOpen(false);
    },
    params
  };
};

interface ClassName {
  overlay?: string;
  modal?: string;
  closeButton?: string;
}

export interface ModalProps extends Omit<PropsOf<typeof ResponsiveModal>, "classNames" | "showCloseIcon" | "open"> {
  className?: ClassName | ((css: ClassName) => ClassName);
  hasCloseIcon?: boolean;
  isOpen: boolean;
  centerText?: boolean;
}

const closeIconSvgPath = (
  <g fill="none" fillRule="evenodd" stroke={css.darkMint} strokeWidth="2">
    <path d="M1 1l15 15M16 1L1 16" />
  </g>
);

export const Modal: React.FC<ModalProps> = ({
  className = {},
  hasCloseIcon = true,
  children,
  isOpen,
  centerText = true,
  ...props
}) => {
  const classNameApplied = {
    overlay: css.overlay,
    modal: classNames(css.modal, { [css.centerText]: centerText }),
    closeButton: css.closeButton,
    ...(typeof className === "function" ? className(css) : className)
  };

  // Hack: fix tooltips for modals
  setImmediate(ReactTooltip.rebuild);

  return (
    <ResponsiveModal
      {...props}
      open={isOpen}
      showCloseIcon={hasCloseIcon}
      classNames={classNameApplied}
      closeIconSvgPath={closeIconSvgPath}
      closeIconSize={42}
    >
      {children}
    </ResponsiveModal>
  );
};
