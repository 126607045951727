import { useState } from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { routes } from "triangular/Routes/routesConfiguration";
import { EntityBlueprint } from "triangular/services/Api/Resource/utils/types";
import { SystemEntity } from "triangular/services/Api/resources/SystemResource";
import { gaActions, GaCategories, googleAnalytics } from "triangular/services/googleAnalytics";
import { useStore } from "triangular/stores/StoreContext";
import { createNumericStringOrNull, createRelationOrNull } from "triangular/utils/api";
import { resolveRequestOptionalValue } from "triangular/utils/systems";

import { SystemSchema } from "./types";

export const useSystemForm = (queryParamId: string | null) => {
  const { systemsStore, snackbarStore, userStore } = useStore();
  const [systemId, setSystemId] = useState<typeof queryParamId>(queryParamId);
  const { history } = useRouter();
  const { t } = useTranslation();

  const handleSystemRequest = async (values: SystemSchema, state = "submitted") => {
    const {
      processCategory,
      systemCategory,
      systemType,
      designType,
      systemDocumentFiles,
      systemPhotoFiles,
      installedPowerMillSelect,
      installedPowerClassifier,
      installedPowerBar,
      installedPowerFlowRate,
      installedPowerKw,
      airflow,
      airflowEnabled,
      minimumMaterialAmount,
      minimumMaterialAmountEnabled,
      targetIndustries,
      materialCategories,
      ...attributes
    } = values;

    if (!userStore.profileId) {
      throw new Error("Profile id not available");
    }

    const systemParameters = {
      ...attributes,
      state,
      abrasiveness: attributes.abrasiveness,
      corrosiveness: attributes.corrosiveness,
      explosiveness: attributes.explosiveness,
      installedPowerMill:
        installedPowerBar && installedPowerFlowRate
          ? {
              bar: createNumericStringOrNull(installedPowerBar),
              "m3/h": createNumericStringOrNull(installedPowerFlowRate)
            }
          : null,
      installedPower: installedPowerKw
        ? {
            kw: createNumericStringOrNull(installedPowerKw)
          }
        : null,
      installedPowerClassifierKw: installedPowerClassifier,
      systemOwner: {
        id: userStore.profileId
      },
      processCategory: createRelationOrNull(values.processCategory),
      systemCategory: createRelationOrNull(values.systemCategory),
      systemType: createRelationOrNull(values.systemType),
      designTypes: designType ? designType.map(id => ({ id })) : null,
      systemPhotos: systemPhotoFiles ? systemPhotoFiles.map(({ id }) => ({ id })) : undefined,
      systemDocuments: systemDocumentFiles ? systemDocumentFiles.map(({ id }) => ({ id })) : undefined,
      airflow: resolveRequestOptionalValue(airflowEnabled, createNumericStringOrNull(airflow)),
      minimumMaterialAmount: resolveRequestOptionalValue(
        minimumMaterialAmountEnabled,
        createNumericStringOrNull(minimumMaterialAmount)
      ),
      industries: targetIndustries ? targetIndustries.map(id => ({ id })) : [],
      materialCategories: materialCategories ? materialCategories.map(id => ({ id })) : []
    };

    if (!systemId) {
      const createdSystemId = await systemsStore.createSystem(
        (systemParameters as unknown) as EntityBlueprint<SystemEntity>
      );
      setSystemId(createdSystemId);
      return createdSystemId;
    } else {
      await systemsStore.updateSystem(systemId, (systemParameters as unknown) as EntityBlueprint<SystemEntity>);
      return systemId;
    }
  };

  return {
    handleSubmit: async (values: SystemSchema) => {
      await handleSystemRequest(values, "submitted");

      googleAnalytics.sendEvent({
        category: GaCategories.systemOwner,
        action: gaActions.systemOwner.systemSubmit.succeeded
      });

      snackbarStore.addSnackbar({ type: "success", message: t("addSystem.systemSubmitted") });
      history.push(routes.mySystemsList.build());
    },
    handleSave: async (values: SystemSchema) => {
      const savedSystemId = await handleSystemRequest(values, "draft");

      googleAnalytics.sendEvent({
        category: GaCategories.systemOwner,
        action: gaActions.systemOwner.systemSave.succeeded
      });

      setSystemId(savedSystemId);
    },
    handleSavedModalClose: () => {
      if (systemId) {
        history.push(routes.editSystem.build({ id: systemId }));
      }
    }
  };
};
