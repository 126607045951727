import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

import { AgreementModal } from "triangular/components/AgreementModal/AgreementModal";
import { Layout } from "triangular/components/Layout/Layout";
import { useModal } from "triangular/components/Modal/Modal";
import { SearchResultsList } from "triangular/components/SearchResultsList/SearchResultsList";
import { SystemTile } from "triangular/components/SystemTile/SystemTile";
import { Typography } from "triangular/components/Typography/Typography";
import { UserGreeting } from "triangular/components/UserGreeting/UserGreeting";
import { UserHomeButtons } from "triangular/components/UserHomeButtons/UserHomeButtons";
import { useStore } from "triangular/stores/StoreContext";
import { SystemModelSnapshot } from "triangular/stores/SystemSearchStore/models";
import { useCancellation } from "triangular/utils/api";

import css from "./MySystemsList.module.scss";
import { parsePaginationQuery, usePagination } from "./utils";

export const MySystemsList: React.FC<RouteComponentProps> = observer(({ location }) => {
  const cancellation = useCancellation();
  const { mySystemsStore, snackbarStore, agreementStore } = useStore();
  const agreementConfirmation = useModal();

  const closeAgreementModal = () => {
    agreementConfirmation.close();
  };

  useEffect(() => {
    if (agreementStore.openConfirmation) {
      agreementConfirmation.open();
    }
  }, [agreementConfirmation, agreementStore]);

  const pagination = usePagination({
    totalItems: mySystemsStore.totalItems,
    location
  });

  const { t } = useTranslation();

  const removeSystem = async (id: string, isDraft: boolean) => {
    try {
      await mySystemsStore.deleteMySystem(id);

      if (isDraft) {
        await mySystemsStore.loadSystems({
          page: pagination.paginationQuery.page,
          cancelToken: cancellation.cancelToken
        });
      } else {
        snackbarStore.addSnackbar({ type: "success", message: t("mySystemsList.removeItem.requestSent") });
      }
    } catch (err) {
      snackbarStore.showGenericError(err);
    }
  };

  const loadSystems = useCallback(async () => {
    try {
      const { page } = parsePaginationQuery(location.search);
      await mySystemsStore.loadSystems({
        page,
        cancelToken: cancellation.cancelToken
      });
    } catch (err) {
      snackbarStore.showGenericError(err);
    }
  }, [cancellation.cancelToken, location.search, mySystemsStore, snackbarStore]);

  useEffect(() => {
    loadSystems();
  }, [loadSystems]);

  useEffect(
    () => () => {
      mySystemsStore.setSystems([]);
      mySystemsStore.setIsLoading(true);
      mySystemsStore.setTotalItems(0);
    },
    [mySystemsStore]
  );

  return (
    <Layout withMolecules={false}>
      <UserGreeting />
      <UserHomeButtons />
      <AgreementModal isOpen={agreementConfirmation.isOpen} onClose={closeAgreementModal} />
      <div>
        <Typography className={css.center} component="h2" size="large" bold={true} data-testid="mySystemsHeader">
          {t("mySystemsList.title")}
        </Typography>
      </div>

      <SearchResultsList
        isLoading={mySystemsStore.isLoading}
        items={mySystemsStore.systems}
        noResultsMessage={() => null}
        hasTripleColumnsLayout={true}
        {...pagination}
      >
        {(eachSystem: SystemModelSnapshot) => (
          <SystemTile
            key={eachSystem.id}
            id={eachSystem.id}
            state={eachSystem.state}
            systemCategory={eachSystem.systemCategory}
            systemType={eachSystem.systemType}
            name={eachSystem.name}
            countryCode={eachSystem.countryCode}
            materialsBlacklist={eachSystem.materialsBlacklist}
            materialsWhitelist={eachSystem.materialsWhitelist}
            industries={eachSystem.industries}
            isMySystem={true}
            onRemove={removeSystem}
          />
        )}
      </SearchResultsList>
    </Layout>
  );
});
