import React from "react";

import { PageTitle as BasePageTitle } from "triangular/components/PageTitle/PageTitle";

import css from "./PageTitle.module.scss";

export const PageTitle = ({ title, description }: { title: string; description: string }) => {
  return (
    <>
      <BasePageTitle
        className={{
          description: css.description
        }}
        title={title}
        description={description}
      />
    </>
  );
};
