import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";

import { SystemForm } from "triangular/components/form/system/SystemForm/SystemForm";
import { useSystemForm } from "triangular/components/form/system/SystemForm/useSystemForm";
import { Layout } from "triangular/components/Layout/Layout";
import { Typography } from "triangular/components/Typography/Typography";
import { routes } from "triangular/Routes/routesConfiguration";

import css from "./AddSystem.module.scss";

export const AddSystem: React.FC<RouteComponentProps> = observer(({ history }) => {
  const { t } = useTranslation();
  const { handleSave, handleSubmit, handleSavedModalClose } = useSystemForm(null);

  return (
    <Layout withMolecules={false}>
      <div className={css.wrapper}>
        <Typography component="h2" size="large" bold={true} center={true} className={css.header}>
          {t("addSystem.addNewSystem")}
        </Typography>
        <SystemForm
          handleSave={handleSave}
          handleSubmit={handleSubmit}
          onGoBack={() => history.push(routes.home.build())}
          onSavedModalClose={handleSavedModalClose}
        />
      </div>
    </Layout>
  );
});
