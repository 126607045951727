import { connect } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { MaterialCategoryField as MaterialCategoryFieldBase } from "../../system/MaterialCategoryField";

export const MaterialCategoryField = connect<
  {},
  {
    targetIndustry?: string | null;
  }
>(
  observer(({ formik }) => {
    const { t } = useTranslation();
    const selectedTargetIndustryIds = formik.values.targetIndustry ? [formik.values.targetIndustry] : [];

    return (
      <MaterialCategoryFieldBase
        label={t("materialForm.materialInfo.materialCategory")}
        name="materialCategory"
        isMultiple={false}
        hasNoRestrictionsOption={false}
        selectedTargetIndustryIds={selectedTargetIndustryIds}
      />
    );
  })
);
