import { History } from "history";
import i18n, { Module } from "i18next";
import { initReactI18next } from "react-i18next";

import { languageOptions } from "triangular/consts";

const resources = languageOptions.reduce(
  (acc, { value }) =>
    Object.assign(acc, {
      [value]: {
        translation: require(`./locales/${value}.json`)
      }
    }),
  {}
);

i18n.use(initReactI18next as Module).init({
  resources,
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  },
  debug: process.env.NODE_ENV === "development"
});

i18n.on("languageChanged", value => {
  localStorage.setItem("language", value);
});

function setupInitialLanguage(history: History) {
  const { search } = history.location;

  const browserLanguage = window.navigator.language.split("-")[0];
  const languages = languageOptions.map(({ value }) => value);
  const languageQueryParam = new URLSearchParams(search).get("language");
  const languageFromQuery = languageOptions.some(({ value }) => languageQueryParam === value)
    ? languageQueryParam
    : null;

  if (languageQueryParam) {
    const { href, origin } = window.location;
    const currentRouteWithoutLanguageParam = href.replace(origin, "").replace(/language=[A-z]{2}/, "");
    history.replace(currentRouteWithoutLanguageParam);
  }

  const currentLanguage =
    languageFromQuery ||
    localStorage.getItem("language") ||
    languages.find(eachLanguage => eachLanguage === browserLanguage);

  if (currentLanguage) {
    i18n.changeLanguage(currentLanguage);
  }
}

export { i18n, setupInitialLanguage };
