import { types } from "mobx-state-tree";

import { SortingOrder } from "triangular/services/Api/Resource/utils/types";

export function createSortModel<Attribute extends string>(possibleAttributes: Attribute[]) {
  return types
    .model("Sort", {
      attributes: types.array(
        types.model({
          name: types.enumeration(possibleAttributes),
          order: types.enumeration(["ASC", "DESC"])
        })
      )
    })
    .actions(self => ({
      setAttributes(attributes: Array<{ order?: SortingOrder; name?: Attribute }>) {
        Object.assign(self, {
          attributes: attributes.map(eachAttribute => ({
            name: eachAttribute.name,
            order: eachAttribute.order || "ASC"
          }))
        });
      },
      getAttributes() {
        return self.attributes.map(({ name, order }) => [name, order]) as Array<[string, "ASC" | "DESC"]>;
      }
    }));
}

export type SortModelType = ReturnType<typeof createSortModel>["Type"];
