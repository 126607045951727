import { types } from "mobx-state-tree";

import { settingItemModel } from "../utils/settingItemModel";

export const commonSettingsModel = types.model("CommonSettings", {
  colors: types.array(types.string),
  systemTypes: types.map(types.array(settingItemModel)),
  designTypes: types.map(types.array(settingItemModel)),
  systemCategories: types.map(types.array(settingItemModel)),
  industries: types.array(settingItemModel),
  materials: types.array(types.string),
  processes: types.array(settingItemModel),
  materialCategoriesByIndustries: types.map(types.array(settingItemModel)),
  noRestrictionsIds: types.model({
    materialCategory: types.string,
    industry: types.string
  })
});
