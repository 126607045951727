import classNames from "classnames";
import React from "react";

import css from "./HamburgerToggle.module.scss";

interface Props {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  isOpen: boolean;
  isNavBar?: boolean;
}

export const HamburgerToggle = React.forwardRef<HTMLDivElement, Props>(({ onClick, isOpen, isNavBar }, ref) => (
  <div>
    <div className={classNames(css.hamburger, { [css.open]: isOpen, [css.nav]: isNavBar })} onClick={onClick}>
      <span className={css.bar}></span>
      <div className={css.overlay} ref={ref} />
    </div>
  </div>
));
