import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Masonry } from "triangular/components/Masonry/Masonry";
import { PageLoader } from "triangular/components/PageLoader/PageLoader";
import { getPaginationProps, Pagination } from "triangular/components/Pagination/Pagination";
import { useStore } from "triangular/stores/StoreContext";
import { useCancellation } from "triangular/utils/api";
import { useAutoScrollOnChange, useOnHistoryBack } from "triangular/utils/components";

import { MaterialItem } from "../MaterialItem/MaterialItem";

export const MaterialsList: React.FC = observer(() => {
  const { t } = useTranslation();
  const { myMaterialsStore, snackbarStore } = useStore();
  const { pagination } = myMaterialsStore;
  const list = Array.from(myMaterialsStore.pagination.currentItems);
  const { cancel, cancelToken } = useCancellation();

  useEffect(() => () => cancel(), [cancel]);

  useAutoScrollOnChange([pagination.currentItems]);

  const loadMyMaterials = useCallback(async () => {
    try {
      await myMaterialsStore.fetchMyMaterials();
    } catch (e) {
      snackbarStore.addSnackbar({ type: "error", message: t("errors.generic") });
    }
  }, [myMaterialsStore, snackbarStore, t]);

  useEffect(() => {
    loadMyMaterials();
  }, [loadMyMaterials]);

  useOnHistoryBack(() => {
    loadMyMaterials();
  }, []);

  if (pagination.loading) {
    return <PageLoader />;
  }

  if (list.length === 0) {
    return null;
  }

  return (
    <>
      <Masonry>
        {list.map(
          ({
            id,
            materialIndex,
            feedParticleSize,
            processCategory,
            indexNumber,
            name,
            state,
            systemCategory,
            systemType,
            designType
          }) => (
            <MaterialItem
              key={id}
              id={id}
              materialIndex={materialIndex}
              indexNumber={indexNumber}
              name={name}
              state={state}
              feedParticleSize={feedParticleSize}
              processCategory={processCategory}
              systemCategory={systemCategory}
              systemType={systemType}
              designType={designType}
            />
          )
        )}
      </Masonry>
      <Pagination {...getPaginationProps({ pagination, config: { cancelToken }, refetch: true })} />
    </>
  );
});
