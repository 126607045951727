import pick from "lodash/pick";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { routes } from "triangular/Routes/routesConfiguration";
import { ParticleSize } from "triangular/services/Api/resources/MaterialResource";
import { gaActions, GaCategories, googleAnalytics } from "triangular/services/googleAnalytics";
import { useStore } from "triangular/stores/StoreContext";
import { createNumericStringOrNull, createRelationOrNull } from "triangular/utils/api";
import { pickNotEmpty } from "triangular/utils/language";

import { MaterialSchema } from "./materialSchema";

interface UseMaterialParams {
  derivedSystemId?: string | null;
  derivedMaterialId?: string | null;
  isCopy?: boolean;
}

export const useMaterialForm = ({
  derivedSystemId = null,
  derivedMaterialId = null,
  isCopy = false
}: UseMaterialParams) => {
  const { history } = useRouter();
  const { t } = useTranslation();
  const { snackbarStore, userStore, materialsStore } = useStore();
  const [materialId, setMaterialId] = useState<string | null>(isCopy ? null : derivedMaterialId);

  const personalAndCompanyData = useMemo(
    () => pickNotEmpty(pick(userStore, ["firstName", "lastName", "phoneNumber", "companyName", "email"])),
    [userStore]
  );

  const formatFormData = (values: MaterialSchema) => {
    const {
      materialAmount,
      density,
      bulkDensity,
      humidity,
      processCategory,
      systemCategory,
      systemType,
      designType,
      deadline,
      feedParticleSize,
      desiredParticleSize,
      materialSafetyFiles,
      materialTechFiles,
      abrasiveness,
      corrosiveness,
      explosiveness,
      hazardousness,
      targetIndustry,
      materialCategory,
      ...appliedValues
    } = values;

    const formatParticleSize = (particleSize: ParticleSize<number>) =>
      particleSize
        ? Array(3)
            .fill(null)
            .map((__, index) =>
              particleSize[index]
                ? {
                    size: createNumericStringOrNull(particleSize[index]!.size),
                    percent: createNumericStringOrNull(particleSize[index]!.percent)
                  }
                : null
            )
        : null;

    return {
      ...appliedValues,
      abrasiveness: abrasiveness,
      corrosiveness: createNumericStringOrNull(corrosiveness),
      explosiveness: explosiveness,
      hazardousness: hazardousness,
      materialAmount: createNumericStringOrNull(materialAmount),
      density: createNumericStringOrNull(density),
      bulkDensity: createNumericStringOrNull(bulkDensity),
      humidity: createNumericStringOrNull(humidity),
      feedParticleSize: formatParticleSize((feedParticleSize as unknown) as ParticleSize<number>),
      desiredParticleSize: formatParticleSize((desiredParticleSize as unknown) as ParticleSize<number>),
      deadline: deadline ? new Date(deadline).toISOString() : null,
      materialOwner: createRelationOrNull(userStore.profileId),
      processCategory: createRelationOrNull(processCategory),
      systemCategory: createRelationOrNull(systemCategory),
      systemType: createRelationOrNull(systemType),
      designType: createRelationOrNull(designType),
      materialSafetyFiles: materialSafetyFiles ? materialSafetyFiles.map(({ id }) => ({ id })) : null,
      materialTechFiles: materialTechFiles ? materialTechFiles.map(({ id }) => ({ id })) : null,
      system: createRelationOrNull(derivedSystemId),
      industry: createRelationOrNull(targetIndustry),
      materialCategory: createRelationOrNull(materialCategory)
    };
  };

  const request = materialId
    ? (params: Parameters<typeof materialsStore.updateMaterial>[1]) => materialsStore.updateMaterial(materialId, params)
    : materialsStore.createMaterial;

  const handleSubmit = async (values: MaterialSchema) => {
    const { id: createdMaterialId } = await request({
      ...formatFormData(values),
      state: "submitted"
    });

    googleAnalytics.sendEvent({
      category: GaCategories.materialOwner,
      action: gaActions.materialOwner.materialSubmit.succeeded
    });

    setMaterialId(createdMaterialId);
    snackbarStore.addSnackbar({ type: "success", message: t("materialForm.submitSuccess") });
    history.push(routes.myMaterialsList.build());
  };

  const handleSave = async (values: MaterialSchema) => {
    const formatted = formatFormData(values);

    const { id: createdMaterialId } = await request({
      ...formatted,
      state: "draft"
    });

    googleAnalytics.sendEvent({
      category: GaCategories.materialOwner,
      action: gaActions.materialOwner.materialSave.succeeded
    });

    if (!derivedMaterialId || isCopy) {
      setMaterialId(createdMaterialId);
    }
  };

  const handleSavedModalClose = () => {
    history.push(routes.editMaterial.build({ id: materialId! }));
  };

  return {
    personalAndCompanyData,
    handleSubmit,
    handleSave,
    handleSavedModalClose
  };
};
