import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { MultiSelectField, MultiSelectFieldProps } from "triangular/components/form/MultiSelectField/MultiSelectField";
import { useStore } from "triangular/stores/StoreContext";
import { settingItemsToSelectOptions } from "triangular/utils/experts";

interface SpecialtiesFieldProps {
  className?: MultiSelectFieldProps["className"];
  name: string;
  label?: string;
  disabled?: boolean;
}

export const SpecialtiesField: React.FC<SpecialtiesFieldProps> = observer(({ className, name, label, disabled }) => {
  const expertSettings = useStore().expertStore.getSettings();
  const { t } = useTranslation();
  const specialtiesOptions = settingItemsToSelectOptions(t)("specialtyOptions", expertSettings.specialties);

  return (
    <MultiSelectField
      className={className}
      label={label}
      name={name}
      disabled={disabled}
      selectProps={{
        options: specialtiesOptions
      }}
      required={true}
      data-testid="targetSpecialties"
    />
  );
});
