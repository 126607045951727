import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { InvoiceDetailsForm } from "triangular/components/form/InvoiceDetailsForm/InvoiceDetailsForm";
import { PageLoader } from "triangular/components/PageLoader/PageLoader";
import { SectionTitle } from "triangular/components/SectionTitle/SectionTitle";
import { FlatEntity } from "triangular/services/Api/Resource/utils/types";
import {
  InvoiceDetailsAttributes,
  InvoiceDetailsEntity
} from "triangular/services/Api/resources/InvoiceDetailsResource";
import { useStore } from "triangular/stores/StoreContext";
import { useLoadable } from "triangular/utils/components";

import css from "./InvoiceDetails.module.scss";

export const InvoiceDetails = observer(() => {
  const { t } = useTranslation();
  const { paymentsStore, snackbarStore } = useStore();
  const { loadingState, setLoadable } = useLoadable();
  const [fetchedInvoiceDetails, setFetchedInvoiceDetails] = useState<FlatEntity<InvoiceDetailsEntity> | null>(null);

  const fetchInvoiceDetails = useCallback(
    () =>
      setLoadable(async () => {
        setFetchedInvoiceDetails(await paymentsStore.fetchInvoiceDetails());
      }),
    [paymentsStore, setLoadable]
  );

  const updateInvoiceDetails = useCallback(
    async (values: InvoiceDetailsAttributes) => {
      try {
        await paymentsStore.updateInvoiceDetails(values);
        snackbarStore.addSnackbar({
          type: "success",
          message: t("payments.invoiceDetailsUpdated")
        });
      } catch (err) {
        await fetchInvoiceDetails();
        throw err;
      }
    },
    [fetchInvoiceDetails, paymentsStore, snackbarStore, t]
  );

  useEffect(() => {
    fetchInvoiceDetails();
  }, [fetchInvoiceDetails]);

  if (loadingState === "in_progress") {
    return <PageLoader />;
  }

  return (
    <FormSection>
      <SectionTitle className={css.titleSpacing}>{t("payments.invoiceDetails")}</SectionTitle>
      <InvoiceDetailsForm
        initialValues={
          fetchedInvoiceDetails
            ? {
                name: fetchedInvoiceDetails.name,
                postalCode: fetchedInvoiceDetails.postalCode,
                city: fetchedInvoiceDetails.city,
                countryCode: fetchedInvoiceDetails.countryCode,
                taxId: fetchedInvoiceDetails.taxId,
                addressLine1: fetchedInvoiceDetails.line1,
                addressLine2: fetchedInvoiceDetails.line2
              }
            : null
        }
        onSubmit={updateInvoiceDetails}
      />
    </FormSection>
  );
});
