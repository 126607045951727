import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import AnimateHeight from "react-animate-height";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { LanguageFlag } from "triangular/components/LanguageFlag/LanguageFlag";
import { Typography } from "triangular/components/Typography/Typography";
import { languageOptions } from "triangular/consts";
import { useStore } from "triangular/stores/StoreContext";

import { HamburgerToggle } from "../HamburgerToggle/HamburgerToggle";
import { MenuItemsGroup } from "../Header";
import { useMenuTypes } from "../menuItemsGroups";
import { useProfileTypeSwitches } from "../useProfileTypeSwitches";
import { useUserMenuItems } from "../useUserMenuItems";

import css from "./MenuMobile.module.scss";

export const MenuMobile: React.FC = observer(() => {
  const { i18n } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const toggleMenu = () => setOpen(open => !open);
  const { userStore, appLoadingState } = useStore();
  const { isAuthenticated } = userStore;
  const profileTypeSwitches = useProfileTypeSwitches();
  const userMenuItems = useUserMenuItems();
  const menuTypes = useMenuTypes();
  const menuItemsGroups = menuTypes[userStore.type || "default"];

  if (appLoadingState === "in_progress") {
    return <div className={classNames(css.container, { [css.open]: isOpen })} />;
  }

  return (
    <div className={classNames(css.container, { [css.open]: isOpen })}>
      <div className={css.navWrapper}>
        <HamburgerToggle isOpen={isOpen} onClick={toggleMenu} />
      </div>
      <AnimateHeight className={css.menu} duration={300} height={isOpen ? "auto" : 0}>
        {menuItemsGroups.map(({ items, id }: MenuItemsGroup) => (
          <ul key={id} className={css.itemsGroup}>
            {items.map((item, index) => (
              <li key={index} className={css.item}>
                <Typography upperCase={true}>
                  <NavLink className={css.link} activeClassName={css.activeLink} to={item.path}>
                    {item.name}
                  </NavLink>
                </Typography>
              </li>
            ))}
          </ul>
        ))}
        {profileTypeSwitches && (
          <ul className={css.itemsGroup}>
            {profileTypeSwitches.map(({ label, onClick, isCurrent }) => (
              <li
                key={label}
                className={classNames(css.item, css.profileTypeSwitch, { [css.profileTypeSwitchActive]: isCurrent })}
                onClick={onClick}
              >
                <Typography upperCase={true}>
                  <span className={css.link} onClick={onClick}>
                    {label}
                  </span>
                </Typography>
              </li>
            ))}
          </ul>
        )}
        {isAuthenticated ? (
          <ul className={css.itemsGroup}>
            {userMenuItems.map(({ label, onClick, danger }) => (
              <li key={label} className={css.item}>
                <Typography upperCase={true}>
                  <span className={classNames(css.link, { [css.danger]: danger })} onClick={onClick}>
                    {label}
                  </span>
                </Typography>
              </li>
            ))}
          </ul>
        ) : null}
        <ul className={css.itemsGroup}>
          {languageOptions.map(({ value, label }) => (
            <li
              key={value}
              className={classNames(css.item, css.languageItem)}
              onClick={() => i18n.changeLanguage(value)}
            >
              <Typography upperCase={true} color={value === i18n.language ? "mint" : "black"}>
                <LanguageFlag language={value} /> {label}
              </Typography>
            </li>
          ))}
        </ul>
      </AnimateHeight>
    </div>
  );
});
