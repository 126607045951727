import classNames from "classnames";
import { connect } from "formik";
import get from "lodash/get";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { FieldError } from "triangular/components/form/FieldError";
import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { IconButton } from "triangular/components/IconButton/IconButton";
import { Select, SelectProps } from "triangular/components/Select/Select";
import { Typography } from "triangular/components/Typography/Typography";
import { useStore } from "triangular/stores/StoreContext";
import { namesToSelectOptions } from "triangular/utils/experts";

import cssAlt from "../ExpertForm.module.scss";
import { ExpertSchema, Language } from "../expertSchema";

import css from "./Languages.module.scss";

export const Languages = connect<{}, ExpertSchema>(
  observer(({ formik }) => {
    const { t } = useTranslation();
    const fieldName = "languagesKnowledge";
    const prevFieldValue = formik.values[fieldName] || [];
    const setFieldValue = (value: Language[]) => formik.setFieldValue(fieldName, value);
    const setTouched = () => formik.setFieldTouched(fieldName);
    const { expertStore } = useStore();
    const { languages, languageLevels } = expertStore.getSettings();
    const isTouched = formik.touched[fieldName];
    const isFormDisabled = expertStore.isConfirmed();

    const languageOptions: SelectProps<string>["options"] = namesToSelectOptions(t)(
      "languageOptions",
      languages.filter(eachOption => !prevFieldValue.find(eachLanguage => eachLanguage.name === eachOption))
    );

    const languageLevelOptions: SelectProps<string>["options"] = namesToSelectOptions(t)(
      "languageLevelOptions",
      languageLevels
    );

    const getLanguageLabel = (name: string) => t(`expertSettings.languageOptions.${name}`);

    const handleLevelChange = (eachLanguage: Language) => (level: string) => {
      const updatedFieldValue = prevFieldValue.map(prevLanguage => {
        if (prevLanguage.name === eachLanguage.name) {
          return {
            name: prevLanguage.name,
            level
          };
        }

        return prevLanguage;
      });

      setFieldValue(updatedFieldValue);
    };

    const handleRemoveLanguage = (eachLanguage: Language) => () => {
      const updatedFieldValue = prevFieldValue.filter(prevLanguage => prevLanguage.name !== eachLanguage.name);
      setFieldValue(updatedFieldValue);
    };

    return (
      <FormSection className={cssAlt.spacing}>
        <FieldsRow className={cssAlt.altHeader}>
          <Typography bold component="h3" color="white" size="big" upperCase={true}>
            {t("languages")}
          </Typography>
        </FieldsRow>
        <div className={css.languageSelect}>
          <Select
            name="languages"
            options={languageOptions}
            onChange={languageName => {
              setFieldValue([
                ...prevFieldValue,
                {
                  name: languageName,
                  level: get(languageLevelOptions, ["0", "value"])
                }
              ]);

              setTouched();
            }}
            value={""}
            placeholder={t("editExpert.oneLanguageIsRequired")}
            disabled={isFormDisabled}
          />
          {isTouched && <FieldError name={fieldName} className={{ container: css.errorContainer }} />}
        </div>
        <FieldsRow>
          {prevFieldValue.map((eachLanguage, index) => {
            return (
              <FieldWrapper key={index}>
                <div className={classNames(css.languageLabel, { [css.languageLabelDisabled]: isFormDisabled })}>
                  {getLanguageLabel(eachLanguage.name)}
                  {!isFormDisabled && <IconButton onClick={handleRemoveLanguage(eachLanguage)} icon="trash" />}
                </div>
                <Select
                  options={languageLevelOptions}
                  onChange={handleLevelChange(eachLanguage)}
                  value={eachLanguage.level}
                  disabled={isFormDisabled}
                />
              </FieldWrapper>
            );
          })}
        </FieldsRow>
      </FormSection>
    );
  })
);
