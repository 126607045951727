import { getParent, types } from "mobx-state-tree";

import { EntityBlueprint, UrlConfig } from "triangular/services/Api/Resource/utils/types";
import { SystemEntity } from "triangular/services/Api/resources/SystemResource";

import { SnackbarStoreType } from "../SnackbarStore";
import { createStore } from "../utils/createStore";
import { createStateMerger } from "../utils/state";

import { systemSettingsModel } from "./systemSettingsModel";

export type SystemsStoreModel = ReturnType<typeof createSystemsStoreModel>;

export const createSystemsStoreModel = () =>
  types.model("SystemsStore", {
    systemSettings: types.maybeNull(systemSettingsModel)
  });

export const SystemsStore = createStore(storeDeps =>
  createSystemsStoreModel()
    .actions(self => ({
      mergeWithState: createStateMerger(self),
      getSnackbars() {
        return getParent(self).snackbarStore as SnackbarStoreType;
      },
      getSettings() {
        if (!self.systemSettings) {
          throw new Error("Settings not available!");
        }

        return self.systemSettings;
      }
    }))
    .actions(self => ({
      async loadSettings() {
        if (self.systemSettings) {
          return;
        }

        const {
          data: [fetchedSettings]
        } = await storeDeps.api.systemSettings.find();

        const {
          contactMaterials = [],
          nonContactMaterials = [],
          abrasiveness = [],
          explosiveness = [],
          installedPowerKw = [],
          materialCategories = [],
          targetIndustries = []
        } = fetchedSettings;

        self.mergeWithState({
          systemSettings: {
            contactMaterials,
            nonContactMaterials,
            abrasiveness,
            explosiveness,
            installedPowerKw,
            materialCategories,
            targetIndustries
          }
        });
      },
      async getSystem(id: string, { isSystemOwner, ...config }: UrlConfig<SystemEntity> & { isSystemOwner: boolean }) {
        return (await storeDeps.api[isSystemOwner ? "systemOwnerSystem" : "system"].findById(id, config)).data;
      },
      async createSystem(parameters: Partial<EntityBlueprint<SystemEntity>>) {
        const [data] = await storeDeps.api.systemOwnerSystem.create(parameters);
        return data.id;
      },
      async updateSystem(id: string, parameters: Partial<EntityBlueprint<SystemEntity>>) {
        await storeDeps.api.systemOwnerSystem.update(id, parameters);
      },
      uploadSystemPhoto(name: string, data: string) {
        return storeDeps.api.systemPhoto.create({ filename: name, data });
      },
      uploadSystemDocument(name: string, data: string) {
        return storeDeps.api.systemDocument.create({ filename: name, data });
      },
      deleteSystemPhoto(photoId: string) {
        return storeDeps.api.systemPhoto.delete(photoId);
      },
      deleteSystemDocument(documentId: string) {
        return storeDeps.api.systemDocument.delete(documentId);
      },
      cleanUp() {
        const { systemSettings, ...initialValues } = systemsStoreInitialState;
        self.mergeWithState(initialValues);
      }
    }))
);

export const systemsStoreInitialState = {
  systemSettings: null
};

export type SystemStoreType = ReturnType<typeof SystemsStore>["Type"];
export type SystemStoreSnapshot = ReturnType<typeof SystemsStore>["SnapshotType"];
export type FetchedSystem = FromPromise<ReturnType<SystemStoreType["getSystem"]>>;
export type FetchedSystemSettings = NonNullable<SystemsStoreModel["Type"]["systemSettings"]>;
