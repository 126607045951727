import { AxiosRequestConfig } from "axios";
import classNames from "classnames";
import range from "lodash/range";
import React from "react";

import { ReactComponent as ArrowDown } from "triangular/static/images/arrow-down.svg";
import { PaginationModel } from "triangular/stores/utils/createPagination";

import { Typography } from "../Typography/Typography";

import css from "./Pagination.module.scss";

type PaginationProps = ReturnType<typeof getPaginationProps>;

const paginationNeighbors = 3;
const maxNotDividedNumbers = 9;

export const Pagination: React.FC<PaginationProps> = ({
  isAtBeginning,
  isAtEnd,
  maxNumber,
  currentNumber,
  onPreviousClick,
  onNextClick,
  onPageSelect
}) => {
  const numbersEnd = maxNumber + 1;
  const allNumbers = range(1, numbersEnd);
  const isInLastPart = currentNumber >= numbersEnd - paginationNeighbors;
  const exceedLastPart = currentNumber + paginationNeighbors >= numbersEnd + 1;

  function countMiddlePart() {
    if (currentNumber < paginationNeighbors || exceedLastPart) {
      return [];
    }

    if (currentNumber === paginationNeighbors) {
      return allNumbers.slice(
        currentNumber - Math.ceil(paginationNeighbors / 2) + 1,
        currentNumber + Math.floor(paginationNeighbors / 2) + 1
      );
    }

    if (!isInLastPart) {
      return allNumbers.slice(
        currentNumber - Math.ceil(paginationNeighbors / 2),
        currentNumber + Math.floor(paginationNeighbors / 2)
      );
    }

    return allNumbers.slice(
      currentNumber - Math.ceil(paginationNeighbors / 2) - 2,
      currentNumber + Math.floor(paginationNeighbors / 2) - 2
    );
  }

  const firstPart =
    currentNumber < paginationNeighbors
      ? allNumbers.slice(0, paginationNeighbors)
      : allNumbers.slice(0, paginationNeighbors - 1);

  const middlePart = countMiddlePart();

  const lastPart = isInLastPart
    ? allNumbers.slice(numbersEnd - (paginationNeighbors + 1), numbersEnd)
    : allNumbers.slice(numbersEnd - paginationNeighbors, numbersEnd);

  const renderNumbers = (numbers: number[]) => {
    return (
      <Typography size="big">
        <ul className={css.numbersList}>
          {numbers.map(eachNumber => {
            const onClick = () => {
              onPageSelect(eachNumber);
            };

            return (
              <li key={eachNumber} className={css.numbersListItem}>
                <button
                  className={classNames(css.button, { [css.activeNumber]: eachNumber === currentNumber })}
                  onClick={onClick}
                >
                  {eachNumber}
                </button>
              </li>
            );
          })}
        </ul>
      </Typography>
    );
  };

  const ellipsis = <>&nbsp; ... &nbsp;</>;

  const dividedPagination = (
    <>
      {renderNumbers(firstPart)}
      {middlePart.length > 0 ? ellipsis : null}
      {renderNumbers(middlePart)}
      {ellipsis}
      {renderNumbers(lastPart)}
    </>
  );

  return (
    <div className={css.container}>
      <button className={classNames(css.button, css.previousIcon)} onClick={onPreviousClick} disabled={isAtBeginning}>
        <ArrowDown width={14} height={10} />
      </button>
      {allNumbers.length > maxNotDividedNumbers ? dividedPagination : renderNumbers(allNumbers)}
      <button className={classNames(css.button, css.nextIcon)} onClick={onNextClick} disabled={isAtEnd}>
        <ArrowDown width={14} height={10} />
      </button>
    </div>
  );
};

export function getPaginationProps(options: {
  pagination: PaginationModel;
  config?: AxiosRequestConfig;
  refetch?: boolean;
  pushHistory?: boolean;
}) {
  const { pagination, config, refetch = false, pushHistory } = options;
  const { number: currentNumber, maxNumber, isAtBeginning, isAtEnd, loading } = pagination;

  const onPreviousClick = async () => {
    await pagination.decreaseNumber(refetch, { ...config, pushHistory });
  };

  const onPageSelect = async (pageNumber: number) => {
    await pagination.setNumber({ nextNumber: pageNumber, refetch }, { ...config, pushHistory });
  };

  const onNextClick = async () => {
    await pagination.increaseNumber(refetch, { ...config, pushHistory });
  };

  return {
    loading,
    onPageSelect,
    isAtBeginning,
    isAtEnd,
    onNextClick,
    onPreviousClick,
    currentNumber,
    maxNumber
  };
}
