import React from "react";

import { IconButton, IconButtonProps } from "triangular/components/IconButton/IconButton";
import { SystemModelSnapshot } from "triangular/stores/SystemsStore/systemModel";

import css from "./ActionIcons.module.scss";

export const ActionIcons: React.FC<{
  isMySystem: boolean;
  handleEdit: IconButtonProps["onClick"];
  openRemovalConfirmation: IconButtonProps["onClick"];
  systemState: SystemModelSnapshot["state"];
}> = ({ systemState, isMySystem, handleEdit, openRemovalConfirmation }) => {
  if (!isMySystem) {
    return null;
  }

  const isSystemEditable = (state: string | null = "") => {
    if (!state) {
      return false;
    }

    const systemStatesForEdition = ["draft", "published"];

    return systemStatesForEdition.includes(state);
  };

  return (
    <div className={css.actions}>
      {isSystemEditable(systemState) && <IconButton onClick={handleEdit} icon="edit" testId="editIcon" />}
      <IconButton onClick={openRemovalConfirmation} icon="trash" testId="trashIcon" />
    </div>
  );
};
