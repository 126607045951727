import { AxiosInstance } from "axios";

import { Resource } from "../Resource/Resource";
import { Entity } from "../Resource/utils/types";

export type InvoiceDetailsAttributes = {
  name: string;
  taxId: string;
  line1: string;
  line2: string | null;
  city: string;
  postalCode: string;
  countryCode: string;
};

export type InvoiceDetailsEntity = Entity<InvoiceDetailsAttributes, {}>;

export class InvoiceDetailsResource extends Resource<InvoiceDetailsEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "invoice_details"
    });
  }
}
