import { History } from "history";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { MaterialForm } from "triangular/components/form/MaterialForm/MaterialForm";
import { useMaterialData } from "triangular/components/form/MaterialForm/useMaterialData";
import { Layout } from "triangular/components/Layout/Layout";
import { PageTitle } from "triangular/components/PageTitle/PageTitle";
import { routes } from "triangular/Routes/routesConfiguration";

import css from "./AddMaterial.module.scss";

interface AddMaterialProps {
  copyFrom?: string;
  history: History;
}

export const AddMaterial: React.FC<AddMaterialProps> = observer(({ history, copyFrom }) => {
  const { t } = useTranslation();

  const {
    isLoading,
    material,
    personalAndCompanyData,
    handleSubmit,
    handleSave,
    handleSavedModalClose
  } = useMaterialData({ materialId: copyFrom, addingNewMaterial: true });

  return (
    <Layout withMolecules={false} loading={isLoading}>
      <PageTitle
        className={{
          title: css.title,
          description: css.description
        }}
        title={t("addMaterial.addMaterial")}
        data-testid="addMaterialTitle"
      />
      <MaterialForm
        initialValues={material || personalAndCompanyData}
        onSubmit={handleSubmit}
        onSave={handleSave}
        onGoBack={() => history.push(routes.home.build())}
        onSavedModalClose={handleSavedModalClose}
      />
    </Layout>
  );
});
