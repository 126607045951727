import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import useRouter from "use-react-router";

import { Payments } from "triangular/scenes/Payments/Payments";
import { SubscriptionSetup } from "triangular/scenes/SubscriptionSetup/SubscriptionSetup";
import { useStore } from "triangular/stores/StoreContext";

export const SubscriptionSetupGuard = observer(() => {
  const { history } = useRouter();
  const { paymentsStore, userStore } = useStore();

  useEffect(() => {
    history.replace("/");
    return history.listen(location => {
      if (location.pathname !== "/") {
        history.replace("/");
      }
    });
  }, [history, paymentsStore.subscription.status, userStore.isAuthenticated]);

  if (paymentsStore.subscription.status === "inactive") {
    return <SubscriptionSetup />;
  }

  return <Payments />;
});
