import { History } from "history";
import { observer } from "mobx-react-lite";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { Button } from "triangular/components/Button/Button";
import { Hr } from "triangular/components/Hr/Hr";
import { TriLink } from "triangular/components/TriLink/TriLink";
import { WithTooltip } from "triangular/components/WithTooltip/WithTooltip";
import { RouteParameters, routes } from "triangular/Routes/routesConfiguration";
import { FetchedCommonSettings } from "triangular/stores/CommonStore/CommonsStore";
import { useStore } from "triangular/stores/StoreContext";
import { FetchedSystem } from "triangular/stores/SystemsStore/SystemsStore";

import { Section } from "./Section";
import css from "./SystemDetails.module.scss";
import { SystemDetailsFirstPart } from "./SystemDetailsFirstPart";
import { SystemDetailsHeader } from "./SystemDetailsHeader";
import { SystemDetailsPhotos } from "./SystemDetailsPhotos";
import { SystemDetailsSecondPart } from "./SystemDetailsSecondPart";
import { SystemDetailsText } from "./SystemDetailsText";
import { SystemDocuments } from "./SystemDocuments/SystemDocuments";

interface SystemDetailsContentProps {
  commonSettings: FetchedCommonSettings;
  system: FetchedSystem;
}

type MatchParams = Pick<RouteParameters<typeof routes.systemDetailsForRequest>, "materialId">;

function getCurrentOrigin(history: History) {
  const { search } = history.location;
  return new URLSearchParams(search).get("origin");
}

export const SystemDetailsContent: React.FC<SystemDetailsContentProps> = observer(({ system, commonSettings }) => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const { match } = useRouter<MatchParams>();
  const { userStore } = useStore();

  const isMaterialOwner = userStore.type === "material_owners";
  const materialIdForRequest = match.params.materialId;
  const origin = getCurrentOrigin(history);

  const RequestSystemButtonTooltip =
    system.state === "booked"
      ? ({ children }: { children: React.ReactNode }) => (
          <WithTooltip tooltipMessage={t("systemDetails.systemBooked")}>{children}</WithTooltip>
        )
      : Fragment;

  return (
    <div className={css.wrapper}>
      <SystemDetailsHeader system={system} commonSettings={commonSettings} origin={origin} />
      <Hr />
      <SystemDetailsFirstPart system={system} />
      <Hr />
      <SystemDetailsSecondPart system={system} />
      <Hr />
      <SystemDetailsText system={system} type="additional" />
      <SystemDetailsText system={system} type="safety" />
      <SystemDocuments files={system.systemDocuments} />
      <SystemDetailsPhotos system={system} />
      {isMaterialOwner && (
        <Section>
          <div className={css.buttonWrapper}>
            <RequestSystemButtonTooltip>
              <TriLink to={routes.systemRequest.build({ systemId: system.id, materialId: materialIdForRequest })}>
                <Button narrow={true} variant="dark" disabled={system.state === "booked"} type="button">
                  {t("systemDetails.requestSystem")}
                </Button>
              </TriLink>
            </RequestSystemButtonTooltip>
          </div>
        </Section>
      )}
    </div>
  );
});
