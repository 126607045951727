import {
  addMonths,
  addYears,
  differenceInMonths,
  format,
  getMonth,
  getYear,
  setMonth,
  setYear,
  subYears
} from "date-fns";
import { connect } from "formik";
import range from "lodash/range";
import React from "react";

import { DoubleField } from "triangular/components/form/DoubleField/DoubleField";
import { Select } from "triangular/components/Select/Select";

import css from "./YearMonthField.module.scss";

const yearsAmp = 50;

type Timestamp = number | string | Date;

interface YearMonthFieldProps {
  label: string;
  name: string;
  required?: boolean;
  minDate?: Timestamp;
  maxDate?: Timestamp;
  onChange?: (nextValue: string) => void;
}

const getAvailableMonths = ({
  selectedDate,
  minDate,
  maxDate
}: {
  selectedDate: number;
  minDate: Timestamp;
  maxDate: Timestamp;
}) => {
  const selectedMaxYear = getYear(maxDate) === getYear(selectedDate);
  const selectedMinYear = getYear(minDate) === getYear(selectedDate);

  if (selectedMinYear) {
    return range(differenceInMonths(setMonth(minDate, 12), minDate))
      .reverse()
      .map(value => 11 - value);
  }

  if (selectedMaxYear) {
    return range(differenceInMonths(maxDate, setMonth(maxDate, 0)) + 1).map(value => value);
  }

  return range(12);
};

const getAvailableYears = ({ minDate, maxDate }: { minDate: Timestamp; maxDate: Timestamp }) => {
  const minYear = getYear(minDate);
  const maxYear = getYear(maxDate);
  return range(minYear, maxYear + 1);
};

export const YearMonthField = connect<YearMonthFieldProps, any>(
  ({
    label,
    name,
    required,
    formik,
    maxDate = addYears(Date.now(), yearsAmp),
    minDate = subYears(Date.now(), yearsAmp),
    onChange
  }) => {
    const { values, setFieldValue } = formik;
    const selectedDate = values[name];
    const monthOptions = getAvailableMonths({ selectedDate, minDate, maxDate }).map((value: number) => {
      const strValue = String(value);
      return {
        label: format(addMonths(0, value), "MMMM"),
        value: strValue
      };
    });

    const yearOptions = getAvailableYears({ minDate, maxDate }).map((value: number) => {
      const strValue = String(value);

      return {
        label: strValue,
        value: strValue
      };
    });

    const selectedYear = getYear(selectedDate);
    const selectedMonth = getMonth(selectedDate);

    const handleChange = (nextValue: Date) => {
      const appliedValue = nextValue.toISOString();
      setFieldValue(name, appliedValue);

      if (onChange) {
        onChange(appliedValue);
      }
    };

    return (
      <DoubleField
        label={label}
        leftField={
          <Select
            className={{
              container: css.yearContainer
            }}
            options={yearOptions}
            value={String(selectedYear)}
            onChange={selectedValue => {
              handleChange(setYear(selectedDate, Number(selectedValue)));
            }}
            autoComplete={true}
          />
        }
        rightField={
          <Select
            options={monthOptions}
            value={String(selectedMonth)}
            onChange={selectedValue => {
              handleChange(setMonth(selectedDate, Number(selectedValue)));
            }}
            autoComplete={true}
          />
        }
        required={required}
      />
    );
  }
);
