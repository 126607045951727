import { AxiosRequestConfig } from "axios";
import get from "lodash/get";
import isNil from "lodash/isNil";
import { getParent, IAnyModelType, SnapshotIn, types } from "mobx-state-tree";
import qs from "qs";

import { StoreDeps } from "./createStore";

interface Pagination {
  size: number;
  number: number;
}

export type PaginationRequestConfig = AxiosRequestConfig & {
  pushHistory?: boolean;
  changeUrl?: boolean;
};

export type PaginationModel = ReturnType<typeof createPagination>["Type"];

export function createPagination<ModelType extends IAnyModelType>({
  model,
  defaultNumber,
  defaultSize,
  onChange,
  storeDeps,
  loading = true
}: {
  model: ModelType;
  defaultSize?: number;
  defaultNumber?: number;
  onChange: (params: {
    pagination: Pagination;
    parent: any;
    config?: PaginationRequestConfig;
  }) => Promise<{ totalItems: number; items: Array<SnapshotIn<typeof model>>; querySearch?: object }>;
  storeDeps: StoreDeps;
  loading?: boolean;
}) {
  type ModelSnapshot = SnapshotIn<ModelType>;

  const options = {
    defaultSize: isNil(defaultSize) ? 9 : defaultSize,
    defaultNumber: isNil(defaultNumber) ? 1 : defaultNumber,
    onChange
  };

  return types
    .model("Pagination", {
      size: options.defaultSize,
      number: options.defaultNumber,
      totalItems: 0,
      pages: types.map(
        types.model({
          number: types.identifierNumber,
          items: types.array(model)
        })
      ),
      loading
    })
    .views(self => ({
      get maxNumber() {
        return Math.ceil(self.totalItems / self.size) || 1;
      }
    }))
    .views(self => ({
      get isAtEnd() {
        return self.number === self.maxNumber;
      },
      get isAtBeginning() {
        return self.number === 1;
      },
      get currentItems() {
        const currentPage = self.pages.get(self.number.toString());
        return currentPage ? (currentPage.items as Array<ModelType["SnapshotType"]>) : [];
      }
    }))
    .actions(self => {
      type SelfSnapshot = NonNullable<SnapshotIn<typeof self>>;

      return {
        setState(snapshot: DeepPartial<SelfSnapshot>) {
          Object.assign(self, snapshot);
        },
        setPageItems(pageNumber: number, items: ModelSnapshot[]) {
          self.pages.set(pageNumber.toString(), {
            number: pageNumber,
            items
          });
        },
        getQueryParams() {
          const { search } = storeDeps.history.location;
          const { pagination, ...searchParams } = qs.parse(search.replace("?", ""), {
            decoder: value => (value === "" ? undefined : value)
          });

          const paginationNumber = Number(get(pagination, "number"));
          const paginationSize = Number(get(pagination, "size"));

          return {
            params: searchParams,
            pagination: {
              number: isNaN(paginationNumber) ? undefined : paginationNumber,
              size: isNaN(paginationSize) ? undefined : paginationSize
            }
          };
        }
      };
    })
    .actions(self => {
      const handleChange = async (params: { pagination: Pagination; config?: PaginationRequestConfig }) => {
        try {
          self.setState({ loading: true });

          const { push, location, replace } = storeDeps.history;
          const { pagination } = params;
          const pushHistory = get(params.config, "pushHistory", true);

          const { totalItems, items, querySearch: paginationSearch } = await options.onChange({
            ...params,
            parent: getParent(self)
          });

          if (paginationSearch) {
            const historyAction = pushHistory ? push : replace;
            const stringifiedPaginationSearch = qs.stringify(paginationSearch, {
              encode: false,
              encoder: value => (value === null ? undefined : value)
            });

            if (!params.config || params.config.changeUrl !== false) {
              const prevSearchParams = new URLSearchParams(location.search);
              const paginationSearchParams = new URLSearchParams(stringifiedPaginationSearch);

              // Remove keys that come from pagination params to avoid duplication
              Array.from(paginationSearchParams.keys()).forEach(eachKey => prevSearchParams.delete(eachKey));

              const prevSearchQuery = decodeURI(prevSearchParams.toString()).replace("?", "");

              const appliedSearch =
                prevSearchQuery.length > 0
                  ? `?${prevSearchQuery}&${stringifiedPaginationSearch}` // Attach prev search params, if there were some
                  : `?${stringifiedPaginationSearch}`; // Attach only pagination params otherwise

              historyAction(`${location.pathname}${appliedSearch}`);
            }
          }

          self.setPageItems(pagination.number, items);

          self.setState({
            size: pagination.size,
            number: pagination.number,
            totalItems,
            loading: false
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          self.setState({ loading: false });
        }
      };

      return {
        async setNumber(params: { nextNumber: number; refetch: boolean }, config?: PaginationRequestConfig) {
          const refetch = !!params.refetch;

          if (!self.pages.get(params.nextNumber.toString()) || refetch) {
            await handleChange({
              pagination: {
                number: params.nextNumber,
                size: self.size
              },
              config
            });
          } else {
            self.setState({
              number: params.nextNumber
            });
          }
        },
        async setSize(nextSize: number, config?: PaginationRequestConfig) {
          await handleChange({
            pagination: {
              number: self.number,
              size: nextSize
            },
            config
          });
        },
        async reset(config?: PaginationRequestConfig) {
          self.setState({ pages: {} });

          await handleChange({
            pagination: {
              number: options.defaultNumber,
              size: self.size
            },
            config
          });
        },
        async refresh(config?: PaginationRequestConfig) {
          await handleChange({
            pagination: {
              number: self.number,
              size: self.size
            },
            config
          });
        }
      };
    })
    .actions(self => ({
      increaseNumber(refetch = false, config?: PaginationRequestConfig) {
        if (!self.isAtEnd) {
          const nextNumber = self.number + 1;
          self.setNumber({ nextNumber, refetch }, config);
        }
      },
      decreaseNumber(refetch = false, config?: PaginationRequestConfig) {
        if (!self.isAtBeginning) {
          const nextNumber = self.number - 1;
          self.setNumber({ nextNumber, refetch }, config);
        }
      }
    }));
}
