import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { useStore } from "triangular/stores/StoreContext";

import { StepsState } from "../BreadCrumbsMobile/BreadCrumbsMobile";
import { registrationRoutes } from "../RegistrationWrapper";

import css from "./BreadCrumbs.module.scss";

interface BreadCrumbProps {
  isActive?: boolean;
  isFinished?: boolean;
  to?: string;
}

interface BreadCrumbsProps {
  isExpert?: boolean;
  steps: StepsState;
}

export const Breadcrumb: React.FC<BreadCrumbProps> = ({ isActive, isFinished, to, children }) => {
  const { registrationStore } = useStore();

  const isClickable = isFinished && to && !registrationStore.isSubmitted;

  return (
    <div className={classNames([css.breadCrumb, isActive && !registrationStore.isSubmitted ? css.active : null])}>
      {isClickable ? <Link to={to!}>{children}</Link> : children}
    </div>
  );
};

export const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ steps, isExpert = false }) => {
  const { t } = useTranslation();

  return (
    <ul className={css.ul} data-testid="breadcrumbs">
      <li>
        <Breadcrumb {...steps.profileType} to={registrationRoutes.profileType}>
          <>1. {t("registration.step.profileType")}</>
        </Breadcrumb>
      </li>
      <li>
        <Breadcrumb {...steps.personalInfo} to={registrationRoutes.personalInfo}>
          <>2. {t("registration.step.personalInfo")}</>
        </Breadcrumb>
      </li>
      {!isExpert && (
        <li>
          <Breadcrumb {...steps.companyInfo} to={registrationRoutes.companyInfo}>
            <>3. {t("registration.step.companyInfo")}</>
          </Breadcrumb>
        </li>
      )}
    </ul>
  );
};
