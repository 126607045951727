import { AxiosInstance } from "axios";

import { AvatarEntity } from "../entities/AvatarEntity";
import { Resource } from "../Resource/Resource";

export class AvatarResource extends Resource<AvatarEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "avatars",
      prefixes: [],
      relationships: {
        ownerable: {
          type: "ownerable"
        }
      }
    });
  }
}
