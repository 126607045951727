import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import { Form } from "triangular/components/form/Form";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { Typography } from "triangular/components/Typography/Typography";
import { useStore } from "triangular/stores/StoreContext";

import { DefaultFormButtons } from "../../DefaultFormButtons";

import { AdditionalSystemInfo } from "./AdditionalSystemInfo";
import { PlantProtection } from "./PlantProtection";
import { RequiredSystemInfo } from "./RequiredSystemInfo";
import { Safety } from "./Safety";
import css from "./SystemForm.module.scss";
import { createSystemSchema, defaultValues } from "./systemSchema";
import { Technology } from "./Technology";
import { SystemSchema } from "./types";
import { UploadFiles } from "./UploadFiles";

export interface SystemFormProps {
  initialValues?: Partial<SystemSchema>;
  onGoBack(): void;
  handleSave(values: SystemSchema): Promise<any>;
  handleSubmit(values: SystemSchema): Promise<any>;
  onSavedModalClose(): void;
}

export const SystemForm: React.FC<SystemFormProps> = ({
  initialValues = {},
  handleSave,
  handleSubmit,
  onGoBack,
  onSavedModalClose
}) => {
  const { t } = useTranslation();
  const { systemsStore, commonsStore } = useStore();
  const systemSettings = systemsStore.getSettings();
  const commonSettings = commonsStore.getSettings();
  const systemSchema = createSystemSchema(t);

  return (
    <>
      <Form
        className={css.form}
        initialValues={{
          ...defaultValues,
          ...initialValues
        }}
        validationSchema={systemSchema}
        render={formikProps => {
          const formSectionProps = {
            systemSettings,
            commonSettings,
            formikProps
          };

          return (
            <>
              <FormSection>
                <div className={classNames("d-flex justify-content-center", css.buttonSpace)}>
                  <DefaultFormButtons
                    onGoBack={onGoBack}
                    confirmPromptTitle={t("addSystem.submitConfirmation.header")}
                    confirmPromptMessage={t("addSystem.submitConfirmation.message")}
                    savedModalMessage={t("addSystem.systemSavedModal.message")}
                    savedModalTitle={t("addSystem.systemSavedModal.title")}
                    fieldsToValidateBeforeSave={["name"]}
                    onSavedModalClose={onSavedModalClose}
                    submitButtonLabel={t("publish")}
                  />
                </div>
              </FormSection>
              <RequiredSystemInfo {...formSectionProps} />
              <Technology {...formSectionProps} />
              <Safety />
              <PlantProtection {...formSectionProps} />
              <AdditionalSystemInfo {...formSectionProps} />
              <UploadFiles {...formikProps} />
              <FormSection>
                <div className={css.requiredAnnotation}>
                  <Typography size="small">{t("requiredField")}</Typography>
                </div>
              </FormSection>
              <FormSection>
                <DefaultFormButtons
                  onGoBack={onGoBack}
                  onSave={handleSave}
                  confirmPromptTitle={t("addSystem.submitConfirmation.header")}
                  confirmPromptMessage={t("addSystem.submitConfirmation.message")}
                  savedModalMessage={t("addSystem.systemSavedModal.message")}
                  savedModalTitle={t("addSystem.systemSavedModal.title")}
                  fieldsToValidateBeforeSave={["name"]}
                  onSavedModalClose={onSavedModalClose}
                  submitButtonLabel={t("publish")}
                />
              </FormSection>
            </>
          );
        }}
        onSubmit={handleSubmit}
      />
    </>
  );
};
