import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { WithTooltip } from "triangular/components/WithTooltip/WithTooltip";

interface SubscriptionPlanBlockedTooltipProps {
  isSubscriptionCancelled: boolean;
  isSubscriptionUnpaid: boolean;
}

export const SubscriptionPlanBlockedTooltip: React.FC<SubscriptionPlanBlockedTooltipProps> = ({
  children,
  isSubscriptionCancelled,
  isSubscriptionUnpaid
}) => {
  const { t } = useTranslation();
  if (isSubscriptionCancelled || isSubscriptionUnpaid) {
    return (
      <WithTooltip
        WrapperComponent="div"
        tooltipMessage={
          isSubscriptionCancelled
            ? t("subscriptionPlans.cancelledSubscriptionTooltip")
            : t("subscriptionPlans.unpaidSubscriptionTooltip")
        }
      >
        {children}
      </WithTooltip>
    );
  }

  return <Fragment>{children}</Fragment>;
};
