import merge from "lodash/merge";
import qs, { IParseOptions } from "qs";
import * as Yup from "yup";

export function parseQuery<T extends Record<string, unknown>>(
  query: string,
  options: Omit<IParseOptions, "strictNullHandling" | "ignoreQueryPrefix" | "decoder"> & {
    schema: Yup.ObjectSchema<T>;
    defaults: T;
  }
): T {
  const { schema, defaults } = options;

  if (!query) {
    return defaults;
  }

  try {
    const parsedQuery = qs.parse(decodeURI(query).trim(), {
      strictNullHandling: true,
      ignoreQueryPrefix: true,
      decoder: str => {
        const isStrNumber = /^(\d+|\d*\.\d+)$/.test(str);

        if (isStrNumber) {
          return parseFloat(str);
        }

        const primitives = {
          true: true,
          false: false,
          null: null,
          undefined
        };

        if (str in primitives) {
          return primitives[str as keyof typeof primitives];
        } else {
          return str;
        }
      },
      ...options
    });

    return schema.noUnknown().validateSync(merge({}, defaults, parsedQuery), { strict: true });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn("Pagination validation error", err);
    return defaults;
  }
}
