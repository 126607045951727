import classNames from "classnames";
import noop from "lodash/noop";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { ButtonProps } from "triangular/components/Button/Button";
import { Typography } from "triangular/components/Typography/Typography";
import { useStore } from "triangular/stores/StoreContext";
import { formatPrice } from "triangular/utils/numbers";

import css from "./SubscriptionLevelCard.module.scss";

interface SubscriptionLevelCardProps {
  isCurrent: boolean;
  level: SubscriptionLevel;
  pricePerMonth: number;
  pricePerYear: number;
  discount: {
    pricePerMonth: number;
    pricePerYear: number;
    percent: number;
  } | null;
  onClick?: ButtonProps["onClick"];
  disabled?: boolean;
  interval: SubscriptionInterval;
  isSingle?: boolean;
  customPlanTitle?: string;
}

export const SubscriptionLevelCard: React.FC<SubscriptionLevelCardProps> = observer(
  ({
    level,
    isCurrent,
    pricePerMonth,
    pricePerYear,
    onClick,
    disabled = false,
    interval,
    isSingle,
    customPlanTitle,
    discount
  }) => {
    const { t } = useTranslation();
    const { userStore } = useStore();

    const defaultUserTypeName =
      userStore.accountType === "master"
        ? t(`profileTypeNames.master`)
        : t(`profileTypeNames.${userStore.getProfileType()}`);

    const userTypeName = customPlanTitle || defaultUserTypeName;

    return (
      <button
        className={classNames(css.wrapper, { [css.wrapperActive]: isCurrent, [css.wrapperSingle]: isSingle })}
        onClick={disabled ? noop : onClick}
        type="button"
        disabled={disabled}
      >
        {isCurrent ? (
          <Typography color="white" size="medium" bold={true} className={css.currentPlan}>
            {t("subscriptionPlans.currentPlan")}
          </Typography>
        ) : (
          <div className={css.currentPlanPlaceholder} />
        )}
        {disabled ? (
          <Typography bold={true} size="tiny" upperCase={true} className={css.tooltip}>
            {t("subscriptionPlans.comingSoon")}
          </Typography>
        ) : (
          <div className={css.tooltip} />
        )}
        <div className={css.content}>
          <Typography color="mint" size="big" bold={true} className={css.cardTitle} upperCase={true}>
            {userTypeName}
          </Typography>
          {discount ? (
            <div className={css.discountPrice}>
              <Typography color="black" size="large" bold={true} className={css.oldPrice}>
                €&nbsp;{formatPrice(pricePerMonth)}
              </Typography>
              <Typography color="black" size="large" bold={true} className={css.cardTitle}>
                €&nbsp;{formatPrice(discount.pricePerMonth)}
              </Typography>
            </div>
          ) : (
            <Typography color="black" size="large" bold={true} className={css.cardTitle}>
              €&nbsp;{formatPrice(pricePerMonth)}
            </Typography>
          )}
          <Typography color="black" size="small" className={css.cardTitle} verticalSpacing="small">
            {t("subscriptionPlans.perMonth")}
          </Typography>
          {interval === "year" && (
            <Typography
              color="black"
              size="small"
              className={css.cardTitle}
              verticalSpacing="medium"
              upperCase={true}
              bold={true}
            >
              {t("registration.subscriptionInterval.labelAnnually")}
            </Typography>
          )}
          {interval === "year" && (
            <Typography color="black" size="medium" className={css.cardTitle} verticalSpacing="medium">
              ({t("subscriptionPlans.billedAsOnePaymentOf")} €&nbsp;
              {discount ? formatPrice(discount.pricePerYear) : formatPrice(pricePerYear)})
            </Typography>
          )}
          {discount && (
            <Typography
              color="black"
              size="small"
              upperCase={true}
              className={css.cardTitle}
              verticalSpacing="large"
              bold={true}
            >
              {t("subscriptionPlans.discount")}:&nbsp;{discount.percent}%
            </Typography>
          )}
          <Typography color="black" size="medium" verticalSpacing="medium">
            ({t("subscriptionSetup.pricesWithoutVat")})
          </Typography>
        </div>
      </button>
    );
  }
);
