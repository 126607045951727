import { CardCvcElement, CardExpiryElement, CardNumberElement, IbanElement } from "@stripe/react-stripe-js";
import {
  StripeCardCvcElementOptions,
  StripeCardExpiryElementOptions,
  StripeCardNumberElementOptions,
  StripeElementChangeEvent,
  StripeIbanElementOptions
} from "@stripe/stripe-js";
import { connect } from "formik";
import noop from "lodash/noop";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { AbstractField } from "../AbstractField/AbstractField";

type StripeElementType = "CardCvcElement" | "CardExpiryElement" | "CardNumberElement" | "IbanElement";

interface Props {
  type: StripeElementType;
  onReady?: (type: string) => void;
  options?: StripeCardCvcElementOptions &
    StripeCardExpiryElementOptions &
    StripeCardNumberElementOptions &
    StripeIbanElementOptions;
}

const stripeElements = {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  IbanElement
};

export const StripeField = connect<Props, {}>(({ type, formik: { setFieldError }, onReady = noop, options }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setFieldError(type, t("thisFieldIsRequired"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const labelTranslations = {
    CardCvcElement: "stripeField.cardCvc",
    CardExpiryElement: "stripeField.cardExpiry",
    CardNumberElement: "stripeField.cardNumber",
    IbanElement: "stripeField.iban",
    InvoiceElement: "stripefield.invoice"
  };

  const Element = stripeElements[type];
  const label = t(labelTranslations[type]);

  return (
    <AbstractField
      name={type}
      label={label}
      render={({ css, form }) => (
        <div className={css.input}>
          <Element
            onReady={() => onReady(type)}
            onChange={({ error, complete, empty }: StripeElementChangeEvent) => {
              const isValid = complete && !empty;
              form.setFieldTouched(type, true);

              if (error) {
                form.setFieldError(type, error.message);
              } else if (isValid) {
                // Remove field's error
                form.setErrors({ ...form.errors, [type]: undefined });
              } else if (empty) {
                form.setFieldError(type, t("thisFieldIsRequired"));
              } else {
                // User might still typing, field is not valid,
                // however we should not show any message yet.
                form.setFieldError(type, "");
              }
            }}
            options={{
              style: {
                base: {
                  fontFamily: "Rubik",
                  fontWeight: "500"
                }
              },
              ...options
            }}
          />
        </div>
      )}
    />
  );
});
