import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";

import { Layout } from "triangular/components/Layout/Layout";
import { PageLoader } from "triangular/components/PageLoader/PageLoader";
import { Typography } from "triangular/components/Typography/Typography";
import { routes } from "triangular/Routes/routesConfiguration";
import { gaActions, GaCategories, googleAnalytics } from "triangular/services/googleAnalytics";
import { useStore } from "triangular/stores/StoreContext";
import { getApiErrors, getErrorStatus } from "triangular/utils/api";

import { AccountConfirmationError } from "./AccountConfirmationError";

interface AccountConfirmationProps extends RouteComponentProps {
  token: string;
}

export const AccountConfirmation: React.FC<AccountConfirmationProps> = ({ token, history }) => {
  const { t } = useTranslation();
  const { userStore, snackbarStore } = useStore();
  const [isLoading, setLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState<number>();
  const [errorTitle, setErrorTitle] = useState<string>();

  const confirmAccount = useCallback(async () => {
    try {
      await userStore.confirmAccount(token);
      history.push(routes.login.build());
      snackbarStore.addSnackbar({ type: "success", message: t("accountConfirmation.success") });

      googleAnalytics.sendEvent({
        category: GaCategories.registration,
        action: gaActions.registration.accountConfirmation.succeeded
      });
    } catch (error) {
      setLoading(false);
      setErrorStatus(getErrorStatus(error));

      const [apiError] = getApiErrors(error);

      if (apiError) {
        setErrorTitle(apiError.title);
      }

      googleAnalytics.sendEvent({
        category: GaCategories.registration,
        action: gaActions.registration.accountConfirmation.failed
      });
    }
  }, [userStore, token, history, snackbarStore, t]);

  useEffect(() => {
    confirmAccount();
  }, [confirmAccount]);

  if (isLoading) {
    return (
      <Layout withMolecules={false}>
        <PageLoader />
      </Layout>
    );
  }

  return (
    <Layout withMolecules={false}>
      <Typography component="h2" size="veryLarge" verticalSpacing="big" bold={true} center={true}>
        {t("accountConfirmation.sceneTitle")}
      </Typography>
      <AccountConfirmationError status={errorStatus} title={errorTitle} />
    </Layout>
  );
};
