import { connect } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { useStore } from "triangular/stores/StoreContext";

interface AbrasivenessFieldProps {
  label: string;
}

export const AbrasivenessField = connect<AbrasivenessFieldProps, any>(
  observer(({ label }) => {
    const { t } = useTranslation();
    const { systemsStore } = useStore();
    const { systemSettings } = systemsStore;

    const abrasivenessOptions = systemSettings
      ? systemSettings.abrasiveness.map(mohValue => ({
          label: mohValue === "no" ? t("no") : `${mohValue} ${t("addSystem.mohs")}`,
          value: mohValue
        }))
      : [];

    return (
      <SelectField
        name="abrasiveness"
        label={label}
        selectProps={{
          options: abrasivenessOptions
        }}
        data-testid="abrasiveness"
      />
    );
  })
);
