import classNames from "classnames";
import React, { useContext, useState } from "react";

import { FormAdditionalContext } from "triangular/utils/components";

import { AbstractField, AbstractFieldProps, FieldRenderProps } from "../AbstractField/AbstractField";

import { ReactComponent as EyeCrossedIcon } from "./images/eye-crossed.svg";
import { ReactComponent as EyeIcon } from "./images/eye.svg";
import css from "./PasswordField.module.scss";

export interface PasswordFieldProps extends Omit<AbstractFieldProps, "component" | "render" | "children" | "type"> {
  withToggler?: boolean;
  ["data-testid"]?: string;
}

export const PasswordField: React.FC<PasswordFieldProps> = ({
  withToggler = true,
  "data-testid": testId,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(prevState => !prevState);
  const { isReadOnly } = useContext(FormAdditionalContext);

  if (isReadOnly) {
    props.disabled = true;
  }

  const eyeIcon = showPassword ? (
    <EyeCrossedIcon onClick={toggleShowPassword} className={css.crossedEyeIcon} />
  ) : (
    <EyeIcon onClick={toggleShowPassword} className={css.eyeIcon} />
  );

  const type = showPassword ? "text" : "password";

  const renderInput = withToggler
    ? (fieldProps: FieldRenderProps) => {
        return (
          <div className={classNames(fieldProps.css.input, css.inputContainer)}>
            <input data-testid={testId} className={css.input} type={type} {...fieldProps.field} />
            <div className={css.iconWrapper}>{eyeIcon}</div>
          </div>
        );
      }
    : undefined;

  return <AbstractField type="password" render={renderInput} {...props} />;
};
