import { AxiosInstance } from "axios";

import { CertificateEntity } from "../entities/CertificateEntity";
import { Resource } from "../Resource/Resource";

export class CertificateResource extends Resource<CertificateEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "certificates",
      relationships: {
        certificateFiles: {
          type: "certificate_files"
        },
        expert: {
          type: "experts"
        }
      }
    });
  }
}
