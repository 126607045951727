import classNames from "classnames";
import React from "react";

import css from "./Badge.module.scss";

export interface BadgeProps {
  className?: string;
  variant?: "greyishBrown" | "mushroom" | "darkMint" | "mint";
  onDelete?: (event?: React.MouseEvent, value?: string) => void;
  margins?: boolean;
  value?: string;
  testId?: string;
}

export const Badge: React.FC<BadgeProps> = ({
  value,
  children,
  variant = "greyishBrown",
  onDelete,
  margins,
  className,
  testId
}) => {
  const handleOnDeleteClick = (event: React.MouseEvent) => {
    if (onDelete) {
      onDelete(event, value);
    }
  };
  return (
    <div className={classNames(css.badge, css[variant], className, margins && css.margins)} data-testid={testId}>
      {typeof children === "string" ? (
        // it applies different styles for text values to handle ellipsis correctly
        <div className={css.textLabel}>{children}</div>
      ) : (
        <div className={css.label}>{children}</div>
      )}
      {onDelete && (
        <div className={css.delete} onClick={handleOnDeleteClick}>
          &nbsp;
        </div>
      )}
    </div>
  );
};
