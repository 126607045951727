import { createPagination } from "triangular/utils/pagination/pagination";

export const pagination = createPagination({
  paginationDefaults: {
    page: {
      size: 9,
      number: 1
    }
  }
});

export const usePagination = pagination.usePagination;
export const parsePaginationQuery = pagination.parsePaginationQuery;
