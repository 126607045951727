import { FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { FileToUpload } from "triangular/components/form/system/SystemFilesField/FilesField/FilesField";
import { FileListItem } from "triangular/components/form/system/SystemFilesField/FilesList/FilesList";
import { SystemFilesField } from "triangular/components/form/system/SystemFilesField/SystemFilesField";
import { useStore } from "triangular/stores/StoreContext";

import { DropDownHeader } from "../../DropDownHeader/DropDownHeader";

import { SensitiveDataCheckbox } from "./SensitiveDataCheckbox";
import { SystemSchema } from "./types";

export const UploadFiles: React.FC<FormikProps<SystemSchema>> = observer(() => {
  const { systemsStore } = useStore();
  const { t } = useTranslation();

  const handleAddPhotoFile = async (
    filesToUpload: FileToUpload[],
    addFiles: (files: FileListItem[] | null) => void
  ) => {
    const responses = await Promise.all(
      filesToUpload.map(({ filename, data }) => systemsStore.uploadSystemPhoto(filename, data))
    );

    const addedPhotos = responses.flat().map(({ id, filename, originalUrl }) => ({ id, filename, originalUrl }));
    addFiles(addedPhotos);
  };

  const handleRemovePhotoFile = async (file: FileListItem) => systemsStore.deleteSystemPhoto(file.id);

  const handleAddDocumentFile = async (
    filesToUpload: FileToUpload[],
    addFiles: (files: FileListItem[] | null) => void
  ) => {
    const responses = await Promise.all(
      filesToUpload.map(({ filename, data }) => systemsStore.uploadSystemDocument(filename, data))
    );

    const addedDocuments = responses.flat().map(({ id, filename, originalUrl }) => ({ id, filename, originalUrl }));
    addFiles(addedDocuments);
  };

  const handleRemoveDocumentFile = async (file: FileListItem) => systemsStore.deleteSystemDocument(file.id);

  const indexNum = "6";
  const [activeKey, setActiveKey] = useState(indexNum);

  return (
    <Accordion>
      <FormSection>
        <DropDownHeader
          eventKeyNum={indexNum}
          handleClick={() => {
            if (activeKey === indexNum) {
              setActiveKey("");
            } else {
              setActiveKey(indexNum);
            }
          }}
        >
          {t("uploadFiles")}
        </DropDownHeader>
        <Accordion.Collapse eventKey={indexNum}>
          <FieldsRow>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <SystemFilesField
                name="systemPhotoFiles"
                label={t("addSystem.systemPhotoFiles")}
                onRemoveFile={handleRemovePhotoFile}
                onAddFile={handleAddPhotoFile}
                fileType="photo"
                data-testid="systemPhotoFiles"
              />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <SystemFilesField
                name="systemDocumentFiles"
                label={t("addSystem.systemDocumentFiles")}
                onRemoveFile={handleRemoveDocumentFile}
                onAddFile={handleAddDocumentFile}
                fileType="document"
                data-testid="systemDocumentFiles"
              />
              <SensitiveDataCheckbox />
            </div>
          </FieldsRow>
        </Accordion.Collapse>
      </FormSection>
    </Accordion>
  );
});
