import React from "react";

import { IconText } from "triangular/components/IconText/IconText";

import { ReactComponent as Marker } from "./images/marker.svg";

interface LocationMarkerProps {
  className?: string;
}

export const LocationMarker: React.FC<LocationMarkerProps> = ({ children, className }) => (
  <IconText icon={<Marker />} className={className}>
    {children}
  </IconText>
);
