import React from "react";

import { AbstractField, AbstractFieldProps } from "./AbstractField/AbstractField";
import { DisconnectedAbstractField } from "./AbstractField/DisconnectedAbstractField";

export interface TextFieldProps extends Omit<AbstractFieldProps, "component" | "render" | "children"> {
  type?: "text" | "email" | "tel" | "url" | "date";
  isConnectedWithFormik?: boolean;
}

export const TextField: React.FC<TextFieldProps> = React.memo(
  ({ type = "text", isConnectedWithFormik = true, ...fieldProps }) => (
    <AbstractField type={type} placeholder={fieldProps.placeholder} {...fieldProps} />
  ),
  (prev, next) => prev.label === next.label
);

export const DisconnectedTextField: React.FC<TextFieldProps> = ({
  type = "text",
  isConnectedWithFormik = true,
  ...fieldProps
}) => <DisconnectedAbstractField type={type} placeholder={fieldProps.placeholder} {...fieldProps} />;
