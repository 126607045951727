import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { Button } from "triangular/components/Button/Button";
import { Form } from "triangular/components/form/Form";
import { TextField } from "triangular/components/form/TextField";

import css from "./ForgotPasswordForm.module.scss";

export interface ForgotPasswordFormValues {
  email: string;
}

interface ForgotPasswordFormProps {
  onSubmit: (values: ForgotPasswordFormValues) => void;
  onCancel: (e: React.MouseEvent) => void;
}

export const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation();

  return (
    <Form
      initialValues={{
        email: ""
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email(t("validation.email"))
          .required(t("validation.required"))
      })}
      render={props => {
        const { isSubmitting, isValid } = props;

        return (
          <>
            <TextField
              className={{ wrapper: css.input }}
              data-testid="email"
              required={true}
              name="email"
              label="E-mail"
            />

            <div className={css.actionsWrapper}>
              <Button
                data-testid="cancel"
                transparent={true}
                onClick={onCancel}
                narrow={true}
                type="button"
                className={css.cancelButton}
              >
                {t("passwordRecovery.cancelButton")}
              </Button>

              <Button data-testid="submit" type="submit" disabled={isSubmitting || !isValid} showLoader={isSubmitting}>
                {t("passwordRecovery.submitButton")}
              </Button>
            </div>
          </>
        );
      }}
      onSubmit={onSubmit}
    />
  );
};
