import { routes } from "triangular/Routes/routesConfiguration";
import { DocumentIdentifier } from "triangular/services/Api/Resource/utils/types";

export function useSystemsSearchUrl(
  materialId: string,
  {
    processCategory,
    systemCategory,
    systemType
  }: {
    processCategory?: DocumentIdentifier | null;
    systemCategory?: DocumentIdentifier | null;
    systemType?: DocumentIdentifier | null;
  }
) {
  return routes.systemsForRequest.build({
    materialId,
    processCategoryId: processCategory ? processCategory.id : undefined,
    systemCategoryId: systemCategory ? [systemCategory.id] : undefined,
    systemTypeId: systemType ? [systemType.id] : undefined,
    sortBy: "name"
  });
}
