import mapValues from "lodash/mapValues";
import * as pathToRegexp from "path-to-regexp";
import * as yup from "yup";

import { pick } from "triangular/utils/yup";

import { buildMethod } from "./common";

export type RawParams<Params extends {}> = {
  [K in keyof Params]?: string | string[];
};

export class Path<Params extends {} = {}> {
  public readonly schema: yup.ObjectSchema<Params>;
  private readonly parsedPattern: ParsedPattern<Params>;

  get paramNames() {
    return this.parsedPattern.keyNames;
  }

  constructor(public readonly pattern: string, schema: yup.ObjectSchema<Params> = yup.object()) {
    this.parsedPattern = parsePattern(pattern);
    this.schema = pick(schema, ...this.parsedPattern.keyNames);
  }

  // tslint:disable-next-line: member-ordering
  build = buildMethod((params: UndefinedToOptional<Params>) => {
    this.schema.validateSync(params, { strict: true, abortEarly: false });

    const fieldDescriptions = this.schema.describe().fields as { [K in keyof Params]: yup.SchemaDescription };

    const rawParams: RawParams<Params> = mapValues(fieldDescriptions, (description, key) => {
      const value = params[key as keyof UndefinedToOptional<Params>];
      return stringifyValue(value, description);
    });

    let path = this.parsedPattern.build(rawParams);

    // Fix for /:foo* with {foo: []}
    if (this.pattern.startsWith("/") && !path.startsWith("/")) {
      path = `/${path}`;
    }

    return path;
  });

  match(path: string): Params | undefined {
    const rawParams = this.parsedPattern.match(path);

    if (!rawParams) {
      return undefined;
    }

    return this.matchParams(rawParams);
  }

  matchParams(rawParams: RawParams<Params>): Params {
    return this.schema.validateSync(rawParams, { abortEarly: false });
  }

  nested<NestedParams extends {} = {}>(
    pattern: string,
    schema: yup.ObjectSchema<NestedParams> = yup.object()
  ): Path<Params & NestedParams> {
    const pathSchema: yup.ObjectSchema<Params & NestedParams> = this.schema.concat(schema);
    return new Path(this.pattern + pattern, pathSchema);
  }
}

interface ParsedPattern<Params extends {}> {
  readonly tokens: pathToRegexp.Token[];
  readonly keys: pathToRegexp.Key[];
  readonly keyNames: Array<keyof Params>;
  readonly regexp: RegExp;
  readonly build: pathToRegexp.PathFunction<RawParams<Params>>;
  readonly match: (path: string) => RawParams<Params> | undefined;
}

function parsePattern<Params extends {}>(pattern: string): ParsedPattern<Params> {
  const tokens = pathToRegexp.parse(pattern);
  const keys = tokens.filter(token => typeof token !== "string") as pathToRegexp.Key[];
  const keyNames = keys.map(({ name }) => name as keyof Params);
  const regexp = pathToRegexp.tokensToRegExp(tokens);
  const build = pathToRegexp.tokensToFunction<RawParams<Params>>(tokens);

  return { tokens, keys, keyNames, regexp, match, build };

  function match(path: string) {
    const matched = regexp.exec(path);

    if (matched) {
      return keys.reduce((params, key, index) => {
        const name = key.name as keyof Params;
        const value = matched[index + 1];

        if (key.repeat) {
          params[name] = value === undefined ? [] : value.split(key.delimiter);
        } else {
          params[name] = value;
        }

        return params;
      }, {} as RawParams<Params>);
    }

    return undefined;
  }
}

function stringifyValue(value: any, description: yup.SchemaDescription): string | string[] | undefined {
  if (value === undefined) {
    return undefined;
  }

  const { type } = description;

  switch (type) {
    case "string":
    case "number":
    case "boolean": {
      return value.toString();
    }
    case "date": {
      return value.toISOString();
    }
    case "array": {
      const inner: yup.SchemaDescription = (description as any).innerType;
      return value.map((item: any) => stringifyValue(item, inner));
    }
    default:
      throw new Error(`unexpected type ${type} of ${JSON.stringify(value)}`);
  }
}
