import { observer } from "mobx-react-lite";
import React from "react";
import { Redirect, Route, Switch } from "react-router";

import { Layout } from "triangular/components/Layout/Layout";
import { ConditionalRoute } from "triangular/Routes/ConditionalRoute";
import { routes } from "triangular/Routes/routesConfiguration";
import { useStore } from "triangular/stores/StoreContext";
import { locationRouteProps } from "triangular/utils/routes/LocationRoute";

import { AccountCreatedPage } from "./AccountCreatedPage/AccountCreatedPage";
import { CompanyInfo } from "./CompanyInfo/CompanyInfo";
import { PersonalInfo } from "./PersonalInfo/PersonalInfo";
import { ProfileType } from "./ProfileType/ProfileType";
import css from "./Registration.module.scss";
import { RegistrationWrapper } from "./RegistrationWrapper/RegistrationWrapper";

export const Registration: React.FC = observer(() => {
  const { registrationStore } = useStore();

  return (
    <Layout className={{ main: css.main }}>
      <RegistrationWrapper>
        <Switch>
          {registrationStore.isSubmitted ? <AccountCreatedPage /> : null}
          <Route
            {...locationRouteProps(routes.registration.root, () => (
              <Redirect to={routes.registration.profileType.build()} />
            ))}
          />
          <Route {...locationRouteProps(routes.registration.profileType, ProfileType)} />
          {registrationStore.type && <Route {...locationRouteProps(routes.registration.personalInfo, PersonalInfo)} />}
          <ConditionalRoute
            condition={registrationStore.hasPersonalInfo}
            fallback={<Redirect to={routes.registration.personalInfo.build()} />}
            {...locationRouteProps(routes.registration.companyInfo, CompanyInfo)}
          />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </RegistrationWrapper>
    </Layout>
  );
});
