import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";

import { routes } from "triangular/Routes/routesConfiguration";
import { gaActions, GaCategories, googleAnalytics } from "triangular/services/googleAnalytics";
import { useStore } from "triangular/stores/StoreContext";
import { getErrorStatus, useCancellation } from "triangular/utils/api";

import { PersonalInfoForm, PersonalInfoFormProps } from "./PersonalInfoForm/PersonalInfoForm";

export const PersonalInfo: React.FC<RouteComponentProps> = observer(props => {
  const { registrationStore, snackbarStore } = useStore();
  const { cancelToken, cancel } = useCancellation();
  const { t } = useTranslation();

  useEffect(() => () => cancel(), [cancel]);

  const handleSubmit: PersonalInfoFormProps["onSubmit"] = async (values, actions) => {
    registrationStore.setState(values);
    if (registrationStore.type !== "expert") {
      googleAnalytics.sendEvent({
        category: GaCategories.registration,
        action: gaActions.registration.goToThirdStep,
        label: registrationStore.type
      });

      props.history.push(routes.registration.companyInfo.build());
    } else {
      try {
        await registrationStore.createAccount(cancelToken);

        googleAnalytics.sendEvent({
          category: GaCategories.registration,
          action: gaActions.registration.succeeded,
          label: registrationStore.type
        });
      } catch (error) {
        const errorStatus = getErrorStatus(error);
        if (errorStatus === 422) {
          actions.setFieldError("vatNumber", t("registration.companyInfo.invalidVatNumber"));
          snackbarStore.showValidationError();

          googleAnalytics.sendEvent({
            category: GaCategories.registration,
            action: gaActions.registration.failed,
            label: "Invalid VAT"
          });
        } else {
          snackbarStore.showGenericError(error);

          googleAnalytics.sendEvent({
            category: GaCategories.registration,
            action: gaActions.registration.failed
          });
        }
      }
    }
  };

  const handleGoBack = (e: React.MouseEvent) => {
    e.preventDefault();
    props.history.push(routes.registration.profileType.build());
  };

  return <PersonalInfoForm onSubmit={handleSubmit} onGoBack={handleGoBack} />;
});
