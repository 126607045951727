import { AxiosInstance } from "axios";

import { Resource } from "../Resource/Resource";
import { Entity } from "../Resource/utils/types";

type SystemSettingsEntity = Entity<{
  contactMaterials: string[];
  nonContactMaterials: string[];
  abrasiveness: string[];
  explosiveness: string[];
  installedPowerKw: string[];
  materialIndexes: string[];
  materialCategories: string[];
  flowingProperties: string[];
  targetIndustries: string[];
  storages: string[];
}>;

export class SystemSettingsResource extends Resource<SystemSettingsEntity> {
  constructor(protected axios: AxiosInstance) {
    super(axios, {
      type: "system_settings",
      prefixes: []
    });
  }
}
