import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { FormSection } from "triangular/components/form/FormSection/FormSection";

import { DropDownHeader } from "../../DropDownHeader/DropDownHeader";
import { ExplosivenessField } from "../../system/ExplosivenessField/ExplosivenessField";
import { TextArea } from "../../TextArea/TextArea";

import { HazardousnessField } from "./HazardousnessField/HazardousnessField";
import { ProductNumberField } from "./ProductNumberField/ProductNumberField";

export const Safety = React.memo(() => {
  const { t } = useTranslation();
  const currentT = (path: string) => t(`materialForm.safety.${path}`);

  const indexNum = "2";
  const [activeKey, setActiveKey] = useState(indexNum);

  return (
    <Accordion>
      <FormSection>
        <DropDownHeader
          eventKeyNum={indexNum}
          handleClick={() => {
            if (activeKey === indexNum) {
              setActiveKey("");
            } else {
              setActiveKey(indexNum);
            }
          }}
        >
          {t("materialForm.safety.safety")}
        </DropDownHeader>
        <Accordion.Collapse eventKey={indexNum}>
          <FieldsRow>
            <FieldWrapper>
              <ProductNumberField label={currentT("indexNumber")} />
            </FieldWrapper>
            <FieldWrapper>
              <HazardousnessField label={currentT("hazardous")} />
            </FieldWrapper>
            <FieldWrapper>
              <ExplosivenessField label={currentT("explosiveness")} />
            </FieldWrapper>
            <FieldWrapper className="col-12">
              <TextArea name="safetyMeasures" label={currentT("safetyMeasures")} data-testid="safetyMeasures" />
            </FieldWrapper>
          </FieldsRow>
        </Accordion.Collapse>
      </FormSection>
    </Accordion>
  );
});
