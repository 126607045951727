import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { useStore } from "triangular/stores/StoreContext";

interface HazardousnessFieldProps {
  label: string;
}

export const HazardousnessField: React.FC<HazardousnessFieldProps> = observer(({ label }) => {
  const { materialsStore } = useStore();
  const { t } = useTranslation();
  const hazardousnessSettings = materialsStore.getSettings().hazardousness;

  const hazardousnessOptions = hazardousnessSettings.map(eachOption => ({
    value: eachOption,
    label: eachOption === "no" ? t("no") : t(`materialSettings.hazardousnessOptions.${eachOption}`),
    key: eachOption
  }));

  return (
    <SelectField
      name="hazardousness"
      label={label}
      selectProps={{
        options: hazardousnessOptions
      }}
      data-testid="hazardousness"
    />
  );
});
