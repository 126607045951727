import { observer } from "mobx-react-lite";
import React from "react";
import { RouteComponentProps } from "react-router";

import { Breakpoint } from "triangular/components/Breakpoint/Breakpoint";
import { Carousel } from "triangular/components/Carousel/Carousel";
import { gaActions, GaCategories, googleAnalytics } from "triangular/services/googleAnalytics";
import { useStore } from "triangular/stores/StoreContext";

import css from "./ProfileType.module.scss";
import { ProfileTypeOption } from "./ProfileTypeOption/ProfileTypeOption";

const profileTypeOptions = ["systemOwner" as const, "materialOwner" as const, "expert" as const, "master" as const];

export type RegistrationProfileType = ArrayElement<typeof profileTypeOptions>;

export const ProfileType: React.FC<RouteComponentProps> = observer(props => {
  const { registrationStore } = useStore();

  googleAnalytics.useEventOnMount({
    category: GaCategories.registration,
    action: gaActions.registration.firstStep
  });

  const profileTypeOptionElements = profileTypeOptions.map((type, index) => (
    <ProfileTypeOption key={index} type={type} />
  ));

  const Desktop = () => (
    <div className={css.wrapper} data-testid="profileTypes">
      {profileTypeOptionElements}
    </div>
  );

  const Mobile = () => {
    const currentProfileTypeIndex = profileTypeOptions.findIndex(type => type === registrationStore.type);
    const startIndex = currentProfileTypeIndex === -1 ? 0 : currentProfileTypeIndex;

    return (
      <div className={css.carouselWrapper} data-testid="carousel">
        <Carousel startIndex={startIndex} items={profileTypeOptionElements} />
      </div>
    );
  };

  return (
    <div className={css.wrapper}>
      <Breakpoint minWidth="medium">
        <Desktop />
      </Breakpoint>

      <Breakpoint minWidth="small" maxWidth="small">
        <Mobile />
      </Breakpoint>
    </div>
  );
});
