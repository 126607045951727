import React from "react";
import { useTranslation } from "react-i18next";
import useRouter from "use-react-router";

import { LabeledSelect } from "triangular/components/LabeledSelect/LabeledSelect";
import { useMaterialCategoriesOptions } from "triangular/utils/systems";

import { useExpertsPaginationContext } from "../utils/pagination";
import { updateMaterialCategoryIds } from "../utils/updates";

export const MaterialCategories: React.FC = () => {
  const { paginationQuery, buildUrl } = useExpertsPaginationContext();
  const { t } = useTranslation();
  const { history } = useRouter();

  const selectedIndustryIds = paginationQuery.industryIds || [];

  const materialCategories = paginationQuery.materialCategoryIds || [];

  const options = useMaterialCategoriesOptions(selectedIndustryIds);

  if (options.length === 0) {
    return null;
  }

  const handleChange = (selectedValue: string | string[]) =>
    history.push(buildUrl(() => updateMaterialCategoryIds(selectedValue as string[])));

  return (
    <LabeledSelect
      label={t("materialCategories")}
      value={materialCategories}
      options={options}
      onChange={handleChange}
      autoComplete={true}
    />
  );
};
