import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

import { CheckboxField } from "triangular/components/form/CheckboxField/CheckboxField";
import { DefaultFormButtons } from "triangular/components/form/DefaultFormButtons";
import { FieldsRow } from "triangular/components/form/FieldsRow/FieldsRow";
import { FieldWrapper } from "triangular/components/form/FieldWrapper/FieldWrapper";
import { Form } from "triangular/components/form/Form";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { FileToUpload } from "triangular/components/form/system/SystemFilesField/FilesField/FilesField";
import { FileListItem } from "triangular/components/form/system/SystemFilesField/FilesList/FilesList";
import { SystemFilesField } from "triangular/components/form/system/SystemFilesField/SystemFilesField";
import { TextField } from "triangular/components/form/TextField";
import { YearMonthField } from "triangular/components/form/YearMonthField/YearMonthField";
import { RequiredField } from "triangular/components/RequiredField/RequiredField";
import { ExpertCertificateItem } from "triangular/stores/ExpertStore/ExpertStore";
import { useStore } from "triangular/stores/StoreContext";

import css from "./CertificateForm.module.scss";
import { certificateDefaultsValues, createCertificateSchema } from "./certificateSchema";

interface CertificateFormProps {
  onClose: () => void;
  certificate?: ExpertCertificateItem;
}

const getInitialValues = (certificate?: ExpertCertificateItem) => {
  if (certificate) {
    const { id, ...certificateFormValues } = certificate;
    return {
      ...certificateDefaultsValues,
      ...certificateFormValues,
      credentialNotExpire: !certificateFormValues.expirationDate
    };
  }

  return certificateDefaultsValues;
};

export const CertificateForm: React.FC<CertificateFormProps> = observer(({ certificate, onClose }) => {
  const { t } = useTranslation();
  const certificateSchema = createCertificateSchema(t);
  const currentT = (path: string) => t(`editExpert.certificateModal.${path}`);
  const { expertStore } = useStore();

  const initialValues = getInitialValues(certificate);

  const handleAddCertificateFile = async (
    filesToUpload: FileToUpload[],
    addFiles: (fileEntities: FileListItem[] | null) => void
  ) => {
    const responses = await Promise.all(
      filesToUpload.map(({ filename, data }) => expertStore.uploadCertificateFile(filename, data))
    );

    const addedCertificateFiles = responses.flat().map(({ id, filename }) => ({ id, filename }));
    addFiles(addedCertificateFiles);
  };

  const handleRemoveCertificateFile = (file: FileListItem) => expertStore.removeCertificateFile(file.id);

  return (
    <Form
      onSubmit={async ({ certificateFiles, credentialNotExpire, expirationDate, ...values }) => {
        const certificateBlueprint = {
          ...values,
          certificateFiles: certificateFiles ? certificateFiles.map(({ id }) => ({ id })) : null,
          expirationDate: expirationDate ? new Date(expirationDate).toISOString() : null,
          expert: {
            id: expertStore.currentExpert.id!
          }
        };

        if (certificate) {
          await expertStore.updateCertificate(certificate.id, certificateBlueprint);
        } else {
          await expertStore.createCertificate(certificateBlueprint);
        }

        onClose();
      }}
      initialValues={{
        ...certificateDefaultsValues,
        ...initialValues
      }}
      validationSchema={certificateSchema}
      enableReinitialize={true}
      render={({ values, setFieldValue }) => (
        <FormSection>
          <FieldsRow>
            <FieldWrapper>
              <TextField name="name" label={currentT("name")} data-testid="name" required={true} />
            </FieldWrapper>
            <FieldWrapper>
              <TextField
                name="organization"
                label={currentT("organization")}
                data-testid="organization"
                required={true}
              />
            </FieldWrapper>
            <div className={classNames("col-sm-12 col-md-6 col-lg-4", css.noLateralPadding)}>
              {values.expirationDate && (
                <FieldWrapper>
                  <YearMonthField
                    name="expirationDate"
                    label={currentT("expirationDate")}
                    data-testid="expirationDate"
                    required={true}
                  />
                </FieldWrapper>
              )}
              <div className={css.expirationDateCheckbox}>
                <FieldWrapper>
                  <CheckboxField
                    name="credentialNotExpire"
                    label={currentT("credentialNotExpire")}
                    onChange={nextValue => {
                      if (nextValue === false) {
                        setFieldValue("expirationDate", new Date().toISOString());
                      } else {
                        setFieldValue("expirationDate", null);
                      }
                    }}
                  />
                </FieldWrapper>
              </div>
            </div>
          </FieldsRow>
          <FieldsRow>
            <FieldWrapper>
              <TextField name="credentialId" label={currentT("credentialId")} data-testid="credentialId" />
            </FieldWrapper>
            <FieldWrapper className={classNames("col-sm-12 col-md-6 col-lg-8")}>
              <TextField name="credentialUrl" label={currentT("credentialUrl")} data-testid="credentialUrl" />
            </FieldWrapper>
          </FieldsRow>
          <SystemFilesField
            name="certificateFiles"
            label={currentT("certificateFiles")}
            onRemoveFile={handleRemoveCertificateFile}
            onAddFile={handleAddCertificateFile}
            fileType="document"
            data-testid="certificateFiles"
          />
          <RequiredField />
          <DefaultFormButtons
            confirmPromptTitle={currentT("certificateSubmitConfirmationHeader")}
            confirmPromptMessage={currentT("certificateSubmitConfirmationMessage")}
            onGoBack={onClose}
          />
        </FormSection>
      )}
    />
  );
});
