import React, { useRef } from "react";
import { v4 as uuid } from "uuid";

import { TooltipMessage } from "./TooltipMessage";

interface WithTooltipProps {
  className?: string;
  delayHide?: number;
  tooltipClassName?: {
    content?: string;
    wrapper?: string;
  };
  WrapperComponent?: React.ComponentType<unknown> | "div" | "span";
  tooltipMessage: React.ReactNode;
}

export const WithTooltip: React.FC<WithTooltipProps> = ({
  children,
  WrapperComponent = "span",
  delayHide,
  tooltipClassName,
  className,
  tooltipMessage
}) => {
  const idRef = useRef(uuid());

  return (
    <>
      <WrapperComponent data-tip="true" data-for={idRef.current} className={className}>
        {children}
      </WrapperComponent>
      <TooltipMessage id={idRef.current} delayHide={delayHide} className={tooltipClassName}>
        {tooltipMessage}
      </TooltipMessage>
    </>
  );
};
