import React from "react";

import { SystemModelSnapshot } from "triangular/stores/SystemsStore/systemModel";

import { MaterialBadges } from "./MaterialBadges";

export const MaterialBlacklist: React.FC<{ materialsBlacklist: SystemModelSnapshot["materialsBlacklist"] }> = ({
  materialsBlacklist
}) => {
  const materials = materialsBlacklist
    ? materialsBlacklist
        .map((materialName, id) => ({
          id,
          name: materialName
        }))
        .slice(0, 3)
    : [];

  return <MaterialBadges materials={materials} variant="mushroom" />;
};
