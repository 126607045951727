import { AxiosInstance } from "axios";

import { Resource } from "../Resource/Resource";
import { Entity } from "../Resource/utils/types";

export type OwnerEntity = Entity<
  {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    company?: {
      countryCode: string;
      name: string;
      number: string;
    };
  },
  {}
>;

export class OwnerResource extends Resource<OwnerEntity> {
  constructor(protected axios: AxiosInstance, type: ProfileType) {
    super(axios, {
      type,
      relationships: {}
    });
  }
}
