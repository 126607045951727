import { FormikProps } from "formik";
import { TFunction } from "i18next";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { AgreementCheckbox } from "triangular/components/AgreementCheckbox/AgreementCheckbox";
import { Button } from "triangular/components/Button/Button";
import { RequiredAnnotation } from "triangular/components/form/AbstractField/AbstractField";
import { Form, SubmitForm } from "triangular/components/form/Form";
import { SelectField } from "triangular/components/form/SelectField/SelectField";
import { TextField } from "triangular/components/form/TextField";
import { PASSWORD_MIN_CHARACTERS } from "triangular/components/form/validators/passwordValidator";
import { phoneNumberValidator } from "triangular/components/form/validators/phoneNumberValidator";
import { requiredString } from "triangular/components/form/validators/requiredString";
import { taxIdValidator } from "triangular/components/form/validators/taxIdValidator";
import { HelpIconTooltip } from "triangular/components/HelpIconTooltip/HelpIconTooltip";
import { Typography } from "triangular/components/Typography/Typography";
import { countries, countriesSelectOptions } from "triangular/consts";
import { RegistrationStoreModel } from "triangular/stores/RegistrationStore";
import { useStore } from "triangular/stores/StoreContext";

import { TaxIdField } from "../../CompanyInfo/CompanyInfoForm/CompanyInfoForm";

import css from "./PersonalInfoForm.module.scss";

export interface PersonalInfoFormValues
  extends Omit<RegistrationStoreModel, "type" | "country" | "company" | "isSubmitted"> {
  agreement: boolean;
}

export interface PersonalInfoFormProps {
  onSubmit: SubmitForm<PersonalInfoFormValues>;
  onGoBack: (event: React.MouseEvent) => void;
}

export const passwordTooltip = (t: TFunction) => (
  <HelpIconTooltip>
    {t("registration.personalInfo.passwordTip", { minCharacters: PASSWORD_MIN_CHARACTERS })}
  </HelpIconTooltip>
);

const getPrefix = (countryName: string) => {
  const country = countries.find(c => c.code === countryName);
  return country ? country.dialCode : "";
};

export const PersonalInfoForm: React.FC<PersonalInfoFormProps> = observer(({ onSubmit, onGoBack }) => {
  const { t } = useTranslation();
  const { registrationStore } = useStore();
  const isExpert = registrationStore.type === "expert";

  const handleSelectCountry = (form: FormikProps<any>, selectedValue: string) => {
    form.setFieldValue("prefixNumber", getPrefix(selectedValue));
    form.setFieldTouched("prefixNumber");
  };

  const countryField = (
    <div className={css.field}>
      <SelectField
        data-testid="countryCode"
        className={{ wrapper: css.countryField }}
        name="countryCode"
        label={t("registration.personalInfo.country")}
        placeholder={t("selectField.placeholder")}
        onChangeCallback={handleSelectCountry}
        selectProps={{
          options: countriesSelectOptions,
          autoComplete: true
        }}
        required={true}
      />
    </div>
  );

  const schema = {
    firstName: requiredString(t).nullable(false),
    lastName: requiredString(t).nullable(false),
    countryCode: requiredString(t).nullable(false),
    prefixNumber: Yup.string(),
    email: Yup.string()
      .email(t("validation.email"))
      .required(t("validation.required")),
    phoneNumber: phoneNumberValidator(t).required(),
    vatNumber: isExpert ? taxIdValidator["experts"](t) : Yup.mixed().nullable(),
    agreement: isExpert ? Yup.boolean().oneOf([true], t("validation.terms")) : Yup.mixed().nullable()
  };

  return (
    <Form
      isInitialValid={true}
      initialValues={{
        firstName: registrationStore.firstName,
        lastName: registrationStore.lastName,
        countryCode: registrationStore.countryCode || "",
        email: registrationStore.email,
        prefixNumber: registrationStore.prefixNumber,
        phoneNumber: registrationStore.phoneNumber,
        vatNumber: registrationStore.vatNumber,
        agreement: false
      }}
      validationSchema={Yup.object(schema)}
      render={props => {
        const { isSubmitting, values } = props;
        const { countryCode: country } = values;

        return (
          <div className={css.wrapper}>
            <Typography component="h2" size="big" color="mint" upperCase={true} bold={true}>
              {t("registration.personalInfo.personalInfo")}
            </Typography>
            <div className={css.fieldsWrapper}>
              <TextField
                data-testid="firstName"
                autoFocus={true}
                required={true}
                name="firstName"
                label={t("registration.personalInfo.firstName")}
                className={{ wrapper: css.field }}
              />

              <TextField
                required={true}
                data-testid="lastName"
                name="lastName"
                label={t("registration.personalInfo.lastName")}
                className={{ wrapper: css.field }}
              />

              <TextField
                required={true}
                data-testid="email"
                name="email"
                type="email"
                label={t("email")}
                className={{ wrapper: css.field }}
              />

              <div className={css.wideField}>{countryField}</div>

              {isExpert ? (
                <TaxIdField countryCode={country} t={t} name="vatNumber" className={{ wrapper: css.field }} />
              ) : null}

              <div className={css.wideField}>
                <div className={css.field}>
                  <div className={css.phoneLabel}>
                    {t("registration.personalInfo.phoneNumber")} <RequiredAnnotation t={t} name="phoneNumber" />
                  </div>
                  <div className={css.phoneWrapper}>
                    <TextField
                      name="prefixNumber"
                      data-testid="prefixNumber"
                      placeholder="+00"
                      className={{ wrapper: css.prefix }}
                    />
                    <TextField
                      data-testid="phoneNumber"
                      required={true}
                      name="phoneNumber"
                      className={{ wrapper: css.phone }}
                    />
                  </div>
                </div>
              </div>
            </div>

            {isExpert && <AgreementCheckbox />}

            <Typography size="small" verticalSpacing={isExpert ? "large" : "small"} component="div">
              {t("requiredField")}
            </Typography>

            <div className={css.buttonsWrapper}>
              <Button
                transparent={true}
                type="button"
                disabled={isSubmitting}
                narrow={true}
                className={css.backButton}
                onClick={onGoBack}
              >
                {t("defaultFormButtons.back")}
              </Button>

              <Button type="submit" disabled={isSubmitting} showLoader={isSubmitting} data-testid="submit">
                {isExpert ? t("registration.personalInfo.createAccount") : t("registration.personalInfo.next")}
              </Button>
            </div>
          </div>
        );
      }}
      onSubmit={onSubmit}
    />
  );
});
