import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DropDownHeader } from "triangular/components/form/DropDownHeader/DropDownHeader";
import { FormSection } from "triangular/components/form/FormSection/FormSection";
import { useModal } from "triangular/components/Modal/Modal";
import { ExpertCertificateItem } from "triangular/stores/ExpertStore/ExpertStore";
import { useStore } from "triangular/stores/StoreContext";
import { formatDate } from "triangular/utils/dates";

import css from "../ExpertForm.module.scss";
import { ExpertItem } from "../ExpertItem/ExpertItem";
import { ExpertItemList } from "../ExpertItemList/ExpertItemList";

import { CertificateModal } from "./CertificateModal";

export const Certificates: React.FC = observer(() => {
  const { t } = useTranslation();
  const certificateModal = useModal();
  const [certificateId, setCertificateId] = useState<string | null>(null);
  const { expertStore } = useStore();
  const isFormDisabled = expertStore.isConfirmed();
  const expertCertificates: ExpertCertificateItem[] = expertStore.currentExpert.certificates || [];

  const indexNum = "3";
  const [activeKey, setActiveKey] = useState(indexNum);

  const handleCertificateModalClose = () => {
    certificateModal.close();
    setCertificateId(null);
  };

  return (
    <Accordion>
      <CertificateModal
        isOpen={certificateModal.isOpen}
        onClose={handleCertificateModalClose}
        certificateId={certificateId}
      />
      <FormSection className={css.spacing}>
        <DropDownHeader
          eventKeyNum={indexNum}
          handleClick={() => {
            if (activeKey === indexNum) {
              setActiveKey("");
            } else {
              setActiveKey(indexNum);
            }
          }}
        >
          {t("editExpert.certificates")}
        </DropDownHeader>
        <Accordion.Collapse eventKey={indexNum}>
          <ExpertItemList
            header={t("expertDetails.addCertificate")}
            onAdd={certificateModal.open}
            disabled={isFormDisabled}
          >
            {expertCertificates.map(({ id, name, organization, expirationDate, credentialId }) => {
              const spreadExpirationDate = expirationDate
                ? [
                    {
                      icon: "Hourglass" as const,
                      detail: formatDate(expirationDate, false)
                    }
                  ]
                : [];

              return (
                <ExpertItem
                  key={id}
                  title={name}
                  details={[
                    {
                      icon: "Job",
                      detail: organization
                    },
                    ...spreadExpirationDate,
                    {
                      icon: "Id",
                      detail: credentialId
                    }
                  ]}
                  onEdit={() => {
                    setCertificateId(id);
                    certificateModal.open();
                  }}
                  onRemove={() => expertStore.removeCertificate(id)}
                  removePromptHeader={t("editExpert.removeCertificatePromptHeader")}
                  removePromptMessage={t("editExpert.removeCertificatePromptMessage")}
                  disabled={isFormDisabled}
                />
              );
            })}
          </ExpertItemList>
        </Accordion.Collapse>
      </FormSection>
    </Accordion>
  );
});
