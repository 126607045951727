import { isArray } from "util";

import { isFunction, merge, mergeWith } from "lodash";
import { ModelInstanceType, SnapshotIn } from "mobx-state-tree";

type CallbackOrSnapshot<State, Snapshot> = ((param: State) => Snapshot) | Snapshot;

function mergeState(...params: Parameters<typeof merge>) {
  const [target, source] = params;
  return mergeWith(target, source, (__, srcValue) => {
    if (isArray(srcValue)) {
      return srcValue;
    }
  });
}

function createStateApplier<State extends ModelInstanceType<any, any>>(
  state: State,
  apply: (prevState: State, nextState: State) => State
) {
  return (callbackOrSnapshot: CallbackOrSnapshot<State, DeepPartial<SnapshotIn<State>>>) => {
    if (isFunction(callbackOrSnapshot)) {
      apply(state, callbackOrSnapshot(state));
    } else {
      apply(state, callbackOrSnapshot);
    }
  };
}

export function createStateAssigner<State extends ModelInstanceType<any, any>>(state: State) {
  return createStateApplier<State>(state, Object.assign);
}

export function createStateMerger<State extends ModelInstanceType<any, any>>(state: State) {
  return createStateApplier<State>(state, mergeState);
}
