import classNames from "classnames";
import noop from "lodash/noop";
import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as AddIcon } from "triangular/static/images/button-add.svg";
import { ReactComponent as CopyIcon } from "triangular/static/images/copy.svg";
import { ReactComponent as EditIcon } from "triangular/static/images/pencil.svg";
import { ReactComponent as SearchIcon } from "triangular/static/images/search.svg";
import { ReactComponent as TrashIcon } from "triangular/static/images/trash.svg";

import { WithTooltip } from "../WithTooltip/WithTooltip";

import css from "./IconButton.module.scss";

type ButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

type IconVariant = "edit" | "copy" | "trash" | "search" | "add";

type SvgIcon = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

export interface IconButtonProps {
  className?: string;
  iconClassName?: string;
  icon: IconVariant;
  iconSize?: number;
  tooltipMessage?: string;
  type?: "button" | "submit";
  disabled?: boolean;
  onClick?: ButtonProps["onClick"];
  testId?: string;
}

const icons: { [Key in IconVariant]: SvgIcon } = {
  edit: EditIcon,
  copy: CopyIcon,
  trash: TrashIcon,
  search: SearchIcon,
  add: AddIcon
};

export const IconButton = ({
  iconClassName,
  className,
  type = "button",
  icon,
  tooltipMessage,
  disabled,
  onClick,
  testId,
  iconSize = 16
}: IconButtonProps) => {
  const { t, i18n } = useTranslation();

  const Icon = icons[icon];

  const tooltipTranslationPath = `tooltips.${icon}Icon`;
  const defaultTooltipMessage = i18n.exists(tooltipTranslationPath) ? t(tooltipTranslationPath) : null;
  const appliedTooltipMessage = tooltipMessage || defaultTooltipMessage;

  const Wrapper = appliedTooltipMessage
    ? ({ children }: { children: React.ReactNode }) => (
        <WithTooltip WrapperComponent="div" tooltipMessage={appliedTooltipMessage}>
          {children}
        </WithTooltip>
      )
    : "div";

  return (
    <Wrapper>
      <button
        className={classNames(css.button, { [css.disabled]: disabled }, className)}
        type={type}
        onClick={disabled ? noop : onClick}
        data-testid={testId}
      >
        <Icon className={iconClassName} width={iconSize} height={iconSize} />
      </button>
    </Wrapper>
  );
};
