import throttle from "lodash/throttle";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { PageLoader } from "triangular/components/PageLoader/PageLoader";

import css from "./FloatingLoader.module.scss";

export const FloatingLoader: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState(0);
  const topOffset = 100;
  const throttleDelayMs = 60;

  const getParentBoundingRectProperty = useCallback((property: "top" | "bottom" | "height") => {
    const parentBoundingRect =
      ref.current && ref.current.parentElement ? ref.current.parentElement.getBoundingClientRect() : null;
    return parentBoundingRect ? parentBoundingRect[property] : 0;
  }, []);

  const calculateTop = useCallback(() => {
    const parentPositionTop = getParentBoundingRectProperty("top");
    const transformedTopToView = parentPositionTop < 0 ? -parentPositionTop + topOffset : topOffset;
    const scrolledBelowParent = -(getParentBoundingRectProperty("height") - window.scrollY);
    const isScrolledBelowParent = scrolledBelowParent > 0;

    setTop(isScrolledBelowParent ? transformedTopToView - scrolledBelowParent : transformedTopToView);
  }, [getParentBoundingRectProperty]);

  useEffect(() => {
    calculateTop();
    const throttledCalculateTop = throttle(calculateTop, throttleDelayMs);
    window.addEventListener("scroll", throttledCalculateTop);
    return () => window.removeEventListener("scroll", throttledCalculateTop);
  }, [calculateTop]);

  return (
    <>
      <div
        ref={ref}
        className={css.loaderWrapper}
        style={{
          top,
          opacity: 1
        }}
      >
        <PageLoader />
      </div>
    </>
  );
};
