import classNames from "classnames";
import React from "react";

import { AbstractField } from "triangular/components/form/AbstractField/AbstractField";
import { SelectProps } from "triangular/components/Select/Select";

import { AbstractFieldProps } from "../AbstractField/AbstractField";
import { SelectField, SelectFieldProps } from "../SelectField/SelectField";

import css from "./SelectableField.module.scss";

export interface SelectableFieldProps extends AbstractFieldProps {
  name: string;
  className?: {
    wrapper?: string;
    label?: string;
  };
  selectFieldProps?: Partial<SelectFieldProps<string>>;
  label?: React.ReactNode;
  options: Array<
    ArrayElement<SelectProps<any>["options"]> & {
      reactNode: React.ReactNode;
    }
  >;
  defaultOption?: any;
  disabled?: boolean;
  required?: boolean;
  "data-testid"?: string;
}

export const SelectableField: React.FC<SelectableFieldProps> = ({
  name,
  selectFieldProps = {},
  label,
  options,
  defaultOption,
  ...props
}) => {
  const {
    className: selectFieldClassName = {},
    selectProps,
    onChangeCallback,
    ...restSelectFieldProps
  } = selectFieldProps;
  const { wrapper: selectWrapper, input: selectInput, ...selectFieldClassNameRest } = selectFieldClassName;
  return (
    <AbstractField
      name={name}
      label={label}
      className={{
        wrapper: css.wrapper,
        errorContainer: css.errorContainer
      }}
      render={({ form: { errors, touched, setFieldValue }, field }) => {
        const currentField = options.find(({ value }) => value === field.value);
        return (
          <div className={css.selectableField}>
            <SelectField
              name={name}
              className={{
                wrapper: classNames(css.selectContainer, selectWrapper, {
                  [css.selectContainerSelected]: currentField && currentField.value
                }),
                inputContainer: classNames(css.selectInput, selectInput),
                ...selectFieldClassNameRest
              }}
              selectProps={{
                options,
                ...selectProps
              }}
              onChangeCallback={(form, value) => {
                if (onChangeCallback) {
                  onChangeCallback(form, value);
                }

                setFieldValue(field.name, value);
              }}
              renderError={() => null}
              customError={errors[field.name] && touched[field.name] ? " " : null}
              {...restSelectFieldProps}
            />
            {currentField ? currentField.reactNode : null}
          </div>
        );
      }}
      {...props}
    />
  );
};
