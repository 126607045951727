import React from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "triangular/components/Typography/Typography";
import { useStore } from "triangular/stores/StoreContext";

import css from "./AccountCreatedPage.module.scss";

export const AccountCreatedPage: React.FC = () => {
  const { t } = useTranslation();
  const { registrationStore } = useStore();

  return (
    <div className={css.wrapper}>
      <Typography size="large" component="h2" data-testid="accountCreatedTitle">
        {t("registration.accountCreatedModal.title")}
      </Typography>
      <Typography size="big" verticalSpacing="large" component="p">
        {t("registration.accountCreatedModal.message", { email: registrationStore.email })}
        <br />
        {t("registration.accountCreatedModal.passwordComment")}
      </Typography>
    </div>
  );
};
