import classNames from "classnames";
import React from "react";

import { Typography } from "triangular/components/Typography/Typography";

import css from "./PageTitle.module.scss";

interface PageTitleProps {
  title?: string;
  description?: string;
  "data-testid"?: string;
  className?: {
    description?: string;
    title?: string;
  };
}

export const PageTitle: React.FC<PageTitleProps> = ({ title, description, "data-testid": testId, className = {} }) => {
  return (
    <div className={classNames(css.pageTitle, className.title)} data-testid={testId}>
      <Typography size="large" bold={true} component="h2">
        {title}
      </Typography>
      <Typography
        className={classNames(css.description, className.description)}
        size="big"
        bold={true}
        center={true}
        verticalSpacing="big"
      >
        {description}
      </Typography>
    </div>
  );
};
