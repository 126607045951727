import { types } from "mobx-state-tree";

import { relationshipModel } from "../utils/relationshipModel";
import { settingItemModel } from "../utils/settingItemModel";

export const systemModel = types.model("System", {
  id: types.string,
  name: types.maybeNull(types.string),
  brand: types.maybeNull(types.string),
  model: types.maybeNull(types.string),
  countryCode: types.maybeNull(types.string),
  installedPower: types.maybeNull(
    types.maybeNull(
      types.model({
        kw: types.maybeNull(types.string)
      })
    )
  ),
  installedPowerMill: types.maybeNull(
    types.maybeNull(
      types.model({
        bar: types.maybeNull(types.string),
        "m3/h": types.maybeNull(types.string)
      })
    )
  ),
  installedPowerClassifierKw: types.maybeNull(types.string),
  contactMaterials: types.maybeNull(types.array(types.string)),
  nonContactMaterials: types.maybeNull(types.array(types.string)),
  materialsWhitelist: types.maybeNull(types.array(types.string)),
  materialsBlacklist: types.maybeNull(types.array(types.string)),
  colorsWhitelist: types.maybeNull(types.array(types.string)),
  abrasiveness: types.maybeNull(types.string),
  corrosiveness: types.maybeNull(types.string),
  explosiveness: types.maybeNull(types.string),
  hazardousness: types.maybeNull(types.boolean),
  safetyMeasures: types.maybeNull(types.string),
  additionalInfo: types.maybeNull(types.string),
  state: types.maybeNull(types.string),
  materialCategories: types.maybeNull(types.array(types.string)),
  airflow: types.maybeNull(types.string),
  systemCategory: types.maybeNull(relationshipModel),
  systemType: types.maybeNull(relationshipModel),
  designTypes: types.array(relationshipModel),
  industries: types.maybeNull(types.array(settingItemModel))
});

export type SystemModelSnapshot = typeof systemModel["SnapshotType"];
